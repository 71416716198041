import { t, Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import { useEffect, useMemo } from "react";
import { MenuList, MenuListItemVariant } from "@WahooFitness/redesignr";

const DeleteAccount = () => {
  const { setNavHeader } = useHeaderContext();
  const headerProps = useMemo(() => ({ title: t`Delete account` }), []);
  useEffect(() => {
    setNavHeader(headerProps);
  });

  return (
    <Box display="flex" flexDirection="column" overflow="scroll" gap={2} alignItems="center" p={2}>
      <Box height="100%" width="100%" display="flex" flexDirection="column" gap={2}>
        <Typography variant="prose-sm" color="text.secondary">
          <Trans>
            After your account is deleted, you will not be able to recover the information
            associated with this account, including your workout history.
          </Trans>
        </Typography>
        <Typography variant="prose-sm" color="text.secondary">
          <Trans>
            If you have purchased a Wahoo X subscription through in-app purchases, you will need to
            cancel your subscription through the App Store. If you purchased a Wahoo X subscription
            through Wahoo, deleting your account will cancel your subscription.
          </Trans>
        </Typography>
        <Box pt={1}>
          <MenuList
            listItems={[
              {
                id: "deleteAccount",
                content: t`Delete my Wahoo account`,
                variant: MenuListItemVariant.ExternalLink,
                linkLocation: "https://wahoosystm.com/DeleteAccount",
                linkColor: "error",
              },
            ]}
            disableGutters
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteAccount;
