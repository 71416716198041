import { useEffect, useState } from "react";

const useCurrentTime = (refreshMillis: number) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, refreshMillis);

    return () => {
      clearInterval(interval);
    };
  }, [refreshMillis]);

  return currentTime;
};

export default useCurrentTime;
