import { Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import { MenuList, MenuListItemType } from "@WahooFitness/redesignr";

interface Props {
  averageTimeListItems: MenuListItemType[];
}

function AverageTimeQuestion({ averageTimeListItems }: Props) {
  return (
    <Box mx={1} display="flex" flexDirection="column">
      <Box mx={4} display="flex" flexDirection="column">
        <Box>
          <Typography variant="prose-base-medium">
            <Trans>Over the last 6 weeks my average, weekly exercise time was:</Trans>
          </Typography>
        </Box>
      </Box>
      <MenuList listItems={averageTimeListItems} />
    </Box>
  );
}

export default AverageTimeQuestion;
