import { Box, Typography } from "@mui/material";
import RouteCard from "./RouteCard";
import useRouteDetails from "../RouteDetails/useRouteDetails";
import { WSMRoute } from "@WahooFitness/wsm-native/dist/esm/types/route";

interface RouteGroupProps {
  routes: WSMRoute[];
  title?: string;
  fromPublicRouteList?: boolean;
}

const RouteGroup = ({ routes, title, fromPublicRouteList }: RouteGroupProps) => {
  const { getProviderIconImgUrl } = useRouteDetails();
  if (routes.length === 0) {
    return <></>;
  }
  return (
    <Box display="flex" flexDirection="column" rowGap={1} width="100%">
      {title && (
        <Typography variant="ui-base-medium" color="text.secondary">
          {title}
        </Typography>
      )}
      {routes.map((route) => (
        <RouteCard
          key={route.id}
          routeId={route.id}
          providerType={route.route_provider_type}
          providerId={route.provider_id}
          fitnessAppId={route.fitness_app_id}
          name={route.name}
          distance={route.distance}
          ascent={route.ascent}
          workoutTypeFamily={route.workout_type_family_id}
          routeFitUrl={route.file?.url}
          providerIconImgUrl={getProviderIconImgUrl(
            route.fitness_app_id,
            route.route_provider_type
          )}
          expiresAt={route.expires_at ? new Date(route.expires_at) : undefined}
          userId={route.user_id}
          fromPublicRouteList={fromPublicRouteList}
          token={route.token}
        />
      ))}
    </Box>
  );
};

export default RouteGroup;
