import ErrorBoundary from "@/components/ErrorBoundary";
import FullScreenDialog from "@/components/FullScreenDialog";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import { WorkoutProfileResponseType } from "@WahooFitness/cloud-client-ts/Types";
import { BlueSwitch, MenuList, MenuListItemVariant } from "@WahooFitness/redesignr";
import { CloseOutline, Menu } from "@carbon/icons-react";
import { Trans, t } from "@lingui/macro";
import { Button, IconButton, ListItem, Paper, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { FocusEvent, useCallback, useEffect, useMemo } from "react";
import useEditWorkoutPage from "./useEditWorkoutPage";
import useWorkoutPageCategoryStrings from "./useWorkoutPageCategoryStrings";
import useWorkoutPageFieldStrings from "./useWorkoutPageFieldStrings";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./StrictModeDroppable";
import { useNavigate } from "react-router";
import noop from "@/services/noop";
import { BoltDisplayField } from "@WahooFitness/crux-js-display-cfg";

const EditWorkoutPage = ({
  profile,
  pageId,
  elevation = false,
}: {
  profile?: WorkoutProfileResponseType;
  pageId?: number;
  elevation?: boolean;
}) => {
  const {
    pagesAreLoading,
    pageName,
    pageNameError,
    displayFields,
    staticDisplayFields,
    maxFields,
    boltMaxFields,
    fieldsCount,
    openCategoryDialog,
    closeCategoryDialog,
    changingPedalMonitorGraphType,
    removeField,
    categoryDialogOpen,
    setFieldToReplace,
    selectedCategories,
    setSelectedCategories,
    lastSelectedCategory,
    searchTerm,
    handleChangeSearchTerm,
    dialogMenuItems,
    pedalMonitorGraphTypeMenuItems,
    pageNameInputValue,
    handlePageNameChange,
    handlePageNameBlur,
    moveField,
    flushPendingSave,
    hasAssociatedBolt,
    hasAssociatedRoam,
    hasAssociatedAce,
    isMapPage,
    isPedalMonitorPage,
    showElevationProfile,
    toggleShowElevationProfile,
    pedalMonitorGraphType,
  } = useEditWorkoutPage(profile, pageId, elevation);
  const { setNavHeader } = useHeaderContext();
  const navigate = useNavigate();
  const headerProps = useMemo(
    () => ({
      title: pageName ?? t`Loading page data`,
      backAction: async () => {
        await flushPendingSave();
        navigate(-1);
      },
    }),
    [flushPendingSave, navigate, pageName]
  );

  useEffect(() => {
    setNavHeader(headerProps);
  });

  const { workoutPageCategoryTranslations } = useWorkoutPageCategoryStrings();

  const backAction = useCallback(() => {
    if (selectedCategories.length > 0) {
      setSelectedCategories((current) => {
        return current.slice(0, current.length - 1);
      });
    } else {
      closeCategoryDialog();
    }
  }, [selectedCategories.length, closeCategoryDialog, setSelectedCategories]);

  const highlightText = useCallback(
    (event: FocusEvent<HTMLInputElement>) => event.target.select(),
    []
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination) {
        moveField(result.source.index, result.destination.index);
      }
    },
    [moveField]
  );

  const showBoltFieldWarning = useMemo(
    () => hasAssociatedBolt && (hasAssociatedRoam || hasAssociatedAce),
    [hasAssociatedAce, hasAssociatedBolt, hasAssociatedRoam]
  );

  const editorDialogHeaderProps = useMemo(
    () => ({
      title: lastSelectedCategory
        ? workoutPageCategoryTranslations[lastSelectedCategory.getId()]
        : t`Select data field`,
      backAction,
    }),
    [backAction, lastSelectedCategory, workoutPageCategoryTranslations]
  );

  const mapPagePermanentFieldListItems = useMemo(
    () => [
      {
        id: "showElevationProfile",
        variant: MenuListItemVariant.Action,
        content: t`Show elevation profile`,
        action: toggleShowElevationProfile,
        actionComponent: <BlueSwitch checked={showElevationProfile} />,
      },
    ],
    [showElevationProfile, toggleShowElevationProfile]
  );

  return (
    <ErrorBoundary error={undefined} isLoading={pagesAreLoading}>
      <Box display="flex" flexDirection="column" height="100%" width="100%" gap={1} p={2}>
        <Typography variant="ui-base-medium" textAlign="center" px={2}>
          <Trans>Rank up to {maxFields} fields from most to least important.</Trans>
        </Typography>
        <Typography variant="prose-sm" textAlign="center" px={2}>
          <Trans>Tap to replace, drag to reorder.</Trans>
        </Typography>
        <Box width="100%" py={1}>
          <TextField
            value={pageNameInputValue}
            label={t`Page name`}
            variant="filled"
            size="medium"
            fullWidth
            onFocus={highlightText}
            onChange={handlePageNameChange}
            onBlur={handlePageNameBlur}
            helperText={pageNameError && <Typography color="error">{pageNameError}</Typography>}
          ></TextField>
        </Box>
        {isMapPage && <MenuList listItems={mapPagePermanentFieldListItems} disableGutters />}
        {isPedalMonitorPage && (
          <WorkoutPageField
            fieldType="pedalMonitorGraphType"
            fieldValue={pedalMonitorGraphType}
            index={0}
            isEditable={false}
            onFieldClick={() => openCategoryDialog(undefined, true)}
          />
        )}
        {showBoltFieldWarning && (
          <Typography variant="ui-base-medium" textAlign="center" px={2}>
            <Trans>{`Only the first ${boltMaxFields} fields will appear on BOLT`}</Trans>
          </Typography>
        )}
        {staticDisplayFields.map((field: BoltDisplayField, index: number) => {
          return (
            <WorkoutPageField
              key={field.getId().toString()}
              index={index}
              field={field}
              isEditable={false}
            />
          );
        }, [])}
        <DragDropContext onDragEnd={onDragEnd}>
          <StrictModeDroppable droppableId="fields">
            {(provided) => {
              return (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  display="flex"
                  flexDirection="column"
                  gap={1}
                >
                  {displayFields?.map((field: BoltDisplayField, index: number) =>
                    !staticDisplayFields.includes(field) ? (
                      <WorkoutPageField
                        key={field.getId().toString()}
                        field={field}
                        index={index}
                        isEditable={true}
                        onFieldClick={() => openCategoryDialog(field)}
                        onFieldRemove={() => removeField(field)}
                      />
                    ) : undefined
                  )}
                  {provided.placeholder}
                </Box>
              );
            }}
          </StrictModeDroppable>
        </DragDropContext>
        <Box p={2}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={() => {
              openCategoryDialog();
            }}
            disabled={fieldsCount >= maxFields}
          >{t`Add data field`}</Button>
        </Box>
      </Box>
      <FullScreenDialog
        slideDirection="left"
        open={categoryDialogOpen}
        handleClose={() => {
          setFieldToReplace(undefined);
          setSelectedCategories([]);
          closeCategoryDialog();
        }}
        headerProps={editorDialogHeaderProps}
      >
        <Box display="flex" flexDirection="column" gap={1} py={2}>
          {!lastSelectedCategory && !changingPedalMonitorGraphType && (
            <Box width="100%" px={2}>
              <TextField
                fullWidth
                label={t`Search`}
                size="medium"
                variant="standard"
                value={searchTerm}
                onChange={handleChangeSearchTerm}
              />
            </Box>
          )}
          <MenuList
            listItems={
              changingPedalMonitorGraphType ? pedalMonitorGraphTypeMenuItems : dialogMenuItems
            }
          />
        </Box>
      </FullScreenDialog>
    </ErrorBoundary>
  );
};

const WorkoutPageField = ({
  fieldType = "basic",
  field,
  fieldValue,
  index,
  isEditable,
  onFieldClick = noop,
  onFieldRemove = noop,
}: {
  fieldType?: "basic" | "pedalMonitorGraphType";
  field?: BoltDisplayField;
  fieldValue?: number;
  index: number;
  isEditable: boolean;
  onFieldClick?: () => void;
  onFieldRemove?: () => void;
}) => {
  const { workoutFieldTranslations, pedalMonitorGraphTypeFieldTranslations } =
    useWorkoutPageFieldStrings();
  const isBasicField = fieldType === "basic" && field !== undefined;
  const fieldName = useMemo(() => {
    if (isBasicField) {
      return workoutFieldTranslations[field.getId()];
    }
    return pedalMonitorGraphTypeFieldTranslations[fieldValue || 0];
  }, [
    field,
    fieldValue,
    isBasicField,
    pedalMonitorGraphTypeFieldTranslations,
    workoutFieldTranslations,
  ]);
  const fieldComponent = useMemo(
    () => (
      <ListItem
        key={isBasicField ? field.getId() : fieldType}
        component={Paper}
        disablePadding
        elevation={4}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width="100%"
          px={2}
          py={1}
          gap={3}
        >
          {isEditable && (
            <IconButton color="error" size="small" onClick={onFieldRemove}>
              <CloseOutline size="24px" />
            </IconButton>
          )}
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexGrow={1}
            onClick={onFieldClick}
          >
            <Typography>{fieldName}</Typography>
            {isEditable && <Menu size="24px" />}
          </Box>
        </Box>
      </ListItem>
    ),
    [field, fieldName, fieldType, isBasicField, isEditable, onFieldClick, onFieldRemove]
  );

  return isEditable ? (
    <Draggable draggableId={field?.getId().toString() || fieldType?.toString()} index={index}>
      {(provided) => {
        return (
          <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            {fieldComponent}
          </Box>
        );
      }}
    </Draggable>
  ) : (
    fieldComponent
  );
};

export default EditWorkoutPage;
