import { CSSProperties, useCallback, useEffect, useMemo } from "react";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Fab,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { ChevronLeft, Close } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";
import { useNativeMessagingContext } from "@WahooFitness/wahoo-offline-mfe";
import { Capacitor } from "@capacitor/core";

export interface Props {
  title: string;
  hasDivider?: boolean;
  headerAction?: React.ReactElement;
  backAction?: () => void;
  backText?: React.ReactElement | string;
  closeIcon?: boolean;
  maxTitleWidths?: Array<{ mediaQuery: string; maxWidth: string | number }>;
  "data-testid"?: string;
  isTransparent?: boolean;
  isFixed?: boolean;
  disableBoxShadow?: boolean;
  elevation?: number;
  disableBackButton?: boolean;
}

const BackButton = ({
  isTransparent,
  handleBackClick,
  closeIcon,
}: {
  isTransparent: boolean;
  handleBackClick: () => void;
  closeIcon?: boolean;
}) => {
  if (isTransparent) {
    return (
      <Fab size="small" color="primary" aria-label="close" onClick={handleBackClick}>
        <ChevronLeft size={24} />
      </Fab>
    );
  }
  return (
    <IconButton
      onClick={handleBackClick}
      size="small"
      aria-label="backButton"
      edge="start"
      data-testid="backButton"
      color="primary"
    >
      {closeIcon ? <Close size="24" /> : <ChevronLeft size={24} />}
    </IconButton>
  );
};

const NavHeader = ({
  title,
  hasDivider,
  headerAction,
  backAction,
  backText,
  closeIcon,
  maxTitleWidths = [],
  "data-testid": testId,
  isTransparent,
  isFixed,
  disableBoxShadow,
  elevation,
  disableBackButton,
}: Props) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { addBackPressListener, removeBackPressListener } = useNativeMessagingContext();

  const handleBackClick = useCallback(() => {
    const action = backAction || (() => navigate(-1));
    action();
  }, [backAction, navigate]);

  useEffect(() => {
    addBackPressListener(handleBackClick);
    return () => {
      removeBackPressListener();
    };
  }, [addBackPressListener, handleBackClick, removeBackPressListener]);

  const titleCss = useMemo(
    () =>
      // The maxTitleWidths array has the higher priority media queries first, but with CSS,
      // later entries override earlier ones, so the array needs to be reversed to preserve the correct priority
      maxTitleWidths.reverse().map(({ mediaQuery, maxWidth }) => ({
        [mediaQuery]: { maxWidth },
      })),
    [maxTitleWidths]
  );

  const containerStyle: CSSProperties = useMemo(() => {
    const style: CSSProperties = {};
    if (isFixed) {
      style.position = "fixed";
      style.top = 0;
      style.width = "100%";
    }
    if (isTransparent) {
      style.backgroundColor = "transparent";
    }
    return style;
  }, [isFixed, isTransparent]);

  const appBarStyle = useMemo(() => {
    const style: CSSProperties = {
      backgroundColor: palette.background.paper,
      borderRadius: 0,
    };
    if (isTransparent) {
      style.backgroundColor = "transparent";
      style.boxShadow = "none";
      style.backgroundImage = "unset";
    }
    if (disableBoxShadow) {
      style.boxShadow = "none";
    }
    return style;
  }, [disableBoxShadow, isTransparent, palette.background.paper]);

  return (
    <Box zIndex={2} data-testid={testId} sx={containerStyle}>
      <AppBar elevation={elevation ?? 4} position="relative" sx={appBarStyle}>
        <Toolbar
          variant={Capacitor.getPlatform() === "ios" ? "ios" : "regular"}
          sx={{ justifyContent: "space-between" }}
        >
          {disableBackButton ? (
            <Box />
          ) : backText ? (
            <Button
              onClick={handleBackClick}
              aria-label="backButton"
              variant="text"
              data-testid="backButton"
            >
              {backText}
            </Button>
          ) : (
            <BackButton
              isTransparent={isTransparent ?? false}
              closeIcon={closeIcon}
              handleBackClick={handleBackClick}
            ></BackButton>
          )}
          <Box
            position="absolute"
            left="50%"
            textAlign="center"
            sx={{
              transform: "translateX(-50%)",
            }}
          >
            <Typography
              noWrap
              variant="ui-md-medium"
              component="div"
              textAlign="center"
              overflow="hidden"
              textOverflow="ellipsis"
              sx={{
                display: "block",
              }}
              css={titleCss}
            >
              {title}
            </Typography>
          </Box>
          {headerAction}
        </Toolbar>
        {hasDivider && <Divider />}
      </AppBar>
    </Box>
  );
};

export default NavHeader;
