import { useTheme } from "@mui/material";
import { useMemo } from "react";

const useMapTheme = () => {
  const theme = useTheme();

  const mapTheme = useMemo(
    () => ({
      mapBoxStyle: `mapbox://styles/wahoofitness/${
        theme.palette.mode === "dark" ? "cl4fyhobg000014mobeare5gx" : "cl4fyecjv000015s7svqrat0r"
      }`,
      mapBoxSatelliteStyle: "mapbox://styles/mapbox/satellite-streets-v12",
      viewPadding: 40,
      defaultThreeDeePitch: 70,
      routes: {
        primary: {
          color: "#33B4FF",
          width: 7,
          outlineColor: "#000000",
          outlineWidth: 10,
        },
        selected: {
          color: "yellow",
          width: 6,
        },
      },
      markers: {
        start: {
          color: "green",
          rotation: 0,
        },
        end: {
          color: "red",
          rotation: 0,
        },
        selectPoint: {
          color: theme.palette.info.main,
          rotation: 0,
        },
        selectRouteStart: {
          color: "grey",
          rotation: 0,
        },
        selectRouteEnd: {
          color: "grey",
          rotation: 0,
        },
      },
    }),
    [theme.palette.mode, theme.palette.info.main]
  );

  return mapTheme;
};

export default useMapTheme;
