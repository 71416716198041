import { Typography } from "@mui/material";
import { Box } from "@mui/system";

type Props = {
  header: string;
  content: string;
  secondaryContent?: string;
};

const TextBoxWithHeader = ({ header, content, secondaryContent }: Props) => {
  return (
    <Box px={2} pt={1} pb={3} display="flex" flexDirection="column">
      <Box mb={2}>
        <Typography variant="ui-base-bold">{header}</Typography>
      </Box>
      <Typography variant="ui-base">{content}</Typography>
      <Typography variant="ui-base">{secondaryContent}</Typography>
    </Box>
  );
};

export default TextBoxWithHeader;
