import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";

const WarmingUp = ({
  noTopSpacing,
  fullHeight,
}: {
  noTopSpacing?: boolean;
  fullHeight?: boolean;
}) => {
  const defaultHeight = noTopSpacing ? "0" : "40dvh";
  const minHeight = fullHeight ? "100%" : defaultHeight;
  return (
    <Box
      display="flex"
      width="100%"
      minHeight={minHeight}
      alignItems="center"
      justifyContent="center"
      data-testid="inProgress"
    >
      <CircularProgress />
    </Box>
  );
};

export default WarmingUp;
