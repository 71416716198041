import usePairedDevice from "@/hooks/usePairedDevice";
import { useWsmPlugin } from "@/hooks/useWsmPlugin";
import { WSMBoltWifiNetwork } from "@WahooFitness/wsm-native/dist/esm/types/bolt_wifi";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import useSWR from "swr";

const useBCWifiConfig = () => {
  const { sensorManagerPlugin } = useWsmPlugin();
  const emptyWifiNetworks = useMemo(() => ({}), []);

  const { appToken = "" } = useParams();
  const { deviceLoading, isDeviceConnected } = usePairedDevice(appToken);

  const { data: initialWifiEnabled, isLoading: initialWifiEnabledIsLoading } = useSWR(
    () => appToken && ["bcIsBoltWifiAllowed", appToken],
    async () => (await sensorManagerPlugin?.bcIsBoltWifiAllowed({ appToken }))?.allowed
  );

  const [wifiEnabled, setWifiEnabled] = useState<boolean | undefined>(undefined);
  const [wifiScanStarted, setWifiScanStarted] = useState(false);

  useEffect(() => {
    setWifiEnabled(initialWifiEnabled);
  }, [initialWifiEnabled]);

  const { data: wifiNetworks, mutate: mutateWifiNetworks } = useSWR(
    () => wifiEnabled && wifiScanStarted && appToken && ["bcGetBoltWifiNetworks", appToken],
    async ([_, appToken]) => (await sensorManagerPlugin?.bcGetBoltWifiNetworks({ appToken })) || {},
    { fallbackData: emptyWifiNetworks }
  );

  useEffect(() => {
    if (wifiEnabled) {
      sensorManagerPlugin?.bcStartBoltWifiScan({ appToken });
      setWifiScanStarted(true);
      const interval = setInterval(() => {
        sensorManagerPlugin?.bcStartBoltWifiScan({ appToken });
      }, 10000);
      const listener = sensorManagerPlugin?.addListener(
        "boltWifiScanResult",
        ({ appToken: eventAppToken }) => {
          if (eventAppToken === appToken) {
            mutateWifiNetworks();
          }
        }
      );
      return () => {
        (async () => {
          clearInterval(interval);
          (await listener)?.remove();
        })();
      };
    }
  }, [appToken, mutateWifiNetworks, sensorManagerPlugin, wifiEnabled]);

  const cleanupWifiScan = useCallback(() => {
    sensorManagerPlugin?.bcStopBoltWifiScan({ appToken });
    mutateWifiNetworks(emptyWifiNetworks, { revalidate: false });
  }, [emptyWifiNetworks, mutateWifiNetworks, appToken, sensorManagerPlugin]);

  useEffect(() => {
    return () => {
      cleanupWifiScan();
    };
  }, [cleanupWifiScan, appToken, sensorManagerPlugin]);

  const { data: wifiStatus, mutate: mutateWifiStatus } = useSWR(
    () => appToken && ["wifi status", appToken],
    async ([_, appToken]) => {
      const status = await sensorManagerPlugin?.bcGetBoltWifiStatus({ appToken });
      status && setWifiEnabled(status.isAllowed);
      return status;
    }
  );

  useEffect(() => {
    const listener = sensorManagerPlugin?.addListener(
      "boltWifiStatusChangeEvent",
      ({ appToken: eventAppToken }) => {
        if (eventAppToken === appToken) {
          mutateWifiStatus();
        }
      }
    );
    return () => {
      (async () => (await listener)?.remove())();
    };
  }, [appToken, mutateWifiStatus, sensorManagerPlugin]);

  const refreshWifiStatus = useCallback(() => {
    sensorManagerPlugin?.bcRequestUpdatedWifiStatus({ appToken });
  }, [appToken, sensorManagerPlugin]);

  const toggleWifiEnabled = useCallback(() => {
    sensorManagerPlugin?.bcAllowBoltWifi({ appToken, allow: !wifiEnabled });
    if (wifiEnabled) {
      cleanupWifiScan();
    }
    setWifiEnabled(!wifiEnabled);
  }, [cleanupWifiScan, appToken, sensorManagerPlugin, wifiEnabled]);

  const connectHiddenWifi = useCallback(
    (ssid: string, password: string) => {
      sensorManagerPlugin?.bcConnectHiddenBoltWifi({
        appToken,
        ssid,
        password,
      });
    },
    [appToken, sensorManagerPlugin]
  );

  const connectWifi = useCallback(
    (wifiNetwork: WSMBoltWifiNetwork, password: string) => {
      sensorManagerPlugin?.bcConnectBoltWifi({ appToken, wifiNetwork, password });
    },
    [appToken, sensorManagerPlugin]
  );

  const forgetWifi = useCallback(
    (wifiNetwork: WSMBoltWifiNetwork) => {
      sensorManagerPlugin?.bcForgetBoltWifi({ appToken, wifiNetwork });
    },
    [appToken, sensorManagerPlugin]
  );

  return {
    wifiLoading: deviceLoading || initialWifiEnabledIsLoading,
    wifiEnabled,
    wifiNetworks,
    wifiStatus,
    refreshWifiStatus,
    toggleWifiEnabled,
    connectHiddenWifi,
    connectWifi,
    forgetWifi,
    isDeviceConnected: deviceLoading || isDeviceConnected,
  };
};

export default useBCWifiConfig;
