import DeviceInfo from "@/components/Elemnt/DeviceInfo";
import { useLocation } from "react-router";

const DeviceInfoPage = () => {
  const location = useLocation();
  const { firmwareVersion, serialNumber } = location.state ?? {
    firmwareVersion: "not set",
    serialNumber: "not set",
  };
  return <DeviceInfo firmwareVersion={firmwareVersion} serialNumber={serialNumber} />;
};

export default DeviceInfoPage;
