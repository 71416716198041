import { SensorManagerPluginPlugin as SensorManagerPlugin } from "@WahooFitness/wsm-native";
import WSMSensorProductType from "@WahooFitness/wsm-native/dist/esm/types/product_type";
import { Capacitor, registerPlugin } from "@capacitor/core";
import { publish } from "@ionic/portals";
import { PropsWithChildren, createContext, useCallback, useMemo, useState } from "react";

const wsmPluginLabel = "SensorManagerPlugin";

export type IWsmPluginProps = {
  sensorManagerPlugin?: SensorManagerPlugin;
  checkPairedDeviceTypes: () => void;
  hasElemntPaired: boolean;
  hasBoltPaired: boolean;
  hasRoamPaired: boolean;
  hasAcePaired: boolean;
};

export const WsmContext = createContext<IWsmPluginProps | undefined>(undefined);

export const WsmContextProvider = ({ children }: PropsWithChildren) => {
  const sensorManagerPlugin = useMemo(() => {
    if (!Capacitor.isPluginAvailable(wsmPluginLabel)) {
      publish({
        topic: "settingsError",
        data: JSON.stringify({ errorMessage: "WSM plugin not registered", stack: "" }),
      });
      return undefined;
    }
    return registerPlugin<SensorManagerPlugin>(wsmPluginLabel);
  }, []);
  const [hasBoltPaired, setHasBoltPaired] = useState(false);
  const [hasRoamPaired, setHasRoamPaired] = useState(false);
  const [hasAcePaired, setHasAcePaired] = useState(false);
  const checkPairedDeviceTypes = useCallback(async () => {
    const result = await sensorManagerPlugin?.getDevices();
    const devices = result?.devices;
    setHasBoltPaired(
      !!devices?.some((device) => device.productType === WSMSensorProductType.WAHOO_ELEMNT_BOLT)
    );
    setHasRoamPaired(
      !!devices?.some((device) => device.productType === WSMSensorProductType.WAHOO_ELEMNT_ROAM)
    );
    setHasAcePaired(
      !!devices?.some((device) => device.productType === WSMSensorProductType.WAHOO_ELEMNT_ACE)
    );
  }, [sensorManagerPlugin]);

  const hasElemntPaired = useMemo(
    () => hasBoltPaired || hasRoamPaired || hasAcePaired,
    [hasAcePaired, hasBoltPaired, hasRoamPaired]
  );

  return (
    <WsmContext.Provider
      value={{
        sensorManagerPlugin,
        checkPairedDeviceTypes,
        hasElemntPaired,
        hasBoltPaired,
        hasRoamPaired,
        hasAcePaired,
      }}
    >
      {children}
    </WsmContext.Provider>
  );
};
