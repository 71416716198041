import { t } from "@lingui/macro";
import { useMemo } from "react";
import { crux_bolt_display_category_e } from "@WahooFitness/crux-js-display-cfg";

const useWorkoutPageCategoryStrings = () => {
  const workoutPageCategoryTranslations = useMemo(
    () =>
      ({
        [crux_bolt_display_category_e.SPEED]: t`Speed`,
        [crux_bolt_display_category_e.DISTANCE]: t`Distance`,
        [crux_bolt_display_category_e.MAP]: t`Map`,
        [crux_bolt_display_category_e.TIME]: t`Time`,
        [crux_bolt_display_category_e.CLIMBING]: t`Climbing`,
        [crux_bolt_display_category_e.CLIMBING_GENERAL]: t`Climbing`,
        [crux_bolt_display_category_e.CLIMBING_SUMMIT]: t`Summit Segments Live`,
        [crux_bolt_display_category_e.WEATHER]: t`Weather`,
        [crux_bolt_display_category_e.CADENCE]: t`Cadence`,
        [crux_bolt_display_category_e.HEART_RATE]: t`Heart rate`,
        [crux_bolt_display_category_e.CALORIES]: t`Calories`,
        [crux_bolt_display_category_e.POWER]: t`Power`,
        [crux_bolt_display_category_e.TYRE_PRESSURE]: t`Tyre pressure`,
        [crux_bolt_display_category_e.POWER_GENERAL]: t`Power`,
        [crux_bolt_display_category_e.POWER_BALANCE]: t`Power Balance`,
        [crux_bolt_display_category_e.POWER_AVG_OVER_TIME]: t`Power Avg over time`,
        [crux_bolt_display_category_e.POWER_MAX_OVER_TIME]: t`Power Max over time`,
        [crux_bolt_display_category_e.POWER_TORQUE]: t`Power Torque`,
        [crux_bolt_display_category_e.POWER_ZONES]: t`Power Zones`,
        [crux_bolt_display_category_e.POWER_FTP]: t`Power FTP`,
        [crux_bolt_display_category_e.POWER_PEDALING]: t`Power Pedaling`,
        [crux_bolt_display_category_e.ROUTE_TARGET]: t`Route target`,
        [crux_bolt_display_category_e.KICKR]: t`KICKR`,
        [crux_bolt_display_category_e.GEAR_SELECTION]: t`Gear selection`,
        [crux_bolt_display_category_e.MUSCLE_OXYGEN]: t`Muscle oxygen`,
        [crux_bolt_display_category_e.BATTERY]: t`Battery`,
        [crux_bolt_display_category_e.WORKOUT]: t`Workout`,
        [crux_bolt_display_category_e.STRAVA]: t`Strava`,
        [crux_bolt_display_category_e.PLANNED_WORKOUTS]: t`Planned workouts`,
        [crux_bolt_display_category_e.SWIMMING]: t`Swimming`,
        [crux_bolt_display_category_e.LEV]: t`E-Bike`,
        [crux_bolt_display_category_e.MULTISPORT]: t`Multisport`,
        [crux_bolt_display_category_e.RUN_DYNAMICS]: t`Run Dynamics`,
        [crux_bolt_display_category_e.RUN_POWER_DYNAMICS]: t`Run Power and Dynamics`,
        [crux_bolt_display_category_e.CORE_BODY_TEMP]: t`Body Temperature`,
        [crux_bolt_display_category_e.WIND]: t`Wind`,
        [crux_bolt_display_category_e.NONE]: "NONE",
      }) as Record<number, string>,
    []
  );
  return { workoutPageCategoryTranslations };
};

export default useWorkoutPageCategoryStrings;
