import {
  WahooWorkoutTypeFamily,
  WorkoutProfileResponseType,
} from "@WahooFitness/cloud-client-ts/Types";
import {
  BlueSwitch,
  MenuList,
  MenuListItemType,
  MenuListItemVariant,
} from "@WahooFitness/redesignr";
import { t } from "@lingui/macro";
import { useMemo, useState } from "react";
import useEditWorkoutProfile from "./useEditWorkoutProfile";
import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import { FlaggedFeature } from "@/hooks/types/FlaggedFeature";

const SensorsSection = ({ profile }: { profile?: WorkoutProfileResponseType }) => {
  const { handleFieldChange } = useEditWorkoutProfile(profile);

  const [zeroInAvgCadenceDisplay, setZeroInAvgCadenceDisplay] = useState<boolean>(
    !!profile?.include_zero_in_avg_cadence
  );

  const sectionItems = useMemo(
    () =>
      [
        {
          id: "zeroInAvgCadence",
          content: t`Include zero in average cadence`,
          variant: MenuListItemVariant.Action,
          actionComponent: <BlueSwitch checked={zeroInAvgCadenceDisplay} />,
          action: () => {
            handleFieldChange("include_zero_in_avg_cadence", !zeroInAvgCadenceDisplay);
            setZeroInAvgCadenceDisplay(!zeroInAvgCadenceDisplay);
          },
        },
      ] as MenuListItemType[],
    [handleFieldChange, zeroInAvgCadenceDisplay]
  );

  const { checkIsFeatureEnabled, featuresAreLoading } = useFlaggedFeatures();

  const isCyclingProfile = useMemo(
    () => profile?.workout_type_family_id === WahooWorkoutTypeFamily.Cycling,
    [profile?.workout_type_family_id]
  );

  if (
    featuresAreLoading ||
    !checkIsFeatureEnabled(FlaggedFeature.ElemntConfig) ||
    !isCyclingProfile
  ) {
    return undefined;
  }

  return <MenuList title={t`Sensors`} listItems={sectionItems} />;
};

export default SensorsSection;
