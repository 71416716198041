import { useHeaderContext } from "@/hooks/useHeaderContext";
import { t, Trans } from "@lingui/macro";
import { Box, Typography, useTheme } from "@mui/material";
import { PlanGroupLobbyCard } from "@WahooFitness/redesignr";
import { useEffect, useMemo, useState } from "react";
import SearchBar from "../SearchBar";
import useWorkouts from "./useWorkouts";
import FilterBar from "../FilterBar/FilterBar";
import useWorkoutsFilters from "./useWorkoutsFilters";
import { defaultWorkoutFilterParams } from "../FilterBar/FilterParams";
import { useInView } from "react-intersection-observer";
import WarmingUp from "../WarmUp";
import ErrorBoundary from "../ErrorBoundary";

function Workouts() {
  const { palette } = useTheme();
  const { workoutsList, error, isLoading } = useWorkouts();
  const {
    filterParams,
    updateFilter,
    sortParams,
    sortDirections,
    sortOptions,
    setSortDirection,
    setSortKey,
    filteredSortedWorkoutList,
    filtersApplied,
    clearAllFilters,
  } = useWorkoutsFilters(workoutsList);
  const { setNavHeader } = useHeaderContext();

  const headerProps = useMemo(
    () => ({
      title: t`Workouts`,
    }),
    []
  );

  useEffect(() => {
    setNavHeader(headerProps);
  }, [setNavHeader, headerProps]);

  const [visibleCount, setVisibleCount] = useState(6);
  const { ref: loadMoreRef } = useInView({
    onChange: (inView: boolean) => {
      if (inView) {
        setTimeout(() => {
          setVisibleCount((prevCount) => prevCount + 2);
        }, 400);
      }
    },
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <Box display="flex" flexDirection="column" pt={2}>
      <SearchBar searchTerm={filterParams.search} updateFilter={updateFilter} />
      <FilterBar
        sortParams={sortParams}
        sortDirections={sortDirections}
        sortOptions={sortOptions}
        setSortDirection={setSortDirection}
        setSortKey={setSortKey}
        filterParams={filterParams}
        updateFilter={updateFilter}
        defaultFilterParams={defaultWorkoutFilterParams}
      />
      <ErrorBoundary error={error} isLoading={isLoading}>
        <Box display="flex" mx={4} my={1} alignItems="center" justifyContent="space-between">
          {filtersApplied && (
            <Box aria-role="button" sx={{ cursor: "pointer" }} onClick={clearAllFilters}>
              <Typography variant="prose-xs-medium" color={palette.info.main}>
                <Trans>Reset filters</Trans>
              </Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" gap={2} p={2}>
          {filteredSortedWorkoutList.length === 0 ? (
            <Typography>
              <Trans>No workouts matching your current filter selection.</Trans>
            </Typography>
          ) : (
            <>
              <Typography variant="ui-base-medium" color="text.secondary" alignSelf="start">
                {workoutsList.length} <Trans>workouts</Trans>
              </Typography>
              {filteredSortedWorkoutList.slice(0, visibleCount).map((planGroup) => (
                <PlanGroupLobbyCard key={planGroup.id} {...planGroup} />
              ))}
            </>
          )}
          {visibleCount < filteredSortedWorkoutList.length ? (
            <Box display="flex" justifyContent="center" ref={loadMoreRef} my={0.5}>
              <WarmingUp noTopSpacing />
            </Box>
          ) : (
            filteredSortedWorkoutList.length > 0 && (
              <Box my={1} mx={6}>
                <Typography align="center">
                  <Trans>That&apos;s all your results for the current filter selection!</Trans>
                </Typography>
              </Box>
            )
          )}
        </Box>
      </ErrorBoundary>
    </Box>
  );
}

export default Workouts;
