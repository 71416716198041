import {
  Battery100Icon,
  Battery25Icon,
  Battery50Icon,
  Battery75Icon,
  BatteryChargingIcon,
  BatteryEmptyIcon,
  BatteryLowIcon,
} from "@WahooFitness/redesignr";

const BatteryLevelIcon = ({
  batteryPercentage,
  isCharging,
}: {
  batteryPercentage?: number;
  isCharging: boolean;
}) => {
  if (isCharging) {
    return <BatteryChargingIcon color="success" />;
  }
  if (batteryPercentage === -1 || batteryPercentage === undefined) {
    return undefined;
  }
  if (batteryPercentage === 0) {
    return <BatteryEmptyIcon color="error" />;
  }
  if (batteryPercentage <= 20) {
    return <BatteryLowIcon color="error" />;
  }
  if (batteryPercentage <= 40) {
    return <Battery25Icon />;
  }
  if (batteryPercentage <= 60) {
    return <Battery50Icon />;
  }
  if (batteryPercentage <= 90) {
    return <Battery75Icon />;
  }
  return <Battery100Icon />;
};

export default BatteryLevelIcon;
