import { PropsWithChildren } from "react";
import { Box } from "@mui/material";
import { useConfigContext } from "@WahooFitness/wahoo-offline-mfe";

const EditWorkoutProfileWrapper = ({
  hasOwnHeader,
  children,
}: PropsWithChildren<{ hasOwnHeader?: boolean }>) => {
  const { globalBottomPadding } = useConfigContext();
  if (hasOwnHeader) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        overflow="hidden"
        height="100dvh"
        pb={globalBottomPadding}
      >
        {children}
      </Box>
    );
  }
  return <Box>{children}</Box>;
};

export default EditWorkoutProfileWrapper;
