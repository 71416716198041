import { Box } from "@mui/material";
import { MenuList, MenuListItemType } from "@WahooFitness/redesignr";

interface Props {
  bestKnownTimesQuestionItems: MenuListItemType[];
}

function BestKnownTimesQuestion({ bestKnownTimesQuestionItems }: Props) {
  return (
    <Box mx={1}>
      <MenuList listItems={bestKnownTimesQuestionItems} />
    </Box>
  );
}

export default BestKnownTimesQuestion;
