import { t } from "@lingui/macro";
import { forwardRef, useMemo } from "react";
import { IMask, IMaskInput } from "react-imask";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

const DurationInput = forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    const blocks = useMemo(
      () => ({
        H: {
          mask: IMask.MaskedRange,
          placeholderChar: t`h`,
          from: 0,
          to: 9,
          maxLength: 1,
        },
        MM: {
          mask: IMask.MaskedRange,
          placeholderChar: t`m`,
          from: 0,
          to: 59,
          maxLength: 2,
        },
        SS: {
          mask: IMask.MaskedRange,
          placeholderChar: t`s`,
          from: 0,
          to: 59,
          maxLength: 2,
        },
      }),
      []
    );

    return (
      <IMaskInput
        lazy={props.value.length === 0}
        {...other}
        inputRef={ref}
        mask="H:MM:SS"
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        blocks={blocks}
        overwrite
      />
    );
  }
);

export default DurationInput;
