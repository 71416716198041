import AddMaps from "@/components/Elemnt/Maps/AddMaps";
import { useMemo } from "react";
import { useParams } from "react-router";

const AddMapsPage = () => {
  const { appToken = "", tilePackId: rawTilePackId } = useParams();
  const tilePackId = useMemo(() => +(rawTilePackId || 0), [rawTilePackId]);
  return <AddMaps appToken={appToken} tilePackId={tilePackId} />;
};

export default AddMapsPage;
