import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import { Trans } from "@lingui/macro";

type Props = {
  value: "metric" | "imperial" | "custom";
  onChange?: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void;
};

const UnitPreferenceToggle = ({ value, onChange }: Props) => {
  return (
    <>
      <Typography variant="ui-base-medium" pb={1} color="text.primary">
        <Trans>Unit of measure</Trans>
      </Typography>
      <ToggleButtonGroup exclusive value={value} fullWidth onChange={onChange}>
        <ToggleButton value="metric" data-testid="metricToggle">
          <Typography variant="ui-sm" color="primary">
            <Trans>Metric</Trans>
          </Typography>
        </ToggleButton>
        <ToggleButton value="imperial" data-testid="imperialToggle">
          <Typography variant="ui-sm" color="primary">
            <Trans>Imperial</Trans>
          </Typography>
        </ToggleButton>
        <ToggleButton value="custom" data-testid="customToggle">
          <Typography variant="ui-sm" color="primary">
            <Trans>Custom</Trans>
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

export default UnitPreferenceToggle;
