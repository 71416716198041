/**
 * Removes null fields from objects. For arrays, the nulls will be replaced with undefined.
 * @param obj the object to have nulls removed from
 * @param recursive will also remove nulls from objects inside obj
 * @returns the original object with the nulls removed
 */
export const clearNulls = <T>(obj: T, recursive = true) => {
  if (typeof obj === "object") {
    for (const key in obj) {
      if (recursive && obj[key]) {
        clearNulls(obj[key], recursive);
      }
      if (obj[key] === null) {
        delete obj[key];
      }
    }
  }
  return obj;
};
