const HalfHourOfPowerPlanGraph = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2700 38"
    preserveAspectRatio="none"
    height="38"
    width="372"
    shapeRendering="crispEdges"
  >
    <rect x="0" y="32.93333333333334" width="105" height="5.066666666666666" fill="#5fdfff"></rect>
    <rect
      x="105"
      y="31.666666666666668"
      width="90"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect x="195" y="31.03333333333333" width="75" height="6.966666666666667" fill="#5fdfff"></rect>
    <rect
      x="270"
      y="29.766666666666666"
      width="60"
      height="8.233333333333334"
      fill="#5fdfff"
    ></rect>
    <rect
      x="330"
      y="31.666666666666668"
      width="30"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect x="360" y="28.5" width="30" height="9.5" fill="#5fdfff"></rect>
    <rect x="390" y="30.4" width="30" height="7.6000000000000005" fill="#5fdfff"></rect>
    <rect x="420" y="26.6" width="30" height="11.4" fill="#5fdfff"></rect>
    <rect
      x="450"
      y="29.133333333333333"
      width="30"
      height="8.866666666666667"
      fill="#5fdfff"
    ></rect>
    <rect
      x="480"
      y="25.333333333333336"
      width="30"
      height="12.666666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="510"
      y="31.666666666666668"
      width="30"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="540"
      y="20.583333333333336"
      width="30"
      height="17.416666666666664"
      fill="#ffc500"
    ></rect>
    <rect
      x="570"
      y="31.666666666666668"
      width="30"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect x="600" y="15.2" width="30" height="22.8" fill="#fb8b04"></rect>
    <rect
      x="630"
      y="31.666666666666668"
      width="90"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="720"
      y="26.346666666666668"
      width="1800"
      height="11.653333333333332"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2520"
      y="31.666666666666668"
      width="180"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
  </svg>
);
export default HalfHourOfPowerPlanGraph;
