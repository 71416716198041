import { useContext } from "react";
import { UnitFormatterContext } from "@/contexts/UnitFormatterContext";

export const useUnitFormatter = () => {
  const context = useContext(UnitFormatterContext);
  if (!context) {
    throw new Error("useUnitFormatter must be used within a UnitFormatterProvider");
  }
  return context;
};
