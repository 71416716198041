import { t } from "@lingui/macro";

const getLocalizedRiderTypeData = (): Record<string, { name: string; prefixedName: string }> => ({
  Attacker: { name: t`Attacker`, prefixedName: t`an Attacker` },
  Climber: { name: t`Climber`, prefixedName: t`a Climber` },
  Pursuiter: { name: t`Pursuiter`, prefixedName: t`a Pursuiter` },
  Rouleur: { name: t`Rouleur`, prefixedName: t`a Rouleur` },
  Sprinter: { name: t`Sprinter`, prefixedName: t`a Sprinter` },
  TimeTrialist: { name: t`Time Trialist`, prefixedName: t`a Time Trialist` },
});

const getLocalizedRiderTypeWeakness = (): Record<string, string> => ({
  NeuromuscularPower: t`Sprinting`,
  Non4DP: t`Non 4DP`,
  NoWeakness: t`No Weakness`,
  Repeated: t`Repeated`,
  Sustained: t`Sustained`,
  VO2: t`VO2`,
});

export { getLocalizedRiderTypeData, getLocalizedRiderTypeWeakness };
