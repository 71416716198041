import { formatByteCount } from "@/services/formatByteCount";
import { t } from "@lingui/macro";
import { useDialogContext } from "@WahooFitness/wahoo-offline-mfe";
import { useCallback, useEffect, useMemo } from "react";
import useMaps, { TilePackState } from "./useMaps";
import useSWR from "swr";
import useTilePackNames from "./useTilePackNames";
import useWifiCheck from "../useWifiCheck";

const useUpdateMaps = (appToken: string) => {
  const { setDialog, handleClose } = useDialogContext();

  const {
    sendUpgradeMapTilePack,
    mapPack,
    mapPackIsLoading,
    mutateMapPack,
    getAllActiveTilePacks,
    addMapUpdateListener,
    removeMapUpdateListener,
    mapsSynced,
    showSpaceDialog,
  } = useMaps(appToken);

  const {
    data: activeTilePacks,
    isLoading: activeTilePacksAreLoading,
    mutate: mutateActiveTilePacks,
  } = useSWR(() => mapsSynced && "activeTilePacks", getAllActiveTilePacks);

  useEffect(() => {
    if (!mapsSynced) return;
    addMapUpdateListener("useUpdateMaps", () => {
      mutateActiveTilePacks();
      mutateMapPack();
    });
    return () => removeMapUpdateListener("useUpdateMaps");
  }, [
    addMapUpdateListener,
    mapsSynced,
    mutateActiveTilePacks,
    mutateMapPack,
    removeMapUpdateListener,
  ]);

  const updateAllTilePacks = useCallback(() => {
    mapPack?.tilePacks?.forEach((tilePack) => {
      if (tilePack.upgradeSize > 0) {
        sendUpgradeMapTilePack(tilePack.id);
      }
    });
  }, [mapPack?.tilePacks, sendUpgradeMapTilePack]);

  const { isBoltWifiAllowed, wifiIsLoading, getConnectWifiDialogState } = useWifiCheck(appToken);

  const handleContinueUpdateClick = useCallback(() => {
    handleClose();
    if ((mapPack?.wifiNwCount || 0) <= 0 || !isBoltWifiAllowed) {
      setDialog(getConnectWifiDialogState(handleClose, updateAllTilePacks));
    } else {
      updateAllTilePacks();
    }
  }, [
    getConnectWifiDialogState,
    handleClose,
    isBoltWifiAllowed,
    mapPack?.wifiNwCount,
    setDialog,
    updateAllTilePacks,
  ]);

  const tilePackIsUpdating = useCallback(
    (state: number) =>
      [TilePackState.DOWNLOADING, TilePackState.QUEUED, TilePackState.REQUESTED_DOWNLOAD].includes(
        state
      ),
    []
  );

  const { tilePackNameTranslations } = useTilePackNames();

  const sortedTilePacks = useMemo(
    () =>
      activeTilePacks
        ?.filter(
          (tilePack) =>
            tilePack.upgradeSize > 0 ||
            [
              TilePackState.DOWNLOADING,
              TilePackState.FAILED,
              TilePackState.QUEUED,
              TilePackState.REQUESTED_DOWNLOAD,
            ].includes(tilePack.state)
        )
        .sort((a, b) =>
          tilePackNameTranslations[a.id].localeCompare(tilePackNameTranslations[b.id])
        ) || [],
    [activeTilePacks, tilePackNameTranslations]
  );

  const onUpdateAllClick = useCallback(() => {
    const deleteSize =
      sortedTilePacks?.reduce(
        (acc, curr) => acc + (!tilePackIsUpdating(curr.state) ? curr.installedSize : 0),
        0
      ) || 0;
    const downloadSize =
      sortedTilePacks?.reduce(
        (acc, curr) => acc + (!tilePackIsUpdating(curr.state) ? curr.upgradeSize : 0),
        0
      ) || 0;
    if (downloadSize - deleteSize > (mapPack?.availableSpace || 0)) {
      showSpaceDialog(downloadSize - deleteSize, mapPack?.availableSpace || 0);
      return;
    }
    setDialog({
      open: true,
      body: t`This update will delete ${formatByteCount(deleteSize)} of old maps and download ${formatByteCount(downloadSize)} of new maps. For quicker download times, manually select regions to update.`,
      actions: [
        { text: t`Cancel`, action: handleClose },
        {
          text: t`Continue update`,
          color: "info",
          action: () => handleContinueUpdateClick(),
        },
      ],
    });
  }, [
    sortedTilePacks,
    mapPack?.availableSpace,
    setDialog,
    handleClose,
    tilePackIsUpdating,
    showSpaceDialog,
    handleContinueUpdateClick,
  ]);

  const tilePacksAreUpdating = useMemo(
    () => sortedTilePacks?.some((tilePack) => tilePackIsUpdating(tilePack.state)),
    [sortedTilePacks, tilePackIsUpdating]
  );

  return {
    isLoading: mapPackIsLoading || activeTilePacksAreLoading || wifiIsLoading,
    onUpdateAllClick,
    sortedTilePacks,
    tilePacksAreUpdating,
  };
};

export default useUpdateMaps;
