import { useCallback, useState } from "react";
import { Trans, t } from "@lingui/macro";
import { PlayFilledAlt, VideoPlayer } from "@carbon/icons-react";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import OnboardingHeader from "@/components/Onboarding/OnboardingHeader";
import useVideoPlayer from "./useVideoPlayer";

function VideoIntroPage() {
  const { navToFirstVideo, tosText } = useVideoPlayer();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const handleTermsClick = useCallback(() => {
    setTermsAccepted((prev) => !prev);
  }, []);

  return (
    <>
      <OnboardingHeader
        Icon={VideoPlayer}
        title={t`Take a guided tour`}
        subtitle={t`Lace up your running shoes, dive-in, and follow along your guided tour of your KICKR RUN.`}
      />
      <Box mt={4} mx={4}>
        {tosText.map((paragraph: string, index: number) => (
          <Box key={index} mt={4} mx={4}>
            <Typography>{paragraph}</Typography>
          </Box>
        ))}
      </Box>
      <Box mt={4} mx={4} display="flex" alignItems="flex-start" gap={1} onClick={handleTermsClick}>
        <Checkbox sx={{ top: "-9px" }} checked={termsAccepted} />
        <Trans>
          I have reviewed the KICKR RUN instructional materials and agree to operate the KICKR RUN
          in accordance with the safety instructions.
        </Trans>
      </Box>
      <Box p={3} width="100%" mt="auto" maxWidth="sm">
        <Button
          variant="contained"
          size="large"
          fullWidth
          disabled={!termsAccepted}
          onClick={navToFirstVideo}
        >
          <Box display="flex" gap={1} alignItems="center">
            <PlayFilledAlt />
            <Trans>Watch Video</Trans>
          </Box>
        </Button>
      </Box>
    </>
  );
}

export default VideoIntroPage;
