import React, { useEffect } from "react";
import { useBCFirstRunState } from "@/hooks/useBCFirstRunState";
import usePairedDevice from "@/hooks/usePairedDevice";
import { useNavigate } from "react-router";
import { WSMBoltAppFirstRunState } from "@WahooFitness/wsm-native/dist/esm/types/bolt_cfg";
import ErrorBoundary from "@/components/ErrorBoundary";

interface OnboardingRouterProps {
  appToken: string;
}

const OnboardingRouter: React.FC<OnboardingRouterProps> = ({ appToken }) => {
  const { isDeviceConnected } = usePairedDevice(appToken);
  const { firstRunState, firstRunStateLoading, firstRunStateError, reloadFirstRunState } =
    useBCFirstRunState(appToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (firstRunStateLoading || !isDeviceConnected) {
      return;
    }
    switch (firstRunState?.state) {
      case WSMBoltAppFirstRunState.AppSetupFwu:
      case WSMBoltAppFirstRunState.FwuInProgress:
        navigate(`/device-config/${appToken}/onboarding/firmware`);
        break;
      case WSMBoltAppFirstRunState.AppSetupMaps:
        navigate(`/device-config/${appToken}/onboarding/maps`);
        break;
      default:
        navigate(`/device-config/${appToken}/onboarding/wifi`);
        break;
    }
  }, [appToken, firstRunState, firstRunStateLoading, isDeviceConnected, navigate]);

  return (
    <ErrorBoundary
      isLoading={firstRunStateLoading || !isDeviceConnected}
      error={firstRunStateError}
      onReload={reloadFirstRunState}
    />
  );
};

export { OnboardingRouter };
