import { Outlet } from "react-router";
import OnboardingLayout from "@/components/Onboarding/OnboardingLayout";

function OnboardingWrapper() {
  return (
    <OnboardingLayout>
      <Outlet />
    </OnboardingLayout>
  );
}

export default OnboardingWrapper;
