import { createBrowserRouter, RouterProvider } from "react-router-dom";

//@ts-expect-error This is part of vite-pages-plugin, and doesn't play well with TS
import routes from "~react-pages";
import { WorkoutProfilesContextProvider } from "@/contexts/WorkoutProfilesContext";
import "@/assets/Inter-3.19/inter.css";
import "@WahooFitness/redesignr/Fonts/RBNo3.1/index.css";
import { I18nProvider } from "@/contexts/I18nContext";
import { WsmContextProvider as RealWsmProvider } from "@/contexts/WsmContext";
import { WsmContextProvider as WebWsmProvider } from "./webMocks/routeMockData/WebWsmContext";
import WahooOfflineMFE from "@WahooFitness/wahoo-offline-mfe/WahooOfflineMFE";
import { getEnvironmentConfig } from "@/services/getEnvironmentConfig";
import { UnitFormatterProvider } from "@/contexts/UnitFormatterContext";
import { Capacitor } from "@capacitor/core";

function App() {
  const myRoutes = createBrowserRouter(routes);
  const WsmContextProvider = Capacitor.getPlatform() === "web" ? WebWsmProvider : RealWsmProvider;
  return (
    <WahooOfflineMFE
      stagingEnvironment={getEnvironmentConfig("staging")}
      productionEnvironment={getEnvironmentConfig("production")}
      devEnvironment={import.meta.env}
    >
      <I18nProvider>
        <UnitFormatterProvider>
          <WorkoutProfilesContextProvider>
            <WsmContextProvider>
              <RouterProvider router={myRoutes} />
            </WsmContextProvider>
          </WorkoutProfilesContextProvider>
        </UnitFormatterProvider>
      </I18nProvider>
    </WahooOfflineMFE>
  );
}

export default App;
