import { ImperialMetricDisplayPreference } from "@WahooFitness/cloud-client-ts/Types";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";

type Props = {
  value?: ImperialMetricDisplayPreference;
  onChange?: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void;
  label: string;
  metricOption: string;
  imperialOption: string;
  testIdPrefix?: string;
};

const CustomUnitPreferenceToggle = ({
  value,
  onChange,
  label,
  metricOption,
  imperialOption,
  testIdPrefix,
}: Props) => {
  return (
    <>
      <Typography variant="ui-sm" pb={1} mt={2} color="text.secondary">
        {label}
      </Typography>
      <ToggleButtonGroup exclusive value={value} fullWidth onChange={onChange}>
        <ToggleButton
          value={ImperialMetricDisplayPreference.metric}
          data-testid={`${testIdPrefix}.metricToggle`}
        >
          <Typography variant="ui-sm" color="primary">
            {metricOption}
          </Typography>
        </ToggleButton>
        <ToggleButton
          value={ImperialMetricDisplayPreference.imperial}
          data-testid={`${testIdPrefix}.imperialToggle`}
        >
          <Typography variant="ui-sm" color="primary">
            {imperialOption}
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

export default CustomUnitPreferenceToggle;
