import FullScreenDialog from "@/components/FullScreenDialog";
import { useDialogContext, useSnackbarContext } from "@WahooFitness/wahoo-offline-mfe";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import { CopyFile, OverflowMenuHorizontal, Reset, TrashCan } from "@carbon/icons-react";
import { Button, IconButton, ListItemIcon, Menu, MenuItem, useTheme } from "@mui/material";
import { MouseEvent, useCallback, useMemo, useState } from "react";
import EditWorkoutProfile from "./EditWorkoutProfile";
import { Trans, t } from "@lingui/macro";
import { useNavigate } from "react-router-dom";

interface Props {
  profileId: number;
  permanent?: boolean;
}

const ActionMenu = ({ profileId, permanent }: Props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbarContext();
  const { setDialog, handleClose: closeDialog } = useDialogContext();
  const { deleteProfile, copyProfile, resetProfile, workoutProfiles } = useWorkoutProfilesContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [newProfileId, setNewProfileId] = useState<number>();
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = useCallback(async () => {
    const newProfile = await copyProfile(Number(profileId));
    if (newProfile) {
      setNewProfileId(newProfile.id);
    }
  }, [profileId, copyProfile]);

  const profile = useMemo(
    () => (newProfileId ? workoutProfiles.find((wp) => wp.id === newProfileId) : undefined),
    [newProfileId, workoutProfiles]
  );

  const [warningDialogOpen, setWarningDialogOpen] = useState(false);

  const handleDiscardWorkoutProfile = useCallback(() => {
    if (profile?.id) {
      deleteProfile(profile?.id);
    }
    setWarningDialogOpen(false);
    setNewProfileId(undefined);
    handleClose();
  }, [deleteProfile, profile?.id]);

  const handleContinueEditing = useCallback(() => {
    setWarningDialogOpen(false);
  }, []);

  const handleCloseDuplicate = useCallback(() => {
    setWarningDialogOpen(true);
  }, []);

  const handleDoneClick = useCallback(() => {
    enqueueSnackbar({
      message: t`Workout Profile added!`,
      severity: "success",
    });
    navigate(-1);
  }, [enqueueSnackbar, navigate]);

  const handleDelete = useCallback(async () => {
    setDialog({
      open: true,
      title: t`Delete workout profile?`,
      body: t`This action cannot be undone.`,
      actions: [
        { text: t`Cancel` },
        {
          text: t`Delete`,
          color: "error",
          action: async () => {
            await deleteProfile(Number(profileId));
            handleClose();
            navigate("/workout-profiles", { replace: true });
          },
        },
      ],
    });
  }, [deleteProfile, navigate, profileId, setDialog]);

  const handleReset = useCallback(async () => {
    setDialog({
      open: true,
      title: t`Reset to default?`,
      body: t`Are you sure you want to reset this profile to Wahoo default?`,
      actions: [
        { text: t`Cancel` },
        {
          text: t`Reset to default?`,
          color: "error",
          action: async () => {
            resetProfile(Number(profileId));
            handleClose();
            closeDialog();
          },
        },
      ],
    });
  }, [setDialog, profileId, closeDialog, resetProfile]);

  const theme = useTheme();

  return (
    <>
      <IconButton
        id="basic-button"
        edge="end"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="large"
      >
        <OverflowMenuHorizontal size={24} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleCopy}>
          <ListItemIcon>
            <CopyFile size={24} />
          </ListItemIcon>
          <Trans>Duplicate</Trans>
        </MenuItem>
        <MenuItem onClick={handleReset}>
          <ListItemIcon>
            <Reset size={24} />
          </ListItemIcon>
          <Trans>Reset to default?</Trans>
        </MenuItem>
        {!permanent && (
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <TrashCan size={24} color={theme.palette.error.main} />
            </ListItemIcon>
            <Trans>Delete</Trans>
          </MenuItem>
        )}
        <FullScreenDialog
          slideDirection="up"
          open={!!newProfileId}
          handleClose={handleClose}
          headerProps={{
            title: t`Add workout profile`,
            backAction: handleCloseDuplicate,
            backText: t`Cancel`,
            headerAction: (
              <Button onClick={handleDoneClick}>
                <Trans>Done</Trans>
              </Button>
            ),
          }}
        >
          <EditWorkoutProfile
            hasOwnHeader
            title={t`Add workout profile`}
            profile={profile}
            backAction={handleCloseDuplicate}
            headerAction={
              <Button onClick={handleDoneClick}>
                <Trans>Done</Trans>
              </Button>
            }
            warningDialogOpen={warningDialogOpen}
            onContinueEditing={handleContinueEditing}
            onDiscardWorkoutProfile={handleDiscardWorkoutProfile}
          />
        </FullScreenDialog>
      </Menu>
    </>
  );
};

export default ActionMenu;
