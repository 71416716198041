import {
  Dispatch,
  JSXElementConstructor,
  ReactElement,
  SetStateAction,
  createContext,
  useCallback,
  useState,
} from "react";

interface IHeaderContextProps {
  navTitle?: string;
  setNavTitle: (value: string | undefined) => void;
  backAction?: () => void;
  setBackAction: Dispatch<SetStateAction<(() => void) | undefined>>;
  headerAction?: React.ReactElement | undefined;
  setHeaderAction: Dispatch<
    SetStateAction<ReactElement<any, string | JSXElementConstructor<any>> | undefined>
  >;
  setNavHeader: ({
    title,
    backAction,
    headerAction,
    hasOwnHeader,
    showCloseIcon,
    backText,
    maxTitleWidths,
    isTransparent,
    isFixed,
  }: {
    title?: string;
    backAction?: () => void;
    headerAction?: React.ReactElement;
    hasOwnHeader?: boolean;
    showCloseIcon?: boolean;
    backText?: string;
    maxTitleWidths?: Array<{ mediaQuery: string; maxWidth: string | number }>;
    isTransparent?: boolean;
    isFixed?: boolean;
  }) => void;
  showCloseIcon?: boolean;
  backText?: string;
  maxTitleWidths?: Array<{ mediaQuery: string; maxWidth: string | number }>;
  isTransparent?: boolean;
  isFixed?: boolean;
  disableBackButton?: boolean;
}

export const HeaderContext = createContext<IHeaderContextProps | undefined>(undefined);

export const HeaderContextProvider = ({ children }: any) => {
  const [navTitle, setNavTitle] = useState<string | undefined>(undefined);
  const [backAction, setBackAction] = useState<() => void>();
  const [headerAction, setHeaderAction] = useState<React.ReactElement | undefined>(undefined);
  const [showCloseIcon, setShowCloseIcon] = useState<boolean | undefined>(false);
  const [backText, setBackText] = useState<string | undefined>(undefined);
  const [maxTitleWidths, setMaxTitleWidths] = useState<
    Array<{ mediaQuery: string; maxWidth: string | number }> | undefined
  >(undefined);
  const [isTransparent, setIsTransparent] = useState<boolean | undefined>(false);
  const [isFixed, setIsFixed] = useState<boolean | undefined>(false);
  const [disableBackButton, setDisableBackButton] = useState<boolean | undefined>(false);

  const setNavHeader = useCallback(
    ({
      title,
      backAction,
      headerAction,
      hasOwnHeader,
      showCloseIcon,
      backText,
      maxTitleWidths,
      isTransparent,
      isFixed,
      disableBackButton,
    }: {
      title?: string;
      backAction?: () => void;
      headerAction?: React.ReactElement;
      hasOwnHeader?: boolean;
      showCloseIcon?: boolean;
      backText?: string;
      isTransparent?: boolean;
      isFixed?: boolean;
      disableBackButton?: boolean;
      /**
       * Array of max width overrides for the title. If multiple media queries match, the first match will be used.
       */
      maxTitleWidths?: Array<{ mediaQuery: string; maxWidth: string | number }>;
    }) => {
      if (!hasOwnHeader) {
        setNavTitle(title);
        setBackAction(() => backAction);
        setHeaderAction(headerAction);
        setShowCloseIcon(showCloseIcon);
        setBackText(backText);
        setMaxTitleWidths(maxTitleWidths);
        setIsTransparent(isTransparent);
        setIsFixed(isFixed);
        setDisableBackButton(disableBackButton);
      }
    },
    []
  );

  return (
    <HeaderContext.Provider
      value={{
        navTitle,
        setNavTitle,
        backAction,
        setBackAction,
        headerAction,
        setHeaderAction,
        setNavHeader,
        showCloseIcon,
        backText,
        maxTitleWidths,
        isTransparent,
        isFixed,
        disableBackButton,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
