import { Box, debounce } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isAfter, isBefore, startOfDay, sub } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { t } from "@lingui/macro";

type Props = {
  value: Date;
  onChange?: (value: Date | null) => void;
};

const DateOfBirthInput = ({ value, onChange }: Props) => {
  const [birthDateValid, setBirthDateValid] = useState<boolean>(true);
  const [helperText, setHelperText] = useState<string | undefined>(undefined);
  const [birthDate, setBirthDate] = useState<Date>(value);

  useEffect(() => {
    setBirthDate(value);
  }, [value]);

  const minBirthDate = useMemo(() => {
    return new Date(1900, 0, 1);
  }, []);

  const maxBirthDate = useMemo(() => {
    const today = new Date();
    const eighteenYearsAgo = startOfDay(sub(today, { years: 18 }));
    return eighteenYearsAgo;
  }, []);

  const debouncedOnChange = useMemo(() => onChange && debounce(onChange, 1000), [onChange]);

  const handleChange = useCallback(
    (value: Date | null) => {
      if (!value || isNaN(value.getTime())) {
        setBirthDateValid(false);
        setHelperText(t`Please enter a valid birth date`);
      } else if (isAfter(value, maxBirthDate)) {
        setBirthDateValid(false);
        setHelperText(t`Users must be at least 18 years of age`);
      } else if (isBefore(value, minBirthDate)) {
        setBirthDateValid(false);
        setHelperText(t`Please enter a valid birth date`);
      } else {
        setBirthDate(value);
        setBirthDateValid(true);
        setHelperText(undefined);
        debouncedOnChange && debouncedOnChange(value);
      }
    },
    [debouncedOnChange, maxBirthDate, minBirthDate]
  );

  return (
    <Box display="flex" flexDirection="column">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disableFuture
          label={t`Date of birth`}
          openTo="day"
          views={["year", "month", "day"]}
          value={birthDate}
          minDate={minBirthDate}
          maxDate={maxBirthDate}
          onChange={handleChange}
          slotProps={{
            textField: {
              variant: "outlined",
              error: !birthDateValid,
              helperText,
              inputProps: {
                "data-testid": "dateOfBirthInput",
              },
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DateOfBirthInput;
