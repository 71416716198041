import useSWR from "swr";
import { useWsmPlugin } from "./useWsmPlugin";
import { useEffect, useState } from "react";
import { WSMBoltAppFirstRunState } from "@WahooFitness/wsm-native/dist/esm/types/bolt_cfg";

interface StateTransitionType {
  desiredState: WSMBoltAppFirstRunState;
  allowedStates?: WSMBoltAppFirstRunState[];
}

const useBCFirstRunState = (appToken: string) => {
  const [stateTransition, setStateTransition] = useState<StateTransitionType | undefined>();
  const { sensorManagerPlugin } = useWsmPlugin();
  const {
    data: firstRunState,
    error: firstRunStateError,
    isLoading: firstRunStateLoading,
    mutate: reloadFirstRunState,
  } = useSWR(
    ["firstRunState", appToken],
    async ([_key, appToken]) => {
      const firstRunState = await sensorManagerPlugin?.bcGetFirstRunState({ appToken });
      console.log(
        `First run state for ${appToken}: ${firstRunState !== undefined ? WSMBoltAppFirstRunState[firstRunState.state] : "undefined"}`
      );
      return firstRunState;
    },
    { revalidateOnFocus: false, revalidateOnReconnect: false }
  );

  useEffect(() => {
    async function resolveState() {
      if (!stateTransition) {
        return;
      }
      if (firstRunStateLoading) {
        return;
      }
      if (!firstRunState) {
        reloadFirstRunState();
        return;
      }
      const allowedStates = stateTransition.allowedStates || [];
      allowedStates.push(stateTransition.desiredState);
      if (!allowedStates.includes(firstRunState.state)) {
        await sensorManagerPlugin?.bcSetFirstRunState({
          appToken,
          state: stateTransition.desiredState,
        });
        await reloadFirstRunState();
      }
    }
    resolveState();
  }, [
    appToken,
    firstRunState,
    firstRunStateLoading,
    reloadFirstRunState,
    sensorManagerPlugin,
    stateTransition,
  ]);

  return {
    firstRunState,
    firstRunStateError,
    firstRunStateLoading,
    reloadFirstRunState,
    setStateTransition,
  };
};

export { useBCFirstRunState };
