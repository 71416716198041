import ErrorBoundary from "@/components/ErrorBoundary";
import { t } from "@lingui/macro";
import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import { BlueSwitch, MenuList, MenuListItemVariant } from "@WahooFitness/redesignr";
import { useAnalyticsContext } from "@WahooFitness/wahoo-offline-mfe";
import useThirdPartySharing from "@/components/ThirdPartySharing/useThirdPartySharing";
import { useNavigate } from "react-router";

// Don't need this anywhere else, so I'm just keeping it here.
const STRAVA_FITNESS_APP_ID = 1085;

const StravaLiveSegmentSettings = ({ workoutProfileId }: { workoutProfileId: number }) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { getRemoteConfigString } = useAnalyticsContext();

  const learnMoreUrl = useMemo(
    () => getRemoteConfigString("STRAVA_LIVE_SEGMENT_SETTINGS_LEARN_MORE_URL"),
    [getRemoteConfigString]
  );

  const { setNavHeader } = useHeaderContext();
  const headerProps = useMemo(
    () => ({
      title: t`Strava Live Segments`,
    }),
    []
  );

  useEffect(() => {
    setNavHeader(headerProps);
  });

  const { connectedAppsData } = useThirdPartySharing();
  const [stravaConnected, stravaPremium] = useMemo(() => {
    const stravaApp = connectedAppsData?.find(
      (app) => app.fitness_app_id === STRAVA_FITNESS_APP_ID
    );
    return [!!stravaApp?.connected_app_user, !!stravaApp?.connected_app_user?.import_segments];
  }, [connectedAppsData]);

  const { getWorkoutProfile, workoutProfilesLoading, workoutProfilesError, updateProfile } =
    useWorkoutProfilesContext();

  const workoutProfile = useMemo(
    () => getWorkoutProfile(workoutProfileId),
    [getWorkoutProfile, workoutProfileId]
  );

  const sections = useMemo(() => {
    const segmentEnabledSection = {
      id: "segments_enabled",
      content: t`Enable Live Segments`,
      variant: MenuListItemVariant.Action,
      actionComponent: (
        <BlueSwitch
          checked={stravaPremium && workoutProfile?.segments_enabled}
          disabled={!stravaPremium}
          data-testid="segments_enabled"
        />
      ),
      action: stravaPremium
        ? () => {
            if (workoutProfile) {
              updateProfile(
                workoutProfile.id,
                "segments_enabled",
                !workoutProfile.segments_enabled
              );
            }
          }
        : () => navigate("/third-party-sharing"),
    };
    const learnMoreSection = {
      id: "strava_live_segments_learn_more",
      content: t`Learn more`,
      variant: MenuListItemVariant.ExternalLink,
      linkLocation: learnMoreUrl,
    };

    const stravaConnectedSections = [
      {
        id: "segments_auto_page_change",
        content: t`Automatic page change`,
        secondaryContent: t`Will change to the Strava Live Segments page at the start of each segment`,
        variant: MenuListItemVariant.Action,
        actionComponent: (
          <BlueSwitch
            checked={workoutProfile?.segments_auto_page_change}
            data-testid="segments_auto_page_change"
          />
        ),
        action: () => {
          if (workoutProfile) {
            updateProfile(
              workoutProfile.id,
              "segments_auto_page_change",
              !workoutProfile.segments_auto_page_change
            );
          }
        },
      },
      {
        id: "segments_alerts_on_other_pages",
        content: t`Alerts on other pages`,
        secondaryContent: t`Keeps you updated on your segment progress while not on your Strava page`,
        variant: MenuListItemVariant.Action,
        actionComponent: (
          <BlueSwitch
            checked={workoutProfile?.segments_alerts_on_other_pages}
            data-testid="segments_alerts_on_other_pages"
          />
        ),
        action: () => {
          if (workoutProfile) {
            updateProfile(
              workoutProfile.id,
              "segments_alerts_on_other_pages",
              !workoutProfile.segments_alerts_on_other_pages
            );
          }
        },
      },
      {
        id: "segments_during_plan",
        content: t`Enabled during planned workouts`,
        variant: MenuListItemVariant.Action,
        actionComponent: (
          <BlueSwitch
            checked={workoutProfile?.segments_during_plan}
            data-testid="segments_during_plan"
          />
        ),
        action: () => {
          if (workoutProfile) {
            updateProfile(
              workoutProfile.id,
              "segments_during_plan",
              !workoutProfile.segments_during_plan
            );
          }
        },
      },
    ];

    return [
      segmentEnabledSection,
      ...(stravaPremium ? stravaConnectedSections : []),
      learnMoreSection,
    ];
  }, [learnMoreUrl, navigate, stravaPremium, updateProfile, workoutProfile]);

  const topText = useMemo(
    () =>
      stravaPremium
        ? ""
        : stravaConnected
          ? t`A Strava Premium subscription is required to sync starred segments with ELEMNT. If you recently upgraded to Strava Premium, please re-authorize your account in Settings > Authorized Apps.`
          : t`Connect your Strava Premium account to start syncing starred segments with ELEMNT. This can be done in Settings > Authorized Apps.`,
    [stravaConnected, stravaPremium]
  );

  return (
    <ErrorBoundary isLoading={workoutProfilesLoading} error={workoutProfilesError}>
      <Box
        maxWidth="sm"
        width="100%"
        height="100%"
        overflow="scroll"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box margin={1} textAlign="center">
          <Typography variant="prose-sm" color={palette.text.secondary}>
            {topText}
          </Typography>
        </Box>
        <Box width="100%">
          <MenuList listItems={sections} />
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default StravaLiveSegmentSettings;
