import ErrorBoundary from "@/components/ErrorBoundary";
import usePairedDevice from "@/hooks/usePairedDevice";
import { Checkmark, Close } from "@carbon/icons-react";
import { alpha, Avatar, Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import BoltProductImage from "../BoltProductImage";
import { ChevronLeftIcon } from "@WahooFitness/redesignr";
import { useNavigate } from "react-router-dom";
import { Trans } from "@lingui/macro";
import { useBCFirstRunState } from "@/hooks/useBCFirstRunState";
import { WSMBoltAppFirstRunState } from "@WahooFitness/wsm-native/dist/esm/types/bolt_cfg";
import { postRoute } from "@WahooFitness/wahoo-offline-mfe";

interface OnboardingCompleteProps {
  appToken: string;
}

const OnboardingComplete: React.FC<OnboardingCompleteProps> = ({ appToken }) => {
  const { device, isDeviceConnected } = usePairedDevice(appToken);
  const { palette } = useTheme();
  const navigate = useNavigate();

  const { setStateTransition } = useBCFirstRunState(appToken);
  useEffect(() => {
    setStateTransition({
      desiredState: WSMBoltAppFirstRunState.Complete,
    });
  }, [setStateTransition]);

  const computerName = useMemo(() => device?.productTypeName, [device]);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box display="flex" justifyContent="space-between" width="100%" p={2}>
        <ChevronLeftIcon onClick={() => navigate("../maps")} />
        <Close color={palette.text.primary} size={24} onClick={() => postRoute("home")} />
      </Box>
      <ErrorBoundary isLoading={!isDeviceConnected} error={false}>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
        >
          <Box display="flex" flexDirection="column" width="100%" alignItems="center">
            <Box m={3}>
              <BoltProductImage boltType={device?.boltType} appToken={appToken} height={200} />
            </Box>
            <Box display="flex" flexDirection="column" gap={1} m={3} alignItems="center">
              <Avatar
                sx={{ width: "72px", height: "72px", bgcolor: alpha(palette.info.main, 0.1) }}
              >
                <Checkmark size="56px" color={palette.info.main} />
              </Avatar>
              <Typography textAlign="center" variant="prose-lg-bold">
                <Trans>Setup Done!</Trans>
              </Typography>
              <Typography textAlign="center" variant="prose-sm">
                <Trans>Your {computerName} is ready to go!</Trans>
              </Typography>
            </Box>
          </Box>
          <Box px={4} mb={4} width="100%">
            <Button variant="contained" size="large" fullWidth onClick={() => postRoute("home")}>
              <Trans>Complete</Trans>
            </Button>
          </Box>
        </Box>
      </ErrorBoundary>
    </Box>
  );
};

export { OnboardingComplete };
