import { useCallback, useEffect, useMemo, useState } from "react";
import SlidingDrawer from "@/components/StyledComponents/SlidingDrawer";
import { ChevronDown, ChevronUp, Mountain } from "@carbon/icons-react";
import { Box, IconButton, Paper, Stack, Typography, useTheme } from "@mui/material";
import { getWorkoutTypeFamilyIcon } from "@/services/getWorkoutTypeIcon";
import { useUnitFormatter } from "@/hooks/useUnitFormatter";
import ElevationChart from "../Elevation/ElevationChart";
import { useRouteElevationContext } from "@/hooks/useRouteElevationContext";
import { useRoutesContext } from "@/hooks/useRoutesContext";

const MapDrawer = ({
  open,
  updateLatLonSelection,
}: {
  open: boolean;
  updateLatLonSelection: (lat: number, lon: number) => void;
}) => {
  const { palette } = useTheme();
  const { elevationRecords } = useRouteElevationContext();
  const { routeDetails } = useRoutesContext();
  const WorkoutTypeIcon = getWorkoutTypeFamilyIcon(routeDetails?.workout_type_family_id);
  const [miniDrawer, setMiniDrawer] = useState<boolean>(false);
  const { formatDistance, formatElevation } = useUnitFormatter();

  const toggleMiniDrawer = useCallback(() => {
    setMiniDrawer((prev) => !prev);
  }, []);

  const [selectedPointInfo, setSelectedPointInfo] = useState<
    | { alt_m: number; dist_m: number; grade_perc: number; lat_deg: number; lon_deg: number }
    | undefined
  >(undefined);

  useEffect(() => {
    if (selectedPointInfo) {
      updateLatLonSelection(selectedPointInfo.lat_deg, selectedPointInfo.lon_deg);
    }
  }, [updateLatLonSelection, selectedPointInfo]);

  const routeDetailsSection = useMemo(() => {
    let elevationInfo = formatElevation(selectedPointInfo?.alt_m ?? (routeDetails?.ascent || 0));

    if (selectedPointInfo !== undefined) {
      elevationInfo += ` • ${selectedPointInfo.grade_perc}%`;
    }

    return (
      <Box display="flex" columnGap={4} mt={1} padding={0.25}>
        <Box display="flex" flexDirection="row" columnGap={0.5} alignItems="center">
          <WorkoutTypeIcon sx={{ color: palette.text.secondary, width: "14px", height: "14px" }} />
          <Typography variant="ui-xs">
            {formatDistance(selectedPointInfo?.dist_m ?? (routeDetails?.distance || 0))}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" columnGap={0.5} alignItems="center">
          <Mountain width="14px" height="14px" color={palette.text.secondary} />
          <Typography variant="ui-xs">{elevationInfo}</Typography>
        </Box>
      </Box>
    );
  }, [
    WorkoutTypeIcon,
    formatDistance,
    formatElevation,
    palette.text.secondary,
    routeDetails?.ascent,
    routeDetails?.distance,
    selectedPointInfo,
  ]);

  const drawerContent = useMemo(() => {
    if (miniDrawer) {
      return null;
    }
    return (
      <Stack px={3} paddingBottom={3} gap={3}>
        <Stack gap={1}>{!miniDrawer && routeDetailsSection}</Stack>
        <Box component={Paper} p={2}>
          <ElevationChart
            elevationRecords={elevationRecords}
            setSelectedPointInfo={setSelectedPointInfo}
          />
        </Box>
      </Stack>
    );
  }, [elevationRecords, miniDrawer, routeDetailsSection]);

  return (
    <SlidingDrawer
      zIndex={1500}
      open={open}
      handleClose={toggleMiniDrawer}
      handleOpen={toggleMiniDrawer}
      maxWidth={true}
      hideDivider
      title={routeDetails?.name ?? ""}
      titleLeftPadOverride={4}
      isPersistent
      closeIcon={
        <IconButton onClick={toggleMiniDrawer} size="large" data-testid="closeSlidingDrawerButton">
          {miniDrawer ? <ChevronUp size={32} /> : <ChevronDown size={32} />}
        </IconButton>
      }
    >
      {drawerContent}
    </SlidingDrawer>
  );
};

export default MapDrawer;
