import React, { createContext, useMemo } from "react";
import { useLingui } from "@lingui/react";
import { ImperialMetricDisplayPreference } from "@WahooFitness/cloud-client-ts/Types";
import {
  UnitFormatter,
  convertMetersPerSecondToPacePerSecond,
  convertPaceToMetersPerSecond as convertPaceToMetersPerSecondUCT,
  formatDuration,
} from "@WahooFitness/unit-convertr-ts";
import { useUserContext } from "@WahooFitness/wahoo-offline-mfe";

interface IUnitFormatterContextProps {
  unitFormatter: UnitFormatter | undefined;
  formatRunningPace: (metersPerSecond: number) => string;
  convertPaceToMetersPerSecond: (pace: string) => number;
  subtractPaceSecondFromMps: (mps: number) => number;
  addPaceSecondToMps: (mps: number) => number;
  formatTSS: (tss: number) => string;
  formatDistance: (distance: number) => string;
  formatElevation: (elevation: number) => string;
  formatDuration: (duration?: number, format?: "full" | "pretty") => string;
}

export const UnitFormatterContext = createContext<IUnitFormatterContextProps | undefined>(
  undefined
);

export const UnitFormatterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { i18n } = useLingui();
  const { currentUser } = useUserContext();

  const displayPreferences = useMemo(
    () => ({
      speedDistance:
        currentUser?.display_preference?.speed_distance ?? ImperialMetricDisplayPreference.metric,
      elevation:
        currentUser?.display_preference?.elevation ?? ImperialMetricDisplayPreference.metric,
      temperature:
        currentUser?.display_preference?.temperature ?? ImperialMetricDisplayPreference.metric,
      weight: currentUser?.display_preference?.weight ?? ImperialMetricDisplayPreference.metric,
    }),
    [currentUser?.display_preference]
  );

  const unitFormatter = useMemo(() => {
    return new UnitFormatter(i18n as any, displayPreferences);
  }, [i18n, displayPreferences]);

  const formatRunningPace = (metersPerSecond: number) => {
    return unitFormatter.formatRunningPace(metersPerSecond).formattedValue;
  };

  const convertPaceToMetersPerSecond = (pace: string) => {
    return convertPaceToMetersPerSecondUCT(pace, displayPreferences.speedDistance === 1);
  };

  const roundPace = (mps: number) => {
    const paceInSeconds = convertMetersPerSecondToPacePerSecond(
      mps,
      displayPreferences.speedDistance === 1
    );
    return Math.round(paceInSeconds);
  };

  const convertPaceSecondsToMps = (seconds: number) => {
    let secs = seconds;
    const hour = Math.floor(seconds / 3600);
    secs -= hour * 3600;
    const min = Math.floor((secs - hour * 3600) / 60);
    secs -= min * 60;

    const paceString = `${hour}:${min}:${secs}`;
    return convertPaceToMetersPerSecondUCT(paceString, displayPreferences.speedDistance === 1);
  };

  const subtractPaceSecondFromMps = (mps: number) => {
    const paceInSeconds = roundPace(mps);
    return convertPaceSecondsToMps(paceInSeconds - 1);
  };

  const addPaceSecondToMps = (mps: number) => {
    const paceInSeconds = roundPace(mps);
    return convertPaceSecondsToMps(paceInSeconds + 1);
  };

  const formatTSS = (tss: number) => {
    return unitFormatter.formatTSS(tss).formattedValue;
  };

  const formatDistance = (distance: number) => {
    return unitFormatter.formatDistance(distance).formattedValue;
  };

  const formatElevation = (elevation: number) => {
    return unitFormatter.formatElevation(elevation).formattedValue;
  };

  return (
    <UnitFormatterContext.Provider
      value={{
        unitFormatter,
        formatRunningPace,
        convertPaceToMetersPerSecond,
        subtractPaceSecondFromMps,
        addPaceSecondToMps,
        formatTSS,
        formatDistance,
        formatElevation,
        formatDuration,
      }}
    >
      {children}
    </UnitFormatterContext.Provider>
  );
};
