import { t } from "@lingui/macro";
import {
  WorkoutAlertTriggerTypeEnum,
  WorkoutMetricTypeEnum,
} from "@WahooFitness/cloud-client-ts/Types";
import { useCallback, useMemo } from "react";

export enum AlertMetricCategory {
  distance,
  duration,
  calories,
  speedPace,
  heartRate,
  power,
  cadenceStrideRate,
}

const useAlertMetrics = () => {
  const categoryOptions: Record<AlertMetricCategory, { displayString: string }> = useMemo(
    () => ({
      [AlertMetricCategory.distance]: { displayString: t`Distance` },
      [AlertMetricCategory.duration]: { displayString: t`Duration` },
      [AlertMetricCategory.calories]: { displayString: t`Calories` },
      [AlertMetricCategory.speedPace]: { displayString: t`Speed / Pace` },
      [AlertMetricCategory.heartRate]: { displayString: t`Heart Rate` },
      [AlertMetricCategory.power]: { displayString: t`Power` },
      [AlertMetricCategory.cadenceStrideRate]: { displayString: t`Cadence / Stride Rate` },
    }),
    []
  );
  const metricOptions: Record<
    WorkoutMetricTypeEnum,
    {
      displayString: string;
      category: AlertMetricCategory;
      /**
       * if `excludeFrom` is not provided, the metric will be available on all trigger types.
       * If provided, the metric will not be available on the included trigger types
       */
      excludeFrom?: WorkoutAlertTriggerTypeEnum[];
      /**
       * if `includeOn` is not provided, the metric will be available on all trigger types.
       * If provided, the metric will only be available on the included trigger types
       */
      includeOn?: WorkoutAlertTriggerTypeEnum[];
      sortOrderInCategory: number;
      testId: string;
    }
  > = useMemo(
    () => ({
      [WorkoutMetricTypeEnum.distance_workout_total]: {
        displayString: t`Workout total`,
        category: AlertMetricCategory.distance,
        sortOrderInCategory: 1,
        testId: "distance.workoutTotal",
      },
      [WorkoutMetricTypeEnum.distance_lap_total]: {
        displayString: t`Lap total`,
        category: AlertMetricCategory.distance,
        excludeFrom: [WorkoutAlertTriggerTypeEnum.lap],
        sortOrderInCategory: 2,
        testId: "distance.lapTotal",
      },
      [WorkoutMetricTypeEnum.distance_last_lap_total]: {
        displayString: t`Lap total`,
        category: AlertMetricCategory.distance,
        includeOn: [WorkoutAlertTriggerTypeEnum.lap],
        sortOrderInCategory: 3,
        testId: "distance.lapTotal",
      },
      [WorkoutMetricTypeEnum.duration_workout_active]: {
        displayString: t`Workout active`,
        category: AlertMetricCategory.duration,
        sortOrderInCategory: 1,
        testId: "duration.workoutActive",
      },
      [WorkoutMetricTypeEnum.duration_workout_total]: {
        displayString: t`Workout total`,
        category: AlertMetricCategory.duration,
        sortOrderInCategory: 2,
        testId: "duration.workoutTotal",
      },
      [WorkoutMetricTypeEnum.duration_lap_active]: {
        displayString: t`Lap active`,
        category: AlertMetricCategory.duration,
        excludeFrom: [WorkoutAlertTriggerTypeEnum.lap],
        sortOrderInCategory: 3,
        testId: "duration.lapActive",
      },
      [WorkoutMetricTypeEnum.duration_last_lap_active]: {
        displayString: t`Lap active`,
        category: AlertMetricCategory.duration,
        includeOn: [WorkoutAlertTriggerTypeEnum.lap],
        sortOrderInCategory: 4,
        testId: "duration.lapActive",
      },
      [WorkoutMetricTypeEnum.calories_workout_total]: {
        displayString: t`Workout total`,
        category: AlertMetricCategory.calories,
        sortOrderInCategory: 1,
        testId: "calories.workoutTotal",
      },
      [WorkoutMetricTypeEnum.speed_current]: {
        displayString: t`Current`,
        category: AlertMetricCategory.speedPace,
        sortOrderInCategory: 1,
        testId: "speed.current",
      },
      [WorkoutMetricTypeEnum.speed_workout_average]: {
        displayString: t`Workout average`,
        category: AlertMetricCategory.speedPace,
        sortOrderInCategory: 2,
        testId: "speed.workoutAverage",
      },
      [WorkoutMetricTypeEnum.speed_lap_average]: {
        displayString: t`Lap average`,
        category: AlertMetricCategory.speedPace,
        excludeFrom: [WorkoutAlertTriggerTypeEnum.lap],
        sortOrderInCategory: 3,
        testId: "speed.lapAverage",
      },
      [WorkoutMetricTypeEnum.speed_last_lap_average]: {
        displayString: t`Lap average`,
        category: AlertMetricCategory.speedPace,
        includeOn: [WorkoutAlertTriggerTypeEnum.lap],
        sortOrderInCategory: 4,
        testId: "speed.lapAverage",
      },
      [WorkoutMetricTypeEnum.heart_rate_current]: {
        displayString: t`Current`,
        category: AlertMetricCategory.heartRate,
        sortOrderInCategory: 1,
        testId: "heartRate.current",
      },
      [WorkoutMetricTypeEnum.heart_rate_workout_average]: {
        displayString: t`Workout average`,
        category: AlertMetricCategory.heartRate,
        sortOrderInCategory: 2,
        testId: "heartRate.workoutAverage",
      },
      [WorkoutMetricTypeEnum.heart_rate_lap_average]: {
        displayString: t`Lap average`,
        category: AlertMetricCategory.heartRate,
        excludeFrom: [WorkoutAlertTriggerTypeEnum.lap],
        sortOrderInCategory: 3,
        testId: "heartRate.lapAverage",
      },
      [WorkoutMetricTypeEnum.heart_rate_last_lap_average]: {
        displayString: t`Lap average`,
        category: AlertMetricCategory.heartRate,
        includeOn: [WorkoutAlertTriggerTypeEnum.lap],
        sortOrderInCategory: 4,
        testId: "heartRate.lapAverage",
      },
      [WorkoutMetricTypeEnum.heart_rate_current_zone]: {
        displayString: t`Current zone`,
        category: AlertMetricCategory.heartRate,
        sortOrderInCategory: 5,
        testId: "heartRate.currentZone",
      },
      [WorkoutMetricTypeEnum.power_bike_current]: {
        displayString: t`Current`,
        category: AlertMetricCategory.power,
        sortOrderInCategory: 1,
        testId: "power.current",
      },
      [WorkoutMetricTypeEnum.power_workout_average]: {
        displayString: t`Workout average`,
        category: AlertMetricCategory.power,
        sortOrderInCategory: 2,
        testId: "power.workoutAverage",
      },
      [WorkoutMetricTypeEnum.power_workout_normalized]: {
        displayString: t`Workout normalized`,
        category: AlertMetricCategory.power,
        sortOrderInCategory: 3,
        testId: "power.workoutNormalized",
      },
      [WorkoutMetricTypeEnum.power_lap_average]: {
        displayString: t`Lap average`,
        category: AlertMetricCategory.power,
        excludeFrom: [WorkoutAlertTriggerTypeEnum.lap],
        sortOrderInCategory: 4,
        testId: "power.lapAverage",
      },
      [WorkoutMetricTypeEnum.power_last_lap_average]: {
        displayString: t`Lap average`,
        category: AlertMetricCategory.power,
        includeOn: [WorkoutAlertTriggerTypeEnum.lap],
        sortOrderInCategory: 5,
        testId: "power.lapAverage",
      },
      [WorkoutMetricTypeEnum.power_lap_normalized]: {
        displayString: t`Lap normalized`,
        category: AlertMetricCategory.power,
        excludeFrom: [WorkoutAlertTriggerTypeEnum.lap],
        sortOrderInCategory: 6,
        testId: "power.lapNormalized",
      },
      [WorkoutMetricTypeEnum.power_last_lap_normalized]: {
        displayString: t`Lap normalized`,
        category: AlertMetricCategory.power,
        includeOn: [WorkoutAlertTriggerTypeEnum.lap],
        sortOrderInCategory: 7,
        testId: "power.lapNormalized",
      },
      [WorkoutMetricTypeEnum.cadence_current]: {
        displayString: t`Current`,
        category: AlertMetricCategory.cadenceStrideRate,
        sortOrderInCategory: 1,
        testId: "cadence.current",
      },
      [WorkoutMetricTypeEnum.cadence_workout_average]: {
        displayString: t`Workout average`,
        category: AlertMetricCategory.cadenceStrideRate,
        sortOrderInCategory: 2,
        testId: "cadence.workoutAverage",
      },
      [WorkoutMetricTypeEnum.cadence_lap_average]: {
        displayString: t`Lap average`,
        category: AlertMetricCategory.cadenceStrideRate,
        excludeFrom: [WorkoutAlertTriggerTypeEnum.lap],
        sortOrderInCategory: 3,
        testId: "cadence.lapAverage",
      },
      [WorkoutMetricTypeEnum.cadence_last_lap_average]: {
        displayString: t`Lap average`,
        category: AlertMetricCategory.cadenceStrideRate,
        includeOn: [WorkoutAlertTriggerTypeEnum.lap],
        sortOrderInCategory: 4,
        testId: "cadence.lapAverage",
      },
    }),
    []
  );

  const getEquivalentMetric = useCallback(
    (triggerType: WorkoutAlertTriggerTypeEnum, metric: WorkoutMetricTypeEnum) => {
      switch (triggerType) {
        case WorkoutAlertTriggerTypeEnum.lap:
          switch (metric) {
            case WorkoutMetricTypeEnum.cadence_lap_average:
              return WorkoutMetricTypeEnum.cadence_last_lap_average;
            case WorkoutMetricTypeEnum.distance_lap_total:
              return WorkoutMetricTypeEnum.distance_last_lap_total;
            case WorkoutMetricTypeEnum.duration_lap_active:
              return WorkoutMetricTypeEnum.duration_last_lap_active;
            case WorkoutMetricTypeEnum.heart_rate_lap_average:
              return WorkoutMetricTypeEnum.heart_rate_last_lap_average;
            case WorkoutMetricTypeEnum.power_lap_average:
              return WorkoutMetricTypeEnum.power_last_lap_average;
            case WorkoutMetricTypeEnum.power_lap_normalized:
              return WorkoutMetricTypeEnum.power_last_lap_normalized;
            case WorkoutMetricTypeEnum.speed_lap_average:
              return WorkoutMetricTypeEnum.speed_last_lap_average;
            default:
              return metric;
          }
        default:
          switch (metric) {
            case WorkoutMetricTypeEnum.cadence_last_lap_average:
              return WorkoutMetricTypeEnum.cadence_lap_average;
            case WorkoutMetricTypeEnum.distance_last_lap_total:
              return WorkoutMetricTypeEnum.distance_lap_total;
            case WorkoutMetricTypeEnum.duration_last_lap_active:
              return WorkoutMetricTypeEnum.duration_lap_active;
            case WorkoutMetricTypeEnum.heart_rate_last_lap_average:
              return WorkoutMetricTypeEnum.heart_rate_lap_average;
            case WorkoutMetricTypeEnum.power_last_lap_average:
              return WorkoutMetricTypeEnum.power_lap_average;
            case WorkoutMetricTypeEnum.power_last_lap_normalized:
              return WorkoutMetricTypeEnum.power_lap_normalized;
            case WorkoutMetricTypeEnum.speed_last_lap_average:
              return WorkoutMetricTypeEnum.speed_lap_average;
            default:
              return metric;
          }
      }
    },
    []
  );

  const getAlertMetricSummaryDisplayString = useCallback(
    (metric: WorkoutMetricTypeEnum) => {
      const metricOption = metricOptions[metric];
      const categoryDisplayString = categoryOptions[metricOption.category].displayString;
      return `${categoryDisplayString}: ${metricOption.displayString}`;
    },
    [categoryOptions, metricOptions]
  );

  const getCategorizedAlertMetrics = useCallback(
    (triggerType: WorkoutAlertTriggerTypeEnum) =>
      [
        AlertMetricCategory.distance,
        AlertMetricCategory.duration,
        AlertMetricCategory.calories,
        AlertMetricCategory.speedPace,
        AlertMetricCategory.heartRate,
        AlertMetricCategory.power,
        AlertMetricCategory.cadenceStrideRate,
      ].map((category: AlertMetricCategory) => ({
        name: categoryOptions[category].displayString,
        id: category,
        options: Object.entries(metricOptions)
          .filter(
            (entry) =>
              entry[1].category === category &&
              !entry[1].excludeFrom?.includes(triggerType) &&
              (!entry[1].includeOn || entry[1].includeOn.includes(triggerType))
          )
          .sort((left, right) => left[1].sortOrderInCategory - right[1].sortOrderInCategory)
          .map((entry) => ({
            metric: +entry[0],
            name: entry[1].displayString,
            testId: entry[1].testId,
          })),
      })),
    [categoryOptions, metricOptions]
  );
  return { getCategorizedAlertMetrics, getAlertMetricSummaryDisplayString, getEquivalentMetric };
};

export default useAlertMetrics;
