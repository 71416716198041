import { Box, Typography } from "@mui/material";

function MapOptionToggleButton({
  imgSrc,
  label,
  isSelected,
  onClick,
}: {
  imgSrc: string;
  label: string;
  isSelected?: boolean;
  onClick?: () => void;
}) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={1}>
      <Box width={48} height={48}>
        <img
          onClick={onClick}
          alt={label}
          src={imgSrc}
          style={{ border: isSelected ? "3px solid #66C7FF" : "none", borderRadius: "4px" }}
        />
      </Box>
      <Typography
        variant="ui-sm-medium"
        sx={{ maxWidth: "75px", wordWrap: "break-word", textAlign: "center" }}
      >
        {label}
      </Typography>
    </Box>
  );
}

export default MapOptionToggleButton;
