import { Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import { MenuList, MenuListItemType } from "@WahooFitness/redesignr";

interface Props {
  averageIntensityListItems: MenuListItemType[];
}

function AverageIntensityQuestion({ averageIntensityListItems }: Props) {
  return (
    <Box mx={1} display="flex" flexDirection="column">
      <Box mx={4} display="flex" flexDirection="column">
        <Box>
          <Typography variant="prose-base-medium">
            <Trans>Over the last 6 weeks my average high intensity exercise time was:</Trans>
          </Typography>
        </Box>
        <Box>
          <Typography variant="prose-sm" color="text.secondary">
            <Trans>High intensity: periods of very rapid breathing and difficulty speaking</Trans>
          </Typography>
        </Box>
      </Box>
      <MenuList listItems={averageIntensityListItems} />
    </Box>
  );
}

export default AverageIntensityQuestion;
