import { useMemo } from "react";
import EditWorkoutProfile from "@/components/WorkoutProfiles/EditWorkoutProfile/EditWorkoutProfile";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import ActionMenu from "@/components/WorkoutProfiles/EditWorkoutProfile/ActionMenu";
import { useParams } from "react-router";

const ProfileDetailsPage = () => {
  const params = useParams();
  const { workoutProfiles } = useWorkoutProfilesContext();
  const profileId = Number(params.profileId || 0);
  const profile = useMemo(
    () => workoutProfiles.find((wp) => wp.id === profileId),
    [profileId, workoutProfiles]
  );

  return (
    <EditWorkoutProfile
      profile={profile}
      headerAction={<ActionMenu profileId={profileId} permanent={profile?.permanent} />}
    />
  );
};

export default ProfileDetailsPage;
