import { Autocomplete, Box, TextField, useTheme } from "@mui/material";
import { getCodes, getCountry } from "iso-3166-1-alpha-2";
import { t } from "@lingui/macro";
import { useCallback, useState } from "react";

type Props = {
  value: string | null;
  handleChange: (value: string | null) => void;
};

const CountryInput = ({ value, handleChange }: Props) => {
  const countryCodes = getCodes();
  const theme = useTheme();
  const [inputValue, setInputValue] = useState("");
  const handleClear = useCallback(() => setInputValue(""), []);

  return (
    <Autocomplete
      id="country-select"
      disablePortal
      blurOnSelect
      sx={{
        width: "100%",
        ".MuiAutocomplete-endAdornment": {
          position: "absolute",
          inset: 0,
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          right: "0 !important",
        },
        "+ .MuiAutocomplete-popper": {
          border: `1px solid ${theme.palette.divider}`,
          boxShadow: `0 2px 24px rgba(0,0,0,0.2)`,
          borderRadius: "12px",
          overflow: "hidden",
        },
      }}
      options={countryCodes}
      autoHighlight
      value={value}
      onChange={(_event: any, newValue: string | null) => {
        handleChange(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={(option) => getCountry(option) || ""}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{
            "& > img": { mr: 2, flexShrink: 0 },
          }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`}
            alt=""
          />
          {getCountry(option)}
        </Box>
      )}
      componentsProps={{
        popper: {
          placement: "top",
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onClick={handleClear}
          label={t`Country`}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
            "data-testid": "countryInput",
          }}
        />
      )}
    />
  );
};

export default CountryInput;
