export interface FilterParams {
  search?: string;
  family?: Array<number>;
  duration?: {
    userSelection: number[];
    min_m: number;
    max_m: number;
  };
  tss?: {
    userSelection: number[];
    min_m: number;
    max_m: number;
  };
  distance?: {
    userSelection: number[];
    min_m: number;
    max_m: number;
  };
  elevation_gain?: {
    userSelection: number[];
    min_m: number;
    max_m: number;
  };
  surface_type?: Array<number>;
  favorite?: boolean;
}

export const MAX_DURATION_FILTER = 120 * 60; // 120 minutes
export const MAX_TSS_FILTER = 120;
export const MAX_DISTANCE_FILTER = 150 * 1609.34; // 150 miles
export const MAX_ELEVATION_FILTER = 10000 / 3.28084; // 10,000 feet

export const defaultWorkoutFilterParams = {
  search: "",
  duration: { userSelection: [0, 1], min_m: 0, max_m: MAX_DURATION_FILTER },
  tss: { userSelection: [0, 1], min_m: 0, max_m: MAX_TSS_FILTER },
  family: [],
};

export const defaultRouteFilterParams = {
  search: "",
  distance: { userSelection: [0, 1], min_m: 0, max_m: MAX_DISTANCE_FILTER },
  elevation_gain: { userSelection: [0, 1], min_m: 0, max_m: MAX_ELEVATION_FILTER },
  family: [],
};
