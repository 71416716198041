import { useConfigContext } from "@WahooFitness/wahoo-offline-mfe";
import { I18nProvider as LingUiI18Provider } from "@lingui/react";
import { useEffect } from "react";
import { i18n } from "@lingui/core";
import { getMessages as getUctMessages } from "@WahooFitness/unit-convertr-ts";
import { getMessages as getRedesignrMessages } from "@WahooFitness/redesignr";

async function getLocalizationData(
  specifiedLocale: string
): Promise<{ messages: Record<string, any> }> {
  try {
    const { messages: localeMessages } = await import(`../locales/${specifiedLocale}.po`);
    const {
      messages: { ...uctMessages },
    } = getUctMessages(specifiedLocale);
    const {
      messages: { ...redesignrMessages },
    } = getRedesignrMessages(specifiedLocale);
    return {
      messages: Object.assign({ ...localeMessages }, { ...uctMessages }, { ...redesignrMessages }),
    };
  } catch (error) {
    try {
      //@ts-expect-error LingUI po files do not have a type export.
      const { messages: en_messages } = await import("../locales/en.po");
      const { uctMessagesEN } = await import(`@WahooFitness/unit-convertr-ts`);
      return { messages: Object.assign({ ...en_messages }, { ...uctMessagesEN }) };
    } catch (error) {
      return { messages: {} };
    }
  }
}

export const I18nProvider = ({ children }: any) => {
  const { locale } = useConfigContext();
  useEffect(() => {
    async function loadMessages() {
      const { messages } = await getLocalizationData(locale);
      i18n.loadAndActivate({ locale, messages });
    }
    loadMessages();
  }, [locale]);

  return <LingUiI18Provider i18n={i18n}>{children}</LingUiI18Provider>;
};
