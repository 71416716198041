import { useHeaderContext } from "@/hooks/useHeaderContext";
import NavHeader from "./NavHeader";

export default function Header() {
  const {
    navTitle,
    backAction,
    headerAction,
    showCloseIcon,
    backText,
    maxTitleWidths,
    isTransparent,
    isFixed,
    disableBackButton,
  } = useHeaderContext();
  if (navTitle === undefined) {
    return null;
  }
  return (
    <NavHeader
      title={navTitle}
      backAction={backAction}
      headerAction={headerAction}
      closeIcon={showCloseIcon}
      backText={backText}
      data-testid="navHeader"
      maxTitleWidths={maxTitleWidths}
      isTransparent={isTransparent}
      isFixed={isFixed}
      disableBackButton={disableBackButton}
    />
  );
}
