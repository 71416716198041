import { useDateTimeFormatter } from "@/hooks/useDateTimeFormatter";
import { useUnitFormatter } from "@/hooks/useUnitFormatter";
import { getWorkoutTypeFamilyIcon } from "@/services/getWorkoutTypeIcon";
import { LocationCurrent, Mountain } from "@carbon/icons-react";
import { Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";

interface SummaryProps {
  name: string;
  distance?: number;
  ascent?: number;
  workoutTypeFamily?: number;
  createdDate: string;
  providerIconImgUrl?: string;
  location?: string;
  proximity?: string;
}

const Summary = ({
  name,
  distance,
  ascent,
  workoutTypeFamily,
  providerIconImgUrl,
  createdDate,
  location,
  proximity,
}: SummaryProps) => {
  const { palette } = useTheme();
  const { formatDistance, formatElevation } = useUnitFormatter();
  const { dateTimeFormatter } = useDateTimeFormatter();
  const WorkoutTypeIcon = getWorkoutTypeFamilyIcon(Number(workoutTypeFamily));

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" mb={1.5} alignItems="center" columnGap={1}>
        {providerIconImgUrl && (
          <img
            style={{
              width: 20,
              height: 20,
              borderRadius: 4,
            }}
            src={providerIconImgUrl}
          />
        )}
        <Typography variant="ui-xs" color="text.secondary">
          {dateTimeFormatter.formatDateInTimeZone(createdDate)}
        </Typography>
      </Box>
      <Typography variant="ui-xl-medium">{name}</Typography>
      <Box display="flex" columnGap={4} mt={1} padding={0.25}>
        <Box display="flex" flexDirection="row" columnGap={0.5} alignItems="center">
          <WorkoutTypeIcon width="20px" height="20px" sx={{ color: palette.text.secondary }} />
          <Typography variant="ui-lg-medium">{formatDistance(distance || 0)}</Typography>
        </Box>
        <Box display="flex" flexDirection="row" columnGap={0.5} alignItems="center">
          <Mountain width="20px" height="20px" color={palette.text.secondary} />
          <Typography variant="ui-lg-medium">{formatElevation(ascent || 0)}</Typography>
        </Box>
      </Box>
      {(location || proximity) && (
        <Box display="flex" flexDirection="row" mt={1} columnGap={0.5}>
          <Typography variant="ui-sm">{location}</Typography>
          <Box display="flex" alignItems="center" columnGap={0.25}>
            {proximity && (
              <LocationCurrent width="12px" height="12px" color={palette.text.secondary} />
            )}
            <Typography variant="ui-2xs" color="text.secondary">
              {proximity}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Summary;
