import { Grid, Stack } from "@mui/material";
import { ImperialMetricDisplayPreference, UserType } from "@WahooFitness/cloud-client-ts/Types";
import CustomUnitPreferenceToggle from "./CustomUnitPreferenceToggle";
import DateOfBirthInput from "./DateOfBirthInput";
import HeightInput from "./HeightInput";
import NameInput from "./NameInput";
import SexInput from "./SexInput";
import UnitPreferenceToggle from "./UnitPreferenceToggle";
import useAboutYou from "./useAboutYou";
import WeightInput from "./WeightInput";
import CountryInput from "./CountryInput";
import { t } from "@lingui/macro";

const AboutYou = ({ user }: { user: UserType }) => {
  const {
    height,
    weight,
    birthDate,
    unitPreference,
    handleFirstNameChange,
    handleLastNameChange,
    handleSexChange,
    handleBirthDateChange,
    handleUnitPreferenceChange,
    handleDistancePreferenceChange,
    handleElevationPreferenceChange,
    handleTemperaturePreferenceChange,
    handleWeightPreferenceChange,
    handleHeightChange,
    handleWeightChange,
    handleCountryChange,
  } = useAboutYou(user);

  return (
    <Grid container columnSpacing={2} rowSpacing={3} pb={2}>
      <Grid item xs={6}>
        <NameInput
          value={user.first}
          onBlur={handleFirstNameChange}
          label={t`First name`}
          testIdPrefix="first"
        />
      </Grid>
      <Grid item xs={6}>
        <NameInput
          value={user.last}
          onBlur={handleLastNameChange}
          label={t`Last name`}
          testIdPrefix="last"
        />
      </Grid>
      <Grid item xs={6}>
        <SexInput value={user.gender} onChange={handleSexChange} />
      </Grid>
      <Grid item xs={6}>
        <DateOfBirthInput value={birthDate} onChange={handleBirthDateChange} />
      </Grid>
      <Grid item xs={12}>
        <UnitPreferenceToggle value={unitPreference} onChange={handleUnitPreferenceChange} />
        {unitPreference === "custom" && (
          <Stack sx={{ pl: 4, gap: 0, py: 0 }}>
            <CustomUnitPreferenceToggle
              value={
                user.display_preference?.speed_distance ?? ImperialMetricDisplayPreference.metric
              }
              onChange={handleDistancePreferenceChange}
              label={t`Distance`}
              metricOption={t`Kilometers`}
              imperialOption={t`Miles`}
              testIdPrefix="distance"
            />
            <CustomUnitPreferenceToggle
              value={user.display_preference?.elevation ?? ImperialMetricDisplayPreference.metric}
              onChange={handleElevationPreferenceChange}
              label={t`Elevation`}
              metricOption={t`Meters`}
              imperialOption={t`Feet`}
              testIdPrefix="elevation"
            />
            <CustomUnitPreferenceToggle
              value={user.display_preference?.temperature ?? ImperialMetricDisplayPreference.metric}
              onChange={handleTemperaturePreferenceChange}
              label={t`Temperature`}
              metricOption={t`Celsius`}
              imperialOption={t`Fahrenheit`}
              testIdPrefix="temperature"
            />
            <CustomUnitPreferenceToggle
              value={user.display_preference?.weight ?? ImperialMetricDisplayPreference.metric}
              onChange={handleWeightPreferenceChange}
              label={t`Weight`}
              metricOption={t`Kilograms`}
              imperialOption={t`Pounds`}
              testIdPrefix="weight"
            />
          </Stack>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <HeightInput
          value={height}
          unitPreference={
            user.display_preference?.speed_distance ?? ImperialMetricDisplayPreference.metric
          }
          onBlur={handleHeightChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WeightInput
          value={weight}
          unitPreference={user.display_preference?.weight ?? ImperialMetricDisplayPreference.metric}
          onBlur={handleWeightChange}
        />
      </Grid>
      <Grid item xs={12}>
        <CountryInput value={user.country} handleChange={handleCountryChange} />
      </Grid>
    </Grid>
  );
};

export default AboutYou;
