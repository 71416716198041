import { useMemo, useState } from "react";
import { getTemplateTypes } from "./templateData";
import { getWorkoutTypeIcon } from "@/services/getWorkoutTypeIcon";
import { getWorkoutTypeNames } from "./WorkoutTypeNames";
import { WahooWorkoutType, WahooWorkoutTypeFamily } from "@WahooFitness/cloud-client-ts/Types";

const useAddWorkoutProfile = (familyFilter?: WahooWorkoutTypeFamily) => {
  const [newOrExistingToggle, setNewOrExistingToggle] = useState<"newProfile" | "existingProfile">(
    "newProfile"
  );

  const workoutTypeNames = getWorkoutTypeNames();

  const templateData = useMemo(() => {
    return getTemplateTypes()
      .filter((family) =>
        familyFilter !== undefined ? family.workoutFamilyId === familyFilter : true
      )
      .map((family) => ({
        workoutFamily: family.workoutFamilyName,
        workoutTypes: family.workoutTypes.map((typeId) => ({
          name: workoutTypeNames[WahooWorkoutType[typeId]],
          id: typeId,
          icon: getWorkoutTypeIcon(typeId),
        })),
      }));
  }, [familyFilter, workoutTypeNames]);

  return {
    templateData,
    newOrExistingToggle,
    setNewOrExistingToggle,
  };
};

export default useAddWorkoutProfile;
