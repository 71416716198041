import { Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import { MenuList, MenuListItemType } from "@WahooFitness/redesignr";

interface Props {
  moderate5kmListItems: MenuListItemType[];
}

function Moderate5kmQuestion({ moderate5kmListItems }: Props) {
  return (
    <Box mx={1} display="flex" flexDirection="column">
      <Box mx={4} display="flex" flexDirection="column">
        <Box>
          <Typography variant="prose-base-medium">
            <Trans>I would complete a 5km (3.1miles) at a moderate effort by:</Trans>
          </Typography>
        </Box>
        <Box>
          <Typography variant="prose-sm" color="text.secondary">
            <Trans>
              Moderate intensity: controlled breathing and can speak in complete sentences
            </Trans>
          </Typography>
        </Box>
      </Box>
      <MenuList listItems={moderate5kmListItems} />
    </Box>
  );
}

export default Moderate5kmQuestion;
