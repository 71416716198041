import { Trans, t } from "@lingui/macro";
import { Box, Button } from "@mui/material";
import { Task } from "@carbon/icons-react";
import OnboardingHeader from "@/components/Onboarding/OnboardingHeader";
import PrimaryUseQuestion from "./PrimaryUseQuestion";
import MainSportQuestion from "./MainSportQuestion";
import useOnboardingTrainingPreferences from "./useOnboardingTrainingPreferences";

function TrainingPreferences() {
  const {
    sportOptionListItems,
    primaryUseListItems,
    otherSelected,
    nextDisabled,
    saveAndNavigate,
    otherTextRef,
  } = useOnboardingTrainingPreferences();

  return (
    <>
      <Box display="flex" flexDirection="column" gap={4} overflow="auto">
        <OnboardingHeader
          Icon={Task}
          title={t`Training preferences`}
          subtitle={t`Tell us a bit about your preferences to help us tailor workout recommendations just for you.`}
        />
        <MainSportQuestion sportOptionListItems={sportOptionListItems} />
        <PrimaryUseQuestion
          primaryUseListItems={primaryUseListItems}
          otherSelected={otherSelected}
          otherTextRef={otherTextRef}
        />
      </Box>
      <Box p={3} width="100%" mt="auto" maxWidth="sm">
        <Button
          variant="contained"
          size="large"
          fullWidth
          onClick={saveAndNavigate}
          disabled={nextDisabled}
        >
          <Trans>Next</Trans>
        </Button>
      </Box>
    </>
  );
}

export default TrainingPreferences;
