import Settings from "@/components/Settings/Settings";
import { useInitialRouteContext } from "@WahooFitness/wahoo-offline-mfe";
import { Navigate } from "react-router";

function Home() {
  const { route } = useInitialRouteContext();

  if (route !== undefined) {
    return <Navigate to={route} replace state={{ initial: true }} />;
  }

  return <Settings />;
}

export default Home;
