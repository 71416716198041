import { useCloudContext, useConfigContext } from "@WahooFitness/wahoo-offline-mfe";
import { PaceZones } from "@WahooFitness/cloud-client-ts";
import { PaceZoneAlgorithmLookupsResponseType } from "@WahooFitness/cloud-client-ts/Types";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Zone } from "@/components/Zones/useZones";
import { useLingui } from "@lingui/react";
import { useUnitFormatter } from "@/hooks/useUnitFormatter";

type NonBooleanKeys<T> = {
  [K in keyof T]: T[K] extends boolean ? never : K;
}[keyof T];

function useSetUpPaces({ closeAfterSave }: { closeAfterSave: boolean }) {
  const navigate = useNavigate();
  const { wahooToken } = useConfigContext();
  const { getCloudClient } = useCloudContext();
  const { subtractPaceSecondFromMps } = useUnitFormatter();
  const paceZoneClient = useMemo(() => getCloudClient(PaceZones), [getCloudClient]);

  const [setupPacesDrawerOpen, setSetupPacesDrawerOpen] = useState(false);
  const [yourPaceZones, setYourPaceZones] = useState<
    PaceZoneAlgorithmLookupsResponseType | undefined
  >(undefined);
  const [showResultPage, setShowResultPage] = useState(false);

  const handleOpen = useCallback(() => {
    setSetupPacesDrawerOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setSetupPacesDrawerOpen(false);
  }, []);
  const handleSkip = useCallback(() => {
    navigate("/onboarding/guided-video");
  }, [navigate]);
  const handleBackFromResult = useCallback(() => {
    setShowResultPage(false);
  }, []);

  const savePaceZonesToCloud = useCallback(async () => {
    if (yourPaceZones) {
      await paceZoneClient.post(wahooToken, {
        name: "Wahoo Pace Zones",
        primary: true,
        ...yourPaceZones,
      });
    }
  }, [paceZoneClient, wahooToken, yourPaceZones]);

  const resetSetUpPaces = useCallback(() => {
    setYourPaceZones(undefined);
    setShowResultPage(false);
  }, [setYourPaceZones, setShowResultPage]);

  const handleSave = useCallback(async () => {
    if (closeAfterSave) {
      handleClose();
      resetSetUpPaces();
    } else {
      await savePaceZonesToCloud();
      navigate("/onboarding/guided-video");
    }
  }, [closeAfterSave, savePaceZonesToCloud, navigate, handleClose, resetSetUpPaces]);

  const { i18n } = useLingui();

  const getBottomValue = useCallback(
    (yourPaceZones: PaceZoneAlgorithmLookupsResponseType, zone: number) => {
      if (zone === 1) {
        return 0;
      }
      const previousZoneTop =
        yourPaceZones[
          `zone_${zone - 1}` as keyof NonBooleanKeys<PaceZoneAlgorithmLookupsResponseType>
        ];

      return subtractPaceSecondFromMps(previousZoneTop);
    },
    [subtractPaceSecondFromMps]
  );

  const getTopValue: (
    yourPaceZones: PaceZoneAlgorithmLookupsResponseType,
    zone: number
  ) => number | "max" = useCallback(
    (yourPaceZones: PaceZoneAlgorithmLookupsResponseType, zone: number) => {
      if (zone === yourPaceZones.zone_count) {
        return "max";
      }

      return yourPaceZones[
        `zone_${zone}` as keyof NonBooleanKeys<PaceZoneAlgorithmLookupsResponseType>
      ];
    },
    []
  );

  const getZoneSetFromPaceZones = useCallback(
    (displayIndex: number) => {
      if (!yourPaceZones) {
        return undefined;
      }
      const zones = Array.from({ length: yourPaceZones.zone_count }, (_, i) => i + 1);
      const formattedPaceZones: Zone[] = zones.map((zone) => {
        const bottom = getBottomValue(yourPaceZones, zone);
        const top = getTopValue(yourPaceZones, zone);

        return {
          bottom,
          top,
          name: i18n._("Zone {zone}", { zone }),
        };
      });
      return {
        name: yourPaceZones.name,
        threshold_speed: yourPaceZones.threshold_speed,
        algorithm: yourPaceZones.pace_zone_algorithm_id,
        primary: yourPaceZones.primary,
        zone_count: 7,
        zone_1: yourPaceZones.zone_1,
        zone_2: yourPaceZones.zone_2,
        zone_3: yourPaceZones.zone_3,
        zone_4: yourPaceZones.zone_4,
        zone_5: yourPaceZones.zone_5,
        zone_6: yourPaceZones.zone_6,
        zone_7: yourPaceZones.zone_7,
        zones: formattedPaceZones,
        displayIndex,
      };
    },
    [yourPaceZones, getBottomValue, getTopValue, i18n]
  );

  return {
    setupPacesDrawerOpen,
    handleOpen,
    handleClose,
    handleSkip,
    yourPaceZones,
    setYourPaceZones,
    showResultPage,
    setShowResultPage,
    handleBackFromResult,
    handleSave,
    getZoneSetFromPaceZones,
  };
}

export default useSetUpPaces;
