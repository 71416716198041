import { Role, UserType } from "@WahooFitness/cloud-client-ts/Types";
import { t } from "@lingui/macro";
import Sex from "./types/Sex";

export const getKnightTitle = (user?: UserType) => {
  if (user?.roles?.includes(Role.knight)) {
    switch (user?.gender) {
      case Sex.Male:
        return t`Sir`;
      case Sex.Female:
        return t`Dame`;
      case Sex.Other:
        return t`Knight`;
      default:
        return t`Knight`;
    }
  }
  return "";
};
