import { useContext } from "react";
import { RoutesContext } from "@/contexts/RoutesContext";

export const useRoutesContext = () => {
  const context = useContext(RoutesContext);
  if (!context) {
    throw new Error("useRoutesContext must be used within a RoutesContextProvider");
  }
  return context;
};
