import SummitSegmentSettings from "@/components/WorkoutProfiles/SummitSegments/SummitSegmentSettings";
import { useParams } from "react-router";

const SummitSegmentSettingsPage = () => {
  const params = useParams();

  return <SummitSegmentSettings workoutProfileId={+(params.profileId || 0)} />;
};

export default SummitSegmentSettingsPage;
