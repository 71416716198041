import { t } from "@lingui/macro";

enum securityTypes {
  Open = 0,
  Wep = 1,
  Wpa = 2,
  Wpa2 = 3,
  WpaWpa2 = 4,
  Wpa2Enterprise = 5,
  Wpa3 = 6,
  Wpa2Wpa3 = 7,
  Wapi = 8,
}

const getSecurityTypeNames = (): Record<securityTypes, string> => ({
  [securityTypes.Open]: t`Open`,
  [securityTypes.Wep]: t`WEP`,
  [securityTypes.Wpa]: t`WPA`,
  [securityTypes.Wpa2]: t`WPA2`,
  [securityTypes.WpaWpa2]: t`WPA/WPA2`,
  [securityTypes.Wpa2Enterprise]: t`WPA2 Enterprise`,
  [securityTypes.Wpa3]: t`WPA3`,
  [securityTypes.Wpa2Wpa3]: t`WPA2/WPA3`,
  [securityTypes.Wapi]: t`WAPI`,
});

export function getSecurityTypeName(securityType: securityTypes) {
  const typeName = getSecurityTypeNames()[securityType];
  return typeName ?? `Unknown`;
}
