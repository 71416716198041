import { useUnitFormatter } from "@/hooks/useUnitFormatter";
import React, { useCallback } from "react";
import { Zone } from "./useZones";
import { FilledInput, FormControl, TextField } from "@mui/material";
import { t } from "@lingui/macro";
import FlexibleDurationInput from "../StyledComponents/FlexibleDurationInput";

type Props = {
  type: "power" | "heartRate" | "speed";
  index: number;
  zone: Zone;
  handleZoneChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => void;
  handleFocus: (index: number) => void;
  handleBlur: (index: number) => void;
  invalidIndex: number | undefined;
};

const ZonesEditorTextField = ({
  type,
  index,
  zone,
  handleZoneChange,
  handleFocus,
  handleBlur,
  invalidIndex,
}: Props) => {
  const { unitFormatter } = useUnitFormatter();

  const getZoneValue = useCallback((value: number) => value || undefined, []);

  if (type === "speed") {
    const isZone1 = index === 0;
    const value = unitFormatter?.formatRunningPace(getZoneValue(zone.bottom) ?? 0).rawValue;
    return (
      <FormControl fullWidth variant="outlined">
        <FilledInput
          disabled={isZone1}
          placeholder={isZone1 ? t`∞` : t`mm:ss`}
          hiddenLabel
          disableUnderline
          value={value}
          size="small"
          onFocus={() => handleFocus(index)}
          onBlur={(event) => {
            handleZoneChange(event, index);
          }}
          inputComponent={FlexibleDurationInput as any}
          inputProps={{ style: { textAlign: "center" }, inputMode: "numeric" }}
        />
      </FormControl>
    );
  }
  const inputType = "number";
  const pattern = "[0-9]*";
  const inputMode = "numeric";
  const value = getZoneValue(zone.bottom)?.toString() ?? "0";
  return (
    <TextField
      variant="outlined"
      value={value}
      size="small"
      disabled={index === 0}
      type={inputType}
      inputProps={{
        style: { textAlign: "right" },
        inputMode: inputMode,
        pattern: pattern,
        "data-testid": `zoneInput${index + 1}`,
      }}
      error={index === invalidIndex}
      onChange={(event) => handleZoneChange(event, index)}
      onFocus={() => handleFocus(index)}
      onBlur={() => handleBlur(index)}
    />
  );
};

export default ZonesEditorTextField;
