import { MenuListItemType, MenuListItemVariant } from "@WahooFitness/redesignr";
import { Checkmark } from "@carbon/icons-react";
import { t } from "@lingui/macro";
import { useTheme } from "@mui/system";
import { useState, useCallback, useMemo } from "react";

function useBestKnownTimesPage() {
  const { palette } = useTheme();
  const [bestTimeIsKnown, setBestTimeIsKnown] = useState<boolean>(true);

  const handleBestKnownTimeQuestionChange = useCallback(
    (value: boolean) => setBestTimeIsKnown(value),
    [setBestTimeIsKnown]
  );
  const bestKnownTimesQuestionItems: MenuListItemType[] = useMemo(
    () =>
      [
        { name: t`I know`, value: true },
        { name: t`I do not know`, value: false },
      ].map(({ name, value }) => ({
        id: name,
        variant: MenuListItemVariant.Action,
        content: name,
        action: () => handleBestKnownTimeQuestionChange(value),
        actionComponent:
          value === bestTimeIsKnown ? <Checkmark size={24} color={palette.info.main} /> : null,
      })),
    [bestTimeIsKnown, palette.info.main, handleBestKnownTimeQuestionChange]
  );

  return {
    bestTimeIsKnown,
    bestKnownTimesQuestionItems,
  };
}

export default useBestKnownTimesPage;
