import React from "react";
import { Button, Typography } from "@mui/material";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";

const SubmitFeedbackButton: React.FC = () => {
  return (
    <Button
      size="large"
      component={Link as any}
      to={"https://wahoosystm.com/Apollo/reportbug"}
      target="_blank"
      fullWidth
      variant="contained"
      color="secondary"
    >
      <Typography variant="ui-base-medium">
        <Trans>Submit feedback</Trans>
      </Typography>
    </Button>
  );
};

export default SubmitFeedbackButton;
