import { useUnitFormatter } from "@/hooks/useUnitFormatter";
import { useTheme } from "@mui/system";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
  Tooltip,
} from "recharts";
import { CategoricalChartState } from "recharts/types/chart/types";
import ElevationRecord from "./ElevationRecord";

const ElevationChart = ({
  elevationRecords,
  setSelectedPointInfo,
}: {
  elevationRecords: ElevationRecord[];
  setSelectedPointInfo?: Dispatch<
    SetStateAction<
      | { alt_m: number; dist_m: number; grade_perc: number; lat_deg: number; lon_deg: number }
      | undefined
    >
  >;
}) => {
  const { palette } = useTheme();
  const { unitFormatter } = useUnitFormatter();
  const [referenceLineIndex, setReferenceLineIndex] = useState<number | undefined>();

  const onChartMouseMove = useCallback(
    (payload: CategoricalChartState, _event: TouchEvent | MouseEvent) => {
      if (setSelectedPointInfo && payload?.activePayload?.[0]?.payload) {
        setSelectedPointInfo({
          alt_m: payload.activePayload[0].payload.alt_m,
          dist_m: payload.activePayload[0].payload.dist_m,
          grade_perc: payload.activePayload[0].payload.grade_perc,
          lat_deg: payload.activePayload[0].payload.lat_deg,
          lon_deg: payload.activePayload[0].payload.lon_deg,
        });
        setReferenceLineIndex(payload.activePayload[0].payload.dist_m);
      }
    },
    [setSelectedPointInfo]
  );

  const getColorStops = useCallback(
    (data: any[]) => {
      const colorStops: JSX.Element[] = [];
      data.forEach((entry, index) => {
        const offset = (index / (data.length - 1)) * 100;
        let color;
        if (entry.grade_perc < 0) {
          color = palette.dataGrade6.main;
        } else if (entry.grade_perc < 4) {
          color = palette.dataGrade1.main;
        } else if (entry.grade_perc < 8) {
          color = palette.dataGrade2.main;
        } else if (entry.grade_perc < 12) {
          color = palette.dataGrade3.main;
        } else if (entry.grade_perc < 20) {
          color = palette.dataGrade4.main;
        } else {
          color = palette.dataGrade5.main;
        }
        colorStops.push(
          <stop key={`stop-${index}-start`} stopColor={color} stopOpacity={1} />,
          <stop
            key={`stop-${index}-end`}
            offset={`${offset + 0.01}%`}
            stopColor={color}
            stopOpacity={1}
          />
        );
      });

      return colorStops;
    },
    [
      palette.dataGrade1.main,
      palette.dataGrade2.main,
      palette.dataGrade3.main,
      palette.dataGrade4.main,
      palette.dataGrade5.main,
      palette.dataGrade6.main,
    ]
  );

  const maxElevation = useMemo(
    () => Math.max(...elevationRecords.map((record) => record.alt_m ?? 0)),
    [elevationRecords]
  );
  const numDigits = useMemo(
    () => Math.round(unitFormatter?.formatElevation(maxElevation).rawValue ?? 0).toString().length,
    [maxElevation, unitFormatter]
  );

  const marginRight = useMemo(() => {
    const digitCountsMarginWidthLookup: { [key: number]: number } = {
      5: 32,
      4: 26,
      3: 18,
      2: 12,
      1: 8,
    };
    return digitCountsMarginWidthLookup[numDigits] || 32;
  }, [numDigits]);

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={112} minWidth={200}>
      <AreaChart
        onMouseMove={onChartMouseMove}
        width={500}
        height={112}
        data={elevationRecords}
        margin={{
          top: 10,
          right: marginRight,
          left: 0,
          bottom: 0,
        }}
      >
        {setSelectedPointInfo && (
          <Tooltip active={undefined} content={<div style={{ display: "none" }}></div>} />
        )}
        <CartesianGrid strokeDasharray="3 3" vertical={false} stroke={palette.text.secondary} />
        <XAxis dataKey="dist_m" hide />
        <YAxis
          domain={["auto", "auto"]}
          tickCount={4}
          tickLine={false}
          orientation="right"
          axisLine={false}
          width={10}
          tick={{ fontSize: 11, fill: palette.text.secondary }}
          tickFormatter={(value) =>
            Math.round(unitFormatter?.formatElevation(value).rawValue ?? value).toString()
          }
        />
        <defs>
          <linearGradient id="splitColor">{getColorStops(elevationRecords)}</linearGradient>
        </defs>
        <Area
          isAnimationActive={false}
          type="monotone"
          dataKey="alt_m"
          stroke={palette.text.primary}
          fill="url(#splitColor)"
        />
        <ReferenceLine
          ifOverflow="visible"
          isFront
          x={referenceLineIndex}
          stroke={palette.info.light}
          strokeWidth={3}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default ElevationChart;
