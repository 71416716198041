import { WsmContext as RealWsmContext } from "@/contexts/WsmContext";
import { WsmContext as WebWsmContext } from "src/webMocks/routeMockData/WebWsmContext";
import { Capacitor } from "@capacitor/core";
import { useContext } from "react";

export const useWsmPlugin = () => {
  const WsmContext = Capacitor.getPlatform() === "web" ? WebWsmContext : RealWsmContext;
  const context = useContext(WsmContext);
  if (!context) {
    throw new Error("useWsmPlugin must be used within a WsmContextProvider");
  }
  return context;
};
