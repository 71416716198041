import ErrorBoundary from "@/components/ErrorBoundary";
import { Checkmark, Close } from "@carbon/icons-react";
import { alpha, Avatar, Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import { postClose } from "@WahooFitness/wahoo-offline-mfe";
import React, { useCallback } from "react";
import BoltProductImage from "../BoltProductImage";
import { ChevronLeftIcon } from "@WahooFitness/redesignr";
import { useNavigate } from "react-router-dom";
import { Trans } from "@lingui/macro";
import { useFirmwareOnboarding } from "./useFirmwareOnboarding";
import { WSMBoltFwuUpgradeState } from "@WahooFitness/wsm-native/dist/esm/types/bolt_firmware";

interface FirmwareOnboardingProps {
  appToken: string;
}

const FirmwareOnboarding: React.FC<FirmwareOnboardingProps> = ({ appToken }) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const [debugModeTaps, setDebugModeTaps] = React.useState<{ firstTapTime: number; count: number }>(
    { firstTapTime: 0, count: 0 }
  );
  const handleDebugTap = useCallback(() => {
    if (debugModeTaps.count >= 10) {
      return;
    }
    const now = Date.now();
    if (now - debugModeTaps.firstTapTime > 3000) {
      setDebugModeTaps({ firstTapTime: now, count: 1 });
      return;
    }
    setDebugModeTaps((prev) => ({ ...prev, count: prev.count + 1 }));
  }, [debugModeTaps.count, debugModeTaps.firstTapTime]);

  const { displayState, progressPercent, marketingMessage, debugFirmwareStatus, device } =
    useFirmwareOnboarding(appToken);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        p={2}
        height={56}
        onClick={handleDebugTap}
      >
        {displayState === "failed" && (
          <>
            <ChevronLeftIcon onClick={() => navigate("..")} />
            <Close color={palette.text.primary} size={24} onClick={() => postClose("onboarding")} />
          </>
        )}
      </Box>
      <ErrorBoundary isLoading={!device} error={undefined}>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
        >
          <Box display="flex" flexDirection="column" width="100%" alignItems="center">
            <Box m={3}>
              <BoltProductImage boltType={device?.boltType} appToken={appToken} height={200} />
            </Box>
            {displayState === "updating" && (
              <Box display="flex" flexDirection="column" gap={1} m={3} alignItems="center">
                <Avatar
                  sx={{ width: "72px", height: "72px", bgcolor: alpha(palette.info.main, 0.1) }}
                >
                  {progressPercent >= 1 && progressPercent <= 100 ? (
                    <CircularProgress
                      size="56px"
                      color="info"
                      variant="determinate"
                      value={progressPercent}
                    />
                  ) : (
                    <CircularProgress size="56px" color="info" />
                  )}
                </Avatar>
                <Typography textAlign="center" variant="prose-lg-bold">
                  <Trans>Updating {device?.productTypeName}...</Trans>
                </Typography>
                <Typography textAlign="center" variant="prose-sm">
                  {marketingMessage}
                </Typography>
              </Box>
            )}
            {displayState === "done" && (
              <Box display="flex" flexDirection="column" gap={1} m={3} alignItems="center">
                <Avatar
                  sx={{ width: "72px", height: "72px", bgcolor: alpha(palette.success.main, 0.1) }}
                >
                  <Checkmark size="56px" color={palette.success.main} />
                </Avatar>
                <Typography textAlign="center" variant="prose-lg-bold">
                  <Trans>Your {device?.productTypeName} is up to date</Trans>
                </Typography>
              </Box>
            )}
            {displayState === "failed" && (
              <Box display="flex" flexDirection="column" gap={1} m={3} alignItems="center">
                <Avatar
                  sx={{ width: "72px", height: "72px", bgcolor: alpha(palette.error.main, 0.1) }}
                >
                  <Close size="56px" color={palette.error.main} />
                </Avatar>
                <Typography textAlign="center" variant="prose-lg-bold">
                  <Trans>Update failed</Trans>
                </Typography>
              </Box>
            )}
          </Box>
          {debugModeTaps.count >= 10 && (
            <Box mx={2} m={1}>
              <Typography variant="prose-xs-medium">
                <pre>
                  {JSON.stringify(
                    {
                      ...debugFirmwareStatus,
                      upgradeState: WSMBoltFwuUpgradeState[debugFirmwareStatus?.upgradeState ?? 0],
                    },
                    null,
                    2
                  )}
                </pre>
              </Typography>
            </Box>
          )}
          {displayState === "updating" && (
            <Box mx={2} mb={6}>
              <Typography textAlign="center" variant="prose-2xs-medium">
                <Trans>
                  The update should take a few minutes. Keep your device on and do not use other
                  apps during the process. Your {device?.productTypeName} will reboot up to two
                  times automatically.
                </Trans>
              </Typography>
            </Box>
          )}
          {displayState === "done" && (
            <Box px={4} mb={4} width="100%">
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={() => navigate("../maps")}
              >
                <Trans>Continue</Trans>
              </Button>
            </Box>
          )}
        </Box>
      </ErrorBoundary>
    </Box>
  );
};

export { FirmwareOnboarding };
