import { useHeaderContext } from "@/hooks/useHeaderContext";
import { t } from "@lingui/macro";
import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import PlanCard from "./PlanCard";
import routesImage from "@/assets/Routes.png";
import trainingPlansImage from "@/assets/TrainingPlans.png";
import TheShovelPlanGraph from "./WorkoutGraphs/TheShovelPlanGraph";
import HalfHourOfPowerPlanGraph from "./WorkoutGraphs/HalfHourOfPowerPlanGraph";
import EqualizerPlanGraph from "./WorkoutGraphs/EqualizerPlanGraph";
import ButterPlanGraph from "./WorkoutGraphs/ButterPlanGraph";
import AVeryDarkPlacePlanGraph from "./WorkoutGraphs/AVeryDarkPlacePlanGraph";
import TrainingPlansDialog from "./TrainingPlansDialog/TrainingPlansDialog";

const PlanLanding = () => {
  const { setNavHeader } = useHeaderContext();

  const headerProps = useMemo(() => {
    return {
      title: t`Plan`,
      disableBackButton: true,
    };
  }, []);

  useEffect(() => {
    setNavHeader(headerProps);
  });

  const [trainingPlansDialogIsOpen, setTrainingPlansDialogIsOpen] = useState(false);

  const handleCloseTrainingPlansDialog = useCallback(() => {
    setTrainingPlansDialogIsOpen(false);
  }, []);

  return (
    <Box display="flex" flexDirection="column" p={2} gap={2}>
      <PlanCard
        title={t`Workouts`}
        description={t`Developed by world-class coaches to help you reach your goals.`}
        imageElement={
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            style={{ transform: "translateX(190px)" }}
          >
            <TheShovelPlanGraph />
            <AVeryDarkPlacePlanGraph />
            <ButterPlanGraph />
            <EqualizerPlanGraph />
            <HalfHourOfPowerPlanGraph />
          </Box>
        }
        imageMask="radial-gradient(67.58% 72.5% at 67.58% 50%, #000 62.5%, transparent 100%)"
        maskWidth={182}
        maskHeight={200}
        linkLocation={"/workouts"}
        linkType="internal"
      />
      <PlanCard
        title={t`Routes`}
        description={t`Add routes and navigate with ease on ELEMNT.`}
        imageElement={
          <img
            src={routesImage}
            height={269}
            width="auto"
            style={{ transform: "translateY(27px) translateX(-16px)" }}
          />
        }
        imageMask="linear-gradient(185deg, transparent 3.76%, #000 40.96%)"
        maskWidth={233}
        maskHeight={200}
        linkLocation={"/routes?back=true"}
        linkType="internal"
      />
      <PlanCard
        title={t`Training Plans`}
        description={t`Tailored to your goals and lifestyle in the Wahoo SYSTM app.`}
        imageElement={
          <img
            src={trainingPlansImage}
            height={414}
            width="auto"
            style={{ transform: "translateY(-150px) translateX(-8px)" }}
          />
        }
        imageMask="linear-gradient(185deg, transparent 3.76%, #000 40.96%)"
        maskWidth={233}
        maskHeight={200}
        onClick={() => setTrainingPlansDialogIsOpen(true)}
      />
      <TrainingPlansDialog
        open={trainingPlansDialogIsOpen}
        onClose={handleCloseTrainingPlansDialog}
      />
    </Box>
  );
};

export default PlanLanding;
