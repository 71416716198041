import { forwardRef } from "react";
import { IMask, IMaskInput } from "react-imask";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

const blocks = {
  SingleDigit9: {
    mask: IMask.MaskedRange,
    from: 0,
    to: 9,
    maxLength: 1,
  },
  DoubleDigit59: {
    mask: IMask.MaskedRange,
    from: 0,
    to: 59,
    maxLength: 2,
  },
  DoubleDigit99: {
    mask: IMask.MaskedRange,
    from: 0,
    to: 99,
    maxLength: 2,
  },
};

const FlexibleDurationInput = forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask={[
          {
            mask: "SingleDigit9",
            blocks: { ...blocks },
          },
          {
            mask: "DoubleDigit59",
            blocks: { ...blocks },
          },
          {
            mask: "SingleDigit9:DoubleDigit59",
            blocks: { ...blocks },
          },
          {
            mask: "DoubleDigit59:DoubleDigit59",
            blocks: { ...blocks },
          },
        ]}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

export default FlexibleDurationInput;
