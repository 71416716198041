import WorkoutProfiles from "@/components/WorkoutProfiles/WorkoutProfiles";
import React from "react";
import { useSearchParams } from "react-router-dom";

const WorkoutProfilesPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const openAsDrawer = searchParams.get("drawer") === "true";

  return <WorkoutProfiles openAsDrawer={openAsDrawer} />;
};

export default WorkoutProfilesPage;
