import { useHeaderContext } from "@/hooks/useHeaderContext";
import { t } from "@lingui/macro";
import { useEffect } from "react";
import WifiNetworks from "./WifiNetworks";

const WifiConfig = ({ appToken }: { appToken: string }) => {
  const { setNavHeader } = useHeaderContext();
  useEffect(() => {
    setNavHeader({ title: t`Wi-Fi` });
  });

  return <WifiNetworks appToken={appToken} />;
};

export default WifiConfig;
