import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import { useMemo } from "react";
import { useCloudContext, useConfigContext, useOfflineSWR } from "@WahooFitness/wahoo-offline-mfe";
import { WorkoutPlanGroups } from "@WahooFitness/cloud-client-ts";
import usePlanGroupToPlanGroupCardProps from "./usePlanGroupToPlanGroupCardProps";

function useWorkouts() {
  const { curatedWorkoutsList } = useFlaggedFeatures();

  const { getCloudClient } = useCloudContext();
  const { wahooToken } = useConfigContext();
  const planGroupsClient = useMemo(() => getCloudClient(WorkoutPlanGroups), [getCloudClient]);

  const {
    data: cloudPlanGroups,
    error,
    isLoading,
  } = useOfflineSWR(["CloudPlanGroups", wahooToken], ([_key, token]) =>
    planGroupsClient.get(token, ["plans", "plans:plan_metric"])
  );

  const workoutsList = useMemo(() => {
    if (!cloudPlanGroups?.length) {
      return [];
    }
    return cloudPlanGroups
      ?.filter((planGroup) => curatedWorkoutsList.includes(planGroup.id))
      .map(usePlanGroupToPlanGroupCardProps);
  }, [cloudPlanGroups, curatedWorkoutsList]);

  return {
    workoutsList,
    error,
    isLoading,
    // sendWorkoutToConnectedDevices,
    refreshWorkoutsList: () => {},
  };
}

export default useWorkouts;
