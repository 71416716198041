import NavHeader from "@/components/NavHeader";
import { useRoutesContext } from "@/hooks/useRoutesContext";
import { CloseFilled } from "@carbon/icons-react";
import { t, Trans } from "@lingui/macro";
import { Box, Button, Drawer, IconButton, TextField } from "@mui/material";
import { WSMRoute } from "@WahooFitness/wsm-native/dist/esm/types/route";
import { ChangeEvent, useCallback, useState } from "react";

type EditDrawerProps = {
  route: WSMRoute;
  open: boolean;
  onClose: () => void;
};

const EditDrawer = ({ route, open, onClose }: EditDrawerProps) => {
  const { editRoute } = useRoutesContext();

  const [name, setName] = useState(route.name);
  const handleDone = useCallback(() => {
    if (route.token) {
      editRoute(route.token, { name });
    }
    onClose();
  }, [editRoute, name, onClose, route.token]);

  const [nameError, setNameError] = useState(false);

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 191) {
      setNameError(false);
    } else {
      setNameError(true);
    }
    setName(e.target.value);
  }, []);

  const handleClearName = useCallback(() => {
    setName("");
  }, []);
  return (
    <Drawer open={open} onClose={onClose} anchor="bottom" elevation={1}>
      <Box height="80dvh" width="100%">
        <NavHeader
          title="Edit route"
          backText={t`Cancel`}
          backAction={onClose}
          headerAction={
            <Button variant="text" size="large" onClick={handleDone} disabled={!name || nameError}>
              <Trans>Done</Trans>
            </Button>
          }
          disableBoxShadow
          elevation={1}
        />
        <Box m={3}>
          <TextField
            value={name}
            onChange={handleNameChange}
            label={t`Name`}
            InputProps={{
              endAdornment: name ? (
                <IconButton size="small" onClick={handleClearName}>
                  <CloseFilled size={24} />
                </IconButton>
              ) : undefined,
            }}
            helperText={nameError ? t`Name must be 191 characters or less` : ""}
            error={nameError}
            fullWidth
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default EditDrawer;
