import { useWsmPlugin } from "@/hooks/useWsmPlugin";
import { t } from "@lingui/macro";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import useSWR from "swr";

const useWifiCheck = (appToken: string) => {
  const { sensorManagerPlugin } = useWsmPlugin();
  const { data: isBoltWifiAllowed, isLoading: wifiIsLoading } = useSWR(
    ["wifiEnabled", appToken],
    async () => !!(await sensorManagerPlugin?.bcIsBoltWifiAllowed({ appToken }))?.allowed,
    {
      revalidateOnFocus: false,
    }
  );

  const navigate = useNavigate();
  const getConnectWifiDialogState = useCallback(
    (handleClose: () => void, onConfirmation?: () => void) => ({
      open: true,
      title: t`No Wi-Fi connection`,
      body: t`Do you want to connect ELEMNT to Wi-Fi?`,
      actions: [
        { text: t`Cancel`, action: handleClose },
        {
          text: t`Continue update`,
          color: "info" as const,
          action: () => {
            handleClose();
            onConfirmation?.();
            navigate(t`/device-config/${appToken}/wifi`);
          },
        },
      ],
    }),
    [appToken, navigate]
  );

  return { isBoltWifiAllowed, wifiIsLoading, getConnectWifiDialogState };
};

export default useWifiCheck;
