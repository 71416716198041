import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import MapLayerEnum from "./MapLayerEnum";
import { Record as RouteRecord } from "@WahooFitness/wsm-native/dist/esm/types/route";
import useMapTheme from "./useMapTheme";
import { newMarker } from "@/services/Map/mapHelpers";
import { LngLatLike } from "mapbox-gl";
import "@/assets/map-layers/map.css";
// this import is required to make markers work properly
import "mapbox-gl/dist/mapbox-gl.css";
import { useMapContext } from "@/hooks/useMapContext";
import MapToolbar from "./MapToolbar";
import { Box, Fab } from "@mui/material";
import { Fullscreen } from "@mui/icons-material";
import MapDrawer from "./MapDrawer";
import MapOptions from "./MapOptions";

const bottomOffset = 0.998;

const MapContainer = ({
  mapFullScreenOpen,
  handleOpenMapFullScreen,
  routeJsonRecords,
  handleClose,
}: {
  mapFullScreenOpen: boolean;
  handleOpenMapFullScreen: () => void;
  routeJsonRecords: RouteRecord[];
  selectedRouteIndices?: [number, number];
  handleRouteReversalToggle: () => void;
  routeIsReversed: boolean;
  handleClose: () => void;
}) => {
  const [loaded, setLoaded] = useState(false);
  const [mapContainerStyle, setMapContainerStyle] = useState<CSSProperties>({
    width: "100%",
    height: "250px",
  });
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const {
    mapRef,
    initMap,
    loadRoute,
    handleLayerChanged,
    handleMapTypeChanged,
    handleReset,
    resize,
    setInteractive,
  } = useMapContext();

  const mapTheme = useMapTheme();

  const selectedPointMarker = useMemo(
    () => newMarker(mapTheme.markers.selectPoint.color, mapTheme.markers.selectPoint.rotation),
    [mapTheme.markers.selectPoint.color, mapTheme.markers.selectPoint.rotation]
  );

  useEffect(() => {
    loadRoute(routeJsonRecords);
    setLoaded(true);
  }, [routeJsonRecords, loadRoute, loaded]);

  // Initialize the mapbox GL instance
  useEffect(() => {
    if (!mapContainerRef.current || !loaded) {
      return;
    }
    initMap(mapContainerRef.current);
    setInteractive(false);
  }, [mapContainerRef, loaded, initMap, setInteractive]);

  const updateLatLonSelection = useCallback(
    (lat: number, lon: number) => {
      if (!mapRef) {
        return;
      }
      selectedPointMarker?.remove();
      const lonLat: LngLatLike = { lon, lat };
      selectedPointMarker?.setLngLat(lonLat).addTo(mapRef);
    },
    [selectedPointMarker, mapRef]
  );

  const [mapOptionsOpen, setMapOptionsOpen] = useState(false);
  const handleMapOptionsOpen = useCallback(() => {
    setMapOptionsOpen(true);
  }, []);

  const handleMapOptionsClose = useCallback(() => {
    setMapOptionsOpen(false);
  }, []);

  const handleCloseAll = useCallback(() => {
    handleClose();
    handleMapOptionsClose();
  }, [handleClose, handleMapOptionsClose]);

  const resizeMapContainer = useCallback(() => {
    const canvasContainer = mapRef?.getContainer();
    if (mapFullScreenOpen) {
      setMapContainerStyle({
        width: "100%",
        height: "100vh",
      });
      canvasContainer?.style.setProperty("height", "100vh");
      resize(bottomOffset);
      setInteractive(true);
    } else {
      setMapContainerStyle({
        width: "100%",
        height: "250px",
      });
      canvasContainer?.style.setProperty("height", "250px");
      resize();
      setInteractive(false);
    }
  }, [mapFullScreenOpen, resize, mapRef, setInteractive]);

  // This effect monitors mapFullScreenOpen and only calls resize if the map is already loaded
  // The logic that is in resizeMapContainer used to be in this effect but since it depends on `resize` we can't put the logic in effect since the gpsCoordinates change `resize` when reversing the route
  // Setting it up this way ensures that the map isn't shifted when the route is reversed
  useEffect(() => {
    if (!loaded) {
      return;
    }
    resizeMapContainer();
  }, [mapFullScreenOpen, loaded]); // eslint-disable-line react-hooks/exhaustive-deps
  // we do not add `resizeMapContainer` to the dependencies because it would cause resize to run when the route is reversed

  return (
    <>
      <Box position="relative" style={mapContainerStyle}>
        {mapFullScreenOpen ? (
          <MapToolbar
            handleCloseAll={handleCloseAll}
            handleMapOptionsOpen={handleMapOptionsOpen}
            handleReset={handleReset}
            topOffset={50}
          />
        ) : (
          <Fab
            size="small"
            sx={{ position: "absolute", right: 0, margin: 1 }}
            onClick={handleOpenMapFullScreen}
          >
            <Fullscreen sx={{ color: "black" }} />
          </Fab>
        )}

        <div ref={mapContainerRef} id="map" style={{ width: "100%", height: "100%" }} />
      </Box>
      <MapDrawer updateLatLonSelection={updateLatLonSelection} open={mapFullScreenOpen} />
      <MapOptions
        open={mapOptionsOpen}
        onClose={handleMapOptionsClose}
        onLayerChanged={handleLayerChanged}
        onMapTypeChanged={handleMapTypeChanged}
        defaultSelectedLayers={[
          MapLayerEnum.CONTOUR,
          MapLayerEnum.DISTANCE_MARKERS,
          MapLayerEnum.POIS,
        ]}
      />
    </>
  );
};

export default MapContainer;
