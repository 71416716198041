import { publish } from "@ionic/portals";
import { Box, Paper, Typography } from "@mui/material";
import { ReactElement, useCallback } from "react";
import { useNavigate } from "react-router";

type PlanCardProps = {
  title: string;
  description: string;
  imageElement: ReactElement;
  imageMask?: string;
  maskWidth?: number;
  maskHeight?: number;
  linkLocation?: string;
  linkType?: "internal" | "external";
  onClick?: () => void;
};

const PlanCard = ({
  title,
  description,
  imageElement,
  imageMask,
  maskWidth,
  maskHeight,
  linkLocation,
  linkType = "internal",
  onClick,
}: PlanCardProps) => {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    linkType === "internal"
      ? navigate(linkLocation || "/")
      : publish({ topic: "openLinkExternally", data: { url: linkLocation } });
  }, [linkLocation, linkType, navigate]);
  return (
    <Box
      component={Paper}
      width="100%"
      height={200}
      elevation={4}
      overflow="hidden"
      onClick={onClick || handleClick}
      sx={{ cursor: "pointer" }}
    >
      <Box display="flex" flexDirection="row" position="relative">
        <Box display="flex" flexDirection="column" p={2.5} gap={0.5} width="50%" height={200}>
          <Typography variant="prose-xl-bold">{title}</Typography>
          <Typography variant="prose-sm-medium" color="text.secondary">
            {description}
          </Typography>
        </Box>
        <Box
          height={maskHeight}
          width={maskWidth}
          position="absolute"
          right={0}
          top={0}
          display="flex"
          justifyContent="flex-end"
          style={{ maskImage: imageMask }}
        >
          {imageElement}
        </Box>
      </Box>
    </Box>
  );
};

export default PlanCard;
