import { getStoredValue, setStoredValue } from "@WahooFitness/wahoo-offline-mfe";
import { useCallback, useEffect, useState } from "react";

type BoltTypeCache = Record<string, number>;

const storageKey = "boltTypes";

const useBoltTypeStore = () => {
  const [boltTypes, setBoltTypes] = useState<BoltTypeCache>({});
  const [isDirty, setIsDirty] = useState(true);

  useEffect(() => {
    (async () => {
      if (isDirty) {
        const storedValue = (await getStoredValue<BoltTypeCache>(storageKey)) || {};
        setBoltTypes(storedValue);
        setIsDirty(false);
      }
    })();
  }, [isDirty]);

  const getAllStoredBoltTypes = useCallback(() => {
    return boltTypes;
  }, [boltTypes]);

  const storeBoltType = useCallback(
    (appToken: string, boltType: number) => {
      if (boltTypes[appToken] === boltType) {
        return;
      }
      boltTypes[appToken] = boltType;
      (async () => {
        await setStoredValue(storageKey, boltTypes);
        setIsDirty(true);
      })();
    },
    [boltTypes]
  );

  const getStoredBoltType = useCallback(
    (appToken: string) => {
      return boltTypes[appToken];
    },
    [boltTypes]
  );

  const deleteStoredBoltType = useCallback(
    (appToken: string) => {
      if (boltTypes[appToken] === undefined) {
        return;
      }
      delete boltTypes[appToken];
      (async () => {
        setStoredValue(storageKey, boltTypes);
        setIsDirty(true);
      })();
    },
    [boltTypes]
  );

  return { getAllStoredBoltTypes, storeBoltType, getStoredBoltType, deleteStoredBoltType };
};

export default useBoltTypeStore;
