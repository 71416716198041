import AlertList from "@/components/WorkoutProfiles/Alerts/AlertList";
import { useParams } from "react-router-dom";

const AlertsPage = () => {
  const parameters = useParams();

  return <AlertList workoutProfileId={+(parameters.profileId || 0)} />;
};

export default AlertsPage;
