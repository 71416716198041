import React from "react";
import { Chip, useTheme } from "@mui/material";
import { CircleSolid } from "@carbon/icons-react";

export const FourDpChip: React.FC<{ fourDpType: string; value?: number }> = ({
  fourDpType,
  value,
}) => {
  const theme = useTheme();

  // we need that for redesignr color matching
  const colorType = `4dp${fourDpType}` as "4dpMap" | "4dpNm" | "4dpAc" | "4dpFtp";

  return (
    <Chip
      icon={<CircleSolid size={16} fill={theme.palette[colorType].main} />}
      size="small"
      label={`${fourDpType.toUpperCase()} ${value} W`}
      sx={{ maxWidth: "150px", backgroundColor: "transparent" }}
    />
  );
};
