import { Trans } from "@lingui/macro";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Link as ReactRouterLink } from "react-router-dom";

const WorkoutProfilesAlert = ({ onClose }: { onClose?: () => void }) => {
  return (
    <Box p={2}>
      <Alert severity="info" variant="filled" onClose={onClose}>
        <Typography variant="prose-base-medium">
          <Trans>Heads up!</Trans>
        </Typography>
        <Typography variant="prose-xs">
          <Trans>
            Some Device Settings have been relocated to{" "}
            <Link to="/workout-profiles" component={ReactRouterLink}>
              Workout Profiles
            </Link>
            .
          </Trans>
        </Typography>
      </Alert>
    </Box>
  );
};
export default WorkoutProfilesAlert;
