import { useContext } from "react";
import { RouteElevationContext } from "@/contexts/RouteElevationContext";

export const useRouteElevationContext = () => {
  const context = useContext(RouteElevationContext);
  if (!context) {
    throw new Error("useRoutesElevationContext must be used within a RouteElevationProvider");
  }
  return context;
};
