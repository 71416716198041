import { Close, Map } from "@carbon/icons-react";
import { Box, Button, Drawer, Typography, useTheme } from "@mui/material";
import { ChevronLeftIcon } from "@WahooFitness/redesignr";
import { postClose } from "@WahooFitness/wahoo-offline-mfe";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { t, Trans } from "@lingui/macro";
import MapsEmbed from "../Maps/MapsEmbed";
import MapRegionEmbed from "../Maps/MapRegionEmbed";
import useTilePackNames from "../Maps/useTilePackNames";
import AddMapsEmbed from "../Maps/AddMapsEmbed";
import UpdateMapsEmbed from "../Maps/UpdateMapsEmbed";
import { useBCFirstRunState } from "@/hooks/useBCFirstRunState";
import { WSMBoltAppFirstRunState } from "@WahooFitness/wsm-native/dist/esm/types/bolt_cfg";

interface MapsOnboardingProps {
  appToken: string;
}

enum PageType {
  UPDATE,
  ADD,
  INSTALLED,
}

interface SheetProps {
  pageType: PageType;
  tilePackId?: number;
}

const MapsOnboarding: React.FC<MapsOnboardingProps> = ({ appToken }) => {
  const navigate = useNavigate();
  const { palette } = useTheme();

  const [sheetStack, setSheetStack] = useState<SheetProps[]>([]);
  const pushSheet = useCallback(
    (sheet: SheetProps) => setSheetStack((prev) => [...prev, sheet]),
    []
  );

  const popSheet = useCallback(() => {
    setSheetStack((prev) => {
      const newStack = [...prev];
      newStack.pop();
      return newStack;
    });
  }, []);

  const clearSheetStack = useCallback(() => setSheetStack([]), []);

  const handleAddMapsClick = useCallback(() => {
    pushSheet({ pageType: PageType.ADD });
  }, [pushSheet]);

  const handleFolderClickInstalled = useCallback(
    (tilePackId: number) => {
      pushSheet({ pageType: PageType.INSTALLED, tilePackId });
    },
    [pushSheet]
  );

  const handleFolderClickAdd = useCallback(
    (tilePackId: number) => {
      pushSheet({ pageType: PageType.ADD, tilePackId });
    },
    [pushSheet]
  );

  const handleUpdateAllClick = useCallback(() => {
    pushSheet({ pageType: PageType.UPDATE });
  }, [pushSheet]);

  const activeSheet = useMemo(
    () => (sheetStack.length > 0 ? sheetStack[sheetStack.length - 1] : undefined),
    [sheetStack]
  );

  const { tilePackNameTranslations } = useTilePackNames();

  const drawerTitle = useMemo(() => {
    if (activeSheet?.tilePackId) {
      return tilePackNameTranslations[activeSheet.tilePackId];
    } else if (activeSheet?.pageType === PageType.ADD) {
      return t`Add maps`;
    } else if (activeSheet?.pageType === PageType.UPDATE) {
      return t`Update maps`;
    }
  }, [activeSheet?.pageType, activeSheet?.tilePackId, tilePackNameTranslations]);

  const { setStateTransition } = useBCFirstRunState(appToken);
  useEffect(() => {
    setStateTransition({
      desiredState: WSMBoltAppFirstRunState.AppSetupMaps,
      allowedStates: [WSMBoltAppFirstRunState.Complete],
    });
  }, [setStateTransition]);

  const [mapsUpToDate, setMapsUpToDate] = useState(false);

  useEffect(() => {
    if (mapsUpToDate) {
      navigate("../complete");
    }
  }, [mapsUpToDate, navigate]);

  const handleMapsUpToDate = useCallback(() => {
    setMapsUpToDate(true);
  }, [setMapsUpToDate]);

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" p={2}>
        <ChevronLeftIcon onClick={() => navigate("../firmware")} />
        <Close color={palette.text.primary} size={24} onClick={() => postClose("onboarding")} />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" p={3}>
        <Map color={palette.info.main} size={56} />
        <Typography variant="prose-lg-bold" textAlign="center">
          <Trans>Manage maps</Trans>
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" flexGrow={1} overflow="scroll" px={2}>
        <MapsEmbed
          appToken={appToken}
          onUpdateAllClick={handleUpdateAllClick}
          onFolderClick={handleFolderClickInstalled}
          onAddMapsClick={handleAddMapsClick}
          onMapsUpToDate={handleMapsUpToDate}
        />
      </Box>
      <Box px={4} mb={4} width="100%">
        <Button variant="contained" size="large" fullWidth onClick={() => navigate("../complete")}>
          <Trans>Continue</Trans>
        </Button>
      </Box>
      <Drawer onClose={clearSheetStack} open={!!activeSheet} anchor="bottom">
        <Box
          height="100dvh"
          width="100dvw"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            width="100%"
            p={2}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {sheetStack.length > 1 ? <ChevronLeftIcon onClick={popSheet} /> : <Box />}
            {(activeSheet?.tilePackId ||
              activeSheet?.pageType === PageType.ADD ||
              activeSheet?.pageType === PageType.UPDATE) && (
              <Box
                position="absolute"
                left="50%"
                textAlign="center"
                sx={{
                  transform: "translateX(-50%)",
                }}
              >
                <Typography
                  noWrap
                  variant="ui-md-medium"
                  component="div"
                  textAlign="center"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  sx={{ display: "block" }}
                >
                  {drawerTitle}
                </Typography>
              </Box>
            )}
            <Button onClick={clearSheetStack}>
              <Trans>Done</Trans>
            </Button>
          </Box>
          <Box p={2} maxWidth="sm" width="100%">
            {activeSheet?.pageType === PageType.INSTALLED && (
              <MapRegionEmbed
                appToken={appToken}
                tilePackId={activeSheet.tilePackId || 0}
                onFolderClick={handleFolderClickInstalled}
              />
            )}
            {activeSheet?.pageType === PageType.ADD && (
              <AddMapsEmbed
                appToken={appToken}
                tilePackId={activeSheet.tilePackId}
                onFolderClick={handleFolderClickAdd}
              />
            )}
            {activeSheet?.pageType === PageType.UPDATE && <UpdateMapsEmbed appToken={appToken} />}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export { MapsOnboarding };
