import { Box, List, ListItem, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { Zone } from "./useZones";
import { t } from "@lingui/macro";
import ZonesEditorTextField from "./ZonesEditorTextField";
import { useUnitFormatter } from "@/hooks/useUnitFormatter";

type Props = {
  type: "power" | "heartRate" | "speed";
  zones: Zone[];
  editable?: boolean;
  onZoneChange: (newValue: number, index: number) => void;
  fixInvalidZones: (startingIndex: number) => void;
};

const ZonesEditor = ({ type, zones, editable, onZoneChange, fixInvalidZones: fixZones }: Props) => {
  const [invalidIndex, setInvalidIndex] = useState<number | undefined>(undefined);
  const { unitFormatter, convertPaceToMetersPerSecond } = useUnitFormatter();

  const speedZoneFormat = useCallback(
    (value: number) => {
      if (value === 0) {
        return t`∞`;
      }
      return unitFormatter?.formatRunningPace(value).rawValue;
    },
    [unitFormatter]
  );

  const [valueBeforeEdit, setValueBeforeEdit] = useState<number | undefined>(undefined);

  const handleBlur = useCallback(
    (index: number) => {
      if (valueBeforeEdit && !zones[index].bottom) {
        zones[index].bottom = valueBeforeEdit;
      }
      setValueBeforeEdit(undefined);
      setInvalidIndex(undefined);
      fixZones(index);
    },
    [fixZones, valueBeforeEdit, zones]
  );

  const handlePaceZoneChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
      const rawValue = event.target.value;
      if (!event.target.value) {
        return;
      }
      const value = convertPaceToMetersPerSecond(rawValue);
      onZoneChange(value, index);
      handleBlur(index);
    },
    [handleBlur, onZoneChange, convertPaceToMetersPerSecond]
  );

  const handleZoneChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
      const rawValue = event.target.value;
      if (type === "speed") {
        return handlePaceZoneChange(event, index);
      }
      const value = +rawValue;
      if (
        (zones[index - 1] && zones[index - 1].bottom >= value) ||
        (zones[index + 1] && zones[index + 1].bottom <= value) ||
        event.target.value.includes(".") ||
        event.target.value.includes(",")
      ) {
        setInvalidIndex(index);
      } else {
        setInvalidIndex(undefined);
      }
      onZoneChange(value, index);
    },
    [handlePaceZoneChange, onZoneChange, type, zones]
  );

  const handleFocus = useCallback(
    (index: number) => setValueBeforeEdit(zones[index].bottom),
    [zones]
  );

  const getZoneText = useCallback(
    (zone: Zone, boundary: "top" | "bottom") => {
      if (boundary === "top" && zone.top === "max") {
        return t`max`;
      }
      const value = boundary === "top" ? (zone.top as number) : zone.bottom;
      return type === "speed" ? speedZoneFormat(value) : value;
    },
    [speedZoneFormat, type]
  );

  return (
    <List sx={{ width: "100%" }} disablePadding>
      {zones.map((zone, index) => {
        return (
          <ListItem key={index} disableGutters>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
              gap={1}
            >
              <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                <Box
                  height={24}
                  width={24}
                  bgcolor={`${zones.length}zonesZone${index + 1}.main`}
                  borderRadius={4}
                />
                <Typography variant="prose-sm" noWrap>
                  {zone.name}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" gap={0.5} alignItems="center">
                <Box width={editable ? "5em" : "2.5em"} textAlign="right">
                  {editable ? (
                    <ZonesEditorTextField
                      type={type}
                      index={index}
                      zone={zone}
                      handleZoneChange={handleZoneChange}
                      handleFocus={handleFocus}
                      handleBlur={handleBlur}
                      invalidIndex={invalidIndex}
                    />
                  ) : (
                    <Typography
                      variant="ui-base"
                      width="2.5em"
                      textAlign="right"
                      color="text.secondary"
                      data-testid={`zoneMin${index + 1}`}
                    >
                      {getZoneText(zone, "bottom")}
                    </Typography>
                  )}
                </Box>
                <Typography variant="ui-base" color="text.secondary" pl={1.5}>
                  -
                </Typography>
                <Typography
                  variant="ui-base"
                  width="2.5em"
                  textAlign="right"
                  color="text.secondary"
                  data-testid={`zoneMax${index + 1}`}
                >
                  {getZoneText(zone, "top")}
                </Typography>
              </Box>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ZonesEditor;
