import ElevationRecord from "@/components/Routes/RouteDetails/Elevation/ElevationRecord";
import { useRoutesContext } from "@/hooks/useRoutesContext";
import { createContext, PropsWithChildren, useCallback, useMemo } from "react";
import { Record as RouteRecord } from "@WahooFitness/wsm-native/dist/esm/types/route";

type RouteElevationContextType = {
  elevationRecords: ElevationRecord[];
  maxGrade?: number;
  minGrade?: number;
  totalAscentCalculated?: number;
  totalDescentCalculated?: number;
};

export const RouteElevationContext = createContext<RouteElevationContextType | undefined>(
  undefined
);

const calculateTotalElevationChange = (
  elevationRecords: RouteRecord[],
  type: "ascent" | "descent"
) => {
  return elevationRecords.reduce((acc, record, index, array) => {
    if (index === 0) return acc;
    const altitudeDiff = (record.alt_m ?? 0) - (array[index - 1]?.alt_m ?? 0);
    if (type === "ascent") {
      return altitudeDiff >= 1.1 ? acc + altitudeDiff : acc;
    } else if (type === "descent") {
      return altitudeDiff <= -1.1 ? acc + Math.abs(altitudeDiff) : acc;
    }
    return acc;
  }, 0);
};

export const RouteElevationProvider = ({ children }: PropsWithChildren) => {
  const { routeJson } = useRoutesContext();

  const reducedRecords = useMemo(() => {
    const routeDistance = routeJson?.records[routeJson.records.length - 1]?.dist_m ?? 0;
    const getInterval = (totalDistance: number): number => {
      if (totalDistance < 10000) {
        return 50; // Total distance under 10km /mi: reduce records to every 50m
      } else if (totalDistance < 20000) {
        return 100; // Total distance between 10km and 20km: reduce records to every 100m
      } else if (totalDistance < 50000) {
        return 200; // Total distance between 20km and 50km or: reduce records to every 200m
      } else if (totalDistance < 100000) {
        return 250; // Total distance between 50km and 100km: reduce records to every 250m
      } else {
        return 500; // Total distance over 100km: reduce records to every 500m
      }
    };
    const interval = getInterval(routeDistance);
    const reducedRecordsData: Record<number, RouteRecord> = {};
    routeJson?.records.forEach((record: any) => {
      const distance = record.dist_m || 0;
      const nearestMultiple = Math.floor(distance / interval) * interval;
      if (distance > nearestMultiple) {
        reducedRecordsData[nearestMultiple] = record;
      }
    });
    return Object.values(reducedRecordsData);
  }, [routeJson?.records]);

  const calculateGrade = useCallback((prev: any, curr: any) => {
    const distanceDiff = curr.dist_m - prev.dist_m;
    const altitudeDiff = curr.alt_m - prev.alt_m;
    return Number(((altitudeDiff / distanceDiff) * 100).toFixed(1));
  }, []);

  const elevationRecords = useMemo(
    () =>
      reducedRecords.map((record: any, index: number, array: any) => {
        if (index === 0) {
          return {
            dist_m: record.dist_m,
            alt_m: record.alt_m,
            grade_perc: 0,
            lat_deg: record.lat_deg,
            lon_deg: record.lon_deg,
          };
        }
        const grade = calculateGrade(array[index - 1], record);
        return {
          dist_m: record.dist_m,
          alt_m: record.alt_m,
          grade_perc: grade,
          lat_deg: record.lat_deg,
          lon_deg: record.lon_deg,
        };
      }) || [],
    [calculateGrade, reducedRecords]
  );

  const maxGrade = useMemo(() => {
    return (
      Math.round(Math.max(...elevationRecords.map((record: any) => record.grade_perc)) * 10) / 10
    );
  }, [elevationRecords]);

  const minGrade = useMemo(() => {
    return (
      Math.round(Math.min(...elevationRecords.map((record: any) => record.grade_perc)) * 10) / 10
    );
  }, [elevationRecords]);

  const { totalAscentCalculated, totalDescentCalculated } = useMemo(() => {
    const totalAscentCalculated = calculateTotalElevationChange(elevationRecords, "ascent");
    const totalDescentCalculated = calculateTotalElevationChange(elevationRecords, "descent");
    return { totalAscentCalculated, totalDescentCalculated };
  }, [elevationRecords]);

  return (
    <RouteElevationContext.Provider
      value={{
        elevationRecords,
        maxGrade,
        minGrade,
        totalAscentCalculated,
        totalDescentCalculated,
      }}
    >
      {children}
    </RouteElevationContext.Provider>
  );
};
