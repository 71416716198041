import WSMSensorConnectionState from "@WahooFitness/wsm-native/dist/esm/types/connection_state";
import WSMDevice from "@WahooFitness/wsm-native/dist/esm/types/device";
import { Trans } from "@lingui/macro";
import { Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useMemo } from "react";

const DisconnectedAlert = ({ device }: { device?: WSMDevice }) => {
  const isConnected = useMemo(
    () => device?.connectionState === WSMSensorConnectionState.CONNECTED,
    [device]
  );
  if (isConnected) {
    return undefined;
  }
  return (
    <Alert severity="warning" variant="filled">
      <Typography variant="prose-xs-medium">
        <Trans>
          Your ELEMNT must be connected to configure device settings and load selected routes and
          workouts.
        </Trans>
      </Typography>
    </Alert>
  );
};

export default DisconnectedAlert;
