import { crux_bolt_type_e } from "@WahooFitness/crux-js-display-cfg";
import WSMSensorConnectionState from "@WahooFitness/wsm-native/dist/esm/types/connection_state";
import WSMDevice from "@WahooFitness/wsm-native/dist/esm/types/device";
import WSMSensorProductType from "@WahooFitness/wsm-native/dist/esm/types/product_type";

export const WebWsmDevice: WSMDevice = {
  id: "ELEMNT_ROAM_WEB",
  name: "ELEMNT ROAM WEB",
  sensorId: 1234,
  displayName: "ELEMNT ROAM WEB",
  productType: WSMSensorProductType.WAHOO_ELEMNT_ROAM,
  productTypeName: "ELEMNT ROAM",
  connectionState: WSMSensorConnectionState.CONNECTED,
  network: "BTLE",
  appToken: "ELEMNT_ROAM_1234",
  rssi: -50,
  battery: { percent: 63, charging: false },
  boltType: crux_bolt_type_e.ROAM_2,
  serialNumber: "123456789",
  firmwareVersion: "WR20-1234",
};
