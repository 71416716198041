import { getEnvironmentConfig } from "@/services/getEnvironmentConfig";
import { useConfigContext } from "@WahooFitness/wahoo-offline-mfe";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import mapboxgl from "mapbox-gl";
import { Geolocation } from "@capacitor/geolocation";
import {
  addDistanceMarkers,
  addRouteLayers,
  addStartAndFinishMarkers,
  getBoundingBox,
  removeDistanceMarkers,
  removeRouteLayers,
  removeStartAndFinishMarkers,
  setIsLayerVisible,
} from "@/services/Map/mapHelpers";
import { Record as RouteRecord } from "@WahooFitness/wsm-native/dist/esm/types/route";
import useMapTheme from "@/components/Routes/RouteDetails/Map/useMapTheme";
import MapTypeEnum from "@/components/Routes/RouteDetails/Map/MapTypeEnum";
import { useUnitFormatter } from "@/hooks/useUnitFormatter";
import MapLayerEnum from "@/components/Routes/RouteDetails/Map/MapLayerEnum";
import { Capacitor } from "@capacitor/core";

type MapContextType = {
  initMap: (mapContainer: HTMLDivElement, bottomOffset?: number) => void;
  loadRoute: (routeJsonRecords: RouteRecord[]) => void;
  handleLayerChanged: (mapLayers: MapLayerEnum[]) => void;
  handleMapTypeChanged: (mapType: MapTypeEnum) => void;
  handleReset: () => void;
  resize: (bottomOffset?: number | undefined) => void;
  setInteractive: (interactive: boolean) => void;
  mapRef: mapboxgl.Map | undefined;
};
export const MapContext = createContext<MapContextType | undefined>(undefined);

export const MapProvider = ({ children }: PropsWithChildren) => {
  const mapTheme = useMapTheme();
  const { environment } = useConfigContext();
  const [selectedMapType, setSelectedMapType] = useState(MapTypeEnum.STANDARD);
  const [finishedInitialLoad, setFinishedInitialLoad] = useState(false);
  const [shouldReloadUserSelectedLayers, setShouldReloadUserSelectedLayers] = useState(false);
  const [timesStyleLoaded, setTimesStyleLoaded] = useState(-1);
  const [lastTimeStyleLoaded, setLastTimeStyleLoaded] = useState(0);
  const [startPosition, setStartPosition] = useState<[mapboxgl.LngLat, number]>();
  const [mapLoaded, setMapLoaded] = useState(false);
  const [selectedMapLayers, setSelectedMapLayers] = useState<MapLayerEnum[]>([
    //MapLayerEnum.CUSTOM_WAYPOINTS, // TODO: Uncomment this line once we have Custom Waypoints
    MapLayerEnum.DISTANCE_MARKERS,
    MapLayerEnum.POIS,
    MapLayerEnum.CONTOUR,
  ]);
  const [routeJsonRecords, setRouteJsonRecords] = useState<RouteRecord[]>();
  const { unitFormatter } = useUnitFormatter();
  const mapboxToken = useMemo(() => {
    const envMapBoxToken = getEnvironmentConfig(environment).mapboxToken;
    if (!envMapBoxToken) {
      throw new Error("Mapbox token is required");
    }
    return envMapBoxToken;
  }, [environment]);
  const mapRef = useRef<mapboxgl.Map | undefined>(undefined);
  const gpsCoordinates: [number, number][] | undefined = useMemo(() => {
    // convert records to gps coordinates
    if (routeJsonRecords !== undefined) {
      return (
        routeJsonRecords
          // cast prevents the type from being evaluated as number[] instead of [number, number]
          .map((record) => [record.lon_deg, record.lat_deg] as [number, number])
          .filter(([lng, lat]) => !Number.isNaN(+lng) && !Number.isNaN(+lat))
      );
    }
    return undefined;
  }, [routeJsonRecords]);

  // #region Mapbox event handlers
  const onStyleLoad = useCallback(() => {
    if (!mapRef.current) {
      return;
    }
    setTimesStyleLoaded((curr) => curr + 1);
  }, []);

  const onStyleData = useCallback(() => {
    if (!mapRef.current) {
      return;
    }
  }, []);

  const onLoad = useCallback(() => {
    setMapLoaded(true);
  }, []);
  // #endregion

  useEffect(() => {
    return () => {
      if (mapRef.current) {
        mapRef.current.off("style.load", onStyleLoad);
        mapRef.current.off("styledata", onStyleData);
        mapRef.current.off("load", onLoad);
        mapRef.current.remove();
      }
    };
  }, [onStyleLoad, onStyleData, onLoad]);

  const loadRoute = (routeJsonRecords: RouteRecord[]) => {
    setRouteJsonRecords(routeJsonRecords);
  };

  const initMap = useCallback(
    (mapContainer: HTMLDivElement) => {
      mapboxgl.accessToken = mapboxToken;
      mapRef.current = new mapboxgl.Map({
        container: mapContainer,
        style: mapTheme.mapBoxStyle,
      });

      mapRef.current.on("style.load", onStyleLoad);
      mapRef.current.on("styledata", onStyleData);
      mapRef.current.on("load", onLoad);
    },
    [mapTheme, mapboxToken, onStyleLoad, onStyleData, onLoad]
  );

  // this is the initial effect that needs to run to set the map view based on teh gps coordinates
  // this only needs to run once after the map is loaded
  useEffect(() => {
    if (!mapRef.current || !mapLoaded || finishedInitialLoad) {
      return;
    }

    if (gpsCoordinates && routeJsonRecords) {
      const boundingBox = getBoundingBox(gpsCoordinates);
      mapRef.current.fitBounds(
        [
          [boundingBox[0], boundingBox[1]],
          [boundingBox[2], boundingBox[3]],
        ],
        {
          padding: mapTheme.viewPadding,
          animate: false,
        }
      );
      setFinishedInitialLoad(true);
    }
  }, [gpsCoordinates, routeJsonRecords, mapLoaded, unitFormatter, mapTheme, finishedInitialLoad]);

  // this effect runs when a user reverses a route as well as when the map first loads
  useEffect(() => {
    if (!mapRef.current || !routeJsonRecords || !gpsCoordinates || !finishedInitialLoad) {
      return;
    }
    removeRouteLayers(mapRef.current);
    removeStartAndFinishMarkers();
    removeDistanceMarkers();
    addRouteLayers(mapRef.current, gpsCoordinates, mapTheme);
    addDistanceMarkers(mapRef.current, routeJsonRecords, unitFormatter);
    addStartAndFinishMarkers(mapRef.current, gpsCoordinates);
    setStartPosition([mapRef.current.getCenter(), mapRef.current.getZoom()]);
  }, [routeJsonRecords, gpsCoordinates, finishedInitialLoad, unitFormatter, mapTheme]);

  // Set the user's current location.
  useEffect(() => {
    async function setCurrentLocation(mapInstance: mapboxgl.Map) {
      const permissionState = await checkLocationPermission();
      if (permissionState?.location === "granted") {
        const coordinates = await Geolocation.getCurrentPosition();
        if (coordinates.coords) {
          const currentLocationMarker = document.createElement("div");
          currentLocationMarker.className = "map_current_location";
          new mapboxgl.Marker(currentLocationMarker)
            .setLngLat([coordinates.coords.longitude, coordinates.coords.latitude])
            .addTo(mapInstance);
        }
      }
    }

    async function checkLocationPermission() {
      if (Capacitor.getPlatform() === "web") {
        // Geolocation plugin is not implemented on web
        return;
      }
      const permission = await Geolocation.checkPermissions();
      if (permission.location === "prompt") {
        return await Geolocation.requestPermissions();
      }
      return permission;
    }

    if (!mapLoaded || !mapRef.current) {
      return;
    }

    setCurrentLocation(mapRef.current);
  }, [mapLoaded]);

  // If a user has changed which layers are visible, changing the map type will reset those layers to their default visibility
  // we need an effect to optionally load those layers based on the user preferences
  // Since we don't know when the style will finish loading, we need a way to know when to run this effect
  // The only way to do that is compare timesStyleLoaded to lastTimeStyleLoaded
  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    if (shouldReloadUserSelectedLayers && timesStyleLoaded !== lastTimeStyleLoaded) {
      // add layers after style is loaded because all layers are removed when style changes
      if (gpsCoordinates) {
        addRouteLayers(mapRef.current, gpsCoordinates, mapTheme);
      }
      try {
        // TODO: Once we update to mapbox v3 after solving the iOS crashing issue, we can then switch to using the new map themes with base layers
        // if (
        //   mapRef.current.style?.stylesheet?.imports &&
        //   mapRef.current.style.stylesheet.imports.findIndex((is) => is.id === "basemap") > -1
        // ) {
        //   mapRef.current.setConfigProperty(
        //     "basemap",
        //     "showPointOfInterestLabels",
        //     selectedMapLayers.includes(MapLayerEnum.POIS)
        //   );
        // }
        setIsLayerVisible(
          mapRef.current,
          "contours",
          selectedMapLayers.includes(MapLayerEnum.CONTOUR)
        );
      } catch (err) {
        console.error("error setting basemap", JSON.stringify(err));
      }
      setShouldReloadUserSelectedLayers(false);
    }
    setLastTimeStyleLoaded(timesStyleLoaded);
  }, [
    shouldReloadUserSelectedLayers,
    selectedMapLayers,
    timesStyleLoaded,
    lastTimeStyleLoaded,
    mapRef,
    mapTheme,
    gpsCoordinates,
  ]);

  const handleLayerChanged = useCallback(
    (mapLayers: MapLayerEnum[]) => {
      if (!mapRef.current || !routeJsonRecords) {
        return;
      }
      const map = mapRef.current;
      // figure out if a new layer was added to the list
      const newLayer = mapLayers.find((layer) => !selectedMapLayers.includes(layer));
      if (newLayer === MapLayerEnum.CONTOUR) {
        setIsLayerVisible(map, "contours", true);
      } else if (newLayer === MapLayerEnum.POIS) {
        map.setConfigProperty("basemap", "showPointOfInterestLabels", true);
        setIsLayerVisible(map, "poi-label", true);
      } else if (newLayer === MapLayerEnum.DISTANCE_MARKERS) {
        addDistanceMarkers(map, routeJsonRecords, unitFormatter);
      }

      // figure out if a layer was removed from the list
      const removedLayer = selectedMapLayers.find((layer) => !mapLayers.includes(layer));
      if (removedLayer === MapLayerEnum.CONTOUR) {
        setIsLayerVisible(map, "contours", false);
      } else if (removedLayer === MapLayerEnum.POIS) {
        map.setConfigProperty("basemap", "showPointOfInterestLabels", false);
        setIsLayerVisible(map, "poi-label", false);
      } else if (removedLayer === MapLayerEnum.DISTANCE_MARKERS) {
        removeDistanceMarkers();
      }
      setSelectedMapLayers(mapLayers);
    },
    [routeJsonRecords, unitFormatter, selectedMapLayers]
  );

  const handleMapTypeChanged = useCallback(
    (mapType: MapTypeEnum) => {
      if (!mapRef.current) {
        return;
      }
      const map = mapRef.current;
      try {
        if (mapType === MapTypeEnum.STANDARD && selectedMapType !== MapTypeEnum.STANDARD) {
          map.setStyle(mapTheme.mapBoxStyle);
          map.setPitch(0);
          map.setTerrain(undefined);
          // style changed, set bit to reload selected layer dependencies
          setShouldReloadUserSelectedLayers(true);
        } else {
          if (mapType !== MapTypeEnum.STANDARD && selectedMapType === MapTypeEnum.STANDARD) {
            // only set the satellite style if the style before was standard
            map.setStyle(mapTheme.mapBoxSatelliteStyle);
            // style changed, set bit to reload selected layer dependencies
            setShouldReloadUserSelectedLayers(true);
          }
          if (mapType === MapTypeEnum.TERRAIN) {
            // add terrain source
            if (!map.getSource("mapbox-dem")) {
              map.addSource("mapbox-dem", {
                type: "raster-dem",
                url: "mapbox://mapbox.mapbox-terrain-dem-v1",
                tileSize: 512,
                maxzoom: 14,
              });
            }
            map.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
            if (map.getPitch() === 0) {
              map.setPitch(mapTheme.defaultThreeDeePitch);
            }
          } else {
            map.setTerrain(undefined);
            map.setPitch(0);
          }
        }

        setSelectedMapType(mapType);
      } catch (err) {
        console.error("error setting map type", JSON.stringify(err));
      }
    },
    [selectedMapType, mapTheme]
  );

  const handleReset = useCallback(() => {
    if (!mapRef.current || !startPosition) {
      return;
    }
    mapRef.current.setCenter(startPosition[0]);
    mapRef.current.setZoom(startPosition[1]);
    mapRef.current.setBearing(0);
    mapRef.current.setPitch(0);
  }, [startPosition]);

  const resize = useCallback(
    (bottomOffset?: number | undefined) => {
      if (!mapRef.current) {
        return;
      }
      mapRef.current.resize();
      if (gpsCoordinates) {
        const boundingBox = getBoundingBox(gpsCoordinates, bottomOffset);
        mapRef.current.fitBounds(
          [
            [boundingBox[0], boundingBox[1]],
            [boundingBox[2], boundingBox[3]],
          ],
          {
            padding: mapTheme.viewPadding,
            animate: false,
          }
        );
        setStartPosition([mapRef.current.getCenter(), mapRef.current.getZoom()]);
      }
    },
    [gpsCoordinates, mapTheme]
  );

  const setInteractive = useCallback(
    (interactive: boolean) => {
      if (!mapRef.current) {
        return;
      }
      const interactivityHandlers: (keyof mapboxgl.Map)[] = [
        "scrollZoom",
        "boxZoom",
        "dragPan",
        "dragRotate",
        "keyboard",
        "doubleClickZoom",
        "touchZoomRotate",
        "touchPitch",
      ];

      if (!interactive) {
        interactivityHandlers.forEach((handlerKey) => {
          (mapRef.current![handlerKey] as any).disable();
        });
        mapRef.current.getCanvas().style.cursor = "pointer";
      } else {
        interactivityHandlers.forEach((handlerKey) => {
          (mapRef.current![handlerKey] as any).enable();
        });
        mapRef.current.getCanvas().style.cursor = "hand";
      }
    },
    [mapRef]
  );

  return (
    <MapContext.Provider
      value={{
        initMap,
        loadRoute,
        handleLayerChanged,
        handleMapTypeChanged,
        handleReset,
        resize,
        setInteractive,
        mapRef: mapRef.current,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
