import { Box, Button, Divider, Paper, Typography, useTheme } from "@mui/material";
import { Trans } from "@lingui/macro";
import { ArrowDownRight, ArrowUpRight } from "@carbon/icons-react";
import ElevationChart from "./ElevationChart";
import { useUnitFormatter } from "@/hooks/useUnitFormatter";
import { useRouteElevationContext } from "@/hooks/useRouteElevationContext";

const ElevationSection = ({
  ascent,
  descent,
  handleOpenFullScreenMap,
}: {
  ascent?: number;
  descent?: number;
  handleOpenFullScreenMap: () => void;
}) => {
  const { palette } = useTheme();
  const { formatElevation } = useUnitFormatter();
  const { elevationRecords, minGrade, maxGrade } = useRouteElevationContext();

  return (
    <Box display="flex" flexDirection="column" rowGap={1}>
      <Box display="flex" ml={2} mr={1} justifyContent="space-between">
        <Typography variant="ui-lg-medium" color="text.secondary">
          <Trans>Elevation</Trans>
        </Typography>
        <Button variant="text" onClick={handleOpenFullScreenMap}>
          <Typography variant="prose-xs-medium" color="info.main">
            <Trans>Details</Trans>
          </Typography>
        </Button>
      </Box>
      <Box component={Paper}>
        <Box px={2} py={1}>
          <ElevationChart elevationRecords={elevationRecords} />
        </Box>
        <Box display="flex" flexDirection="column">
          {(ascent || descent) && (
            <>
              <Box display="flex" justifyContent="space-between" mx={2} my={1}>
                <Box display="flex">
                  <Typography variant="prose-sm">
                    <Trans>Elevation gain</Trans>
                  </Typography>
                </Box>
                <Box display="flex" columnGap={2}>
                  {ascent && (
                    <Box display="flex" columnGap={0.25} alignItems="center">
                      <ArrowUpRight height={16} width={16} color={palette.text.secondary} />
                      <Typography variant="ui-sm" color="text.secondary">
                        {formatElevation(ascent)}
                      </Typography>
                    </Box>
                  )}
                  {descent && (
                    <Box display="flex" columnGap={0.25} alignItems="center">
                      <ArrowDownRight height={16} width={16} color={palette.text.secondary} />
                      <Typography variant="ui-sm" color="text.secondary">
                        {formatElevation(descent)}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Divider />
            </>
          )}
          <Box display="flex" justifyContent="space-between" mx={2} my={1}>
            <Box display="flex">
              <Typography variant="prose-sm">
                <Trans>Max gradient</Trans>
              </Typography>
            </Box>
            <Box display="flex" columnGap={2}>
              <Box display="flex" columnGap={0.25} alignItems="center">
                <ArrowUpRight height={16} width={16} color={palette.text.secondary} />
                <Typography variant="ui-sm" color="text.secondary">
                  {maxGrade}%
                </Typography>
              </Box>
              <Box display="flex" columnGap={0.25} alignItems="center">
                <ArrowDownRight height={16} width={16} color={palette.text.secondary} />
                <Typography variant="ui-sm" color="text.secondary">
                  {minGrade}%
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ElevationSection;
