import StravaLiveSegmentSettings from "@/components/WorkoutProfiles/StravaLiveSegments/StravaLiveSegmentSettings";
import { useParams } from "react-router";

const StravaLiveSegmentsSettingsPage = () => {
  const params = useParams();

  return <StravaLiveSegmentSettings workoutProfileId={+(params.profileId || 0)} />;
};

export default StravaLiveSegmentsSettingsPage;
