import { PropsWithChildren } from "react";

interface Point {
  x: number;
  y: number;
}

interface Props {
  start: Point;
  center: Point;
  bigRadius: number;
  bigCurve: Point;
  littleRadius: number;
  littleCurve: Point;
  color: string;
  rotationOffset?: number;
  innerRef?: React.RefObject<SVGPathElement>;
}

export const Arc = ({
  start,
  center,
  bigRadius,
  bigCurve,
  littleRadius,
  littleCurve,
  color,
  rotationOffset,
  innerRef,
}: PropsWithChildren<Props>) => {
  return (
    <path
      d={[
        `M ${start.x} ${start.y}`,
        `L ${center.x + bigRadius} ${center.y}`,
        `A ${bigRadius} ${bigRadius} 0 0 1 ${bigCurve.x} ${bigCurve.y}`,
        `L ${littleCurve.x} ${littleCurve.y}`,
        `A ${littleRadius} ${littleRadius} 0 0 0 ${start.x} ${start.y}`,
        "Z",
      ].join(" ")}
      fill={color}
      stroke="none"
      transform={`rotate(${(rotationOffset || 0) - 180} ${center.x} ${center.y})`}
      ref={innerRef}
    />
  );
};
