import { t } from "@lingui/macro";
import { useEffect, useMemo } from "react";
import ErrorBoundary from "../ErrorBoundary";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import MainSportQuestion from "../Onboarding/TrainingPreferences/MainSportQuestion";
import useTrainingPreferences from "./useTrainingPreferences";
import { Box } from "@mui/material";
import useSuggestedWorkoutsSetting from "./useSuggestedWorkoutsSetting";
import { useUserContext } from "@WahooFitness/wahoo-offline-mfe";
import SuggestedWorkoutsPreferenceToggle from "./SuggestedWorkoutsPreferenceToggle";

const TrainingPreferences: React.FC = () => {
  const { setNavHeader } = useHeaderContext();
  const { sportOptionListItems } = useTrainingPreferences();
  const { showSuggestedWorkouts } = useSuggestedWorkoutsSetting();
  const { currentUser } = useUserContext();

  const headerProps = useMemo(() => {
    return { title: t`Training preferences` };
  }, []);

  useEffect(() => {
    setNavHeader(headerProps);
  });

  return (
    <ErrorBoundary isLoading={false} error={false} onReload={() => {}}>
      <Box mt={2}>
        <MainSportQuestion sportOptionListItems={sportOptionListItems} />
        {showSuggestedWorkouts && currentUser && (
          <SuggestedWorkoutsPreferenceToggle user={currentUser} />
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default TrainingPreferences;
