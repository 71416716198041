import React, { useCallback, useMemo, useState } from "react";
import { useDialogContext } from "@WahooFitness/wahoo-offline-mfe";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Switch,
  Typography,
} from "@mui/material";
import { CheckMarkIcon, ChevronDownIcon, CloseIcon } from "@WahooFitness/redesignr";
import useThirdPartySharing, { ConnectedAppListItem } from "./useThirdPartySharing";
import { Trans, t } from "@lingui/macro";
import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import { FlaggedFeature } from "@/hooks/types/FlaggedFeature";

const getLocalizedCapabilityMapping = (): Record<string, string> => ({
  import_plans: t`Import plans`,
  import_routes: t`Import routes`,
  import_summaries: t`Import Summaries`,
  share_summaries: t`Share Summaries`,
});

const AuthorizedApp: React.FC<ConnectedAppListItem> = ({
  icon,
  content,
  authorized,
  supportedCapabilities,
  handleDeauth,
}) => {
  const { setDialog, handleClose } = useDialogContext();
  const capabilityMapping = getLocalizedCapabilityMapping();
  const { checkIsFeatureEnabled } = useFlaggedFeatures();

  const handleConfirmDeauth = useCallback(async () => {
    await handleDeauth();
    handleClose();
  }, [handleClose, handleDeauth]);

  const handleOpenDialog = useCallback(() => {
    setDialog({
      open: true,
      title: t`Deauthorize ${content}?`,
      body: t`Are you sure? Deauthorizing will stop sharing across all Wahoo products.`,
      actions: [
        { text: t`Cancel` },
        { text: t`Deauthorize`, color: "error", action: handleConfirmDeauth },
      ],
    });
  }, [handleConfirmDeauth, setDialog, content]);

  const capabilityOrder = useMemo(() => {
    return ["share_summaries", "import_summaries", "import_plans", "import_routes"].reduce(
      (acc, curr, i) => {
        acc[curr] = i;
        return acc;
      },
      {} as Record<string, number>
    );
  }, []);

  const { updateAutoShareSummaries } = useThirdPartySharing();

  const [disableAutoShareToggle, setDisableAutoShareToggle] = useState(false);

  const handleAutoShareToggle = useCallback(
    async (_: any, checked: boolean) => {
      if (!authorized?.id) return;
      setDisableAutoShareToggle(true);
      await updateAutoShareSummaries(authorized.id, checked);
      setDisableAutoShareToggle(false);
    },
    [authorized, updateAutoShareSummaries]
  );

  if (!authorized) {
    // Shouldn't happen, but typescript.
    return <></>;
  }
  const { id: _id, created_at: _created_at, updated_at: _updated_at, ...capabilities } = authorized;

  return (
    <Accordion
      elevation={4}
      disableGutters
      sx={{ "&:last-of-type": { borderRadius: 2 }, "&:first-of-type": { borderRadius: 2 } }}
    >
      <AccordionSummary expandIcon={<ChevronDownIcon />}>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
          <Box display="flex" gap={1}>
            {icon}
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="prose-base">{content}</Typography>
            <Typography variant="prose-sm" color="success.main">
              <Trans>Authorized</Trans>
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0, pb: 0 }}>
        <Box>
          {checkIsFeatureEnabled(FlaggedFeature.AutoShareSummary) && (
            <>
              <Divider />
              <ListItem dense>
                <ListItemText
                  primary={t`Auto-share`}
                  primaryTypographyProps={{ variant: "prose-base" }}
                  secondary={t`Automatically share activity after each workout. This applies to any Wahoo workout from ELEMNT or the app.`}
                />
                <Switch
                  checked={capabilities.auto_share_summaries}
                  onChange={handleAutoShareToggle}
                  color="info"
                  disabled={disableAutoShareToggle}
                />
              </ListItem>
            </>
          )}
          <Divider />
          {Object.entries(capabilities)
            .filter(([cap]) => supportedCapabilities.includes(cap as keyof typeof capabilities))
            .sort(([a], [b]) => (capabilityOrder[a] ?? Infinity) - (capabilityOrder[b] ?? Infinity))
            .map(([cap, value]) => {
              if (capabilityMapping[cap]) {
                return (
                  <React.Fragment key={cap}>
                    <ListItem dense>
                      <ListItemText>
                        <Typography variant="prose-sm" color={value ? undefined : "text.disabled"}>
                          {capabilityMapping[cap]}
                        </Typography>
                      </ListItemText>
                      {value ? <CheckMarkIcon /> : <CloseIcon color="disabled" />}
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                );
              }
            })}
          <ListItem onClick={handleOpenDialog} disablePadding>
            <ListItemButton sx={{ height: "46px" }}>
              <Typography variant="prose-base" color="error">
                <Trans>Deauthorize</Trans>
              </Typography>
            </ListItemButton>
          </ListItem>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AuthorizedApp;
