import { t } from "@lingui/macro";

const useTilePackNames = () => {
  const tilePackNameTranslations: Record<number, string> = {
    1: t`Africa`,
    2: t`Algeria`,
    3: t`Angola`,
    4: t`Benin`,
    5: t`Botswana`,
    6: t`Burkina Faso`,
    7: t`Burundi`,
    8: t`Cameroon`,
    9: t`Cape Verde`,
    10: t`Central African Republic`,
    11: t`Chad`,
    12: t`Comoros`,
    13: t`Côte d'Ivoire`,
    14: t`Democratic Republic of the Congo`,
    15: t`Djibouti`,
    16: t`Egypt`,
    17: t`Equatorial Guinea`,
    18: t`Eritrea`,
    19: t`Ethiopia`,
    20: t`French Southern Territories`,
    21: t`Gabon`,
    22: t`Gambia`,
    23: t`Ghana`,
    24: t`Guinea`,
    25: t`Guinea-Bissau`,
    26: t`Kenya`,
    27: t`Lesotho`,
    28: t`Liberia`,
    29: t`Libya`,
    30: t`Madagascar`,
    31: t`Malawi`,
    32: t`Mali`,
    33: t`Mauritania`,
    34: t`Mauritius`,
    35: t`Mayotte`,
    36: t`Morocco`,
    37: t`Mozambique`,
    38: t`Namibia`,
    39: t`Niger`,
    40: t`Nigeria`,
    41: t`Republic of Congo`,
    42: t`Reunion`,
    43: t`Rwanda`,
    44: t`Saint Helena`,
    45: t`Sao Tome and Principe`,
    46: t`Senegal`,
    47: t`Seychelles`,
    48: t`Sierra Leone`,
    49: t`Somalia`,
    50: t`South Africa`,
    51: t`Sudan`,
    52: t`Swaziland`,
    53: t`Tanzania`,
    54: t`Togo`,
    55: t`Tunisia`,
    56: t`Uganda`,
    57: t`Western Sahara`,
    58: t`Zambia`,
    59: t`Zimbabwe`,
    60: t`Antarctica`,
    61: t`Antarctica`,
    62: t`Bouvet Island`,
    63: t`Heard Island and McDonald Islands`,
    64: t`South Georgia and the South Sandwich Islands`,
    65: t`Asia`,
    66: t`Afghanistan`,
    67: t`Armenia`,
    68: t`Azerbaijan`,
    69: t`Bahrain`,
    70: t`Bangladesh`,
    71: t`Bhutan`,
    72: t`British Indian Ocean Territory`,
    73: t`Brunei`,
    74: t`Cambodia`,
    75: t`China`,
    76: t`Christmas Island`,
    77: t`Cocos Islands`,
    78: t`Cyprus`,
    79: t`East Timor`,
    80: t`Georgia`,
    81: t`Hong Kong`,
    82: t`India`,
    83: t`Indonesia`,
    84: t`Iran`,
    85: t`Iraq`,
    86: t`Israel`,
    87: t`Japan`,
    88: t`Jordan`,
    89: t`Kazakhstan`,
    90: t`Kuwait`,
    91: t`Kyrgyzstan`,
    92: t`Laos`,
    93: t`Lebanon`,
    94: t`Macao`,
    95: t`Malaysia`,
    96: t`Maldives`,
    97: t`Mongolia`,
    98: t`Myanmar`,
    99: t`Nepal`,
    100: t`North Korea`,
    101: t`Oman`,
    102: t`Pakistan`,
    103: t`Palestina`,
    104: t`Paracel Islands`,
    105: t`Philippines`,
    106: t`Qatar`,
    107: t`Saudi Arabia`,
    108: t`Singapore`,
    109: t`South Korea`,
    110: t`Spratly islands`,
    111: t`Sri Lanka`,
    112: t`Syria`,
    113: t`Taiwan`,
    114: t`Tajikistan`,
    115: t`Thailand`,
    116: t`Turkey`,
    117: t`Turkmenistan`,
    118: t`United Arab Emirates`,
    119: t`Uzbekistan`,
    120: t`Vietnam`,
    121: t`Yemen`,
    122: t`Europe`,
    123: t`Albania`,
    124: t`Andorra`,
    125: t`Austria`,
    126: t`Belarus`,
    127: t`Belgium`,
    128: t`Bosnia and Herzegovina`,
    129: t`Bulgaria`,
    130: t`Croatia`,
    131: t`Czech Republic`,
    132: t`Denmark`,
    133: t`Estonia`,
    134: t`Faroe Islands`,
    135: t`Finland`,
    136: t`France`,
    137: t`Germany`,
    138: t`Gibraltar`,
    139: t`Greece`,
    140: t`Guernsey`,
    141: t`Hungary`,
    142: t`Iceland`,
    143: t`Ireland`,
    144: t`Isle of Man`,
    145: t`Italy`,
    146: t`Jersey`,
    147: t`Latvia`,
    148: t`Liechtenstein`,
    149: t`Lithuania`,
    150: t`Luxembourg`,
    151: t`Macedonia`,
    152: t`Malta`,
    153: t`Moldova`,
    154: t`Monaco`,
    155: t`Montenegro`,
    156: t`Netherlands`,
    157: t`Norway`,
    158: t`Poland`,
    159: t`Portugal`,
    160: t`Romania`,
    161: t`Russia`,
    162: t`San Marino`,
    163: t`Serbia`,
    164: t`Slovakia`,
    165: t`Slovenia`,
    166: t`Spain`,
    167: t`Svalbard and Jan Mayen`,
    168: t`Sweden`,
    169: t`Switzerland`,
    170: t`Ukraine`,
    171: t`United Kingdom`,
    172: t`Vatican City`,
    173: t`Åland`,
    174: t`North America`,
    175: t`Anguilla`,
    176: t`Antigua and Barbuda`,
    177: t`Bahamas`,
    178: t`Barbados`,
    179: t`Belize`,
    180: t`Bermuda`,
    181: t`British Virgin Islands`,
    182: t`Canada`,
    183: t`Cayman Islands`,
    184: t`Costa Rica`,
    185: t`Cuba`,
    186: t`Dominica`,
    187: t`Dominican Republic`,
    188: t`El Salvador`,
    189: t`Greenland`,
    190: t`Grenada`,
    191: t`Guadeloupe`,
    192: t`Guatemala`,
    193: t`Haiti`,
    194: t`Honduras`,
    195: t`Jamaica`,
    196: t`Martinique`,
    197: t`Mexico`,
    198: t`Montserrat`,
    199: t`Nicaragua`,
    200: t`Panama`,
    201: t`Puerto Rico`,
    202: t`Saint Kitts and Nevis`,
    203: t`Saint Lucia`,
    204: t`Saint Vincent and the Grenadines`,
    205: t`Saint-Barthélemy`,
    206: t`Saint-Martin`,
    207: t`Sint Maarten`,
    208: t`Turks and Caicos Islands`,
    209: t`United States`,
    210: t`Alabama`,
    211: t`Alaska`,
    212: t`American Samoa`,
    213: t`Arizona`,
    214: t`Arkansas`,
    215: t`California`,
    216: t`Colorado`,
    217: t`Commonwealth of the Northern Mariana Islands`,
    218: t`Connecticut`,
    219: t`Delaware`,
    220: t`District of Columbia`,
    221: t`Florida`,
    222: t`Georgia`,
    223: t`Guam`,
    224: t`Hawaii`,
    225: t`Idaho`,
    226: t`Illinois`,
    227: t`Indiana`,
    228: t`Iowa`,
    229: t`Kansas`,
    230: t`Kentucky`,
    231: t`Louisiana`,
    232: t`Maine`,
    233: t`Maryland`,
    234: t`Massachusetts`,
    235: t`Michigan`,
    236: t`Minnesota`,
    237: t`Mississippi`,
    238: t`Missouri`,
    239: t`Montana`,
    240: t`Nebraska`,
    241: t`Nevada`,
    242: t`New Hampshire`,
    243: t`New Jersey`,
    244: t`New Mexico`,
    245: t`New York`,
    246: t`North Carolina`,
    247: t`North Dakota`,
    248: t`Ohio`,
    249: t`Oklahoma`,
    250: t`Oregon`,
    251: t`Pennsylvania`,
    252: t`Puerto Rico`,
    253: t`Rhode Island`,
    254: t`South Carolina`,
    255: t`South Dakota`,
    256: t`Tennessee`,
    257: t`Texas`,
    258: t`United States Virgin Islands`,
    259: t`Utah`,
    260: t`Vermont`,
    261: t`Virginia`,
    262: t`Washington`,
    263: t`West Virginia`,
    264: t`Wisconsin`,
    265: t`Wyoming`,
    266: t`Virgin Islands, U.S.`,
    267: t`Oceania`,
    268: t`American Samoa`,
    269: t`Australia`,
    270: t`Cook Islands`,
    271: t`Fiji`,
    272: t`French Polynesia`,
    273: t`Guam`,
    274: t`Kiribati`,
    275: t`Marshall Islands`,
    276: t`Micronesia`,
    277: t`Nauru`,
    278: t`New Caledonia`,
    279: t`New Zealand`,
    280: t`Niue`,
    281: t`Norfolk Island`,
    282: t`Northern Mariana Islands`,
    283: t`Palau`,
    284: t`Papua New Guinea`,
    285: t`Pitcairn Islands`,
    286: t`Samoa`,
    287: t`Solomon Islands`,
    288: t`Tokelau`,
    289: t`Tonga`,
    290: t`Tuvalu`,
    291: t`United States Minor Outlying Islands`,
    292: t`Vanuatu`,
    293: t`Wallis and Futuna`,
    294: t`South America`,
    295: t`Argentina`,
    296: t`Aruba`,
    297: t`Bolivia`,
    298: t`Bonaire, Saint Eustatius and Saba`,
    299: t`Brazil`,
    300: t`Chile`,
    301: t`Clipperton Island`,
    302: t`Colombia`,
    303: t`Curaçao`,
    304: t`Ecuador`,
    305: t`Falkland Islands`,
    306: t`French Guiana`,
    307: t`Guyana`,
    308: t`Paraguay`,
    309: t`Peru`,
    310: t`Saint Pierre and Miquelon`,
    311: t`Suriname`,
    312: t`Trinidad and Tobago`,
    313: t`Uruguay`,
    314: t`Venezuela`,
    315: t`Alsace`,
    316: t`Aquitaine`,
    317: t`Auvergne`,
    318: t`Île-de-France`,
    319: t`Basse-Normandie`,
    320: t`Bourgogne`,
    321: t`Bretagne`,
    322: t`Centre`,
    323: t`Champagne-Ardenne`,
    324: t`Corse`,
    325: t`Franche-Comté`,
    326: t`Haute-Normandie`,
    327: t`Languedoc-Roussillon`,
    328: t`Limousin`,
    329: t`Lorraine`,
    330: t`Midi-Pyrénées`,
    331: t`Nord-Pas-de-Calais`,
    332: t`Pays de la Loire`,
    333: t`Picardie`,
    334: t`Poitou-Charentes`,
    335: t`Provence-Alpes-Côte d'Azur`,
    336: t`Rhône-Alpes`,
    337: t`Baden-Württemberg`,
    338: t`Bavaria`,
    339: t`Berlin`,
    340: t`Brandenburg`,
    341: t`Bremen`,
    342: t`Hamburg`,
    343: t`Hessen`,
    344: t`Niedersachsen`,
    345: t`Mecklenburg-Vorpommern`,
    346: t`Nordrhein-Westfalen`,
    347: t`Rheinland-Pfalz`,
    348: t`Saarland`,
    349: t`Sachsen`,
    350: t`Sachsen-Anhalt`,
    351: t`Schleswig-Holstein`,
    352: t`Thüringen`,
    353: t`England`,
    354: t`Northern Ireland`,
    355: t`Scotland`,
    356: t`Wales`,
    357: t`Chubu Region`,
    358: t`Chugoku Region`,
    359: t`Hokkaido Region`,
    360: t`Kanto`,
    361: t`Kinki Region`,
    362: t`Kyushu Region`,
    363: t`Shikoku Region`,
    364: t`Tohoku`,
    365: t`Auvergne-Rhône-Alpes`,
    366: t`Bourgogne-Franche-Comté`,
    367: t`Grand Est`,
    368: t`Hauts-de-France`,
    369: t`Normandie`,
    370: t`Nouvelle-Aquitaine`,
    371: t`Occitanie`,
  };

  return { tilePackNameTranslations };
};

export default useTilePackNames;
