import mapboxgl from "mapbox-gl";
import geoViewport from "@mapbox/geo-viewport";
import { Record as RouteRecord } from "@WahooFitness/wsm-native/dist/esm/types/route";
import { UnitFormatter } from "@WahooFitness/unit-convertr-ts";

function getGeoJsonSource(gpsCoordinates: [number, number][]): GeoJSON.Feature {
  return {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: gpsCoordinates,
    },
  };
}

function addRouteLayers(
  mapInstance: mapboxgl.Map,
  gpsCoordinates: [number, number][],
  mapTheme: any
) {
  try {
    // add route source
    if (!mapInstance.getSource("primary")) {
      mapInstance.addSource("primary", {
        type: "geojson",
        data: getGeoJsonSource(gpsCoordinates),
      });
    }

    // we will add the route layer here since it seems to disappear
    if (!mapInstance.getLayer("primary_outline")) {
      mapInstance.addLayer({
        id: "primary_outline",
        type: "line",
        source: "primary",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": mapTheme.routes.primary.outlineColor,
          "line-width": mapTheme.routes.primary.outlineWidth,
        },
      });
    }

    if (!mapInstance.getLayer("primary")) {
      mapInstance.addLayer({
        id: "primary",
        type: "line",
        source: "primary",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": mapTheme.routes.primary.color,
          "line-width": mapTheme.routes.primary.width,
        },
      });

      if (!mapInstance.getLayer("chevron_line")) {
        mapInstance.addLayer({
          id: "chevron_line",
          type: "symbol",
          source: "primary",
          layout: {
            "icon-image": "chevron",
            "icon-size": 0.5,
            "symbol-placement": "line",
            "symbol-spacing": 25,
            //"symbol-z-elevate": false, // TODO: This is new feature not supported in mapbox-gl v2.0.0, we need this for the satellite view
          },
        });
      }

      if (!mapInstance.getSource("contours")) {
        mapInstance.addSource("contours", {
          type: "vector",
          url: "mapbox://mapbox.mapbox-terrain-v2",
        });
      }

      if (!mapInstance.getLayer("contours")) {
        mapInstance.addLayer({
          id: "contours",
          type: "line",
          source: "contours",
          "source-layer": "contour",
          layout: {
            visibility: "visible",
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#877b59",
            "line-width": 1,
          },
        });
      }
    }
  } catch (error) {
    console.error("Error adding route layers", error);
  }
}

function removeRouteLayers(mapInstance: mapboxgl.Map) {
  if (mapInstance.getLayer("primary")) {
    mapInstance.removeLayer("primary");
  }

  if (mapInstance.getLayer("primary_outline")) {
    mapInstance.removeLayer("primary_outline");
  }

  if (mapInstance.getLayer("chevron_line")) {
    mapInstance.removeLayer("chevron_line");
  }

  if (mapInstance.getSource("primary")) {
    mapInstance.removeSource("primary");
  }
}

function addStartAndFinishMarkers(mapInstance: mapboxgl.Map, gpsCoordinates: [number, number][]) {
  const selectedStartMarker = document.createElement("div");
  selectedStartMarker.className = "map_start_location";
  new mapboxgl.Marker({ element: selectedStartMarker })
    .setLngLat(gpsCoordinates[0])
    .addTo(mapInstance);

  const selectedFinishMarker = document.createElement("div");
  selectedFinishMarker.className = "map_finish_location";
  new mapboxgl.Marker({ element: selectedFinishMarker })
    .setLngLat(gpsCoordinates[gpsCoordinates.length - 1])
    .addTo(mapInstance);
}

const removeStartAndFinishMarkers = () => {
  const startMarker = document.querySelectorAll(".map_start_location");
  startMarker.forEach((marker) => marker.remove());
  const finishMarker = document.querySelectorAll(".map_finish_location");
  finishMarker.forEach((marker) => marker.remove());
};

function newMarker(color: string | undefined, rotation: number) {
  return new mapboxgl.Marker({
    color,
    rotation,
  });
}

function getBoundingBox(
  coordinates: [number, number][],
  bottomOffset?: number
): geoViewport.BoundingBox {
  let latMin: number | undefined;
  let latMax: number | undefined;
  let longMin: number | undefined;
  let longMax: number | undefined;

  coordinates.forEach((coord) => {
    const [long, lat] = coord;
    latMin = !latMin || lat < latMin ? lat : latMin;
    latMax = !latMax || lat > latMax ? lat : latMax;

    longMin = !longMin || long < longMin ? long : longMin;
    longMax = !longMax || long > longMax ? long : longMax;
  });
  return [longMin || 0, (latMin || 0) * (bottomOffset || 1), longMax || 0, latMax || 0];
}

const addDistanceMarkers = (
  mapInstance: mapboxgl.Map,
  routeJsonRecords: RouteRecord[],
  unitFormatter: UnitFormatter | undefined
) => {
  if (routeJsonRecords.length && unitFormatter) {
    const routeDistance = routeJsonRecords[routeJsonRecords.length - 1]?.dist_m;
    const routeDistanceFormatted = routeDistance
      ? unitFormatter.formatDistance(routeDistance).rawValue
      : 0;
    const getInterval = (totalDistance: number): number => {
      if (totalDistance < 10) {
        return 1; // Total distance under 10km /mi: show markers every 1km or mi
      } else if (totalDistance < 20) {
        return 2; // Total distance between 10km or mi and 20km or mi: show markers every 2km or mi
      } else if (totalDistance < 100) {
        return 5; // Total distance between 20km or mi and 100km or mi: show markers every 5km or mi
      } else {
        return 10; // Total distance over 100km or mi: show markers every 10km or mi
      }
    };

    const interval = getInterval(routeDistanceFormatted);

    const selectedRecords: Record<number, RouteRecord> = {};
    routeJsonRecords.forEach((record) => {
      const distance = unitFormatter.formatDistance(record.dist_m).rawValue;
      const nearestMultiple = Math.floor(distance / interval) * interval;
      if (distance > nearestMultiple) {
        selectedRecords[nearestMultiple] = record;
      }
    });
    // cut the last one off since that would be beyond the end point (rounded up)
    const distanceMarkerRecords = Object.values(selectedRecords).slice(0, -1);

    distanceMarkerRecords.forEach((record, index) => {
      const markerElement = document.createElement("div");
      markerElement.className = "map_distance_marker";
      const markerText = (markerElement.textContent = ((index + 1) * interval).toString());
      markerElement.textContent = markerText;

      // Adjust font size if the text is three digits
      if (markerText.length >= 3) {
        markerElement.style.fontSize = "6px";
      }

      new mapboxgl.Marker(markerElement)
        .setLngLat([record.lon_deg, record.lat_deg])
        .addTo(mapInstance);
    });
  }
};

const removeDistanceMarkers = () => {
  const markers = document.querySelectorAll(".map_distance_marker");
  markers.forEach((marker) => marker.remove());
};

function setIsLayerVisible(mapInstance: mapboxgl.Map, layerId: string, visible: boolean) {
  if (mapInstance.getLayer(layerId)) {
    mapInstance.setLayoutProperty(layerId, "visibility", visible ? "visible" : "none");
  }
}

export {
  newMarker,
  getBoundingBox,
  addDistanceMarkers,
  removeDistanceMarkers,
  getGeoJsonSource,
  addRouteLayers,
  setIsLayerVisible,
  addStartAndFinishMarkers,
  removeStartAndFinishMarkers,
  removeRouteLayers,
};
