import { useDialogContext } from "@WahooFitness/wahoo-offline-mfe";
import Sex from "@/services/types/Sex";
import { Box, Link, MenuItem, TextField } from "@mui/material";
import { ChangeEventHandler, useCallback } from "react";
import { Trans, t } from "@lingui/macro";

type Props = {
  value?: Sex | null;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
};

const SexInput = ({ value, onChange }: Props) => {
  const { setDialog } = useDialogContext();

  const openDialog = useCallback(
    () =>
      setDialog({
        open: true,
        title: t`Why do we ask for your biological sex?`,
        body: t`Your assigned biological sex at birth can be a good indicator of your power output abilities, and is used to help inform your 4DP profile, rider type, and other defaults.`,
        actions: [
          {
            text: t`Got it`,
          },
        ],
      }),
    [setDialog]
  );

  return (
    <Box display="flex" flexDirection="column">
      <TextField
        select
        label={t`Sex`}
        value={value ?? Sex.None}
        variant="outlined"
        onChange={onChange}
        helperText={
          <Link variant="ui-xs-medium" color="info.main" onClick={openDialog}>
            <Trans>Why do we ask this?</Trans>
          </Link>
        }
        inputProps={{ "data-testid": "sexInput" }}
      >
        <MenuItem value={Sex.Male}>
          <Trans>Male</Trans>
        </MenuItem>
        <MenuItem value={Sex.Female}>
          <Trans>Female</Trans>
        </MenuItem>
        <MenuItem value={Sex.Other}>
          <Trans>Other</Trans>
        </MenuItem>
        <MenuItem value={Sex.None}>
          <Trans>Prefer not to say</Trans>
        </MenuItem>
      </TextField>
    </Box>
  );
};

export default SexInput;
