import { Box, Divider, ListItemText, Menu, MenuItem, useTheme } from "@mui/material";
import Chip from "./StyledChip";
import { ArrowsVertical, CaretDown, Checkmark } from "@carbon/icons-react";
import { useCallback, useState, MouseEvent } from "react";
import { t } from "@lingui/macro";
import { SortDirectionOption, SortOption, SortParams } from "./SortParams";

interface SortChipProps {
  sortParams: SortParams;
  sortOptions: Array<SortOption>;
  sortDirections: Array<SortDirectionOption>;
  setSortKey: (key: string) => void;
  setSortDirection: (key: string) => void;
}

function SortChip({
  sortParams,
  sortOptions,
  sortDirections,
  setSortKey,
  setSortDirection,
}: SortChipProps) {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpen = useCallback((ev: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(ev.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onSortDirectionChange = useCallback(
    (ev: MouseEvent<HTMLLIElement>) => {
      setSortDirection(ev.currentTarget.id);
      setAnchorEl(null);
    },
    [setSortDirection]
  );

  const onSortKeyChange = useCallback(
    (ev: MouseEvent<HTMLLIElement>) => {
      setSortKey(ev.currentTarget.id);
      setAnchorEl(null);
    },
    [setSortKey]
  );

  return (
    <Box>
      <Chip
        onClick={handleOpen}
        label={
          <Box display="flex" gap={1}>
            <ArrowsVertical />
            <CaretDown />
          </Box>
        }
      />
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        <MenuItem disabled dense divider>
          {t`Sort by`}
        </MenuItem>
        {sortOptions.map((sortKey) => (
          <MenuItem key={sortKey.id} id={sortKey.id} onClick={onSortKeyChange}>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <ListItemText>{sortKey.label}</ListItemText>
              {sortKey.id === sortParams?.sortKey && <Checkmark color={palette.success.main} />}
            </Box>
          </MenuItem>
        ))}
        <Divider />
        {sortDirections.map((direction) => (
          <MenuItem key={direction.id} id={direction.id} onClick={onSortDirectionChange}>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <ListItemText>{direction.label}</ListItemText>
              {direction.id === sortParams?.sortDir && <Checkmark color={palette.success.main} />}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

export default SortChip;
