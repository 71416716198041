import { useHeaderContext } from "@/hooks/useHeaderContext";
import { t } from "@lingui/macro";
import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import useTilePackNames from "./useTilePackNames";
import useNavUpOnDeviceDisconnect from "../useNavUpOnDeviceDisconnect";
import { WSMBoltTilePack } from "@WahooFitness/wsm-native/dist/esm/types/bolt_map";
import AddMapsEmbed from "./AddMapsEmbed";

const AddMaps = ({ appToken, tilePackId }: { appToken: string; tilePackId?: number }) => {
  const { setNavHeader } = useHeaderContext();

  useNavUpOnDeviceDisconnect(appToken);

  const [title, setTitle] = useState(t`Add maps`);

  const { tilePackNameTranslations } = useTilePackNames();

  const onTilePackLoad = useCallback(
    (tilePack: WSMBoltTilePack) => {
      setTitle(tilePackNameTranslations[tilePack.id] || tilePack.name);
    },
    [tilePackNameTranslations]
  );

  const headerProps = useMemo(
    () => ({
      title,
    }),
    [title]
  );

  useEffect(() => {
    setNavHeader(headerProps);
  });

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      p={2}
      justifyContent="space-between"
    >
      <AddMapsEmbed appToken={appToken} onTilePackLoad={onTilePackLoad} tilePackId={tilePackId} />
    </Box>
  );
};

export default AddMaps;
