import { PullToRefresh, useDialogContext, useUserContext } from "@WahooFitness/wahoo-offline-mfe";
import { Box, IconButton, Paper, Switch, Typography } from "@mui/material";
import { InfoIcon, MenuList } from "@WahooFitness/redesignr";
import React, { useCallback, useEffect, useMemo } from "react";
import ErrorBoundary from "../ErrorBoundary";
import WarmingUp from "../WarmUp";
import AuthorizedApp from "./AuthorizedApp";
import useThirdPartySharing from "./useThirdPartySharing";
import { Trans, t } from "@lingui/macro";
import { useHeaderContext } from "@/hooks/useHeaderContext";

const ThirdPartySharing: React.FC = () => {
  const {
    connectedAppsLoading,
    connectedAppsError,
    mutateConnectedApps,
    availableApps,
    authorizedApps,
    emailAuthorized,
    authorizeEmail,
    deauthorizeEmail,
    emailSharingUpdateInProgress,
  } = useThirdPartySharing();

  const { setDialog } = useDialogContext();
  const { setNavHeader } = useHeaderContext();

  const openInfoDialog = useCallback(() => {
    setDialog({
      open: true,
      title: t`About authorized apps`,
      body: t`Authorizing select third party apps may boost functionality to your account. Some external apps offer extra privacy settings when sharing your data`,
      actions: [{ text: t`Got it` }],
    });
  }, [setDialog]);

  const handleReload = useCallback(() => {
    mutateConnectedApps();
  }, [mutateConnectedApps]);

  const HeaderActionComponent = useMemo(
    () => (
      <Box mr={1}>
        <IconButton onClick={openInfoDialog}>
          <InfoIcon />
        </IconButton>
      </Box>
    ),
    [openInfoDialog]
  );

  const handleEmailToggle = useCallback(
    (_: any, checked: boolean) => {
      if (emailSharingUpdateInProgress) {
        return;
      }
      if (checked) {
        authorizeEmail();
      } else {
        deauthorizeEmail();
      }
    },
    [authorizeEmail, deauthorizeEmail, emailSharingUpdateInProgress]
  );

  const { currentUser } = useUserContext();

  const headerProps = useMemo(() => {
    return { title: t`Authorized apps`, headerAction: HeaderActionComponent };
  }, [HeaderActionComponent]);

  useEffect(() => {
    setNavHeader(headerProps);
  });

  return (
    <ErrorBoundary
      isLoading={connectedAppsLoading}
      error={connectedAppsError}
      onReload={handleReload}
    >
      <PullToRefresh onRefresh={handleReload} />
      <Box display="flex" alignItems="center" justifyContent="center" m={2}>
        <Typography variant="prose-sm" color="text.secondary" align="center">
          <Trans>
            Unlock more features and enhance your experience by authorizing third-party services.
          </Trans>
        </Typography>
      </Box>
      {connectedAppsLoading ? (
        <WarmingUp />
      ) : (
        <>
          <Box px={2}>
            <Paper elevation={4}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                py={1}
                px={2}
              >
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <Typography variant="prose-base">{t`Email activities`}</Typography>
                  <Typography variant="prose-sm">{currentUser?.email}</Typography>
                </Box>
                <Switch
                  checked={emailAuthorized}
                  onChange={handleEmailToggle}
                  color="info"
                  disabled={emailSharingUpdateInProgress}
                />
              </Box>
            </Paper>
          </Box>
          <Box display="flex" flexDirection="column" gap={1} px={2} pt={1} pb={3}>
            {authorizedApps.map((app) => (
              <AuthorizedApp key={app.id} {...app} />
            ))}
          </Box>
          <Box mx={2}>
            <Typography variant="prose-md-bold">
              <Trans>Available apps</Trans>
            </Typography>
          </Box>
          <MenuList listItems={availableApps} />
        </>
      )}
    </ErrorBoundary>
  );
};

export default ThirdPartySharing;
