import { PropsWithChildren, RefObject, useEffect } from "react";
import { Button, Divider, List, ListItem, Stack } from "@mui/material";
import AutoCalculate from "./AutoCalculate";
import { ZoneSet } from "./useZones";
import ZoneCountSelector from "./ZoneCountSelector";
import ZonesEditor from "./ZonesEditor";
import AssignedProfiles from "./AssignedProfiles/AssignedProfiles";
import { Trans } from "@lingui/macro";
import useSetUpPaces from "../Onboarding/SetUpPaces/useSetUpPaces";
import SetUpPacesDrawer from "../Onboarding/SetUpPaces/SetUpPacesDrawer";

type Props = {
  zoneSet: ZoneSet;
  zoneSetId: number;
  primaryZoneSetId?: number;
  type: "power" | "heartRate" | "speed";
  savedZoneSet: ZoneSet;
  editing: boolean;
  zoneCount: number;
  zoneCountChoices: number[] | undefined;
  updatedAt: Date | undefined;
  enableEditing: (zoneSetId: number) => void;
  handleZoneCountChange: (zoneSetId: number, value: number) => void;
  handleZoneRangeChange: (value: number, index: number, zoneSetId: number) => void;
  autoCalculateVariant: "ftp" | "thr" | "threshold_speed";
  maxAutoCalculateValue?: number;
  handleAutoCalcZones: (zoneSet: ZoneSet, params: Record<string, number>) => Promise<void>;
  fixInvalidZones: (zoneSetId: number, startingIndex: number) => void;
  allowEditing: boolean;
  parentDrawerRef?: RefObject<HTMLDivElement>;
  displayIndex: number;
  replaceZoneSet: (zoneSetId: number, zoneSet: ZoneSet) => void;
};

const ZoneSetView = ({
  zoneSet,
  zoneSetId,
  primaryZoneSetId,
  type,
  savedZoneSet,
  editing,
  zoneCount,
  zoneCountChoices,
  updatedAt,
  enableEditing,
  handleZoneCountChange,
  handleZoneRangeChange,
  autoCalculateVariant,
  maxAutoCalculateValue,
  handleAutoCalcZones,
  fixInvalidZones,
  allowEditing,
  parentDrawerRef,
  children,
  displayIndex,
  replaceZoneSet,
}: PropsWithChildren<Props>) => {
  const {
    setupPacesDrawerOpen,
    handleOpen: handleOpenSetUpPacesDrawer,
    handleClose: handleCloseSetUpPacesDrawer,
    yourPaceZones,
    setYourPaceZones,
    showResultPage,
    setShowResultPage,
    handleBackFromResult,
    handleSave,
    getZoneSetFromPaceZones,
  } = useSetUpPaces({ closeAfterSave: true });

  useEffect(() => {
    const newZoneSet = getZoneSetFromPaceZones(displayIndex);
    if (newZoneSet) {
      if (newZoneSet) {
        replaceZoneSet(zoneSetId, {
          ...newZoneSet,
          id: zoneSet.id,
          name: zoneSet.name,
          primary: zoneSet.primary,
        });
      }
    }
  }, [
    yourPaceZones,
    replaceZoneSet,
    zoneSetId,
    getZoneSetFromPaceZones,
    displayIndex,
    zoneSet.id,
    zoneSet.name,
    zoneSet.primary,
  ]);

  return (
    <List disablePadding>
      {(!editing || (zoneCountChoices && zoneCountChoices.length > 1)) && (
        <>
          <ListItem disableGutters={editing}>
            <ZoneCountSelector
              zoneCount={zoneCount}
              updatedAt={updatedAt}
              editable={editing}
              onClickEdit={() => enableEditing(zoneSetId)}
              onZoneCountChange={(value) => handleZoneCountChange(zoneSetId, value)}
              zoneCountChoices={zoneCountChoices}
              allowEditing={allowEditing}
              displayIndex={displayIndex}
            />
          </ListItem>
          {!editing && <Divider variant="middle" />}
        </>
      )}
      <ListItem>
        <Stack direction="column" spacing={1} width="100%">
          {editing && type === "speed" && (
            <Button
              variant="outlined"
              size="large"
              fullWidth
              onClick={handleOpenSetUpPacesDrawer}
              data-testid="estimateMyPaceButton"
            >
              <Trans>Estimate my threshold pace</Trans>
            </Button>
          )}
          <AutoCalculate
            variant={autoCalculateVariant}
            editable={editing}
            zoneSet={zoneSet}
            disabled={type === "heartRate" && zoneSet.zones.length !== 5}
            handleAutoCalcZones={handleAutoCalcZones}
            zoneCount={zoneCount}
            maxValue={maxAutoCalculateValue}
          />
        </Stack>
      </ListItem>
      <Divider variant="middle" />
      <AssignedProfiles
        editing={editing}
        zoneSetId={zoneSetId}
        primaryZoneSetId={primaryZoneSetId}
        type={type}
        parentDrawerRef={parentDrawerRef}
      />
      <Divider variant="middle" />
      <ListItem>
        <ZonesEditor
          type={type}
          zones={(editing ? zoneSet?.zones : savedZoneSet?.zones) || []}
          editable={editing}
          onZoneChange={(value: number, index: number) =>
            handleZoneRangeChange(value, index, zoneSetId)
          }
          fixInvalidZones={(startingIndex) => fixInvalidZones(zoneSetId, startingIndex)}
        />
      </ListItem>
      {editing && children}
      <SetUpPacesDrawer
        open={setupPacesDrawerOpen}
        handleClose={handleCloseSetUpPacesDrawer}
        yourPaceZones={yourPaceZones}
        setYourPaceZones={setYourPaceZones}
        showResultPage={showResultPage}
        setShowResultPage={setShowResultPage}
        handleBackFromResult={handleBackFromResult}
        handleSave={handleSave}
      />
    </List>
  );
};

export default ZoneSetView;
