import { ChangeEvent, FocusEvent, useCallback, useEffect, useMemo, useState } from "react";
import useWorkoutPages from "./useWorkoutPages";
import useWorkoutPageDisplayStrings from "./useWorkoutPageDisplayStrings";
import { useUserContext } from "@WahooFitness/wahoo-offline-mfe";
import {
  ImperialMetricDisplayPreference,
  WorkoutProfileResponseType,
} from "@WahooFitness/cloud-client-ts/Types";
import { MenuListItemVariant } from "@WahooFitness/redesignr";
import { ChevronRight } from "@carbon/icons-react";
import { Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { FuzzyResult } from "@nozbe/microfuzz";
import { useFuzzySearchList } from "@nozbe/microfuzz/react";
import useWorkoutPageCategoryStrings from "./useWorkoutPageCategoryStrings";
import useWorkoutPageFieldStrings from "./useWorkoutPageFieldStrings";
import useAssociatedDevices from "@/hooks/useAssociatedDevices";
import {
  BoltDisplayCategory,
  BoltDisplayField,
  crux_bolt_display_pedaling_monitor_graph_type_e,
} from "@WahooFitness/crux-js-display-cfg";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import { t } from "@lingui/macro";

const useEditWorkoutPage = (
  profile?: WorkoutProfileResponseType,
  pageId?: number,
  elevation?: boolean
) => {
  const {
    getPage,
    pagesAreLoading,
    getCategoriesForPageType,
    savePages,
    flushPendingSave,
    displayConfig,
    setDisplayConfig,
    elevationPage,
    maxPageNameLength,
  } = useWorkoutPages(elevation);

  const page = useMemo(
    () => (elevation ? elevationPage?.getClimbSubPage() : getPage(pageId || 0)),
    [elevation, elevationPage, getPage, pageId]
  );

  const {
    associatedDevicesIsLoading,
    associatedDevicesError,
    hasAssociatedBolt,
    hasAssociatedRoam,
    hasAssociatedAce,
  } = useAssociatedDevices();

  const boltMaxFields = useMemo(() => page?.getBoltMaxFields() || 0, [page]);
  const maxFields = useMemo(() => {
    let max;
    if (hasAssociatedAce) {
      max = page?.getAceMaxFields();
    } else if (hasAssociatedRoam) {
      max = page?.getRoamMaxFields();
    } else if (hasAssociatedBolt) {
      max = page?.getBoltMaxFields();
    } else {
      max = 0;
    }
    return max ?? 0;
  }, [hasAssociatedAce, hasAssociatedBolt, hasAssociatedRoam, page]);
  const fieldsCount = useMemo(() => page?.getFieldsCount() || 0, [page]);

  const { workoutPageTranslations } = useWorkoutPageDisplayStrings();

  const pageName = useMemo(
    () => page && (page?.getCustomTitle() || workoutPageTranslations[page.getType()]),
    [page, workoutPageTranslations]
  );

  const isMapPage = useMemo(() => !!page?.isMapPage(), [page]);

  const isPedalMonitorPage = useMemo(() => !!page?.isPedalMonitorPage(), [page]);

  const [pageNameInputValue, setPageNameInputValue] = useState<string>("");
  const [pageNameError, setPageNameError] = useState("");

  useEffect(() => {
    setPageNameInputValue(pageName || "");
  }, [pageName]);

  const handlePageNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (value.length > maxPageNameLength) {
        setPageNameError(t`Please enter a value with ${maxPageNameLength} or less characters`);
      } else {
        setPageNameError("");
      }
      setPageNameInputValue(value);
    },
    [maxPageNameLength]
  );

  const handlePageNameBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (!page || !displayConfig || value.length > maxPageNameLength) {
        return;
      }
      page?.setCustomTitle(value);
      const updatedPage = page.updateParentPage();
      displayConfig?.update(updatedPage);
      savePages(true);
    },
    [displayConfig, maxPageNameLength, page, savePages]
  );

  const [displayFields, setDisplayFields] = useState<BoltDisplayField[]>([]);

  useEffect(() => {
    if (!page) {
      return;
    }
    const fields = [];
    for (let i = 0; i < fieldsCount; i++) {
      fields.push(page.getFieldAt(i));
    }
    setDisplayFields(fields);
  }, [fieldsCount, page]);

  const staticDisplayFields = useMemo(
    () => displayFields.filter((field) => field.isPermanent()),
    [displayFields]
  );

  const [fieldToReplace, setFieldToReplace] = useState<BoltDisplayField | undefined>(undefined);
  const [selectedCategories, setSelectedCategories] = useState<BoltDisplayCategory[]>([]);
  const lastSelectedCategory = useMemo(
    () =>
      selectedCategories.length > 0 ? selectedCategories[selectedCategories.length - 1] : undefined,
    [selectedCategories]
  );
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [changingPedalMonitorGraphType, setChangingPedalMonitorGraphType] = useState(false);

  const openCategoryDialog = useCallback(
    (fieldToReplace?: BoltDisplayField, pedalMonitorGraphType?: boolean) => {
      setFieldToReplace(fieldToReplace);
      setCategoryDialogOpen(true);
      setSearchTerm("");
      setChangingPedalMonitorGraphType(pedalMonitorGraphType || false);
    },
    [setFieldToReplace]
  );

  const closeCategoryDialog = useCallback(() => {
    setFieldToReplace(undefined);
    setSelectedCategories([]);
    setCategoryDialogOpen(false);
    setSearchTerm("");
    setChangingPedalMonitorGraphType(false);
  }, [setFieldToReplace, setSelectedCategories]);

  const handleChangeSearchTerm = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  const { workoutFieldTranslations, pedalMonitorGraphTypeFieldTranslations } =
    useWorkoutPageFieldStrings();
  const { workoutPageCategoryTranslations } = useWorkoutPageCategoryStrings();

  const baseCategories = useMemo(
    () =>
      getCategoriesForPageType(page?.getType()).sort((left, right) =>
        workoutPageCategoryTranslations[left.getId()].localeCompare(
          workoutPageCategoryTranslations[right.getId()]
        )
      ),
    [getCategoriesForPageType, page, workoutPageCategoryTranslations]
  );

  const allAvailableFields = useMemo(() => {
    return baseCategories
      .reduce((acc, category) => {
        return [
          ...acc,
          ...category.getFields(),
          ...category.getSubCategories().flatMap((c) => c.getFields()),
        ];
      }, [] as BoltDisplayField[])
      .sort((left, right) =>
        workoutFieldTranslations[left.getId()].localeCompare(
          workoutFieldTranslations[right.getId()]
        )
      );
  }, [baseCategories, workoutFieldTranslations]);

  const getText = useCallback(
    (field: BoltDisplayField) => [workoutFieldTranslations[field.getId()]],
    [workoutFieldTranslations]
  );

  const mapResultItem = useCallback((val: FuzzyResult<BoltDisplayField>) => val.item, []);

  const matchingFields = useFuzzySearchList({
    list: allAvailableFields,
    queryText: searchTerm,
    getText,
    mapResultItem,
  });

  const availableCategories = useMemo(() => {
    return lastSelectedCategory
      ? lastSelectedCategory
          .getSubCategories()
          .sort((left, right) =>
            workoutPageCategoryTranslations[left.getId()].localeCompare(
              workoutPageCategoryTranslations[right.getId()]
            )
          )
      : baseCategories;
  }, [baseCategories, lastSelectedCategory, workoutPageCategoryTranslations]);

  const availableFields = useMemo(() => {
    return searchTerm
      ? matchingFields
      : lastSelectedCategory
          ?.getFields()
          .sort((left, right) =>
            workoutFieldTranslations[left.getId()].localeCompare(
              workoutFieldTranslations[right.getId()]
            )
          ) || [];
  }, [lastSelectedCategory, matchingFields, searchTerm, workoutFieldTranslations]);

  const replaceField = useCallback(
    (newField: BoltDisplayField) => {
      if (!fieldToReplace || !page) {
        return;
      }
      const replaceIndex = page.getIndexOfField(fieldToReplace);
      page.removeFieldAt(replaceIndex);
      page.insertFieldAt(replaceIndex, newField);
      const updatedPage = page.updateParentPage();
      const newDisplayConfig = displayConfig?.update(updatedPage);
      setDisplayConfig(newDisplayConfig);
      savePages();
    },
    [displayConfig, fieldToReplace, page, savePages, setDisplayConfig]
  );

  const addField = useCallback(
    (newField: BoltDisplayField) => {
      if (!page) return;
      page?.addField(newField);
      const updatedPage = page.updateParentPage();
      const newDisplayConfig = displayConfig?.update(updatedPage);
      setDisplayConfig(newDisplayConfig);
      savePages();
    },
    [displayConfig, page, savePages, setDisplayConfig]
  );

  const removeField = useCallback(
    (field: BoltDisplayField) => {
      if (!page) return;
      const fieldIndex = page.getIndexOfField(field);
      page?.removeFieldAt(fieldIndex);
      const updatedPage = page.updateParentPage();
      const newDisplayConfig = displayConfig?.update(updatedPage);
      setDisplayConfig(newDisplayConfig);
      savePages();
    },
    [displayConfig, page, savePages, setDisplayConfig]
  );

  const handleFieldOptionClick = useCallback(
    (clickedField: BoltDisplayField) => {
      if (!page) return;
      if (fieldToReplace) {
        replaceField(clickedField);
        closeCategoryDialog();
        return;
      }
      if (page.containsField(clickedField)) {
        removeField(clickedField);
        return;
      }
      if (fieldsCount < maxFields) {
        addField(clickedField);
      }
    },
    [
      addField,
      closeCategoryDialog,
      fieldToReplace,
      fieldsCount,
      page,
      removeField,
      replaceField,
      maxFields,
    ]
  );

  const moveField = useCallback(
    (startIndex: number, newIndex: number) => {
      if (!page) return;
      const field = page.getFieldAt(startIndex);
      setDisplayFields((displayFields) => {
        displayFields.splice(startIndex, 1);
        return [...displayFields.slice(0, newIndex), field, ...displayFields.slice(newIndex)];
      });
      page.removeFieldAt(startIndex);
      page.insertFieldAt(newIndex, field);
      const updatedPage = page.updateParentPage();
      const newDisplayConfig = displayConfig?.update(updatedPage);
      setDisplayConfig(newDisplayConfig);
      savePages();
    },
    [displayConfig, page, savePages, setDisplayConfig]
  );

  const theme = useTheme();
  const { currentUser } = useUserContext();

  const fieldIdsOnPage = useMemo(
    () => displayFields.map((field) => field.getId()),
    [displayFields]
  );

  const savePage = useCallback(
    async (immediately?: boolean) => {
      if (!page) return;
      const updatedPage = page.updateParentPage();
      displayConfig?.update(updatedPage);
      await savePages(immediately);
    },
    [displayConfig, page, savePages]
  );

  const dialogMenuItems = useMemo(() => {
    if (!profile || !page || !currentUser) return [];
    return availableFields.length > 0 || searchTerm
      ? availableFields?.map((field) => {
          const fieldPosition = page.getIndexOfField(field) + 1;
          return {
            key: field.getId(),
            variant: MenuListItemVariant.Action,
            id: field.getId().toString(),
            action: () => handleFieldOptionClick(field),
            actionComponent: (
              <Typography variant="prose-base" color="text.secondary">
                {field.getUnitName(
                  profile.workout_type_id || 0,
                  currentUser.display_preference?.speed_distance !==
                    ImperialMetricDisplayPreference.imperial,
                  currentUser.display_preference?.elevation !==
                    ImperialMetricDisplayPreference.imperial,
                  currentUser.display_preference?.weight !==
                    ImperialMetricDisplayPreference.imperial,
                  currentUser.display_preference?.temperature !==
                    ImperialMetricDisplayPreference.imperial
                )}
              </Typography>
            ),
            content: workoutFieldTranslations[field.getId()],
            icon:
              !fieldToReplace && fieldPosition > 0 ? (
                <Box
                  bgcolor="success.main"
                  borderRadius="50%"
                  height={20}
                  width={20}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography color="success.contrastText" variant="ui-2xs-medium">
                    {fieldPosition}
                  </Typography>
                </Box>
              ) : undefined,
            disabled:
              (fieldToReplace && fieldIdsOnPage.includes(field.getId())) ||
              (!fieldToReplace &&
                fieldsCount >= maxFields &&
                !fieldIdsOnPage.includes(field.getId())),
          };
        })
      : availableCategories.map((category) => ({
          key: category.getId(),
          variant: MenuListItemVariant.Action,
          id: category.getId().toString(),
          content: workoutPageCategoryTranslations[category.getId()],
          action: () => {
            setSelectedCategories((current) => [...current, category]);
          },
          actionComponent: (
            <ChevronRight height={24} width={24} color={theme.palette.text.secondary} />
          ),
        }));
  }, [
    availableCategories,
    availableFields,
    currentUser,
    fieldIdsOnPage,
    fieldToReplace,
    fieldsCount,
    handleFieldOptionClick,
    maxFields,
    page,
    profile,
    searchTerm,
    theme.palette.text.secondary,
    workoutFieldTranslations,
    workoutPageCategoryTranslations,
  ]);

  const [showElevationProfile, setShowElevationProfile] = useState(true);

  useEffect(() => {
    setShowElevationProfile(page?.getMapShowElevationProfile() || false);
  }, [page]);

  const toggleShowElevationProfile = useCallback(() => {
    page?.setMapShowElevationProfile(!showElevationProfile);
    setShowElevationProfile((prev) => !prev);
    savePage();
  }, [page, savePage, showElevationProfile]);

  const [pedalMonitorGraphType, setPedalMonitorGraphType] = useState(0);

  useEffect(() => {
    setPedalMonitorGraphType(page?.getPedalingMonitorGraphType() || 0);
  }, [page]);

  const handleChangePedalMonitorGraphType = useCallback(
    (graphType: number) => {
      page?.setPedalingMonitorGraphType(pedalMonitorGraphType);
      setPedalMonitorGraphType(graphType);
      savePage();
      closeCategoryDialog();
    },
    [closeCategoryDialog, page, pedalMonitorGraphType, savePage]
  );

  const pedalMonitorGraphTypeMenuItems = useMemo(() => {
    const fieldOptions = [
      crux_bolt_display_pedaling_monitor_graph_type_e.FORCE_VECTORS_POWER_LR,
      crux_bolt_display_pedaling_monitor_graph_type_e.FORCE_VECTORS_BALANCE_LR,
      crux_bolt_display_pedaling_monitor_graph_type_e.FORCE_VECTORS_EFFICIENCY_LR,
      crux_bolt_display_pedaling_monitor_graph_type_e.TORQUE_CHART_POWER_LR,
      crux_bolt_display_pedaling_monitor_graph_type_e.TORQUE_CHART_BALANCE_LR,
      crux_bolt_display_pedaling_monitor_graph_type_e.TORQUE_CHART_EFFICIENCY_LR,
    ];
    return fieldOptions.map((fieldOption) => ({
      key: fieldOption.toString(),
      variant: MenuListItemVariant.Action,
      id: fieldOption.toString(),
      content: pedalMonitorGraphTypeFieldTranslations[fieldOption],
      action: () => handleChangePedalMonitorGraphType(fieldOption),
    }));
  }, [handleChangePedalMonitorGraphType, pedalMonitorGraphTypeFieldTranslations]);

  const { disablePubSubUpdates } = useWorkoutProfilesContext();

  useEffect(() => {
    disablePubSubUpdates();
  }, [disablePubSubUpdates]);

  return {
    pagesAreLoading: pagesAreLoading || associatedDevicesIsLoading,
    error: associatedDevicesError,
    pageName,
    pageNameError,
    maxFields,
    displayFields,
    staticDisplayFields,
    boltMaxFields,
    fieldsCount,
    openCategoryDialog,
    closeCategoryDialog,
    changingPedalMonitorGraphType,
    removeField,
    categoryDialogOpen,
    setFieldToReplace,
    selectedCategories,
    setSelectedCategories,
    lastSelectedCategory,
    searchTerm,
    handleChangeSearchTerm,
    dialogMenuItems,
    pedalMonitorGraphTypeMenuItems,
    pageNameInputValue,
    handlePageNameChange,
    handlePageNameBlur,
    moveField,
    savePage,
    flushPendingSave,
    hasAssociatedBolt,
    hasAssociatedRoam,
    hasAssociatedAce,
    isMapPage,
    isPedalMonitorPage,
    showElevationProfile,
    toggleShowElevationProfile,
    pedalMonitorGraphType,
    handleChangePedalMonitorGraphType,
  };
};

export default useEditWorkoutPage;
