import NavHeader from "@/components/NavHeader";
import { ChevronLeft } from "@carbon/icons-react";
import { t, Trans } from "@lingui/macro";
import { Box, Button, TextField, Typography } from "@mui/material";
import {
  WorkoutAlertTriggerTypeEnum,
  WorkoutAlertType,
  WorkoutMetricTypeEnum,
} from "@WahooFitness/cloud-client-ts/Types";
import {
  CheckMarkIcon,
  MenuList,
  MenuListItemType,
  MenuListItemVariant,
} from "@WahooFitness/redesignr";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import useAlertMetrics from "./useAlertMetrics";

const AlertMetricSelector = ({
  alert,
  backAction,
  onCreate,
}: {
  alert: WorkoutAlertType;
  backAction: () => void;
  onCreate: (alert: WorkoutAlertType) => void;
  height?: number | string;
}) => {
  const { getCategorizedAlertMetrics, getEquivalentMetric } = useAlertMetrics();
  const categorizedAlertMetrics = useMemo(
    () => getCategorizedAlertMetrics(alert.trigger_type || 0),
    [alert.trigger_type, getCategorizedAlertMetrics]
  );

  const getEquivalentMetrics = useCallback(
    (triggerType: WorkoutAlertTriggerTypeEnum, metrics: WorkoutMetricTypeEnum[]) => {
      const categoryOptions = categorizedAlertMetrics[triggerType].options.map(
        (option) => option.metric
      );

      return metrics.map((metric) => {
        if (!categoryOptions.includes(metric)) {
          return getEquivalentMetric(triggerType, metric);
        }
        return metric;
      });
    },
    [categorizedAlertMetrics, getEquivalentMetric]
  );
  const [selectedMetrics, setSelectedMetrics] = useState<number[]>(
    getEquivalentMetrics(alert.trigger_type ?? 0, alert.workout_metric_type_ids)
  );

  const handleSelection = useCallback(
    (metric: number) => {
      const index = selectedMetrics.findIndex((metricType) => metricType === metric);
      if (index === -1) {
        selectedMetrics.push(metric);
      } else {
        selectedMetrics.splice(index, 1);
      }
      setSelectedMetrics([...selectedMetrics]);
    },
    [selectedMetrics]
  );

  const metricOptions = useMemo(
    () =>
      categorizedAlertMetrics.map((category) => ({
        name: category.name,
        id: category.id,
        listItems: category.options.map(
          (option) =>
            ({
              id: option.metric.toString(),
              content: (
                <Typography
                  variant={
                    selectedMetrics.includes(option.metric) ? "prose-base-bold" : "prose-base"
                  }
                  data-testid={option.testId}
                >
                  {option.name}
                </Typography>
              ),
              variant: MenuListItemVariant.Action,
              actionComponent: selectedMetrics.includes(option.metric) ? (
                <CheckMarkIcon height={24} width={24} />
              ) : undefined,
              action: () => handleSelection(option.metric),
            }) as MenuListItemType
        ),
      })),
    [categorizedAlertMetrics, handleSelection, selectedMetrics]
  );

  const [customTextSelected, setCustomTextSelected] = useState(!!alert.custom_alert_text);
  const [customText, setCustomText] = useState(alert.custom_alert_text);
  const trimmedCustomText = useMemo(() => customText.trimStart(), [customText]);

  const handleCreate = useCallback(() => {
    alert.workout_metric_type_ids = selectedMetrics;
    alert.custom_alert_text = trimmedCustomText.trimEnd();
    onCreate(alert);
  }, [alert, onCreate, selectedMetrics, trimmedCustomText]);

  const customTextCount = useMemo(
    () => (customTextSelected && trimmedCustomText ? 1 : 0),
    [customTextSelected, trimmedCustomText]
  );
  const handleCustomTextChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const trimmed = event.target.value.trimStart();
    if (trimmed.length <= 50) {
      setCustomText(event.target.value);
    }
  }, []);

  const customTextListItem = useMemo(
    () =>
      [
        {
          id: "customText",
          content: (
            <Typography
              variant={customTextSelected ? "prose-base-bold" : "prose-base"}
              data-testid="customText"
            >
              {t`Custom text`}
            </Typography>
          ),
          variant: MenuListItemVariant.Action,
          actionComponent: customTextSelected ? (
            <CheckMarkIcon height={24} width={24} />
          ) : undefined,
          action: () =>
            setCustomTextSelected((prev) => {
              if (prev) {
                setCustomText("");
              }
              return !prev;
            }),
        },
      ] as MenuListItemType[],
    [customTextSelected]
  );

  const disableCreateButton = useMemo(
    () => selectedMetrics.length + customTextCount <= 0,
    [customTextCount, selectedMetrics.length]
  );

  return (
    <Box overflow="hidden" display="flex" flexDirection="column">
      <NavHeader
        title={t`Information`}
        backText={
          <Box display="flex" flexDirection="row" gap={1}>
            <ChevronLeft height={20} width={20} />
            <Box>{t`Back`}</Box>
          </Box>
        }
        backAction={backAction}
        headerAction={
          <Button
            variant="text"
            size="medium"
            color="info"
            onClick={handleCreate}
            disabled={disableCreateButton}
            data-testid="createButton"
          >
            {alert.id === -1 ? t`Create` : t`Update`}
          </Button>
        }
        disableBoxShadow
        elevation={1}
      />
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        overflow="auto"
        flexGrow={1}
        px={2}
        gap={1}
      >
        <Typography
          variant="prose-md-medium"
          textAlign="center"
        >{t`What information do you want to hear?`}</Typography>
        <Box maxWidth="sm" width="100%" display="flex" flexDirection="column" gap={1}>
          <MenuList key="customText" listItems={customTextListItem} disableGutters />
          {customTextSelected && (
            <TextField
              value={customText}
              onChange={handleCustomTextChange}
              InputProps={{
                endAdornment: (
                  <Typography variant="prose-base">
                    <Trans>{trimmedCustomText.length}/50</Trans>
                  </Typography>
                ),
              }}
            />
          )}
          {metricOptions.map((category) => (
            <Box key={category.id}>
              <Typography variant="prose-sm-bold" color="text.secondary">
                {category.name}
              </Typography>
              <MenuList key={category.id} listItems={category.listItems} disableGutters />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AlertMetricSelector;
