import { useMemo } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { hasMinProfile } from "@/services/profileService";
import { Profile } from "@WahooFitness/cloud-client-ts/Types";
import { Trans } from "@lingui/macro";
import { useConfigContext } from "@WahooFitness/wahoo-offline-mfe";

function About({ profile }: { profile?: Profile }) {
  const {
    palette: {
      text: { secondary },
    },
  } = useTheme();

  // App Info is the rest of the config, minus the wahoo token.
  const { version, os, wahooToken: _wahooToken, ...appInfo } = useConfigContext();

  const currentYear = new Date().getFullYear();
  const toCurrentYear = useMemo(() => (currentYear > 2023 ? `-${currentYear}` : ""), [currentYear]);

  return (
    <Box mx={2} display="flex" flexDirection="column">
      <Typography color={secondary} variant="ui-2xs">
        <Trans>© 2023{toCurrentYear} Wahoo Fitness L.L.C. All Rights Reserved.</Trans>
      </Typography>
      <Typography color={secondary} variant="ui-2xs">
        <Trans>
          version: {version} ({SETTINGS_MFE_VERSION})
        </Trans>
      </Typography>
      {!!os && (
        <Typography color={secondary} variant="ui-2xs">
          <Trans>os: {os}</Trans>
        </Typography>
      )}
      {hasMinProfile(Profile.beta, profile) &&
        Object.entries(appInfo)
          .filter(
            ([_key, value]) => value !== undefined && !["object", "function"].includes(typeof value)
          )
          .map(([key, value]) => (
            <Typography key={key} color={secondary} variant="ui-2xs">
              {key}: {`${value}`}
            </Typography>
          ))}
    </Box>
  );
}

export default About;
