const TheShovelPlanGraph = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 4434 38"
    preserveAspectRatio="none"
    height="38"
    width="372"
    shapeRendering="crispEdges"
  >
    <rect x="0" y="31.03333333333333" width="322" height="6.966666666666667" fill="#5fdfff"></rect>
    <rect
      x="322"
      y="29.133333333333333"
      width="241"
      height="8.866666666666667"
      fill="#5fdfff"
    ></rect>
    <rect
      x="563"
      y="27.866666666666667"
      width="180"
      height="10.133333333333333"
      fill="#5fdfff"
    ></rect>
    <rect x="743" y="26.6" width="121" height="11.4" fill="#5fdfff"></rect>
    <rect
      x="864"
      y="25.333333333333336"
      width="59"
      height="12.666666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="923"
      y="12.666666666666668"
      width="11"
      height="25.333333333333332"
      fill="#fb8b04"
    ></rect>
    <rect
      x="934"
      y="31.666666666666668"
      width="75"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1009"
      y="17.099999999999998"
      width="10"
      height="20.900000000000002"
      fill="#ffc500"
    ></rect>
    <rect
      x="1019"
      y="31.666666666666668"
      width="80"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1099"
      y="24.700000000000003"
      width="55"
      height="13.299999999999999"
      fill="#5fdfff"
    ></rect>
    <rect x="1154" y="32.93333333333334" width="6" height="5.066666666666666" fill="#5fdfff"></rect>
    <rect
      x="1160"
      y="24.066666666666666"
      width="49"
      height="13.933333333333334"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1209"
      y="32.93333333333334"
      width="10"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1219"
      y="22.325000000000003"
      width="45"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect
      x="1264"
      y="32.93333333333334"
      width="15"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1279"
      y="21.454166666666666"
      width="40"
      height="16.545833333333334"
      fill="#ffc500"
    ></rect>
    <rect
      x="1319"
      y="32.93333333333334"
      width="20"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1339"
      y="20.583333333333336"
      width="35"
      height="17.416666666666664"
      fill="#ffc500"
    ></rect>
    <rect
      x="1374"
      y="32.93333333333334"
      width="24"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect x="1398" y="19.7125" width="30" height="18.2875" fill="#ffc500"></rect>
    <rect
      x="1428"
      y="32.93333333333334"
      width="31"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1459"
      y="18.84166666666667"
      width="25"
      height="19.15833333333333"
      fill="#ffc500"
    ></rect>
    <rect
      x="1484"
      y="32.93333333333334"
      width="35"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1519"
      y="13.933333333333334"
      width="20"
      height="24.066666666666666"
      fill="#fb8b04"
    ></rect>
    <rect
      x="1539"
      y="32.93333333333334"
      width="40"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1579"
      y="10.133333333333333"
      width="15"
      height="27.866666666666667"
      fill="#fb8b04"
    ></rect>
    <rect
      x="1594"
      y="32.93333333333334"
      width="46"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1640"
      y="15.833333333333332"
      width="10"
      height="22.166666666666668"
      fill="#dd0000"
    ></rect>
    <rect
      x="1650"
      y="32.93333333333334"
      width="50"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1700"
      y="12.666666666666668"
      width="5"
      height="25.333333333333332"
      fill="#dd0000"
    ></rect>
    <rect
      x="1705"
      y="32.93333333333334"
      width="55"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1760"
      y="12.666666666666668"
      width="5"
      height="25.333333333333332"
      fill="#dd0000"
    ></rect>
    <rect
      x="1765"
      y="32.93333333333334"
      width="56"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1821"
      y="15.833333333333332"
      width="10"
      height="22.166666666666668"
      fill="#dd0000"
    ></rect>
    <rect
      x="1831"
      y="32.93333333333334"
      width="50"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1881"
      y="10.133333333333333"
      width="15"
      height="27.866666666666667"
      fill="#fb8b04"
    ></rect>
    <rect
      x="1896"
      y="32.93333333333334"
      width="45"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1941"
      y="13.933333333333334"
      width="20"
      height="24.066666666666666"
      fill="#fb8b04"
    ></rect>
    <rect
      x="1961"
      y="32.93333333333334"
      width="40"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2001"
      y="18.84166666666667"
      width="24"
      height="19.15833333333333"
      fill="#ffc500"
    ></rect>
    <rect
      x="2025"
      y="32.93333333333334"
      width="35"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect x="2060" y="19.7125" width="30" height="18.2875" fill="#ffc500"></rect>
    <rect
      x="2090"
      y="32.93333333333334"
      width="30"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2120"
      y="20.583333333333336"
      width="35"
      height="17.416666666666664"
      fill="#ffc500"
    ></rect>
    <rect
      x="2155"
      y="32.93333333333334"
      width="26"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2181"
      y="21.454166666666666"
      width="40"
      height="16.545833333333334"
      fill="#ffc500"
    ></rect>
    <rect
      x="2221"
      y="32.93333333333334"
      width="20"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2241"
      y="22.325000000000003"
      width="45"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect
      x="2286"
      y="32.93333333333334"
      width="15"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2301"
      y="24.066666666666666"
      width="50"
      height="13.933333333333334"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2351"
      y="32.93333333333334"
      width="10"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2361"
      y="25.333333333333336"
      width="60"
      height="12.666666666666666"
      fill="#5fdfff"
    ></rect>
    <rect x="2421" y="32.93333333333334" width="4" height="5.066666666666666" fill="#5fdfff"></rect>
    <rect
      x="2425"
      y="25.333333333333336"
      width="60"
      height="12.666666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2485"
      y="32.93333333333334"
      width="303"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2788"
      y="12.666666666666668"
      width="5"
      height="25.333333333333332"
      fill="#dd0000"
    ></rect>
    <rect
      x="2793"
      y="32.93333333333334"
      width="55"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2848"
      y="15.833333333333332"
      width="10"
      height="22.166666666666668"
      fill="#dd0000"
    ></rect>
    <rect
      x="2858"
      y="32.93333333333334"
      width="50"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2908"
      y="10.133333333333333"
      width="15"
      height="27.866666666666667"
      fill="#fb8b04"
    ></rect>
    <rect
      x="2923"
      y="32.93333333333334"
      width="45"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2968"
      y="13.933333333333334"
      width="20"
      height="24.066666666666666"
      fill="#fb8b04"
    ></rect>
    <rect
      x="2988"
      y="32.93333333333334"
      width="40"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="3028"
      y="18.84166666666667"
      width="25"
      height="19.15833333333333"
      fill="#ffc500"
    ></rect>
    <rect
      x="3053"
      y="32.93333333333334"
      width="35"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect x="3088" y="19.7125" width="30" height="18.2875" fill="#ffc500"></rect>
    <rect
      x="3118"
      y="32.93333333333334"
      width="30"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="3148"
      y="20.583333333333336"
      width="35"
      height="17.416666666666664"
      fill="#ffc500"
    ></rect>
    <rect
      x="3183"
      y="32.93333333333334"
      width="26"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="3209"
      y="21.454166666666666"
      width="40"
      height="16.545833333333334"
      fill="#ffc500"
    ></rect>
    <rect
      x="3249"
      y="32.93333333333334"
      width="20"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="3269"
      y="22.325000000000003"
      width="45"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect
      x="3314"
      y="32.93333333333334"
      width="15"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="3329"
      y="24.066666666666666"
      width="50"
      height="13.933333333333334"
      fill="#5fdfff"
    ></rect>
    <rect
      x="3379"
      y="32.93333333333334"
      width="10"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="3389"
      y="24.700000000000003"
      width="50"
      height="13.299999999999999"
      fill="#5fdfff"
    ></rect>
    <rect x="3439" y="32.93333333333334" width="5" height="5.066666666666666" fill="#5fdfff"></rect>
    <rect
      x="3444"
      y="25.333333333333336"
      width="61"
      height="12.666666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="3505"
      y="32.93333333333334"
      width="60"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="3565"
      y="24.700000000000003"
      width="55"
      height="13.299999999999999"
      fill="#5fdfff"
    ></rect>
    <rect x="3620" y="32.93333333333334" width="6" height="5.066666666666666" fill="#5fdfff"></rect>
    <rect
      x="3626"
      y="24.066666666666666"
      width="50"
      height="13.933333333333334"
      fill="#5fdfff"
    ></rect>
    <rect
      x="3676"
      y="32.93333333333334"
      width="10"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="3686"
      y="22.325000000000003"
      width="46"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect
      x="3732"
      y="32.93333333333334"
      width="15"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="3747"
      y="21.454166666666666"
      width="40"
      height="16.545833333333334"
      fill="#ffc500"
    ></rect>
    <rect
      x="3787"
      y="32.93333333333334"
      width="20"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="3807"
      y="20.583333333333336"
      width="35"
      height="17.416666666666664"
      fill="#ffc500"
    ></rect>
    <rect
      x="3842"
      y="32.93333333333334"
      width="25"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect x="3867" y="19.7125" width="30" height="18.2875" fill="#ffc500"></rect>
    <rect
      x="3897"
      y="32.93333333333334"
      width="31"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="3928"
      y="18.84166666666667"
      width="25"
      height="19.15833333333333"
      fill="#ffc500"
    ></rect>
    <rect
      x="3953"
      y="32.93333333333334"
      width="35"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="3988"
      y="12.666666666666668"
      width="20"
      height="25.333333333333332"
      fill="#fb8b04"
    ></rect>
    <rect
      x="4008"
      y="32.93333333333334"
      width="45"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="4053"
      y="10.133333333333333"
      width="15"
      height="27.866666666666667"
      fill="#fb8b04"
    ></rect>
    <rect
      x="4068"
      y="32.93333333333334"
      width="50"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="4118"
      y="15.833333333333332"
      width="10"
      height="22.166666666666668"
      fill="#dd0000"
    ></rect>
    <rect
      x="4128"
      y="32.93333333333334"
      width="51"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="4179"
      y="12.666666666666668"
      width="6"
      height="25.333333333333332"
      fill="#dd0000"
    ></rect>
    <rect
      x="4185"
      y="32.93333333333334"
      width="249"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
  </svg>
);
export default TheShovelPlanGraph;
