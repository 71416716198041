export enum SortKey {
  DATE = "DATE",
  ALPHA = "ALPHA",
  DURATION = "DURATION",
  TSS = "TSS",
  DISTANCE = "DISTANCE",
  PROXIMITY = "PROXIMITY",
}
export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export interface SortParams {
  sortKey: SortKey;
  sortDir: SortDirection;
}

export interface SortOption {
  id: SortKey;
  label: string;
}

export interface SortDirectionOption {
  id: SortDirection;
  label: string;
}
