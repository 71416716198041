import ErrorBoundary from "@/components/ErrorBoundary";
import useMaps from "./useMaps";
import useSWR from "swr";
import { WSMBoltTilePack } from "@WahooFitness/wsm-native/dist/esm/types/bolt_map";
import { useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import MapList from "./MapList";
import { Trans } from "@lingui/macro";
import useTilePackNames from "./useTilePackNames";

interface AddMapsEmbedProps {
  appToken: string;
  tilePackId?: number;
  onTilePackLoad?: (tilePack: WSMBoltTilePack) => void;
  onFolderClick?: (tilePackId: number) => void;
}

const AddMapsEmbed = ({
  appToken,
  tilePackId,
  onTilePackLoad,
  onFolderClick,
}: AddMapsEmbedProps) => {
  const {
    mapsSynced,
    mapsSyncError,
    retrySync,
    mapPack,
    mapPackIsLoading,
    mutateMapPack,
    getTilePack,
    sendDownloadMapTilePack,
    sendUpgradeMapTilePack,
    addMapUpdateListener,
    removeMapUpdateListener,
  } = useMaps(appToken);

  const {
    data: tilePacks,
    isLoading: tilePacksAreLoading,
    mutate: mutateTilePacks,
  } = useSWR(
    () => (mapsSynced && !mapPackIsLoading ? ["tilePacks", tilePackId] : undefined),
    async () => {
      if (tilePackId === undefined) {
        return mapPack?.tilePacks;
      }

      const tilePack = await getTilePack(tilePackId);
      if (tilePack) {
        onTilePackLoad?.(tilePack);
      }
      return tilePack?.tilePacks;
    }
  );

  useEffect(() => {
    if (!mapsSynced || tilePacksAreLoading) return;
    const key = `AddMapsEmbed ${tilePackId}`;
    addMapUpdateListener(key, () => {
      mutateTilePacks();
    });
    return () => removeMapUpdateListener(key);
  }, [
    addMapUpdateListener,
    mapsSynced,
    mutateMapPack,
    mutateTilePacks,
    removeMapUpdateListener,
    tilePackId,
    tilePacksAreLoading,
  ]);

  const { tilePackNameTranslations } = useTilePackNames();

  const sortedTilePacks = useMemo(
    () =>
      tilePacks
        // Sort US (id 209) first, then the rest in alphabetical order.
        ?.sort((a, b) => {
          if (a.id === 209) return -1;
          if (b.id === 209) return 1;
          return tilePackNameTranslations[a.id].localeCompare(tilePackNameTranslations[b.id]);
        }),
    [tilePackNameTranslations, tilePacks]
  );

  return (
    <ErrorBoundary
      error={mapsSyncError}
      isLoading={!mapsSynced || tilePacksAreLoading}
      onReload={retrySync}
    >
      <Box display="flex" flexDirection="column" gap={2} width="100%" overflow="scroll">
        {tilePackId === undefined && (
          <Typography variant="prose-base" textAlign="center">
            <Trans>
              Your ELEMNT must be connected to a Wi-Fi network to update and download maps.
            </Trans>
          </Typography>
        )}
        <MapList
          editing={false}
          appToken={appToken}
          onAddTilePack={sendDownloadMapTilePack}
          onUpdateTilePack={sendUpgradeMapTilePack}
          tilePacks={sortedTilePacks || []}
          onFolderClick={onFolderClick}
          wifiNetworkCount={mapPack?.wifiNwCount || 0}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default AddMapsEmbed;
