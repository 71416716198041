import { t } from "@lingui/macro";

const getWorkoutTypeNames = (): Record<string, string> => ({
  Biking: t`Cycling`,
  Cycling: t`Cycling`,
  Running: t`Running`,
  FE: t`Fitness Equipment`,
  RunningTrack: t`Track Running`,
  RunningTrail: t`Trail Running`,
  RunningTreadmill: t`Treadmill Running`,
  Walking: t`Walking`,
  WalkingSpeed: t`Speed Walking`,
  WalkingNordic: t`Nordic Walking`,
  Hiking: t`Hiking`,
  Mountaineering: t`Mountaineering`,
  BikingCyclocross: t`Cyclocross`,
  BikingIndoor: t`Indoor Cycling`,
  BikingMountain: t`Mountain Biking`,
  BikingRecumbent: t`Recumbent Biking`,
  BikingRoad: t`Road Cycling`,
  BikingTrack: t`Track Cycling`,
  BikingMotocycling: t`Motorbiking`,
  FEGeneral: t`General Fitness Equipment`,
  FETreadmill: t`Treadmill`,
  FEElliptical: t`Elliptical`,
  FEBike: t`Stationary Biking`,
  FERower: t`Rower`,
  FEClimber: t`Stair Climber`,
  Swimming: t`Swimming`,
  SwimmingLap: t`Lap Swimming`,
  SwimmingOpenWater: t`Open Water Swimming`,
  Snowboarding: t`Snowboarding`,
  Skiing: t`Skiing`,
  SkiingDownhill: t`Downhill Skiing`,
  SkiingCrossCountry: t`Cross Country Skiing`,
  Skating: t`Skating`,
  SkatingIce: t`Ice Skating`,
  SkatingInline: t`Inline Skating`,
  LongBoarding: t`Long Boarding`,
  Sailing: t`Sailing`,
  WindSurfing: t`Wind Surfing`,
  Canoeing: t`Canoeing`,
  Kayaking: t`Kayaking`,
  Rowing: t`Rowing`,
  Kiteboarding: t`Kiteboarding`,
  StandUpPaddleBoard: t`Stand-up Paddle Board`,
  Strength: t`Strength`,
  CardioClass: t`Cardio Class`,
  StairClimber: t`Stair Climber`,
  Wheelchair: t`Wheelchair`,
  Golfing: t`Golfing`,
  Other: t`Other`,
  BikingIndoorCyclingClass: t`Indoor Cycling Class`,
  WalkingTreadmill: t`Treadmill Walking`,
  FEStepper: t`Stair Climber`,
  FEStepMill: t`Stair Climber`,
  FETreadClimber: t`Stair Climber`,
  FETotalBody: t`Fitness Equipment`,
  BikingIndoorTraining: t`Indoor Cycling Training`,
  Multisport: t`Multisport`,
  Transition: t`Transition`,
  EBiking: t`E-Biking`,
  TickrOffline: t`Tickr`,
  Yoga: t`Yoga`,
  RunningRace: t`Race Running`,
  BikingIndoorVirtual: t`Indoor Cycling Virtual`,
  MentalStrength: t`Mental Strength`,
  Unknown: t`General Workout`,
});

export { getWorkoutTypeNames };
