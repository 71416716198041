import {
  Box,
  Button,
  Chip,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { Trans, t } from "@lingui/macro";

const ZoneCountSelector = ({
  zoneCount,
  updatedAt,
  editable,
  zoneCountChoices,
  onClickEdit,
  onZoneCountChange,
  allowEditing,
  displayIndex,
}: {
  zoneCount: number;
  updatedAt?: Date;
  editable: boolean;
  zoneCountChoices?: number[];
  onClickEdit: () => void;
  onZoneCountChange: (value: number) => void;
  allowEditing: boolean;
  displayIndex: number;
}) => {
  return editable ? (
    zoneCountChoices ? (
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        pl={2}
        py={0.5}
        overflow="hidden"
        gap={1}
      >
        <Typography variant="ui-2xs" color="action.active">
          <Trans>Number of zones</Trans>
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          gap={0.5}
          overflow="auto"
          maxWidth="100%"
          pr={2}
          sx={{ "&::-webkit-scrollbar": { display: "none" } }}
        >
          {zoneCountChoices.map((option) => {
            return (
              <Chip
                label={option}
                key={option}
                clickable
                color={option === zoneCount ? "primary" : "default"}
                variant="filled"
                onClick={() => onZoneCountChange(option)}
              />
            );
          })}
        </Box>
      </Box>
    ) : (
      <></>
    )
  ) : (
    <>
      <ListItemText
        primary={
          <Typography variant="prose-base">
            <Trans>{zoneCount} Zones</Trans>
          </Typography>
        }
        secondary={updatedAt && t`Last changed: ${updatedAt.toLocaleDateString()}`}
      />
      {allowEditing && (
        <ListItemSecondaryAction onClick={onClickEdit}>
          <Button
            onClick={onClickEdit}
            variant="contained"
            size="large"
            data-testid={`editButton${displayIndex + 1}`}
          >
            <Trans>Edit</Trans>
          </Button>
        </ListItemSecondaryAction>
      )}
    </>
  );
};

export default ZoneCountSelector;
