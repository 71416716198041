import { Profile } from "@WahooFitness/cloud-client-ts/Types";

const profilePriority = {
  [Profile.std]: 0,
  [Profile.beta]: 1,
  [Profile.alpha]: 2,
  [Profile.dev]: 3,
  [Profile.factory]: 4,
};

export function hasMinProfile(
  requiredProfile: Profile,
  userProfile: Profile = Profile.std
): boolean {
  return (
    !!userProfile &&
    (profilePriority[userProfile] ?? -1) >= (profilePriority[requiredProfile] ?? Infinity)
  );
}
