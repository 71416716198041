import { ReactRouterLinkWrapper } from "@/components/MenuList/ReactRouterLinkWrapper";
import { formatByteCount } from "@/services/formatByteCount";
import { t, Trans } from "@lingui/macro";
import { Button, Checkbox, CircularProgress, Typography } from "@mui/material";
import { MenuList, MenuListItemVariant } from "@WahooFitness/redesignr";
import { WSMBoltTilePack } from "@WahooFitness/wsm-native/dist/esm/types/bolt_map";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import useMaps, { TilePackState } from "./useMaps";
import { ChevronRight, Download } from "@carbon/icons-react";
import useTilePackNames from "./useTilePackNames";
import useWifiCheck from "../useWifiCheck";
import { useDialogContext } from "@WahooFitness/wahoo-offline-mfe";

interface MapListProps {
  appToken?: string;
  editing: boolean;
  onMapSelect?: (checked: boolean, tilePackId: number) => void;
  onUpdateTilePack?: (tilePackId: number) => void;
  onAddTilePack?: (tilePackId: number) => void;
  tilePacks: WSMBoltTilePack[];
  pendingDeleteTilePackIds?: number[];
  title?: string;
  onFolderClick?: (tilePackId: number) => void;
  wifiNetworkCount: number;
}

const MapList = ({
  appToken = "",
  editing,
  onMapSelect = () => {},
  onUpdateTilePack,
  onAddTilePack,
  tilePacks,
  pendingDeleteTilePackIds = [],
  title,
  onFolderClick,
  wifiNetworkCount,
}: MapListProps) => {
  const [selectedMaps, setSelectedMaps] = useState<number[]>([]);
  const handleMapSelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>, tilePackId: number) => {
      if (event.target.checked) {
        setSelectedMaps((prev) => [...prev, tilePackId]);
      } else {
        setSelectedMaps((prev) => prev.filter((i) => i !== tilePackId));
      }
      onMapSelect(event.target.checked, tilePackId);
    },
    [onMapSelect]
  );

  // This keeps track of tile packs for which the add or update button has been clicked, but a new state has not been received yet.
  // This is used to reduce the delay between clicking the button and the UI updating.
  const [pendingUpdateTilePackIds, setPendingUpdateTilePackIds] = useState<number[]>([]);

  const { getConnectWifiDialogState, isBoltWifiAllowed } = useWifiCheck(appToken);
  const { setDialog, handleClose } = useDialogContext();

  const handleWifiCheck = useCallback(
    (updateAction: () => void) => {
      if (wifiNetworkCount <= 0 || !isBoltWifiAllowed) {
        setDialog(getConnectWifiDialogState(handleClose, updateAction));
      } else {
        updateAction();
      }
    },
    [wifiNetworkCount, isBoltWifiAllowed, setDialog, getConnectWifiDialogState, handleClose]
  );

  useEffect(() => {
    for (const tilePackId of pendingUpdateTilePackIds) {
      const tilePack = tilePacks.find((tilePack) => tilePack.id === tilePackId);
      if (
        tilePack &&
        [
          TilePackState.REQUESTED_DOWNLOAD,
          TilePackState.DOWNLOADING,
          TilePackState.QUEUED,
          TilePackState.SAVED,
        ].includes(tilePack.state) &&
        tilePack.upgradeSize === 0
      ) {
        setPendingUpdateTilePackIds((prev) => prev.filter((id) => id !== tilePackId));
      }
    }
  }, [pendingUpdateTilePackIds, tilePacks]);

  const { mapPack, showSpaceDialog } = useMaps(appToken);
  const handleAddTilePack = useCallback(
    (tilePack: WSMBoltTilePack) => {
      const requiredSpace = tilePack.totalSize;
      const availableSpace = mapPack?.availableSpace || 0;
      if (requiredSpace <= availableSpace) {
        handleWifiCheck(() => {
          setPendingUpdateTilePackIds((prev) => [...prev, tilePack.id]);
          onAddTilePack?.(tilePack.id);
        });
      } else {
        showSpaceDialog(requiredSpace, availableSpace);
      }
    },
    [handleWifiCheck, mapPack?.availableSpace, onAddTilePack, showSpaceDialog]
  );

  const handleUpdateTilePack = useCallback(
    (tilePack: WSMBoltTilePack) => {
      if (tilePack.upgradeSize === 0) {
        handleAddTilePack(tilePack);
        return;
      }
      const requiredSpace = tilePack.upgradeSize - tilePack.installedSize;
      const availableSpace = mapPack?.availableSpace || 0;
      if (requiredSpace <= availableSpace) {
        handleWifiCheck(() => {
          setPendingUpdateTilePackIds((prev) => [...prev, tilePack.id]);
          onUpdateTilePack?.(tilePack.id);
        });
      } else {
        showSpaceDialog(requiredSpace, availableSpace);
      }
    },
    [handleAddTilePack, handleWifiCheck, mapPack?.availableSpace, onUpdateTilePack, showSpaceDialog]
  );

  const { tilePackNameTranslations } = useTilePackNames();

  const menuListItems = useMemo(
    () =>
      tilePacks.map((tilePack) => {
        if (
          tilePack.state === TilePackState.REQUESTED_DELETE ||
          pendingDeleteTilePackIds.includes(tilePack.id)
        ) {
          return {
            id: tilePack.id.toString(),
            key: tilePack.id.toString(),
            content: tilePackNameTranslations[tilePack.id],
            secondaryContent: t`Deleting`,
            variant: MenuListItemVariant.NoAction,
          };
        }

        if (editing) {
          return {
            id: tilePack.id.toString(),
            key: tilePack.id.toString(),
            content: tilePackNameTranslations[tilePack.id],
            secondaryContent: tilePack.isFolder
              ? undefined
              : formatByteCount(tilePack.installedSize),
            variant: MenuListItemVariant.NoAction,
            actionComponent: (
              <Checkbox
                checked={selectedMaps.includes(tilePack.id)}
                onChange={(event) => handleMapSelect(event, tilePack.id)}
                color={selectedMaps.includes(tilePack.id) ? "error" : undefined}
              />
            ),
          };
        }

        if (tilePack.isFolder && onFolderClick) {
          return {
            id: tilePack.id.toString(),
            key: tilePack.id.toString(),
            content: tilePackNameTranslations[tilePack.id],
            variant: MenuListItemVariant.Action,
            actionComponent: <ChevronRight size={24} />,
            action: () => onFolderClick(tilePack.id),
          };
        }

        if (tilePack.isFolder) {
          return {
            id: tilePack.id.toString(),
            key: tilePack.id.toString(),
            content: tilePackNameTranslations[tilePack.id],
            variant: MenuListItemVariant.InternalLink,
            linkLocation: onAddTilePack
              ? `/device-config/${appToken}/maps/add/${tilePack.id}`
              : `/device-config/${appToken}/maps/region/${tilePack.id}`,
            linkComponent: ReactRouterLinkWrapper,
          };
        }

        if (tilePack.state === TilePackState.DOWNLOADING) {
          return {
            id: tilePack.id.toString(),
            key: tilePack.id.toString(),
            content: tilePackNameTranslations[tilePack.id],
            secondaryContent: t`Downloading...`,
            variant: MenuListItemVariant.NoAction,
            actionComponent: (
              <CircularProgress
                variant="determinate"
                value={tilePack.progress}
                size={32}
                color="info"
              />
            ),
          };
        }

        if (
          tilePack.state === TilePackState.QUEUED ||
          tilePack.state === TilePackState.REQUESTED_DOWNLOAD ||
          pendingUpdateTilePackIds.includes(tilePack.id)
        ) {
          return {
            id: tilePack.id.toString(),
            key: tilePack.id.toString(),
            content: tilePackNameTranslations[tilePack.id],
            secondaryContent: t`Queued`,
            variant: MenuListItemVariant.NoAction,
            actionComponent: (
              <CircularProgress variant="indeterminate" size={22} color="secondary" />
            ),
          };
        }

        if (tilePack.state === TilePackState.FAILED || tilePack.upgradeSize > 0) {
          return {
            id: tilePack.id.toString(),
            key: tilePack.id.toString(),
            variant: MenuListItemVariant.NoAction,
            content: tilePackNameTranslations[tilePack.id],
            secondaryContent: (
              <>
                {formatByteCount(tilePack.installedSize || tilePack.totalSize)} |{" "}
                <Typography
                  variant="inherit"
                  color={tilePack.state === TilePackState.FAILED ? "error" : undefined}
                >
                  {tilePack.state === TilePackState.FAILED ? (
                    <Trans>Download failed</Trans>
                  ) : (
                    <Trans>Update available</Trans>
                  )}
                </Typography>
              </>
            ),
            actionComponent: (
              <Button
                size="small"
                color="secondary"
                variant="contained"
                onClick={() => handleUpdateTilePack(tilePack)}
              >
                {tilePack.state === TilePackState.FAILED ? (
                  <Trans>Try again</Trans>
                ) : (
                  <Trans>Update</Trans>
                )}
              </Button>
            ),
          };
        }

        if (tilePack.installedSize > 0) {
          return {
            id: tilePack.id.toString(),
            key: tilePack.id.toString(),
            content: tilePackNameTranslations[tilePack.id],
            secondaryContent: formatByteCount(tilePack.installedSize || tilePack.totalSize),
            variant: MenuListItemVariant.NoAction,
          };
        }

        return {
          id: tilePack.id.toString(),
          key: tilePack.id.toString(),
          content: tilePackNameTranslations[tilePack.id],
          secondaryContent: formatByteCount(tilePack.installedSize || tilePack.totalSize),
          variant: MenuListItemVariant.Action,
          actionComponent: (
            <Typography color="action">
              <Download size={24} />
            </Typography>
          ),
          action: () => handleAddTilePack(tilePack),
        };
      }),
    [
      appToken,
      editing,
      handleAddTilePack,
      handleMapSelect,
      handleUpdateTilePack,
      onAddTilePack,
      onFolderClick,
      pendingDeleteTilePackIds,
      pendingUpdateTilePackIds,
      selectedMaps,
      tilePackNameTranslations,
      tilePacks,
    ]
  );
  return <MenuList listItems={menuListItems} disableGutters title={title} />;
};

export default MapList;
