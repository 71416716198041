import { MenuList, MenuListItemType } from "@WahooFitness/redesignr";
import { Trans, t } from "@lingui/macro";
import { Box, Paper, TextField, Typography } from "@mui/material";

interface Props {
  primaryUseListItems: MenuListItemType[];
  otherSelected: boolean;
  otherTextRef: React.RefObject<HTMLTextAreaElement>;
}

function PrimaryUseQuestion({ primaryUseListItems, otherSelected, otherTextRef }: Props) {
  return (
    <Box mx={1} display="flex" flexDirection="column">
      <Box mx={4} display="flex" flexDirection="column">
        <Box>
          <Typography variant="prose-base-medium">
            <Trans>What is the primary way you will use the KICKR RUN?</Trans>
          </Typography>
        </Box>
        <Box>
          <Typography variant="prose-sm" color="text.secondary">
            <Trans>Select all that apply.</Trans>
          </Typography>
        </Box>
      </Box>
      <MenuList listItems={primaryUseListItems} />
      {otherSelected && (
        <Paper elevation={4} sx={{ marginX: 2, marginY: 1, padding: 2 }}>
          <TextField
            inputRef={otherTextRef}
            aria-label="other primary use input"
            label={t`Primary KICKR RUN usage`}
            multiline
            variant="filled"
            minRows={3}
            fullWidth
          />
        </Paper>
      )}
    </Box>
  );
}

export default PrimaryUseQuestion;
