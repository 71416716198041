import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { t } from "@lingui/macro";
import { Checkmark } from "@carbon/icons-react";
import { useTheme } from "@mui/material";
import { MenuListItemType, MenuListItemVariant } from "@WahooFitness/redesignr";
import { ProductQuestionnaire } from "@WahooFitness/cloud-client-ts";
import { ProductTypeEnum } from "@WahooFitness/cloud-client-ts/Types";
import { useCloudContext, useConfigContext } from "@WahooFitness/wahoo-offline-mfe";
import useTrainingPreferences from "@/components/TrainingPreferences/useTrainingPreferences";

function useOnboardingTrainingPreferences() {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { wahooToken } = useConfigContext();
  const { getCloudClient } = useCloudContext();
  const { selectedSportOptions, sportOptionListItems } = useTrainingPreferences();
  const [selectedPrimaryUses, setSelectedPrimaryUses] = useState<Set<number>>(new Set());
  const otherTextRef = useRef<HTMLTextAreaElement>(null);

  const nextDisabled = useMemo(() => selectedSportOptions.size === 0, [selectedSportOptions]);

  const primaryUseOptions = useMemo(
    () => [
      t`Fun, I simply enjoy running`,
      t`Maintain or improve health and wellness`,
      t`Maintain or improve fitness`,
      t`Training to complete an event`,
      t`Training to improve performance at an event`,
      t`Other`,
    ],
    []
  );

  const selectPrimaryUse = useCallback(
    (primaryUse: number) => {
      const newSelectedPrimaryUses = new Set(selectedPrimaryUses);
      if (newSelectedPrimaryUses.has(primaryUse)) {
        newSelectedPrimaryUses.delete(primaryUse);
      } else {
        newSelectedPrimaryUses.add(primaryUse);
      }
      setSelectedPrimaryUses(newSelectedPrimaryUses);
    },
    [selectedPrimaryUses]
  );

  const primaryUseListItems: MenuListItemType[] = useMemo(
    () =>
      primaryUseOptions.map((value: string, idx: number) => ({
        id: String(idx),
        variant: MenuListItemVariant.Action,
        content: value,
        action: () => selectPrimaryUse(idx),
        actionComponent: selectedPrimaryUses.has(idx) ? (
          <Checkmark size={24} color={palette.info.main} />
        ) : null,
      })),
    [palette.info.main, primaryUseOptions, selectPrimaryUse, selectedPrimaryUses]
  );

  const otherSelected = useMemo(
    () => selectedPrimaryUses.has(primaryUseOptions.length - 1),
    [primaryUseOptions.length, selectedPrimaryUses]
  );

  useEffect(() => {
    if (otherSelected) {
      otherTextRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [otherSelected]);

  const productQuestionnaireClient = useMemo(
    () => getCloudClient(ProductQuestionnaire),
    [getCloudClient]
  );

  const saveAndNavigate = useCallback(async () => {
    await productQuestionnaireClient.productOnboardingQuestionnaire(
      wahooToken,
      ProductTypeEnum.TREADMILL,
      Array.from(selectedSportOptions),
      Array.from(selectedPrimaryUses),
      otherTextRef.current?.value
    );
    navigate("/onboarding/set-up-paces");
  }, [navigate, productQuestionnaireClient, selectedPrimaryUses, selectedSportOptions, wahooToken]);

  return {
    sportOptionListItems,
    primaryUseListItems,
    otherSelected,
    nextDisabled,
    saveAndNavigate,
    otherTextRef,
  };
}

export default useOnboardingTrainingPreferences;
