import { useBCFirstRunState } from "@/hooks/useBCFirstRunState";
import usePairedDevice from "@/hooks/usePairedDevice";
import { useWsmPlugin } from "@/hooks/useWsmPlugin";
import { WSMBoltAppFirstRunState } from "@WahooFitness/wsm-native/dist/esm/types/bolt_cfg";
import {
  WSMBoltFirmwareStatus,
  WSMBoltFwuUpgradeState,
} from "@WahooFitness/wsm-native/dist/esm/types/bolt_firmware";
import { useCallback, useEffect, useMemo, useState } from "react";

const useFirmwareOnboarding = (appToken: string) => {
  const { sensorManagerPlugin } = useWsmPlugin();
  const [firmwareStatus, setFirmwareStatus] = useState<WSMBoltFirmwareStatus | undefined>();

  // The user is sitting on this page while the BC is disconnected and rebooting, so we need to keep
  // the device info around.
  const [savedDevice, setSavedDevice] = useState<
    { productTypeName: string; boltType: number | undefined } | undefined
  >();
  const { device, deviceLoading, reloadDevice, isDeviceConnected } = usePairedDevice(appToken);
  const displayState = useMemo<"updating" | "done" | "failed">(() => {
    switch (firmwareStatus?.upgradeState) {
      case WSMBoltFwuUpgradeState.UpToDate:
        return "done";
      case WSMBoltFwuUpgradeState.PackageBad:
        return "failed";
      default:
        return "updating";
    }
  }, [firmwareStatus?.upgradeState]);

  useEffect(() => {
    if (!deviceLoading) {
      const interval = setInterval(() => {
        reloadDevice();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [deviceLoading, reloadDevice]);

  const getStatus = useCallback(async () => {
    if (!appToken) {
      return;
    }
    const status = await sensorManagerPlugin?.bcGetFirmwareStatus({ appToken });
    setFirmwareStatus(status);
    if (
      status?.upgradeState === WSMBoltFwuUpgradeState.ReadyToInstall ||
      status?.upgradeState === WSMBoltFwuUpgradeState.Available
    ) {
      sensorManagerPlugin?.bcStartFirmwareUpdate({ appToken });
    }
  }, [sensorManagerPlugin, appToken]);

  useEffect(() => {
    // Do this whenever the device connects
    if (!isDeviceConnected) {
      return;
    }
    if (!savedDevice && device) {
      setSavedDevice({ productTypeName: device.productTypeName, boltType: device.boltType });
    }
    sensorManagerPlugin?.bcStartFirmwareUpdate({ appToken });
    getStatus();
  }, [appToken, device, getStatus, isDeviceConnected, savedDevice, sensorManagerPlugin]);

  useEffect(() => {
    const listener = sensorManagerPlugin?.addListener(
      "boltFwuStatusChangeEvent",
      ({ appToken: eventAppToken }) => {
        eventAppToken === appToken && getStatus();
      }
    );
    return () => {
      (async () => (await listener)?.remove())();
    };
  }, [appToken, getStatus, sensorManagerPlugin]);

  const { setStateTransition } = useBCFirstRunState(appToken);
  useEffect(() => {
    setStateTransition({
      desiredState: WSMBoltAppFirstRunState.AppSetupFwu,
      allowedStates: [WSMBoltAppFirstRunState.Complete, WSMBoltAppFirstRunState.FwuInProgress],
    });
  }, [setStateTransition]);

  // const marketingMessage = useMemo(() => {
  //   // TODO: This message should be random, and related to the paired device.
  //   return (
  //     <Trans>
  //       [Placeholder for marketing message]: Did you know ELEMNT ACE has a built in aero sensor that
  //       gives you real time aerodynamics data?
  //     </Trans>
  //   );
  // }, []);
  const marketingMessage = useMemo(() => "", []);

  return {
    displayState,
    marketingMessage,
    progressPercent: firmwareStatus?.progressPercent ?? -1,
    debugFirmwareStatus: firmwareStatus,
    device: savedDevice,
  };
};

export { useFirmwareOnboarding };
