const ButterPlanGraph = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 3477 38"
    preserveAspectRatio="none"
    height="38"
    width="372"
    shapeRendering="crispEdges"
  >
    <rect x="0" y="31.666666666666668" width="83" height="6.333333333333333" fill="#5fdfff"></rect>
    <rect x="83" y="30.4" width="60" height="7.6000000000000005" fill="#5fdfff"></rect>
    <rect
      x="143"
      y="29.133333333333333"
      width="30"
      height="8.866666666666667"
      fill="#5fdfff"
    ></rect>
    <rect
      x="173"
      y="27.866666666666667"
      width="60"
      height="10.133333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="233"
      y="25.96666666666667"
      width="45"
      height="12.033333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="278"
      y="31.666666666666668"
      width="60"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="338"
      y="24.700000000000003"
      width="60"
      height="13.299999999999999"
      fill="#5fdfff"
    ></rect>
    <rect
      x="398"
      y="31.666666666666668"
      width="54"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect x="452" y="19.7125" width="22" height="18.2875" fill="#ffc500"></rect>
    <rect
      x="474"
      y="31.666666666666668"
      width="120"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="594"
      y="22.325000000000003"
      width="76"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect
      x="670"
      y="21.976666666666667"
      width="14"
      height="16.023333333333333"
      fill="#ffc500"
    ></rect>
    <rect
      x="684"
      y="29.133333333333333"
      width="180"
      height="8.866666666666667"
      fill="#5fdfff"
    ></rect>
    <rect
      x="864"
      y="22.325000000000003"
      width="34"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect
      x="898"
      y="21.976666666666667"
      width="28"
      height="16.023333333333333"
      fill="#ffc500"
    ></rect>
    <rect
      x="926"
      y="22.325000000000003"
      width="28"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect
      x="954"
      y="29.133333333333333"
      width="180"
      height="8.866666666666667"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1134"
      y="22.325000000000003"
      width="90"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect
      x="1224"
      y="29.133333333333333"
      width="180"
      height="8.866666666666667"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1404"
      y="22.325000000000003"
      width="32"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect
      x="1436"
      y="22.325000000000003"
      width="58"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect
      x="1494"
      y="29.133333333333333"
      width="180"
      height="8.866666666666667"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1674"
      y="22.325000000000003"
      width="24"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect
      x="1698"
      y="21.976666666666667"
      width="16"
      height="16.023333333333333"
      fill="#ffc500"
    ></rect>
    <rect
      x="1714"
      y="20.583333333333336"
      width="50"
      height="17.416666666666664"
      fill="#ffc500"
    ></rect>
    <rect x="1764" y="32.3" width="180" height="5.7" fill="#5fdfff"></rect>
    <rect
      x="1944"
      y="31.03333333333333"
      width="180"
      height="6.966666666666667"
      fill="#5fdfff"
    ></rect>
    <rect x="2124" y="21.8025" width="90" height="16.1975" fill="#ffc500"></rect>
    <rect
      x="2214"
      y="29.133333333333333"
      width="180"
      height="8.866666666666667"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2394"
      y="22.325000000000003"
      width="51"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect
      x="2445"
      y="21.454166666666666"
      width="39"
      height="16.545833333333334"
      fill="#ffc500"
    ></rect>
    <rect
      x="2484"
      y="29.133333333333333"
      width="180"
      height="8.866666666666667"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2664"
      y="22.673333333333332"
      width="25"
      height="15.326666666666666"
      fill="#ffc500"
    ></rect>
    <rect
      x="2689"
      y="20.583333333333336"
      width="36"
      height="17.416666666666664"
      fill="#ffc500"
    ></rect>
    <rect
      x="2725"
      y="22.673333333333332"
      width="29"
      height="15.326666666666666"
      fill="#ffc500"
    ></rect>
    <rect
      x="2754"
      y="29.133333333333333"
      width="180"
      height="8.866666666666667"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2934"
      y="22.325000000000003"
      width="49"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect x="2983" y="21.8025" width="42" height="16.1975" fill="#ffc500"></rect>
    <rect
      x="3025"
      y="29.133333333333333"
      width="180"
      height="8.866666666666667"
      fill="#5fdfff"
    ></rect>
    <rect
      x="3205"
      y="22.673333333333332"
      width="30"
      height="15.326666666666666"
      fill="#ffc500"
    ></rect>
    <rect
      x="3235"
      y="21.105833333333333"
      width="44"
      height="16.894166666666667"
      fill="#ffc500"
    ></rect>
    <rect
      x="3279"
      y="12.666666666666668"
      width="16"
      height="25.333333333333332"
      fill="#fb8b04"
    ></rect>
  </svg>
);
export default ButterPlanGraph;
