import React, { useCallback, useState } from "react";
import { useDialogContext } from "@WahooFitness/wahoo-offline-mfe";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

const GlobalDialog: React.FC = () => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const {
    dialog: { open, dialogComponent, title = "", body = "", actions, dialogProps = {} },
    handleClose,
  } = useDialogContext();

  const handleAction = useCallback(async (action?: () => void) => {
    if (action) {
      setIsDisabled(true);
      await action();
      setIsDisabled(false);
    }
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth {...dialogProps}>
      {dialogComponent ? (
        dialogComponent
      ) : (
        <React.Fragment>
          <DialogTitle>
            <Typography variant="prose-md-medium">{title}</Typography>
          </DialogTitle>
          <DialogContent>
            {typeof body === "string" ? (
              <DialogContentText>
                <Typography variant="prose-base">{body}</Typography>
              </DialogContentText>
            ) : (
              body
            )}
          </DialogContent>
          <DialogActions>
            {actions?.map((action) => (
              <Button
                key={action.text}
                variant="text"
                size="large"
                color={action.color || "primary"}
                onClick={action.action ? () => handleAction(action.action) : handleClose}
                disabled={isDisabled}
              >
                {action.text}
              </Button>
            ))}
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
};

export default GlobalDialog;
