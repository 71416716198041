import ElemntImage from "@/assets/Elemnt.png";
import ElemntAceImage from "@/assets/ElemntAce.png";
import ElemntBoltImage from "@/assets/ElemntBolt.png";
import ElemntBolt2Image from "@/assets/ElemntBolt2.png";
import ElemntRoamImage from "@/assets/ElemntRoam.png";
import ElemntRoam2Image from "@/assets/ElemntRoam2.png";
import useBoltTypeStore from "@/hooks/useBoltTypeStore";
import { crux_bolt_type_e } from "@WahooFitness/crux-js-display-cfg";
import React, { useMemo } from "react";

interface BoltProductImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  boltType: number | undefined;
  appToken: string;
  fallback?: React.ReactNode;
}

const BoltProductImage: React.FC<BoltProductImageProps> = ({
  boltType: deviceBoltType,
  appToken,
  fallback,
  ...rest
}) => {
  const { getStoredBoltType } = useBoltTypeStore();

  const boltType = useMemo(
    () => deviceBoltType ?? getStoredBoltType(appToken),
    [appToken, deviceBoltType, getStoredBoltType]
  );

  const productImage = useMemo(() => {
    switch (boltType) {
      case crux_bolt_type_e.BOLT:
        return ElemntBoltImage;
      case crux_bolt_type_e.BOLT_2:
        return ElemntBolt2Image;
      case crux_bolt_type_e.ROAM:
      case crux_bolt_type_e.ROAM_1_1:
        return ElemntRoamImage;
      case crux_bolt_type_e.ROAM_2:
        return ElemntRoam2Image;
      case crux_bolt_type_e.ACE:
        return ElemntAceImage;
      case crux_bolt_type_e.ELEMNT:
        return ElemntImage;
    }
  }, [boltType]);
  if (!productImage) {
    return fallback;
  }
  return <img {...rest} src={productImage} />;
};

export default BoltProductImage;
