import { t, Trans } from "@lingui/macro";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import MapStandardLight from "@/assets/map-options/map-standard-light.svg";
import MapSatelliteLight from "@/assets/map-options/map-satellite-light.svg";
import MapTerrainLight from "@/assets/map-options/map-terrain-light.svg";
import MapPOIsLight from "@/assets/map-options/map-pois-light.svg";
import MapDistanceMarkersLight from "@/assets/map-options/map-distance-markers-light.svg";
import MapContourLight from "@/assets/map-options/map-contour-light.svg";
import MapContourDark from "@/assets/map-options/map-contour-dark.svg";
import MapStandardDark from "@/assets/map-options/map-standard-dark.svg";
import MapSatelliteDark from "@/assets/map-options/map-satellite-dark.svg";
import MapTerrainDark from "@/assets/map-options/map-terrain-dark.svg";
import MapPOIsDark from "@/assets/map-options/map-pois-dark.svg";
// import MapWaypointsLight from "@/assets/map-options/map-custom-waypoints-light.svg"; // TODO: uncomment this when we support custom waypoints
// import MapWaypointsDark from "@/assets/map-options/map-custom-waypoints-dark.svg"; // TODO: uncomment this when we support custom waypoints
import MapDistanceMarkersDark from "@/assets/map-options/map-distance-markers-dark.svg";
import MapLayerEnum from "./MapLayerEnum";
import MapOptionToggleButton from "./MapOptionToggleButton";
import { useCallback, useMemo, useState } from "react";
import MapTypeEnum from "./MapTypeEnum";
import SlidingDrawer from "@/components/StyledComponents/SlidingDrawer";

function MapOptions({
  open,
  onClose,
  onLayerChanged,
  onMapTypeChanged,
  defaultSelectedLayers,
}: {
  open: boolean;
  onClose: () => void;
  onLayerChanged: (selectedLayers: MapLayerEnum[]) => void;
  onMapTypeChanged: (selectedMapType: MapTypeEnum) => void;
  defaultSelectedLayers: MapLayerEnum[];
}) {
  const [selectedLayers, setSelectedLayers] = useState<string[]>(defaultSelectedLayers);
  const [mapType, setMapType] = useState<string>("standard");
  const theme = useTheme();
  const isDarkMode = useMemo(() => theme.palette.mode === "dark", [theme.palette.mode]);
  const handleMapTypeChange = (value: string) => {
    if (value !== null) {
      setMapType(value);
      onMapTypeChanged(MapTypeEnum[value.toUpperCase() as keyof typeof MapTypeEnum]);
    }
  };

  const handleToggleLayer = useCallback(
    (value: string) => {
      // if the layer is already selected, remove it
      let newLayers;
      if (selectedLayers.includes(value)) {
        newLayers = selectedLayers.filter((layer) => layer !== value);
      } else {
        newLayers = [...selectedLayers, value];
      }
      setSelectedLayers(newLayers);
      onLayerChanged(
        newLayers.map((value) => MapLayerEnum[value.toUpperCase() as keyof typeof MapLayerEnum])
      );
    },
    [selectedLayers, onLayerChanged]
  );
  return (
    <SlidingDrawer
      zIndex={2000}
      open={open}
      title={t`Map Options`}
      handleOpen={() => {}}
      handleClose={onClose}
      maxWidth={true}
      hidePuller
      hideDivider
      isPersistent
    >
      <Box display="flex" flexDirection="column" alignItems="center" padding={1} gap={1}>
        <Typography variant="ui-md-medium" sx={{ alignSelf: "flex-start" }}>
          <Trans>Layers</Trans>
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-around"
          sx={{ width: "100%" }}
          padding={1}
        >
          <MapOptionToggleButton
            imgSrc={isDarkMode ? MapPOIsDark : MapPOIsLight}
            label={t`POIs`}
            onClick={() => handleToggleLayer("pois")}
            isSelected={selectedLayers.includes("pois")}
          />
          {/* TODO: Add this block back when we support custom waypoints
            <MapOptionToggleButton
              imgSrc={isDarkMode ? MapWaypointsDark : MapWaypointsLight}
              label={t`Custom Waypoints`}
              value="custom_waypoints"
              isSelected={selectedLayers.includes("custom_waypoints")}
            /> */}
          <MapOptionToggleButton
            imgSrc={isDarkMode ? MapDistanceMarkersDark : MapDistanceMarkersLight}
            label={t`Distance Markers`}
            onClick={() => handleToggleLayer("distance_markers")}
            isSelected={selectedLayers.includes("distance_markers")}
          />
          <MapOptionToggleButton
            imgSrc={isDarkMode ? MapContourDark : MapContourLight}
            label={t`Contour Lines`}
            onClick={() => handleToggleLayer("contour")}
            isSelected={selectedLayers.includes("contour")}
          />
        </Box>
        <Typography variant="ui-md-medium" sx={{ alignSelf: "flex-start" }}>
          <Trans>Map type</Trans>
        </Typography>
        <Paper elevation={2} sx={{ marginBottom: 2, width: "100%" }}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-around"
            sx={{ width: "100%" }}
            padding={1}
          >
            <MapOptionToggleButton
              imgSrc={isDarkMode ? MapStandardDark : MapStandardLight}
              label="Standard"
              onClick={() => handleMapTypeChange("standard")}
              isSelected={mapType === "standard"}
            />
            <MapOptionToggleButton
              imgSrc={isDarkMode ? MapSatelliteDark : MapSatelliteLight}
              label="Satellite"
              onClick={() => handleMapTypeChange("satellite")}
              isSelected={mapType === "satellite"}
            />
            <MapOptionToggleButton
              imgSrc={isDarkMode ? MapTerrainDark : MapTerrainLight}
              label="Terrain"
              onClick={() => handleMapTypeChange("terrain")}
              isSelected={mapType === "terrain"}
            />
          </Box>
        </Paper>
      </Box>
    </SlidingDrawer>
  );
}

export default MapOptions;
