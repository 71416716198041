import { Box, Typography, alpha, useTheme } from "@mui/material";
import { CarbonIconType } from "@carbon/icons-react/lib/CarbonIcon";

interface Props {
  Icon: CarbonIconType;
  title: string;
  subtitle: string;
}

function OnboardingHeader({ Icon, title, subtitle }: Props) {
  const { palette } = useTheme();
  return (
    <Box m={3} display="flex" flexDirection="column" alignItems="center" gap={1}>
      <Box
        height={72}
        width={72}
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="50%"
        bgcolor={alpha(palette.info.main, 0.2)}
      >
        <Icon size={56} color={palette.info.main} />
      </Box>
      <Typography variant="prose-lg-bold" color="text.primary">
        {title}
      </Typography>
      <Typography variant="prose-sm" color="text.secondary" textAlign="center" maxWidth="40ch">
        {subtitle}
      </Typography>
    </Box>
  );
}

export default OnboardingHeader;
