import { RoutesProvider } from "@/contexts/RoutesContext";
import { Outlet } from "react-router";
import { RouteElevationProvider } from "@/contexts/RouteElevationContext";

function RoutesWrapper() {
  return (
    <RoutesProvider>
      <RouteElevationProvider>
        <Outlet />
      </RouteElevationProvider>
    </RoutesProvider>
  );
}

export default RoutesWrapper;
