import {
  useCloudContext,
  useConfigContext,
  useDialogContext,
} from "@WahooFitness/wahoo-offline-mfe";
import useConvertUnits from "@/hooks/useConvertUnits";
import { PaceZoneAlgorithmLookups } from "@WahooFitness/cloud-client-ts";
import {
  PaceZoneAlgorithmLookupsResponseType,
  PaceZoneKnownDistanceAlgorithmType,
} from "@WahooFitness/cloud-client-ts/Types";
import { Trans, t } from "@lingui/macro";
import { Box, SelectChangeEvent } from "@mui/material";
import { useCallback, useMemo, useReducer } from "react";

export type DistanceOption = { key: "t5k" | "t10k" | "thm" | "tfm"; name: string };
type BestKnownTimesValuesType = { [K in DistanceOption["key"]]?: string };
type BestKnownTimesActions =
  | { type: "CHANGE_DISTANCE"; index: number; value: string }
  | { type: "CHANGE_TIME"; name: string; value: string }
  | { type: "REMOVE"; index: number }
  | { type: "ADD"; value: string };

function useBestKnownTimesInput(
  setPaceResultsAvailable: (value: boolean) => void,
  setYourPaceZones: (paceZones: PaceZoneAlgorithmLookupsResponseType) => void
) {
  const { convertToSeconds } = useConvertUnits();

  const [bestKnownTimeValues, dispatch] = useReducer(bestKnownTimesReducer, { t5k: "" });
  const distanceOptions: DistanceOption[] = useMemo(
    () => [
      { key: "t5k", name: t`5K` },
      { key: "t10k", name: t`10K` },
      { key: "thm", name: t`Half Marathon` },
      { key: "tfm", name: t`Full Marathon` },
    ],
    []
  );
  const displayedDistanceOptions = useMemo(() => {
    return Object.keys(bestKnownTimeValues);
  }, [bestKnownTimeValues]);

  const nextDisabled = !Object.values(bestKnownTimeValues).some(
    (value) => value !== undefined && value !== ""
  );

  function bestKnownTimesReducer(
    state: BestKnownTimesValuesType,
    action: BestKnownTimesActions
  ): BestKnownTimesValuesType {
    const entries = Object.entries(state);
    switch (action.type) {
      case "CHANGE_DISTANCE":
        entries[action.index] = [action.value, ""];
        return Object.fromEntries(entries);
      case "CHANGE_TIME":
        return { ...state, [action.name]: action.value };
      case "REMOVE":
        entries.splice(action.index, 1);
        return Object.fromEntries(entries);
      case "ADD":
        return { ...state, [action.value]: "" };
      default:
        return state;
    }
  }

  const handleDistanceChange = useCallback((event: SelectChangeEvent, index: number) => {
    dispatch({ type: "CHANGE_DISTANCE", index, value: event.target.value as string });
  }, []);
  const handleTimeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "CHANGE_TIME", name: event.target.name, value: event.target.value });
  }, []);
  const handleRemove = useCallback((index: number) => {
    dispatch({ type: "REMOVE", index });
  }, []);
  const handleAddAnother = useCallback(() => {
    const newDistanceOption = distanceOptions.find(
      (option) => !displayedDistanceOptions.includes(option.key)
    );
    if (newDistanceOption) {
      dispatch({ type: "ADD", value: newDistanceOption.key });
    }
  }, [distanceOptions, displayedDistanceOptions]);

  const { wahooToken } = useConfigContext();
  const { getCloudClient } = useCloudContext();
  const paceZoneAlgorithmLookupsClient = useMemo(
    () => getCloudClient(PaceZoneAlgorithmLookups),
    [getCloudClient]
  );

  const { handleClose, setDialog } = useDialogContext();
  const openErrorDialog = useCallback(async () => {
    setDialog({
      open: true,
      title: t`Something went wrong`,
      body: (
        <Box display="flex" flexDirection="column" gap={1}>
          <Box>
            <Trans>
              Your times are not similar. Verify your entries or only use your most recent time.
            </Trans>
          </Box>
        </Box>
      ),
      actions: [{ text: t`Back`, action: handleClose }],
    });
  }, [handleClose, setDialog]);

  const lookupPaceZones = useCallback(
    async (paceZoneKnownDistanceAlgorithm: PaceZoneKnownDistanceAlgorithmType) => {
      if (wahooToken) {
        try {
          const paceZoneAlgorithmLookupsResult =
            await paceZoneAlgorithmLookupsClient.postKnownDistance(
              wahooToken,
              paceZoneKnownDistanceAlgorithm
            );
          setYourPaceZones(paceZoneAlgorithmLookupsResult);
          setPaceResultsAvailable(true);
        } catch (error) {
          openErrorDialog();
        }
      }
    },
    [
      openErrorDialog,
      paceZoneAlgorithmLookupsClient,
      setPaceResultsAvailable,
      setYourPaceZones,
      wahooToken,
    ]
  );

  const handlePaceLookup = useCallback(() => {
    const convertedValues: { [K in DistanceOption["key"]]?: number } = {};
    for (const key in bestKnownTimeValues) {
      const time = bestKnownTimeValues[key as DistanceOption["key"]];
      if (time !== undefined) {
        const timeInSeconds = convertToSeconds(time);
        convertedValues[key as DistanceOption["key"]] = timeInSeconds;
      }
    }
    lookupPaceZones(convertedValues);
  }, [bestKnownTimeValues, convertToSeconds, lookupPaceZones]);

  const handleApply = useCallback(() => {
    handlePaceLookup();
  }, [handlePaceLookup]);

  return {
    distanceOptions,
    displayedDistanceOptions,
    bestKnownTimeValues,
    handleDistanceChange,
    handleTimeChange,
    handleRemove,
    handleAddAnother,
    handlePaceLookup,
    handleApply,
    nextDisabled,
  };
}

export default useBestKnownTimesInput;
