const EqualizerPlanGraph = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2418 38"
    preserveAspectRatio="none"
    height="38"
    width="372"
    shapeRendering="crispEdges"
  >
    <rect x="0" y="31.666666666666668" width="261" height="6.333333333333333" fill="#5fdfff"></rect>
    <rect
      x="261"
      y="29.133333333333333"
      width="120"
      height="8.866666666666667"
      fill="#5fdfff"
    ></rect>
    <rect x="381" y="30.4" width="60" height="7.6000000000000005" fill="#5fdfff"></rect>
    <rect x="441" y="26.6" width="90" height="11.4" fill="#5fdfff"></rect>
    <rect
      x="531"
      y="31.666666666666668"
      width="60"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="591"
      y="25.333333333333336"
      width="60"
      height="12.666666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="651"
      y="31.666666666666668"
      width="45"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="696"
      y="22.499166666666667"
      width="45"
      height="15.500833333333333"
      fill="#ffc500"
    ></rect>
    <rect
      x="741"
      y="31.666666666666668"
      width="90"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect x="831" y="20.93166666666667" width="10" height="17.06833333333333" fill="#ffc500"></rect>
    <rect
      x="841"
      y="31.666666666666668"
      width="10"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect x="851" y="20.93166666666667" width="10" height="17.06833333333333" fill="#ffc500"></rect>
    <rect
      x="861"
      y="31.666666666666668"
      width="10"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect x="871" y="20.93166666666667" width="11" height="17.06833333333333" fill="#ffc500"></rect>
    <rect
      x="882"
      y="31.666666666666668"
      width="10"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect x="892" y="20.93166666666667" width="10" height="17.06833333333333" fill="#ffc500"></rect>
    <rect x="902" y="31.666666666666668" width="9" height="6.333333333333333" fill="#5fdfff"></rect>
    <rect x="911" y="20.93166666666667" width="10" height="17.06833333333333" fill="#ffc500"></rect>
    <rect
      x="921"
      y="31.666666666666668"
      width="10"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect x="931" y="20.93166666666667" width="10" height="17.06833333333333" fill="#ffc500"></rect>
    <rect
      x="941"
      y="31.666666666666668"
      width="100"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1041"
      y="22.499166666666667"
      width="22"
      height="15.500833333333333"
      fill="#ffc500"
    ></rect>
    <rect x="1063" y="26.6" width="19" height="11.4" fill="#5fdfff"></rect>
    <rect
      x="1082"
      y="22.499166666666667"
      width="19"
      height="15.500833333333333"
      fill="#ffc500"
    ></rect>
    <rect x="1101" y="26.6" width="20" height="11.4" fill="#5fdfff"></rect>
    <rect
      x="1121"
      y="22.499166666666667"
      width="22"
      height="15.500833333333333"
      fill="#ffc500"
    ></rect>
    <rect x="1143" y="26.6" width="19" height="11.4" fill="#5fdfff"></rect>
    <rect
      x="1162"
      y="22.499166666666667"
      width="19"
      height="15.500833333333333"
      fill="#ffc500"
    ></rect>
    <rect x="1181" y="26.6" width="21" height="11.4" fill="#5fdfff"></rect>
    <rect
      x="1202"
      y="22.499166666666667"
      width="20"
      height="15.500833333333333"
      fill="#ffc500"
    ></rect>
    <rect x="1222" y="26.6" width="21" height="11.4" fill="#5fdfff"></rect>
    <rect
      x="1243"
      y="22.499166666666667"
      width="20"
      height="15.500833333333333"
      fill="#ffc500"
    ></rect>
    <rect
      x="1263"
      y="32.93333333333334"
      width="110"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1373"
      y="22.499166666666667"
      width="30"
      height="15.500833333333333"
      fill="#ffc500"
    ></rect>
    <rect x="1403" y="26.6" width="30" height="11.4" fill="#5fdfff"></rect>
    <rect
      x="1433"
      y="22.499166666666667"
      width="30"
      height="15.500833333333333"
      fill="#ffc500"
    ></rect>
    <rect
      x="1463"
      y="27.866666666666667"
      width="30"
      height="10.133333333333333"
      fill="#5fdfff"
    ></rect>
    <rect x="1493" y="20.235" width="29" height="17.765" fill="#ffc500"></rect>
    <rect
      x="1522"
      y="29.133333333333333"
      width="29"
      height="8.866666666666667"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1551"
      y="19.015833333333333"
      width="30"
      height="18.984166666666667"
      fill="#ffc500"
    ></rect>
    <rect
      x="1581"
      y="31.666666666666668"
      width="32"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect x="1613" y="20.235" width="30" height="17.765" fill="#ffc500"></rect>
    <rect
      x="1643"
      y="29.133333333333333"
      width="30"
      height="8.866666666666667"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1673"
      y="20.583333333333336"
      width="29"
      height="17.416666666666664"
      fill="#ffc500"
    ></rect>
    <rect
      x="1702"
      y="27.866666666666667"
      width="31"
      height="10.133333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1733"
      y="22.499166666666667"
      width="30"
      height="15.500833333333333"
      fill="#ffc500"
    ></rect>
    <rect x="1763" y="26.6" width="29" height="11.4" fill="#5fdfff"></rect>
    <rect
      x="1792"
      y="32.93333333333334"
      width="149"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1941"
      y="20.583333333333336"
      width="20"
      height="17.416666666666664"
      fill="#ffc500"
    ></rect>
    <rect
      x="1961"
      y="31.666666666666668"
      width="21"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1982"
      y="20.583333333333336"
      width="19"
      height="17.416666666666664"
      fill="#ffc500"
    ></rect>
    <rect
      x="2001"
      y="31.666666666666668"
      width="21"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2022"
      y="20.583333333333336"
      width="20"
      height="17.416666666666664"
      fill="#ffc500"
    ></rect>
    <rect
      x="2042"
      y="31.666666666666668"
      width="20"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2062"
      y="20.583333333333336"
      width="19"
      height="17.416666666666664"
      fill="#ffc500"
    ></rect>
    <rect
      x="2081"
      y="31.666666666666668"
      width="82"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2163"
      y="20.06083333333333"
      width="10"
      height="17.93916666666667"
      fill="#ffc500"
    ></rect>
    <rect
      x="2173"
      y="31.666666666666668"
      width="10"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2183"
      y="19.015833333333333"
      width="10"
      height="18.984166666666667"
      fill="#ffc500"
    ></rect>
    <rect
      x="2193"
      y="31.666666666666668"
      width="10"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect x="2203" y="16.46666666666667" width="9" height="21.53333333333333" fill="#fb8b04"></rect>
    <rect
      x="2212"
      y="31.666666666666668"
      width="11"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2223"
      y="12.666666666666668"
      width="10"
      height="25.333333333333332"
      fill="#fb8b04"
    ></rect>
    <rect
      x="2233"
      y="32.93333333333334"
      width="185"
      height="5.066666666666666"
      fill="#5fdfff"
    ></rect>
  </svg>
);
export default EqualizerPlanGraph;
