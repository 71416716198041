import { t, Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import { PlanRecommendationsPreference, UserType } from "@WahooFitness/cloud-client-ts/Types";
import { BlueSwitch, MenuList, MenuListItemVariant } from "@WahooFitness/redesignr";
import useAboutYou from "../User/AboutYou/useAboutYou";

function SuggestedWorkoutsPreferenceToggle({ user }: { user: UserType }) {
  const { handleRecommendationsPreferenceChange } = useAboutYou(user);
  return (
    <Box display="flex" flexDirection="column" mx={1} mt={2} justifyContent="space-around">
      <MenuList
        listItems={[
          {
            id: "recommendedWorkouts",
            content: t`Recommended workouts`,
            variant: MenuListItemVariant.Action,
            actionComponent: (
              <BlueSwitch
                checked={
                  user.display_preference?.plan_recommendations !==
                  PlanRecommendationsPreference.never
                }
              />
            ),
            action: () =>
              handleRecommendationsPreferenceChange(
                user.display_preference?.plan_recommendations ===
                  PlanRecommendationsPreference.never
              ),
          },
        ]}
      />
      <Box display="flex" flexDirection="column" mx={4} gap={2}>
        <Typography variant="prose-xs-medium">
          <Trans>
            Cyclists are presented a workout recommendation from the Wahoo Sports Science team.
            Recommendations are personalized based upon your recent training and your subjective
            training load.
          </Trans>
        </Typography>
        <Typography variant="prose-xs-medium">
          <Trans>
            If you have a planned workout in the next two days, then no recommendation will be
            presented.
          </Trans>
        </Typography>
      </Box>
    </Box>
  );
}

export default SuggestedWorkoutsPreferenceToggle;
