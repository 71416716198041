import ErrorBoundary from "@/components/ErrorBoundary";
import { Trans, t } from "@lingui/macro";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import { BlueSwitch, MenuList, MenuListItemVariant } from "@WahooFitness/redesignr";
import useAssociatedDevices from "@/hooks/useAssociatedDevices";
import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import { FlaggedFeature } from "@/hooks/types/FlaggedFeature";

const PlannedWorkoutSettings = ({ workoutProfileId }: { workoutProfileId: number }) => {
  const { setNavHeader } = useHeaderContext();
  const headerProps = useMemo(
    () => ({
      title: t`Planned workouts`,
    }),
    []
  );

  useEffect(() => {
    setNavHeader(headerProps);
  });

  const { hasAssociatedAce } = useAssociatedDevices();

  const { getWorkoutProfile, workoutProfilesLoading, workoutProfilesError, updateProfile } =
    useWorkoutProfilesContext();

  const workoutProfile = useMemo(
    () => getWorkoutProfile(workoutProfileId),
    [getWorkoutProfile, workoutProfileId]
  );

  const handleChangeAlertsPlansUseVoice = useCallback(
    (_: any, value: boolean) => {
      if (value !== null && value !== undefined && workoutProfile) {
        updateProfile(workoutProfile.id, "alerts_plans_use_voice", value);
      }
    },
    [updateProfile, workoutProfile]
  );

  const { checkIsFeatureEnabled, featuresAreLoading } = useFlaggedFeatures();

  const elemntPlannedWorkoutsEnabled = useMemo(
    () => checkIsFeatureEnabled(FlaggedFeature.ElemntPlannedWorkouts),
    [checkIsFeatureEnabled]
  );

  const sections = useMemo(
    () => [
      {
        id: "plans_alerts_on_other_pages",
        content: t`Alerts on other pages`,
        secondaryContent: t`Keeps you updated on your planned workout progress while not on planned workout page.`,
        variant: MenuListItemVariant.Action,
        actionComponent: (
          <BlueSwitch
            checked={workoutProfile?.plans_alerts_on_other_pages}
            data-testid="plans_alerts_on_other_pages"
          />
        ),
        action: () => {
          if (workoutProfile) {
            updateProfile(
              workoutProfile.id,
              "plans_alerts_on_other_pages",
              !workoutProfile.plans_alerts_on_other_pages
            );
          }
        },
      },
      {
        id: "alerts_plans",
        content: t`Play sound for new intervals`,
        secondaryContent:
          hasAssociatedAce && elemntPlannedWorkoutsEnabled ? (
            <Box display="flex" flexDirection="column" rowGap={1}>
              <ToggleButtonGroup
                exclusive
                fullWidth
                value={workoutProfile?.alerts_plans_use_voice}
                onChange={handleChangeAlertsPlansUseVoice}
              >
                <ToggleButton value={true}>
                  <Trans>Voice</Trans>
                </ToggleButton>
                <ToggleButton value={false}>
                  <Trans>Beeps</Trans>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          ) : (
            <></>
          ),
        variant: MenuListItemVariant.Accordion,
        expanded: hasAssociatedAce && elemntPlannedWorkoutsEnabled && workoutProfile?.alerts_plans,
        actionComponent: (
          <BlueSwitch checked={workoutProfile?.alerts_plans} data-testid="alerts_plans" />
        ),
        action: () => {
          if (workoutProfile) {
            updateProfile(workoutProfile.id, "alerts_plans", !workoutProfile.alerts_plans);
          }
        },
      },
      {
        id: "plans_auto_lap_on_interval",
        content: t`Auto lap on interval`,
        secondaryContent: t`Trigger a new lap every interval.`,
        variant: MenuListItemVariant.Action,
        actionComponent: (
          <BlueSwitch
            checked={workoutProfile?.plans_auto_lap_on_interval}
            data-testid="plans_auto_lap_on_interval"
          />
        ),
        action: () => {
          if (workoutProfile) {
            updateProfile(
              workoutProfile.id,
              "plans_auto_lap_on_interval",
              !workoutProfile.plans_auto_lap_on_interval
            );
          }
        },
      },
    ],
    [
      elemntPlannedWorkoutsEnabled,
      handleChangeAlertsPlansUseVoice,
      hasAssociatedAce,
      updateProfile,
      workoutProfile,
    ]
  );

  return (
    <ErrorBoundary
      isLoading={workoutProfilesLoading || featuresAreLoading}
      error={workoutProfilesError}
    >
      <Box maxWidth="sm" width="100%" height="100%" overflow="scroll">
        <MenuList listItems={sections} />
      </Box>
    </ErrorBoundary>
  );
};

export default PlannedWorkoutSettings;
