import {
  BlueSwitch,
  ChevronRightIcon,
  MenuList,
  MenuListItemType,
  MenuListItemVariant,
} from "@WahooFitness/redesignr";
import { Checkmark, Locked } from "@carbon/icons-react";
import { t } from "@lingui/macro";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useBCWifiConfig from "./useBCWifiConfig";
import ErrorBoundary from "../../ErrorBoundary";
import { WifiIcon } from "./WifiIcon";
import WarmingUp from "@/components/WarmUp";
import WifiDialog from "./WifiDialog";
import { WSMBoltWifiNetwork } from "@WahooFitness/wsm-native/dist/esm/types/bolt_wifi";
import { WifiDialogState } from "./WifiDialogState";
import useNavUpOnDeviceDisconnect from "../useNavUpOnDeviceDisconnect";
// import { useWifiMock } from "./useWifiMock";

interface WifiNetworksProps {
  appToken: string;
  wifiStatusCallback?: (connected: boolean) => void;
}

const WifiNetworks: React.FC<WifiNetworksProps> = ({ appToken, wifiStatusCallback }) => {
  const {
    wifiLoading,
    wifiEnabled,
    wifiNetworks,
    wifiStatus: _wifiStatus,
    refreshWifiStatus: _refreshStatus,
    toggleWifiEnabled,
    connectHiddenWifi,
    connectWifi,
    forgetWifi,
  } = useBCWifiConfig();

  // Uncomment for testing locally
  // const { wifiNetworks, wifiLoading, wifiEnabled } = useWifiMock({ emptyScan: false });

  const { palette } = useTheme();

  useNavUpOnDeviceDisconnect(appToken);

  const [networkDialogState, setNetworkDialogState] = useState<WifiDialogState>(
    WifiDialogState.Closed
  );
  const [selectedNetwork, setSelectedNetwork] = useState<WSMBoltWifiNetwork | undefined>(undefined);

  const onJoinNetwork = useCallback(
    (networkName: string, networkPassword: string, hidden?: boolean) => {
      if (hidden) {
        connectHiddenWifi(networkName, networkPassword);
      } else {
        const network = Object.values(wifiNetworks).find((network) => network.ssid === networkName);
        if (!network) {
          return;
        }
        connectWifi(network, networkPassword);
      }
      setNetworkDialogState(WifiDialogState.Closed);
    },
    [connectHiddenWifi, connectWifi, wifiNetworks]
  );

  const onForgetNetwork = useCallback(
    (networkName: string) => {
      const network = Object.values(wifiNetworks).find((network) => network.ssid === networkName);
      if (!network) {
        return;
      }
      forgetWifi(network);
      setNetworkDialogState(WifiDialogState.Closed);
    },
    [forgetWifi, wifiNetworks]
  );

  const onCancel = useCallback(() => {
    setNetworkDialogState(WifiDialogState.Closed);
  }, []);

  const savedWifi = useMemo(() => {
    return Object.values(wifiNetworks).filter((network) => network.isSaved);
  }, [wifiNetworks]);

  const availableWifi = useMemo(() => {
    return Object.values(wifiNetworks).filter((network) => !network.isSaved);
  }, [wifiNetworks]);

  useEffect(() => {
    wifiStatusCallback?.(savedWifi.some((network) => network.isConnected));
  }, [savedWifi, wifiStatusCallback]);

  const wifiConfig: MenuListItemType[] = useMemo(
    () => [
      ...savedWifi.map(
        (network): MenuListItemType => ({
          id: `${network.id}`,
          content: (
            <Box display="flex" gap={1}>
              {network.isConnected && <Checkmark size={24} fill={palette.info.main} />}
              {network.ssid}
            </Box>
          ),
          variant: MenuListItemVariant.Action,
          actionComponent: (
            <Box display="flex" gap={2}>
              {network.securityType !== 0 && <Locked size={24} />}
              <WifiIcon signalLevel={network.signalLevel} />
              <ChevronRightIcon sx={{ color: palette.action.active }} />
            </Box>
          ),
          action: () => {
            setSelectedNetwork(network);
            setNetworkDialogState(WifiDialogState.Forget);
          },
        })
      ),
    ],
    [savedWifi, palette.info.main, palette.action.active]
  );

  const availableNetworks: MenuListItemType[] = useMemo(
    () =>
      availableWifi.map(
        (network): MenuListItemType => ({
          id: `${network.id}`,
          content: network.ssid,
          variant: MenuListItemVariant.Action,
          actionComponent: (
            <Box display="flex" gap={2}>
              {network.securityType !== 0 && <Locked size={24} />}
              <WifiIcon signalLevel={network.signalLevel} />
              <ChevronRightIcon sx={{ color: palette.action.active }} />
            </Box>
          ),
          action: () => {
            setSelectedNetwork(network);
            setNetworkDialogState(WifiDialogState.Join);
          },
        })
      ),
    [availableWifi, palette.action.active]
  );

  return (
    <Box maxWidth="sm" display="flex" flexDirection="column" mt={1} width="100%">
      <ErrorBoundary error={false} isLoading={wifiLoading}>
        <MenuList
          listItems={[
            {
              id: "toggleWifi",
              content: t`ELEMNT Wi-Fi Enabled`,
              variant: MenuListItemVariant.Action,
              actionComponent: <BlueSwitch checked={wifiEnabled} />,
              action: toggleWifiEnabled,
            },
          ]}
        />
        {wifiEnabled && savedWifi.length > 0 && (
          <MenuList title={t`Saved networks`} listItems={wifiConfig} />
        )}
        {wifiEnabled &&
          (availableNetworks.length === 0 ? (
            <Box width="100%" display="flex" flexDirection="column" px={2}>
              <Typography
                pl={2}
                py={1}
                variant="prose-md"
              >{t`Searching nearby networks`}</Typography>
              <Paper elevation={4}>
                <Box
                  height={48}
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  m={1}
                >
                  <WarmingUp noTopSpacing />
                </Box>
              </Paper>
            </Box>
          ) : (
            <MenuList listItems={availableNetworks} title={t`Available networks`} />
          ))}
        {wifiEnabled && (
          <Box py={2}>
            <MenuList
              listItems={[
                {
                  id: "join other network",
                  content: t`Join other network`,
                  variant: MenuListItemVariant.Action,
                  action: () => {
                    setSelectedNetwork(undefined);
                    setNetworkDialogState(WifiDialogState.Join);
                  },
                  actionComponent: <ChevronRightIcon sx={{ color: palette.action.active }} />,
                },
              ]}
            />
          </Box>
        )}
      </ErrorBoundary>
      <WifiDialog
        dialogState={networkDialogState}
        network={selectedNetwork}
        onJoinNetwork={onJoinNetwork}
        onForgetNetwork={onForgetNetwork}
        onCancel={onCancel}
      />
    </Box>
  );
};

export default WifiNetworks;
