import { Box, ListItemIcon, ListItemText, Menu, MenuItem, useTheme } from "@mui/material";
import Chip from "./StyledChip";
import { CaretDown, Checkmark } from "@carbon/icons-react";
import { useCallback, useState, MouseEvent, useMemo } from "react";
import { t } from "@lingui/macro";
import { RoadIcon, RunningIcon } from "@WahooFitness/redesignr";
import { WahooWorkoutTypeFamily } from "@WahooFitness/cloud-client-ts/Types";
import { getWorkoutTypeFamilyIcon } from "@/services/getWorkoutTypeIcon";
import { FilterParams } from "./FilterParams";

interface FamilyFilterProps {
  filterParams: FilterParams;
  updateFilter: (newFilterParams: Partial<FilterParams>) => void;
}

function FamilyFilterChip({ filterParams, updateFilter }: FamilyFilterProps) {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpen = useCallback((ev: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(ev.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const setFilter = useCallback(
    (newValue: WahooWorkoutTypeFamily[]) => {
      updateFilter({ family: newValue });
      setAnchorEl(null);
    },
    [updateFilter]
  );

  const FilterChipIcons = useCallback(() => {
    if (!filterParams?.family) {
      return <></>;
    }
    if (!filterParams.family.length) {
      return (
        <>
          <RoadIcon fontSize="small" />
          <RunningIcon fontSize="small" />
        </>
      );
    }
    return (
      <>
        {filterParams.family.map((family) => {
          const Icon = getWorkoutTypeFamilyIcon(family);
          return <Icon key={family} fontSize="small" />;
        })}
      </>
    );
  }, [filterParams?.family]);

  const familyFilters = useMemo(
    () => [
      {
        id: [],
        label: t`Any (default)`,
        icon: (
          <>
            <RoadIcon />
            <RunningIcon />
          </>
        ),
      },
      { id: [WahooWorkoutTypeFamily.Cycling], label: t`Biking`, icon: <RoadIcon /> },
      { id: [WahooWorkoutTypeFamily.Running], label: t`Running`, icon: <RunningIcon /> },
    ],
    []
  );

  return (
    <Box>
      <Chip
        onClick={handleOpen}
        label={
          <Box display="flex" alignItems="center" gap={1}>
            <FilterChipIcons />
            <CaretDown />
          </Box>
        }
      />
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        <MenuItem onClick={handleClose} disabled dense divider>
          {t`Route type`}
        </MenuItem>
        {familyFilters.map((filter) => (
          <MenuItem key={filter.id.toString()} onClick={() => setFilter(filter.id)}>
            <ListItemIcon>{filter.icon}</ListItemIcon>
            <Box
              ml={1}
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <ListItemText>{filter.label}</ListItemText>
              {filter.id.toString() === filterParams?.family?.toString() && (
                <Checkmark color={palette.success.main} />
              )}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

export default FamilyFilterChip;
