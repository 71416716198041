import usePairedDevice from "@/hooks/usePairedDevice";
import {
  getStoredValue,
  postEvent,
  setStoredValue,
  useDialogContext,
  useNativeMessagingContext,
} from "@WahooFitness/wahoo-offline-mfe";
import { BlueSwitch, MenuListItemType, MenuListItemVariant } from "@WahooFitness/redesignr";
import { SensorManagerPlugin } from "@WahooFitness/wsm-native";
import { BacklightCfg, PageColorMode } from "@WahooFitness/wsm-native/dist/esm/types/bolt_cfg";
import { Asleep, Chat, CircleFill, Email, Phone, VolumeUp, Wifi } from "@carbon/icons-react";
import { Trans, t } from "@lingui/macro";
import {
  Box,
  InputAdornment,
  ListItemText,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { KeyboardEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { ReactRouterLinkWrapper } from "../MenuList/ReactRouterLinkWrapper";
import WSMBCNotificationPermissionType from "@WahooFitness/wsm-native/dist/esm/types/bc_notification_permission_type";
import FeatureInDev from "../FeatureInDev";
import useMaps from "./Maps/useMaps";
import { useCheckForOnboardingEffect } from "./useCheckForOnboardingEffect";
import { PluginListenerHandle } from "@capacitor/core";
import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import { FlaggedFeature } from "@/hooks/types/FlaggedFeature";

type ConfigSection = {
  name?: string;
  items: MenuListItemType[];
  bottomContent?: string | JSX.Element;
  hidden?: boolean;
};

type NotificationStatusType = {
  supported: boolean;
  permitted: boolean;
  enabled: boolean;
};

const defaultNotificationStatus: NotificationStatusType = {
  supported: false,
  permitted: false,
  enabled: false,
};

const defaultAutoShutdownDuration = 15;
const workoutProfilesAlertKey = "workoutProfilesAlertShown";

function useDeviceSettings(appToken: string) {
  const { device, deviceError, deviceLoading, isDeviceConnected, reloadDevice } =
    usePairedDevice(appToken);

  // Get out if onboarding isn't finished.
  useCheckForOnboardingEffect(appToken);

  const { setDialog } = useDialogContext();
  const { addRefreshListener, removeRefreshListener } = useNativeMessagingContext();

  const { palette } = useTheme();
  const { checkIsFeatureEnabled, featuresAreLoading } = useFlaggedFeatures();

  const [showWorkoutProfilesAlert, setShowWorkoutProfilesAlert] = useState(false);
  const onCloseWorkoutProfilesAlert = useCallback(() => {
    setShowWorkoutProfilesAlert(false);
  }, []);

  useEffect(() => {
    getStoredValue(workoutProfilesAlertKey).then((value) => {
      if (!value) {
        setShowWorkoutProfilesAlert(true);
        setStoredValue(workoutProfilesAlertKey, true);
      }
    });
  }, []);

  const {
    data: autoShutdownMinutes,
    error: autoShutdownError,
    isLoading: autoShutdownIsLoading,
    mutate: mutateAutoShutdownMinutes,
  } = useSWR(
    () => appToken && ["autoShutdownDuration", appToken],
    async ([_, appToken]) =>
      (await SensorManagerPlugin?.bcGetAutoShutdownDurationMin({ appToken }))?.minutes,
    { fallbackData: defaultAutoShutdownDuration }
  );

  const autoShutdownEnabled = useMemo(() => (autoShutdownMinutes || 0) > 0, [autoShutdownMinutes]);

  const {
    data: displayMode,
    error: displayModeError,
    isLoading: displayModeIsLoading,
    mutate: mutateDisplayMode,
  } = useSWR(
    () => appToken && ["displayMode", appToken],
    async ([_, appToken]) => (await SensorManagerPlugin?.bcGetLightDarkMode({ appToken }))?.mode,
    { fallbackData: PageColorMode.Light }
  );

  const {
    data: backlightCfg,
    error: backlightCfgError,
    isLoading: backlightCfgIsLoading,
    mutate: mutateBacklightCfg,
  } = useSWR(
    () => appToken && ["backLightCfg", appToken],
    async ([_, appToken]) =>
      (await SensorManagerPlugin?.bcGetBacklightCfg({ appToken }))?.backlightCfg,
    { fallbackData: BacklightCfg.Auto }
  );

  const {
    data: backlightDurationSec,
    error: backlightDurationError,
    isLoading: backlightDurationIsLoading,
    mutate: mutateBacklightDurationSec,
  } = useSWR(
    () => appToken && ["backLightDurectionSec", appToken],
    async ([_, appToken]) =>
      (await SensorManagerPlugin?.bcGetBacklightDurationSec({ appToken }))?.seconds?.toString() ||
      "",
    { fallbackData: "" }
  );

  const {
    data: alwaysRotateMapsEnabled,
    error: alwaysRotateMapsEnabledError,
    isLoading: alwaysRotateMapsEnabledIsLoading,
    mutate: mutateAlwaysRotateMapsEnabled,
  } = useSWR(
    () => appToken && ["alwaysRotateMapsEnabled", appToken],
    async ([_, appToken]) =>
      !!(await SensorManagerPlugin?.bcGetAlwaysRotateMapsEnabled({ appToken }))?.enabled,
    { fallbackData: false }
  );

  const {
    data: autoRerouteEnabled,
    error: autoRerouteEnabledError,
    isLoading: autoRerouteEnabledIsLoading,
    mutate: mutateAutoRerouteEnabled,
  } = useSWR(
    () => appToken && ["autoRerouteEnabled", appToken],
    async ([_, appToken]) =>
      !!(await SensorManagerPlugin?.bcGetAutoRerouteEnabled({ appToken }))?.enabled,
    { fallbackData: false }
  );

  const {
    data: emailNotificationStatus,
    error: emailNotificationStatusError,
    isLoading: emailAlertsEnabledIsLoading,
    mutate: mutateEmailAlertsEnabled,
  } = useSWR<NotificationStatusType>(
    () => appToken && ["emailAlertsEnabled", appToken],
    async ([_, appToken]) => {
      return {
        supported: !!(
          await SensorManagerPlugin?.bcIsNotificationPermissionSupported({
            type: WSMBCNotificationPermissionType.EMAIL,
          })
        )?.supported,
        permitted: !!(
          await SensorManagerPlugin?.bcIsNotificationPermissionPermitted({
            type: WSMBCNotificationPermissionType.EMAIL,
          })
        )?.permitted,
        enabled: !!(await SensorManagerPlugin?.bcGetEmailAlertsEnabled({ appToken }))?.enabled,
      };
    },
    { fallbackData: defaultNotificationStatus }
  );

  const {
    data: msgNotificationStatus,
    error: msgNotificationStatusError,
    isLoading: msgAlertsEnabledIsLoading,
    mutate: mutateMsgAlertsEnabled,
  } = useSWR<NotificationStatusType>(
    () => appToken && ["msgAlertsEnabled", appToken],
    async ([_, appToken]) => {
      return {
        supported: !!(
          await SensorManagerPlugin?.bcIsNotificationPermissionSupported({
            type: WSMBCNotificationPermissionType.MESSAGE,
          })
        )?.supported,
        permitted: !!(
          await SensorManagerPlugin?.bcIsNotificationPermissionPermitted({
            type: WSMBCNotificationPermissionType.MESSAGE,
          })
        )?.permitted,
        enabled: !!(await SensorManagerPlugin?.bcGetMsgAlertsEnabled({ appToken }))?.enabled,
      };
    },
    { fallbackData: defaultNotificationStatus }
  );

  const {
    data: phoneNotificationStatus,
    error: phoneNotificationStatusError,
    isLoading: phoneAlertsEnabledIsLoading,
    mutate: mutatePhoneAlertsEnabled,
  } = useSWR<NotificationStatusType>(
    () => appToken && ["phoneAlertsEnabled", appToken],
    async ([_, appToken]) => {
      return {
        supported: !!(
          await SensorManagerPlugin?.bcIsNotificationPermissionSupported({
            type: WSMBCNotificationPermissionType.PHONE,
          })
        )?.supported,
        permitted: !!(
          await SensorManagerPlugin?.bcIsNotificationPermissionPermitted({
            type: WSMBCNotificationPermissionType.PHONE,
          })
        )?.permitted,
        enabled: !!(await SensorManagerPlugin?.bcGetPhoneAlertsEnabled({ appToken }))?.enabled,
      };
    },
    { fallbackData: defaultNotificationStatus }
  );

  const {
    data: notificationSoundEnabled,
    error: notificationSoundEnabledError,
    isLoading: notificationSoundEnabledIsLoading,
    mutate: mutateNotificationSoundEnabled,
  } = useSWR(
    () => appToken && ["notificationSoundEnabled", appToken],
    async ([_, appToken]) =>
      !!(await SensorManagerPlugin?.bcGetNotificationSoundEnabled({ appToken }))?.enabled,
    { fallbackData: false }
  );

  const {
    data: dndIntervalSec,
    error: dndIntervalSecError,
    isLoading: dndIntervalSecIsLoading,
    mutate: mutateDndIntervalSec,
  } = useSWR(
    () => appToken && ["dndIntervalSec", appToken],
    async ([_, appToken]) =>
      (await SensorManagerPlugin?.bcGetDndIntervalSec({ appToken }))?.seconds,
    { fallbackData: 0 }
  );

  useEffect(() => {
    const listeners: Promise<PluginListenerHandle>[] = [
      SensorManagerPlugin.addListener("autoShutDownDurationChange", (event) => {
        if (event.appToken === appToken) mutateAutoShutdownMinutes();
      }),
      SensorManagerPlugin.addListener("dndIntervalChange", (event) => {
        if (event.appToken === appToken) mutateDndIntervalSec();
      }),
      SensorManagerPlugin.addListener("emailAlertsEnabledChange", (event) => {
        if (event.appToken === appToken) mutateEmailAlertsEnabled();
      }),
      SensorManagerPlugin.addListener("msgAlertsEnabledChange", (event) => {
        if (event.appToken === appToken) mutateMsgAlertsEnabled();
      }),
      SensorManagerPlugin.addListener("phoneAlertsEnabledChange", (event) => {
        if (event.appToken === appToken) mutatePhoneAlertsEnabled();
      }),
      SensorManagerPlugin.addListener("backlightCfgChange", (event) => {
        if (event.appToken === appToken) mutateBacklightCfg();
      }),
      SensorManagerPlugin.addListener("backlightDurationSecChange", (event) => {
        if (event.appToken === appToken) mutateBacklightDurationSec();
      }),
      SensorManagerPlugin.addListener("lightDarkModeChange", (event) => {
        if (event.appToken === appToken) mutateDisplayMode();
      }),
      SensorManagerPlugin.addListener("alwaysRotateMapsEnabledChange", (event) => {
        if (event.appToken === appToken) mutateAlwaysRotateMapsEnabled();
      }),
      SensorManagerPlugin.addListener("autoRerouteEnabledChange", (event) => {
        if (event.appToken === appToken) mutateAutoRerouteEnabled();
      }),
      SensorManagerPlugin.addListener("notificationSoundEnabledChange", (event) => {
        if (event.appToken === appToken) mutateNotificationSoundEnabled();
      }),
    ];
    return () => {
      listeners.forEach(async (listener) => (await listener).remove());
    };
  }, [
    appToken,
    mutateAlwaysRotateMapsEnabled,
    mutateAutoRerouteEnabled,
    mutateAutoShutdownMinutes,
    mutateBacklightCfg,
    mutateBacklightDurationSec,
    mutateDisplayMode,
    mutateDndIntervalSec,
    mutateEmailAlertsEnabled,
    mutateMsgAlertsEnabled,
    mutateNotificationSoundEnabled,
    mutatePhoneAlertsEnabled,
  ]);

  useEffect(() => {
    addRefreshListener("notificationPermissions", () => {
      mutateEmailAlertsEnabled();
      mutateMsgAlertsEnabled();
      mutatePhoneAlertsEnabled();
    });
    return () => {
      removeRefreshListener("notificationPermissions");
    };
  }, [
    addRefreshListener,
    mutateEmailAlertsEnabled,
    mutateMsgAlertsEnabled,
    mutatePhoneAlertsEnabled,
    removeRefreshListener,
  ]);

  const updateAutoShutdownDuration = useCallback(
    async (minutes: number) => {
      if (!appToken) return;
      mutateAutoShutdownMinutes(minutes, { revalidate: false });
      return await SensorManagerPlugin?.bcSetAutoShutdownDurationMin({
        appToken,
        minutes,
      });
    },
    [appToken, mutateAutoShutdownMinutes]
  );

  const updateDisplayMode = useCallback(
    async (mode: PageColorMode) => {
      if (!appToken) return;
      mutateDisplayMode(mode, { revalidate: false });
      return await SensorManagerPlugin?.bcSetLightDarkMode({ appToken, mode });
    },
    [appToken, mutateDisplayMode]
  );

  const updateBacklightCfg = useCallback(
    async (backlightCfg: BacklightCfg) => {
      if (!appToken) return;
      mutateBacklightCfg(backlightCfg, { revalidate: false });
      return await SensorManagerPlugin?.bcSetBacklightCfg({
        appToken,
        backlightCfg,
      });
    },
    [appToken, mutateBacklightCfg]
  );

  const [backlightDurationSecondsValid, setBacklightDurationSecondsValid] = useState<boolean>(true);

  const updateBacklightDurationSec = useCallback(
    async (seconds: string) => {
      if (!appToken) return;
      mutateBacklightDurationSec(seconds, { revalidate: false });
      if (+seconds < 256) {
        setBacklightDurationSecondsValid(true);
        await SensorManagerPlugin?.bcSetBacklightDurationSec({
          appToken,
          seconds: +seconds,
        });
      } else {
        setBacklightDurationSecondsValid(false);
      }
    },
    [appToken, mutateBacklightDurationSec]
  );

  const updateAlwaysRotateMapsEnabled = useCallback(
    async (enabled: boolean) => {
      if (!appToken) return;
      mutateAlwaysRotateMapsEnabled(enabled, { revalidate: false });
      return await SensorManagerPlugin?.bcSetAlwaysRotateMapsEnabled({
        appToken,
        enabled,
      });
    },
    [appToken, mutateAlwaysRotateMapsEnabled]
  );

  const updateAutoRerouteEnabled = useCallback(
    async (enabled: boolean) => {
      if (!appToken) return;
      mutateAutoRerouteEnabled(enabled, { revalidate: false });
      return await SensorManagerPlugin?.bcSetAutoRerouteEnabled({ appToken, enabled });
    },
    [appToken, mutateAutoRerouteEnabled]
  );

  const updateEmailAlertsEnabled = useCallback(
    async (enabled: boolean) => {
      if (!appToken || !emailNotificationStatus) return;
      mutateEmailAlertsEnabled({ ...emailNotificationStatus, enabled }, { revalidate: false });
      return await SensorManagerPlugin?.bcSetEmailAlertsEnabled({ appToken, enabled });
    },
    [appToken, emailNotificationStatus, mutateEmailAlertsEnabled]
  );

  const updateMsgAlertsEnabled = useCallback(
    async (enabled: boolean) => {
      if (!appToken || !msgNotificationStatus) return;
      mutateMsgAlertsEnabled({ ...msgNotificationStatus, enabled }, { revalidate: false });
      return await SensorManagerPlugin?.bcSetMsgAlertsEnabled({ appToken, enabled });
    },
    [appToken, msgNotificationStatus, mutateMsgAlertsEnabled]
  );

  const updatePhoneAlertsEnabled = useCallback(
    async (enabled: boolean) => {
      if (!appToken || !phoneNotificationStatus) return;
      mutatePhoneAlertsEnabled({ ...phoneNotificationStatus, enabled }, { revalidate: false });
      return await SensorManagerPlugin?.bcSetPhoneAlertsEnabled({ appToken, enabled });
    },
    [appToken, mutatePhoneAlertsEnabled, phoneNotificationStatus]
  );

  const updateNotificationSoundEnabled = useCallback(
    async (enabled: boolean) => {
      if (!appToken) return;
      mutateNotificationSoundEnabled(enabled, { revalidate: false });
      return await SensorManagerPlugin?.bcSetNotificationSoundEnabled({ appToken, enabled });
    },
    [appToken, mutateNotificationSoundEnabled]
  );

  const updateDndIntervalSec = useCallback(
    async (seconds: number) => {
      if (!appToken) return;
      mutateDndIntervalSec(seconds, { revalidate: false });
      return await SensorManagerPlugin?.bcSetDndIntervalSec({ appToken, seconds });
    },
    [appToken, mutateDndIntervalSec]
  );

  const handleChangeAutoShutdownDuration = useCallback(
    (_: any, value: number) => {
      if (value !== null) {
        updateAutoShutdownDuration(value);
      }
    },
    [updateAutoShutdownDuration]
  );

  const handleChangeDisplayMode = useCallback(
    (_: any, value: PageColorMode) => {
      if (value !== null) {
        updateDisplayMode(value);
      }
    },
    [updateDisplayMode]
  );

  const handleChangeBacklightCfg = useCallback(
    (_: any, value: number) => {
      if (value !== null) {
        updateBacklightCfg(value);
      }
    },
    [updateBacklightCfg]
  );

  const handleChangeBacklightDurationSec = useCallback(
    (event: any) => {
      updateBacklightDurationSec(event.target.value);
    },
    [updateBacklightDurationSec]
  );

  const toggleAlwaysRotateMapsEnabled = useCallback(() => {
    updateAlwaysRotateMapsEnabled(!alwaysRotateMapsEnabled);
  }, [alwaysRotateMapsEnabled, updateAlwaysRotateMapsEnabled]);

  const toggleAutoRerouteEnabled = useCallback(() => {
    updateAutoRerouteEnabled(!autoRerouteEnabled);
  }, [autoRerouteEnabled, updateAutoRerouteEnabled]);

  const toggleEmailAlertsEnabled = useCallback(() => {
    if (!emailNotificationStatus) {
      return;
    }
    if (!emailNotificationStatus.supported) {
      setDialog({
        open: true,
        title: t`Feature not supported`,
        body: t`Email notifications are not supported on your device.`,
        actions: [{ text: t`Dismiss` }],
      });
      return;
    }
    if (!emailNotificationStatus.permitted) {
      postEvent("requestPermission", { permissionType: "emailNotifications" });
      return;
    }
    updateEmailAlertsEnabled(!emailNotificationStatus.enabled);
  }, [emailNotificationStatus, setDialog, updateEmailAlertsEnabled]);

  const toggleMsgAlertsEnabled = useCallback(() => {
    if (!msgNotificationStatus) {
      return;
    }
    if (!msgNotificationStatus.supported) {
      setDialog({
        open: true,
        title: t`Feature not supported`,
        body: t`Message notifications are not supported on your device.`,
        actions: [{ text: t`Dismiss` }],
      });
      return;
    }
    if (!msgNotificationStatus.permitted) {
      postEvent("requestPermission", { permissionType: "msgNotifications" });
      return;
    }
    updateMsgAlertsEnabled(!msgNotificationStatus.enabled);
  }, [msgNotificationStatus, setDialog, updateMsgAlertsEnabled]);

  const togglePhoneAlertsEnabled = useCallback(() => {
    if (!phoneNotificationStatus) {
      return;
    }
    if (!phoneNotificationStatus.supported) {
      setDialog({
        open: true,
        title: t`Feature not supported`,
        body: t`Phone notifications are not supported on your device.`,
        actions: [{ text: t`Dismiss` }],
      });
      return;
    }
    if (!phoneNotificationStatus.permitted) {
      postEvent("requestPermission", { permissionType: "phoneNotifications" });
      return;
    }
    updatePhoneAlertsEnabled(!phoneNotificationStatus.enabled);
  }, [phoneNotificationStatus, setDialog, updatePhoneAlertsEnabled]);

  const toggleNotificationSoundEnabled = useCallback(() => {
    updateNotificationSoundEnabled(!notificationSoundEnabled);
  }, [notificationSoundEnabled, updateNotificationSoundEnabled]);

  const handleChangeDndIntervalSec = useCallback(
    (_: any, value: number) => {
      if (value !== null) {
        updateDndIntervalSec(value);
      }
    },
    [updateDndIntervalSec]
  );

  const enableAutoShutdown = useCallback(async () => {
    if (!appToken) return;
    updateAutoShutdownDuration(defaultAutoShutdownDuration);
  }, [appToken, updateAutoShutdownDuration]);

  const disableAutoShutdown = useCallback(async () => {
    if (!appToken) return;
    updateAutoShutdownDuration(0);
  }, [appToken, updateAutoShutdownDuration]);

  const autoShutdownListItem = useMemo(
    () => ({
      id: "autoShutdown",
      content: t`Auto shutdown`,
      variant: MenuListItemVariant.Accordion,
      expanded: autoShutdownEnabled,
      actionComponent: <BlueSwitch checked={autoShutdownEnabled} />,
      action: () => (autoShutdownEnabled ? disableAutoShutdown() : enableAutoShutdown()),
      secondaryContent: (
        <Box display="flex" flexDirection="column" rowGap={1}>
          <ToggleButtonGroup
            exclusive
            fullWidth
            value={autoShutdownMinutes}
            onChange={handleChangeAutoShutdownDuration}
          >
            <ToggleButton value={15}>
              <Trans>15 min</Trans>
            </ToggleButton>
            <ToggleButton value={30}>
              <Trans>30 min</Trans>
            </ToggleButton>
            <ToggleButton value={60}>
              <Trans>60 min</Trans>
            </ToggleButton>
            <ToggleButton value={120}>
              <Trans>120 min</Trans>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      ),
    }),
    [
      autoShutdownEnabled,
      autoShutdownMinutes,
      disableAutoShutdown,
      enableAutoShutdown,
      handleChangeAutoShutdownDuration,
    ]
  );

  const displayModeListItem = useMemo(
    () => ({
      id: "displayMode",
      content: t`Display mode`,
      variant: MenuListItemVariant.NoAction,
      secondaryContent: (
        <Box display="flex" flexDirection="column" rowGap={1}>
          <ToggleButtonGroup
            exclusive
            fullWidth
            value={displayMode}
            onChange={handleChangeDisplayMode}
          >
            <ToggleButton value={PageColorMode.Light}>
              <Trans>Light</Trans>
            </ToggleButton>
            <ToggleButton value={PageColorMode.Dark}>
              <Trans>Dark</Trans>
            </ToggleButton>
            <ToggleButton value={PageColorMode.Auto}>
              <Trans>Auto</Trans>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      ),
    }),
    [displayMode, handleChangeDisplayMode]
  );

  const isInvalidNumberChar = (value: string) => {
    return ["-", "e", "!"].includes(value);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
    if (isInvalidNumberChar(event.key)) {
      event.preventDefault();
    }
  }, []);

  const backlightListItem = useMemo(
    () => ({
      id: "backlight",
      content: t`Backlight`,
      variant: MenuListItemVariant.NoAction,
      secondaryContent: (
        <Box display="flex" flexDirection="column" rowGap={1}>
          <ToggleButtonGroup
            exclusive
            fullWidth
            value={backlightCfg}
            onChange={handleChangeBacklightCfg}
          >
            <ToggleButton value={BacklightCfg.Off}>
              <Trans>Off</Trans>
            </ToggleButton>
            <ToggleButton value={BacklightCfg.Button}>
              <Trans>Time</Trans>
            </ToggleButton>
            <ToggleButton value={BacklightCfg.On}>
              <Trans>On</Trans>
            </ToggleButton>
            <ToggleButton value={BacklightCfg.Auto}>
              <Trans>Auto</Trans>
            </ToggleButton>
          </ToggleButtonGroup>
          {backlightCfg === BacklightCfg.Button && (
            <TextField
              value={backlightDurationSec?.toString() || ""}
              type="number"
              onChange={handleChangeBacklightDurationSec}
              onKeyDown={handleKeyDown}
              helperText={backlightDurationSecondsValid ? "" : t`Enter a value from 0 to 255`}
              error={!backlightDurationSecondsValid}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Trans>sec</Trans>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                min: 0,
                max: 255,
                sx: {
                  "::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "-moz-appearance": "textfield",
                },
                "data-testid": "backlightDurationInput",
              }}
            >
              <Trans>Duration (sec)</Trans>
            </TextField>
          )}
          {backlightCfg === BacklightCfg.Auto && (
            <ListItemText secondary={t`The backlight will turn on when it’s dark.`} />
          )}
        </Box>
      ),
    }),
    [
      backlightCfg,
      backlightDurationSec,
      backlightDurationSecondsValid,
      handleChangeBacklightCfg,
      handleChangeBacklightDurationSec,
      handleKeyDown,
    ]
  );

  const displayConfig: ConfigSection = useMemo(
    () => ({
      name: t`Display`,
      items: [autoShutdownListItem, displayModeListItem, backlightListItem],
    }),
    [autoShutdownListItem, backlightListItem, displayModeListItem]
  );

  const wifiConfig: ConfigSection = useMemo(
    () => ({
      name: t`Wi-Fi`,
      items: [
        {
          id: "wifiNetwork",
          icon: <Wifi size={24} />,
          content: t`Wi-Fi network`,
          secondaryContent: t`ELEMNT uses Wi-Fi to automatically upload workouts, update firmware, and download maps & routes`,
          variant: MenuListItemVariant.InternalLink,
          linkLocation: `/device-config/${appToken}/wifi`,
          "data-testid": "settings.device.wifi",
          disabled: !isDeviceConnected,
          linkComponent: ReactRouterLinkWrapper,
        },
      ],
    }),
    [isDeviceConnected, appToken]
  );

  const rotateMapsListItem = useMemo(
    () => ({
      id: "rotateMaps",
      content: t`Always rotate maps`,
      secondaryContent: t`When enabled, the map will rotate so you are always heading towards the top of the display, similar to when using routes.`,
      variant: MenuListItemVariant.Action,
      actionComponent: <BlueSwitch checked={alwaysRotateMapsEnabled} />,
      action: toggleAlwaysRotateMapsEnabled,
      "data-testid": "settings.device.rotateMaps",
    }),
    [alwaysRotateMapsEnabled, toggleAlwaysRotateMapsEnabled]
  );

  const autoRerouteListItem = useMemo(
    () => ({
      id: "reroute",
      content: t`Automatically reroute`,
      secondaryContent: t`When enabled, we’ll route you back to your original route if you go off-route.`,
      variant: MenuListItemVariant.Action,
      actionComponent: <BlueSwitch checked={autoRerouteEnabled} />,
      action: toggleAutoRerouteEnabled,
      "data-testid": "settings.device.reroute",
    }),
    [autoRerouteEnabled, toggleAutoRerouteEnabled]
  );

  const { mapPack, mapPackIsLoading } = useMaps(appToken || "", true);

  const mapsNeedUpdate = useMemo(
    () => !mapPackIsLoading && mapPack?.mapUpgradeRequired,
    [mapPack?.mapUpgradeRequired, mapPackIsLoading]
  );
  const manageMapsListItem = useMemo(
    () => ({
      id: "manageMaps",
      icon: mapsNeedUpdate ? <CircleFill size={24} fill={palette.error.main} /> : undefined,
      content: <FeatureInDev featureId="elemntMapManagement">{t`Manage maps`}</FeatureInDev>,
      variant: MenuListItemVariant.InternalLink,
      linkComponent: ReactRouterLinkWrapper,
      linkLocation: `/device-config/${appToken}/maps`,
      "data-testid": "settings.device.manageMaps",
    }),
    [appToken, mapsNeedUpdate, palette.error.main]
  );

  const navConfig: ConfigSection = useMemo(
    () => ({
      name: t`Navigation`,
      items: [rotateMapsListItem, autoRerouteListItem, manageMapsListItem],
    }),
    [rotateMapsListItem, autoRerouteListItem, manageMapsListItem]
  );

  const locationSharingConfig: ConfigSection = useMemo(
    () => ({
      name: t`Location sharing`,
      hidden: !checkIsFeatureEnabled(FlaggedFeature.LocationSharing),
      items: [
        {
          id: "liveTrack",
          content: t`Live Track`,
          secondaryContent: t`By enabling Live Track, anybody with access to this link will be able to see your location.`,
          variant: MenuListItemVariant.Action,
          actionComponent: <BlueSwitch />,
          "data-testid": "settings.device.liveTrack",
        },
        {
          id: "otherUsers",
          content: t`Show other ELEMNT users location`,
          secondaryContent: t`When enabled, your first name, last initial and location will be shown to other users, and you will see other users' locations.`,
          variant: MenuListItemVariant.Action,
          actionComponent: <BlueSwitch />,
          "data-testid": "settings.device.otherUsers",
        },
      ],
    }),
    [checkIsFeatureEnabled]
  );

  const messageNotificationListItem = useMemo(
    () => ({
      id: "messages",
      icon: <Chat size={24} />,
      content: t`Show messages`,
      variant: MenuListItemVariant.Action,
      actionComponent: (
        <BlueSwitch
          disabled={!msgNotificationStatus?.supported || !msgNotificationStatus.permitted}
          checked={
            msgNotificationStatus?.supported &&
            msgNotificationStatus.permitted &&
            msgNotificationStatus.enabled
          }
        />
      ),
      action: toggleMsgAlertsEnabled,
    }),
    [
      msgNotificationStatus?.enabled,
      msgNotificationStatus?.permitted,
      msgNotificationStatus?.supported,
      toggleMsgAlertsEnabled,
    ]
  );

  const phoneNotificationListItem = useMemo(
    () => ({
      id: "phoneCalls",
      icon: <Phone size={24} />,
      content: t`Show phone calls`,
      variant: MenuListItemVariant.Action,
      actionComponent: (
        <BlueSwitch
          disabled={!phoneNotificationStatus?.supported || !phoneNotificationStatus.permitted}
          checked={
            phoneNotificationStatus?.supported &&
            phoneNotificationStatus.permitted &&
            phoneNotificationStatus.enabled
          }
        />
      ),
      action: togglePhoneAlertsEnabled,
    }),
    [
      phoneNotificationStatus?.enabled,
      phoneNotificationStatus?.permitted,
      phoneNotificationStatus?.supported,
      togglePhoneAlertsEnabled,
    ]
  );

  const emailNotificationListItem = useMemo(
    () => ({
      id: "emails",
      icon: <Email size={24} />,
      content: t`Show emails`,
      variant: MenuListItemVariant.Action,
      actionComponent: (
        <BlueSwitch
          disabled={!emailNotificationStatus?.supported || !emailNotificationStatus.permitted}
          checked={
            emailNotificationStatus?.supported &&
            emailNotificationStatus.permitted &&
            emailNotificationStatus.enabled
          }
        />
      ),
      action: toggleEmailAlertsEnabled,
    }),
    [
      emailNotificationStatus?.enabled,
      emailNotificationStatus?.permitted,
      emailNotificationStatus?.supported,
      toggleEmailAlertsEnabled,
    ]
  );

  const notificationSoundListItem = useMemo(
    () => ({
      id: "notificationSound",
      icon: <VolumeUp size={24} />,
      content: t`Play sounds for notifications`,
      variant: MenuListItemVariant.Action,
      actionComponent: <BlueSwitch checked={notificationSoundEnabled} />,
      action: toggleNotificationSoundEnabled,
    }),
    [notificationSoundEnabled, toggleNotificationSoundEnabled]
  );

  const dndNotificationListItem = useMemo(
    () => ({
      id: "dnd",
      icon: <Asleep size={24} />,
      content: t`Do not disturb`,
      variant: MenuListItemVariant.Accordion,
      expanded: true,
      secondaryContent: (
        <Box display="flex" flexDirection="column" rowGap={1}>
          <ToggleButtonGroup
            exclusive
            fullWidth
            value={dndIntervalSec}
            onChange={handleChangeDndIntervalSec}
          >
            <ToggleButton value={0}>
              <Trans>Off</Trans>
            </ToggleButton>
            <ToggleButton value={300}>
              <Trans>5 min</Trans>
            </ToggleButton>
            <ToggleButton value={900}>
              <Trans>15 min</Trans>
            </ToggleButton>
            <ToggleButton value={1800}>
              <Trans>30 min</Trans>
            </ToggleButton>
          </ToggleButtonGroup>
          <ListItemText
            secondary={t`When a notification pops up on your ELEMNT, pressing ‘DND’ will mute notifications for this amount of time.`}
          />
        </Box>
      ),
    }),
    [dndIntervalSec, handleChangeDndIntervalSec]
  );
  const notificationsConfig: ConfigSection = useMemo(
    () => ({
      name: t`Notifications`,
      bottomContent: (
        <Typography variant="prose-3xs" color="text.secondary">
          <Trans>
            If alerts aren’t appearing as expected, open{" "}
            <Typography variant="prose-3xs-medium" color="text.secondary">
              iOS Settings {">"} Notifications
            </Typography>{" "}
            and ensure ‘Show Previews’ is set to either ‘Always’ or ‘When Unlocked’.
          </Trans>
        </Typography>
      ),
      items: [
        messageNotificationListItem,
        phoneNotificationListItem,
        emailNotificationListItem,
        notificationSoundListItem,
        dndNotificationListItem,
      ],
    }),
    [
      dndNotificationListItem,
      emailNotificationListItem,
      messageNotificationListItem,
      notificationSoundListItem,
      phoneNotificationListItem,
    ]
  );

  const configItems: ConfigSection[] = useMemo(
    () => [displayConfig, wifiConfig, navConfig, locationSharingConfig, notificationsConfig],
    [displayConfig, locationSharingConfig, navConfig, notificationsConfig, wifiConfig]
  );

  const configIsLoading = useMemo(
    () =>
      deviceLoading ||
      featuresAreLoading ||
      autoShutdownIsLoading ||
      displayModeIsLoading ||
      backlightCfgIsLoading ||
      backlightDurationIsLoading ||
      alwaysRotateMapsEnabledIsLoading ||
      autoRerouteEnabledIsLoading ||
      msgAlertsEnabledIsLoading ||
      phoneAlertsEnabledIsLoading ||
      emailAlertsEnabledIsLoading ||
      notificationSoundEnabledIsLoading ||
      dndIntervalSecIsLoading,
    [
      deviceLoading,
      featuresAreLoading,
      autoShutdownIsLoading,
      displayModeIsLoading,
      backlightCfgIsLoading,
      backlightDurationIsLoading,
      alwaysRotateMapsEnabledIsLoading,
      autoRerouteEnabledIsLoading,
      msgAlertsEnabledIsLoading,
      phoneAlertsEnabledIsLoading,
      emailAlertsEnabledIsLoading,
      notificationSoundEnabledIsLoading,
      dndIntervalSecIsLoading,
    ]
  );

  const configError = useMemo(
    () =>
      deviceError ||
      autoShutdownError ||
      displayModeError ||
      backlightCfgError ||
      backlightDurationError ||
      alwaysRotateMapsEnabledError ||
      autoRerouteEnabledError ||
      emailNotificationStatusError ||
      msgNotificationStatusError ||
      phoneNotificationStatusError ||
      notificationSoundEnabledError ||
      dndIntervalSecError,
    [
      deviceError,
      autoShutdownError,
      displayModeError,
      backlightCfgError,
      backlightDurationError,
      alwaysRotateMapsEnabledError,
      autoRerouteEnabledError,
      emailNotificationStatusError,
      msgNotificationStatusError,
      phoneNotificationStatusError,
      notificationSoundEnabledError,
      dndIntervalSecError,
    ]
  );

  const reloadConfig = useCallback(() => {
    reloadDevice();
    mutateAutoShutdownMinutes();
    mutateDisplayMode();
    mutateBacklightCfg();
    mutateBacklightDurationSec();
    mutateAlwaysRotateMapsEnabled();
    mutateAutoRerouteEnabled();
    mutateEmailAlertsEnabled();
    mutateMsgAlertsEnabled();
    mutatePhoneAlertsEnabled();
    mutateNotificationSoundEnabled();
    mutateDndIntervalSec();
  }, [
    mutateAlwaysRotateMapsEnabled,
    mutateAutoRerouteEnabled,
    mutateAutoShutdownMinutes,
    mutateBacklightCfg,
    mutateBacklightDurationSec,
    mutateDisplayMode,
    mutateDndIntervalSec,
    mutateEmailAlertsEnabled,
    mutateMsgAlertsEnabled,
    mutateNotificationSoundEnabled,
    mutatePhoneAlertsEnabled,
    reloadDevice,
  ]);

  return {
    configItems,
    configError,
    configIsLoading,
    reloadConfig,
    device,
    isDeviceConnected: deviceLoading || isDeviceConnected,
    showWorkoutProfilesAlert,
    onCloseWorkoutProfilesAlert,
  };
}

export { useDeviceSettings };
