import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import { clearNulls } from "@/services/clearNulls";
import { ChevronRight } from "@carbon/icons-react";
import { t } from "@lingui/macro";
import { Box, IconButton, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { WorkoutAlerts } from "@WahooFitness/cloud-client-ts";
import {
  ImperialMetricDisplayPreference,
  WahooWorkoutTypeFamily,
  WorkoutAlertTriggerTypeEnum,
  WorkoutAlertType,
  WorkoutProfileType,
} from "@WahooFitness/cloud-client-ts/Types";
import {
  BlueSwitch,
  DeleteIcon,
  MenuListItemType,
  MenuListItemVariant,
} from "@WahooFitness/redesignr";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import AlertIntervalEditor from "./AlertIntervalEditor";
import AlertMetricSelector from "./AlertMetricSelector";
import AlertTriggerSelector from "./AlertTriggerSelector";
import useAlertMetrics from "./useAlertMetrics";
import useAlertTriggers from "./useAlertTriggers";
import { useUserContext, useCloudContext, useConfigContext } from "@WahooFitness/wahoo-offline-mfe";
import useAssociatedDevices from "@/hooks/useAssociatedDevices";
import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import { FlaggedFeature } from "@/hooks/types/FlaggedFeature";

const intervalTriggerTypes = [
  WorkoutAlertTriggerTypeEnum.distance,
  WorkoutAlertTriggerTypeEnum.duration,
];

const useAlertList = (workoutProfileId: number) => {
  const [editing, setEditing] = useState(false);

  const {
    getWorkoutProfile,
    updateProfile,
    mutateProfileState,
    workoutProfilesLoading,
    workoutProfilesError,
  } = useWorkoutProfilesContext();

  const workoutProfile = useMemo(() => {
    return getWorkoutProfile(workoutProfileId);
  }, [getWorkoutProfile, workoutProfileId]);

  const { hasAssociatedElemnt, hasAssociatedAce } = useAssociatedDevices();

  const { checkIsFeatureEnabled, featuresAreLoading } = useFlaggedFeatures();

  const showElemntAlertCountWarning = useMemo(
    () => checkIsFeatureEnabled(FlaggedFeature.ElemntAlertCountWarning) && hasAssociatedElemnt,
    [checkIsFeatureEnabled, hasAssociatedElemnt]
  );

  const showElemntOptions = useMemo(
    () =>
      checkIsFeatureEnabled(FlaggedFeature.ElemntConfig) &&
      hasAssociatedElemnt &&
      workoutProfile?.workout_type_family_id === WahooWorkoutTypeFamily.Cycling,
    [checkIsFeatureEnabled, hasAssociatedElemnt, workoutProfile?.workout_type_family_id]
  );

  const showAceOptions = useMemo(
    () =>
      hasAssociatedAce && workoutProfile?.workout_type_family_id === WahooWorkoutTypeFamily.Cycling,
    [hasAssociatedAce, workoutProfile?.workout_type_family_id]
  );

  const customAlerts = useMemo(() => {
    if (workoutProfilesLoading || !!workoutProfilesError || !workoutProfile) {
      return [];
    }
    return workoutProfile.workout_alerts;
  }, [workoutProfile, workoutProfilesError, workoutProfilesLoading]);

  const { currentUser } = useUserContext();

  const distanceUnitPreference = useMemo(
    () =>
      currentUser?.display_preference?.speed_distance === ImperialMetricDisplayPreference.imperial
        ? ImperialMetricDisplayPreference.imperial
        : ImperialMetricDisplayPreference.metric,
    [currentUser?.display_preference?.speed_distance]
  );

  const { getTriggerSummaryDisplayString } = useAlertTriggers(distanceUnitPreference);

  const toggleEditing = useCallback(() => {
    setEditing((value) => !value);
  }, []);

  const [currentDrawerHeight, setCurrentDrawerHeight] = useState(0);

  /**
   * When editing (or creating) an alert, this stack is used to keep track of the screens
   * viewed during the process, so the back button will work properly.
   * The view at the top of the stack is always the one being shown,
   * and an empty stack means the edit view will not be open
   */
  const [editorStack, setEditorStack] = useState<ReactElement[]>([]);
  const gotoNextEditorView = useCallback(
    (nextEditorView: ReactElement) => setEditorStack((original) => [...original, nextEditorView]),
    []
  );
  const gotoPreviousEditorView = useCallback(
    () => setEditorStack((original) => original.slice(0, -1)),
    []
  );
  const clearEditor = useCallback(() => setEditorStack([]), []);
  const currentEditorView = useMemo(() => editorStack[editorStack.length - 1], [editorStack]);
  const editorOpen = useMemo(() => editorStack.length > 0, [editorStack.length]);

  const { getCloudClient } = useCloudContext();
  const workoutAlertsClient = useMemo(() => getCloudClient(WorkoutAlerts), [getCloudClient]);

  const { wahooToken } = useConfigContext();

  const saveAlert = useCallback(
    async (alert: WorkoutAlertType) => {
      if (!workoutProfile) {
        return false;
      }
      const alerts = workoutProfile.workout_alerts || [];
      const newAlerts = [...alerts];
      if (alert.id === -1) {
        const savedAlert = await workoutAlertsClient.post(workoutProfileId, wahooToken, alert);
        newAlerts.push(savedAlert);
      } else {
        const savedAlert = await workoutAlertsClient.put(
          workoutProfileId,
          alert.id,
          wahooToken,
          clearNulls(alert)
        );
        const alertIndex = newAlerts?.findIndex((savedAlert) => savedAlert.id === alert.id) ?? -1;
        if (alertIndex !== -1) {
          newAlerts[alertIndex] = savedAlert;
        }
      }
      mutateProfileState({
        ...workoutProfile,
        workout_alert_ids: newAlerts.map((alert) => alert.id),
        workout_alerts: newAlerts,
      });
    },
    [mutateProfileState, wahooToken, workoutAlertsClient, workoutProfile, workoutProfileId]
  );

  const deleteAlert = useCallback(
    (alert: WorkoutAlertType) => {
      workoutAlertsClient.delete(workoutProfileId, alert.id, wahooToken);
      const alerts = workoutProfile?.workout_alerts;
      const alertIndex = alerts?.findIndex((savedAlert) => savedAlert.id === alert.id) ?? -1;
      if (alerts && alertIndex !== -1) {
        alerts.splice(alertIndex, 1);
        mutateProfileState({ ...workoutProfile, workout_alerts: [...alerts] });
      }
    },
    [mutateProfileState, wahooToken, workoutAlertsClient, workoutProfile, workoutProfileId]
  );

  const toggleEnabled = useCallback(
    (alert: WorkoutAlertType) => {
      alert.audio = !alert.audio;
      saveAlert(alert);
    },
    [saveAlert]
  );

  const handleCreateAlert = useCallback(
    (alert: WorkoutAlertType) => {
      saveAlert(alert);
      clearEditor();
    },
    [clearEditor, saveAlert]
  );

  const openMetricSelector = useCallback(
    (alert: WorkoutAlertType) => {
      gotoNextEditorView(
        <AlertMetricSelector
          alert={alert}
          backAction={gotoPreviousEditorView}
          onCreate={handleCreateAlert}
        />
      );
    },
    [gotoNextEditorView, gotoPreviousEditorView, handleCreateAlert]
  );

  const handleConfirmInterval = useCallback(
    (alert: WorkoutAlertType) => {
      openMetricSelector(alert);
    },
    [openMetricSelector]
  );

  const openIntervalEditor = useCallback(
    (alert: WorkoutAlertType) => {
      gotoNextEditorView(
        <AlertIntervalEditor
          alert={alert}
          backAction={gotoPreviousEditorView}
          onNext={handleConfirmInterval}
          unitPreference={distanceUnitPreference}
        />
      );
    },
    [distanceUnitPreference, gotoNextEditorView, gotoPreviousEditorView, handleConfirmInterval]
  );

  const handleTriggerSelection = useCallback(
    (alert: WorkoutAlertType) => {
      if (alert.trigger_type === undefined) {
        return;
      }
      if (intervalTriggerTypes.includes(alert.trigger_type)) {
        openIntervalEditor(alert);
      } else {
        openMetricSelector(alert);
      }
    },
    [openIntervalEditor, openMetricSelector]
  );

  const openTriggerSelector = useCallback(
    (alert: WorkoutAlertType) => {
      gotoNextEditorView(
        <AlertTriggerSelector
          alert={alert}
          backAction={clearEditor}
          onSelection={handleTriggerSelection}
        />
      );
    },
    [gotoNextEditorView, clearEditor, handleTriggerSelection]
  );

  const handleEditAlert = useCallback(
    (alert: WorkoutAlertType) => {
      openTriggerSelector({
        ...alert,
        workout_metric_type_ids: [...alert.workout_metric_type_ids],
      });
    },
    [openTriggerSelector]
  );

  const handleAddAlert = useCallback(() => {
    openTriggerSelector({
      id: -1,
      trigger_type: 0,
      workout_metric_type_ids: [],
      audio: true,
      visual: false,
      custom_alert_text: "",
      created_at: "",
      updated_at: "",
    });
  }, [openTriggerSelector]);

  const { getAlertMetricSummaryDisplayString } = useAlertMetrics();

  const getAlertInformationStrings = useCallback(
    (alert: WorkoutAlertType) => {
      const infoStrings = [];
      const totalAlertCount =
        alert.workout_metric_type_ids.length + (alert.custom_alert_text ? 1 : 0);
      if (alert.custom_alert_text) {
        infoStrings.push(t`Custom: ${alert.custom_alert_text}`);
      }
      infoStrings.push(
        ...alert.workout_metric_type_ids
          .slice(0, (totalAlertCount <= 3 ? totalAlertCount : 2) - infoStrings.length)
          .map(getAlertMetricSummaryDisplayString)
      );
      if (totalAlertCount > infoStrings.length) {
        infoStrings.push(t`+${totalAlertCount - infoStrings.length}`);
      }
      return infoStrings;
    },
    [getAlertMetricSummaryDisplayString]
  );

  const mapAlertToListItem = useCallback(
    (alert: WorkoutAlertType) => {
      const infoStrings = getAlertInformationStrings(alert);
      return {
        id: alert.id.toString(),
        content: getTriggerSummaryDisplayString(alert),
        secondaryContent: (
          <>
            {infoStrings.map((infoString) => (
              <React.Fragment key={infoString}>
                {infoString}
                <br />
              </React.Fragment>
            ))}
          </>
        ),
        variant: MenuListItemVariant.Action,
        actionComponent: editing ? (
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">
            <IconButton onClick={() => deleteAlert(alert)} data-testid="deleteAlertButton">
              <DeleteIcon color="error" height={24} width={24} />
            </IconButton>
            <IconButton onClick={() => handleEditAlert(alert)} data-testid="editAlertButton">
              <ChevronRight height={24} width={24} />
            </IconButton>
          </Box>
        ) : (
          <BlueSwitch checked={alert.audio} data-testid="alertToggle" />
        ),
        action: editing ? undefined : () => toggleEnabled(alert),
      } as MenuListItemType;
    },
    [
      getAlertInformationStrings,
      getTriggerSummaryDisplayString,
      editing,
      deleteAlert,
      handleEditAlert,
      toggleEnabled,
    ]
  );

  const toggleField = useCallback(
    (fieldName: keyof WorkoutProfileType) => {
      updateProfile(workoutProfileId, fieldName, !workoutProfile?.[fieldName]);
    },
    [updateProfile, workoutProfile, workoutProfileId]
  );

  const generalListTitle = useMemo(
    () =>
      showElemntOptions ? (
        <Box display="flex" flexDirection="column">
          <Typography variant="prose-base">{t`General workout alerts`}</Typography>
          <Typography
            variant="prose-xs"
            color="text.secondary"
          >{t`Workout started, paused, resumed, stopped, finished`}</Typography>
        </Box>
      ) : undefined,
    [showElemntOptions]
  );

  const generalAlertsSecondaryContent = useMemo(() => {
    if (showAceOptions) return t`Voice`;
    if (showElemntOptions) return undefined;
    return t`Workout started, paused, lap, resumed, stopped, finished`;
  }, [showAceOptions, showElemntOptions]);

  const generalListItems = useMemo(
    () =>
      [
        showElemntOptions
          ? {
              id: "elemnt-general-alerts",
              content: t`ELEMNT`,
              secondaryContent: showAceOptions ? t`Beeps` : undefined,
              variant: MenuListItemVariant.Action,
              actionComponent: (
                <BlueSwitch
                  checked={workoutProfile?.alerts_workouts}
                  data-testid="elemntAlertToggle"
                />
              ),
              action: () => {
                toggleField("alerts_workouts");
              },
            }
          : undefined,
        {
          id: "general-alerts",
          content: showElemntOptions ? t`App recording` : t`General workout alerts`,
          secondaryContent: generalAlertsSecondaryContent,
          variant: MenuListItemVariant.Action,
          actionComponent: (
            <BlueSwitch
              checked={workoutProfile?.general_audio_announcements}
              data-testid="generalAlertToggle"
            />
          ),
          action: () => {
            toggleField("general_audio_announcements");
          },
        },
      ].filter(Boolean) as MenuListItemType[],
    [
      generalAlertsSecondaryContent,
      showAceOptions,
      showElemntOptions,
      toggleField,
      workoutProfile?.alerts_workouts,
      workoutProfile?.general_audio_announcements,
    ]
  );

  const navigationAlertListTitle = useMemo(() => {
    return showElemntOptions ? (
      <Typography variant="prose-base">{t`Turn by turn navigation alerts`}</Typography>
    ) : undefined;
  }, [showElemntOptions]);
  const navigationAlertListItems = useMemo(() => {
    return showElemntOptions
      ? ([
          {
            id: "navigation-alerts",
            content: t`ELEMNT`,
            secondaryContent: showAceOptions ? (
              <ToggleButtonGroup
                value={workoutProfile?.alerts_routes_use_voice}
                exclusive
                fullWidth
                size="small"
                onChange={(_, value) => {
                  if (value !== null) {
                    updateProfile(workoutProfileId, "alerts_routes_use_voice", value);
                  }
                }}
              >
                <ToggleButton value={true}>{t`Voice`}</ToggleButton>
                <ToggleButton value={false}>{t`Beeps`}</ToggleButton>
              </ToggleButtonGroup>
            ) : undefined,
            variant: showAceOptions ? MenuListItemVariant.Accordion : MenuListItemVariant.Action,
            expanded: workoutProfile?.alerts_routes,
            actionComponent: (
              <BlueSwitch
                checked={workoutProfile?.alerts_routes}
                data-testid="elemntNavigationAlertToggle"
              />
            ),
            action: () => {
              toggleField("alerts_routes");
            },
          },
        ] as MenuListItemType[])
      : [];
  }, [
    showAceOptions,
    showElemntOptions,
    toggleField,
    updateProfile,
    workoutProfile?.alerts_routes,
    workoutProfile?.alerts_routes_use_voice,
    workoutProfileId,
  ]);

  const plannedWorkoutAlertListTitle = useMemo(() => {
    return showElemntOptions ? (
      <Typography variant="prose-base">{t`Planned Workout alerts`}</Typography>
    ) : undefined;
  }, [showElemntOptions]);

  const elemntPlannedWorkoutsEnabled = useMemo(
    () => checkIsFeatureEnabled(FlaggedFeature.ElemntPlannedWorkouts),
    [checkIsFeatureEnabled]
  );
  const plannedWorkoutAlertListItems = useMemo(() => {
    return showElemntOptions
      ? ([
          {
            id: "planned-workout-alerts",
            content: t`ELEMNT`,
            secondaryContent:
              showAceOptions && elemntPlannedWorkoutsEnabled ? (
                <ToggleButtonGroup
                  value={workoutProfile?.alerts_plans_use_voice}
                  exclusive
                  fullWidth
                  size="small"
                  onChange={(_, value) => {
                    if (value !== null) {
                      updateProfile(workoutProfileId, "alerts_plans_use_voice", value);
                    }
                  }}
                >
                  <ToggleButton value={true}>{t`Voice`}</ToggleButton>
                  <ToggleButton value={false}>{t`Beeps`}</ToggleButton>
                </ToggleButtonGroup>
              ) : undefined,
            variant:
              showAceOptions && elemntPlannedWorkoutsEnabled
                ? MenuListItemVariant.Accordion
                : MenuListItemVariant.Action,
            expanded: workoutProfile?.alerts_plans,
            actionComponent: (
              <BlueSwitch
                checked={workoutProfile?.alerts_plans}
                data-testid="elemntPlannedWorkoutAlertToggle"
              />
            ),
            action: () => {
              toggleField("alerts_plans");
            },
          },
        ] as MenuListItemType[])
      : [];
  }, [
    elemntPlannedWorkoutsEnabled,
    showAceOptions,
    showElemntOptions,
    toggleField,
    updateProfile,
    workoutProfile?.alerts_plans,
    workoutProfile?.alerts_plans_use_voice,
    workoutProfileId,
  ]);

  const customAlertListTitle = useMemo(
    () => <Typography variant="prose-base">{t`Custom alerts`}</Typography>,
    []
  );

  const customAlertListItems = useMemo(() => {
    return customAlerts.map(mapAlertToListItem);
  }, [customAlerts, mapAlertToListItem]);

  return {
    editing,
    toggleEditing,
    alertsLoading: workoutProfilesLoading || featuresAreLoading,
    alertsError: workoutProfilesError,
    currentEditorView,
    gotoNextEditorView,
    gotoPreviousEditorView,
    clearEditor,
    editorOpen,
    generalListTitle,
    generalListItems,
    navigationAlertListTitle,
    navigationAlertListItems,
    plannedWorkoutAlertListTitle,
    plannedWorkoutAlertListItems,
    customAlertListTitle,
    customAlertListItems,
    handleAddAlert,
    handleEditAlert,
    currentDrawerHeight,
    setCurrentDrawerHeight,
    showElemntOptions,
    showAceOptions,
    showElemntAlertCountWarning,
  };
};

export default useAlertList;
