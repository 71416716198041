import RouteDetails from "@/components/Routes/RouteDetails/RouteDetails";
import { Box } from "@mui/material";
import { useLocation } from "react-router";

function RouteDetailsPage() {
  const { state } = useLocation();
  return (
    <Box height="100%">
      <RouteDetails fromPublicRouteList={!!state?.public} />
    </Box>
  );
}

export default RouteDetailsPage;
