import { useConfigContext } from "@WahooFitness/wahoo-offline-mfe";
import { Close } from "@carbon/icons-react";
import {
  Box,
  Divider,
  IconButton,
  SwipeableDrawer,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { PropsWithChildren, RefObject, forwardRef } from "react";

interface Props {
  open: boolean;
  title: string;
  footer?: React.ReactElement;
  handleOpen: () => void;
  handleClose: () => void;
  parentDrawerRef?: RefObject<HTMLDivElement>;
  zIndex?: number;
  hidePuller?: boolean;
  hideDivider?: boolean;
  centerTitle?: boolean;
  maxWidth?: boolean;
  isPersistent?: boolean;
  closeIcon?: JSX.Element;
  titleLeftPadOverride?: number;
  handleBackElement?: JSX.Element;
}

const Puller = styled(Box)(({ theme }) => ({
  width: 40,
  height: 4,
  backgroundColor: theme.palette.text.disabled,
  borderRadius: 3,
  margin: "8px auto 0px auto",
}));

const SlidingDrawer = forwardRef(function SlidingDrawer(
  {
    open,
    handleClose,
    handleOpen,
    title,
    children,
    footer,
    parentDrawerRef,
    zIndex,
    hidePuller,
    hideDivider,
    centerTitle,
    maxWidth,
    isPersistent,
    closeIcon,
    titleLeftPadOverride,
    handleBackElement,
  }: PropsWithChildren<Props>,
  ref
) {
  const { globalBottomPadding } = useConfigContext();
  const theme = useTheme();
  return (
    <SwipeableDrawer
      elevation={1}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      anchor="bottom"
      PaperProps={{
        sx: {
          "&.MuiPaper-elevation0": {
            backgroundColor: theme.palette.background.paper,
          },
          borderRadius: 3,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          maxWidth: maxWidth ? undefined : "sm",
          margin: "auto",
          zIndex: zIndex,
        },
      }}
      sx={{ zIndex }}
      hideBackdrop={isPersistent}
      variant={isPersistent ? "persistent" : undefined}
    >
      <Box
        display="flex"
        flexDirection="column"
        overflow="hidden"
        pb={globalBottomPadding}
        ref={ref}
        minHeight={parentDrawerRef?.current?.clientHeight}
      >
        <Box display="flex" flexDirection="column">
          {hidePuller ? null : <Puller onMouseDown={handleClose} />}
          <Box
            display="flex"
            pb={1}
            pt={hidePuller ? 1 : 0}
            justifyContent="space-between"
            alignItems={"center"}
            pl={titleLeftPadOverride ?? 2}
            pr={1}
          >
            {handleBackElement ? handleBackElement : centerTitle && <Box width="44px" />}
            <Box>
              <Typography variant="prose-md-medium">{title}</Typography>
            </Box>
            {closeIcon ? (
              closeIcon
            ) : (
              <IconButton onClick={handleClose} size="large" data-testid="closeSlidingDrawerButton">
                <Close size={32} />
              </IconButton>
            )}
          </Box>
          {!hideDivider && <Divider flexItem />}
        </Box>
        <Box overflow="auto" flexGrow={1}>
          {children}
        </Box>
        {footer && <Box>{footer}</Box>}
      </Box>
    </SwipeableDrawer>
  );
});

export default SlidingDrawer;
