import { SVGProps, memo } from "react";
const SvgLEDs = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12.1429H5.2621C5.28616 12.2601 5.29879 12.3815 5.29879 12.5059C5.29879 12.6261 5.28698 12.7435 5.26447 12.8571H6V12.1429ZM5.52035 10.9847L4.99666 11.5084C4.86307 11.3087 4.69051 11.1373 4.48986 11.0052L5.01535 10.4797L5.52035 10.9847ZM3.85714 10V10.7454C3.74355 10.7228 3.62609 10.7109 3.50587 10.7109C3.3815 10.7109 3.26009 10.7236 3.14286 10.7477V10H3.85714ZM1.98468 10.4796L2.51486 11.0098C2.31487 11.1429 2.14309 11.315 2.01041 11.5154L1.47968 10.9846L1.98468 10.4796ZM1.71295 12.5059C1.71295 12.3815 1.72558 12.2601 1.74964 12.1429H1V12.8571H1.74727C1.72476 12.7435 1.71295 12.6261 1.71295 12.5059ZM1.47964 14.0153L2.00573 13.4892C2.13748 13.6903 2.30847 13.8632 2.50779 13.9972L1.98464 14.5203L1.47964 14.0153ZM3.14286 15V14.264C3.26009 14.2881 3.3815 14.3008 3.50587 14.3008C3.62609 14.3008 3.74355 14.2889 3.85714 14.2663V15H3.14286ZM5.01532 14.5204L4.49687 14.0019C4.69686 13.8689 4.86864 13.6967 5.00132 13.4964L5.52032 14.0154L5.01532 14.5204ZM3.49551 13.7471C4.17104 13.7471 4.71866 13.1994 4.71866 12.5239C4.71866 11.8484 4.17104 11.3008 3.49551 11.3008C2.81999 11.3008 2.27237 11.8484 2.27237 12.5239C2.27237 13.1994 2.81999 13.7471 3.49551 13.7471Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4988 7.64383H9.76094C9.785 7.76107 9.79763 7.88248 9.79763 8.00684C9.79763 8.12706 9.78582 8.24452 9.76331 8.35812H10.4988V7.64383ZM10.0192 6.48566L9.4955 7.00935C9.36191 6.80964 9.18935 6.63827 8.9887 6.50615L9.51419 5.98066L10.0192 6.48566ZM8.35598 5.50098V6.24635C8.24239 6.22376 8.12493 6.21191 8.00471 6.21191C7.88034 6.21191 7.75893 6.22459 7.6417 6.24873V5.50098H8.35598ZM6.48352 5.98062L7.0137 6.5108C6.81371 6.64383 6.64193 6.81599 6.50925 7.01635L5.97852 6.48562L6.48352 5.98062ZM6.21179 8.00684C6.21179 7.88248 6.22442 7.76107 6.24848 7.64383H5.49884V8.35812H6.24611C6.2236 8.24452 6.21179 8.12706 6.21179 8.00684ZM5.97848 9.5163L6.50457 8.99021C6.63632 9.19124 6.80731 9.36419 7.00663 9.49815L6.48348 10.0213L5.97848 9.5163ZM7.6417 10.501V9.76495C7.75893 9.78908 7.88034 9.80176 8.00471 9.80176C8.12493 9.80176 8.24239 9.78991 8.35598 9.76732V10.501H7.6417ZM9.51416 10.0213L8.99571 9.50288C9.1957 9.36985 9.36748 9.19769 9.50016 8.99733L10.0192 9.51633L9.51416 10.0213ZM7.99435 9.24805C8.66988 9.24805 9.2175 8.70043 9.2175 8.0249C9.2175 7.34938 8.66988 6.80176 7.99435 6.80176C7.31883 6.80176 6.77121 7.34938 6.77121 8.0249C6.77121 8.70043 7.31883 9.24805 7.99435 9.24805Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0072 3.14481H14.2693C14.2934 3.26205 14.306 3.38345 14.306 3.50781C14.306 3.62803 14.2942 3.74549 14.2717 3.8591H15.0072V3.14481ZM14.5276 1.98663L14.0039 2.51032C13.8703 2.31062 13.6977 2.13925 13.4971 2.00712L14.0226 1.48163L14.5276 1.98663ZM12.8644 1.00195V1.74733C12.7508 1.72474 12.6333 1.71289 12.5131 1.71289C12.3887 1.71289 12.2673 1.72557 12.1501 1.7497V1.00195H12.8644ZM10.9919 1.4816L11.5221 2.01177C11.3221 2.14481 11.1503 2.31697 11.0176 2.51733L10.4869 1.9866L10.9919 1.4816ZM10.7202 3.50781C10.7202 3.38345 10.7328 3.26205 10.7569 3.14481H10.0072V3.8591H10.7545C10.732 3.74549 10.7202 3.62803 10.7202 3.50781ZM10.4869 5.01727L11.013 4.49119C11.1447 4.69222 11.3157 4.86517 11.515 4.99913L10.9919 5.52227L10.4869 5.01727ZM12.1501 6.00195V5.26592C12.2673 5.29006 12.3887 5.30273 12.5131 5.30273C12.6333 5.30273 12.7508 5.29089 12.8644 5.2683V6.00195H12.1501ZM14.0226 5.52231L13.5041 5.00386C13.7041 4.87082 13.8759 4.69867 14.0086 4.49831L14.5276 5.01731L14.0226 5.52231ZM12.5027 4.74902C13.1783 4.74902 13.7259 4.2014 13.7259 3.52588C13.7259 2.85035 13.1783 2.30273 12.5027 2.30273C11.8272 2.30273 11.2796 2.85035 11.2796 3.52588C11.2796 4.2014 11.8272 4.74902 12.5027 4.74902Z"
      />
    </svg>
  );
};
const Memo = memo(SvgLEDs);
export default Memo;
