import React, { RefObject } from "react";
import { Arc } from "./Arc";
import { useTheme } from "@mui/material";

const degToRad = (degrees: number) => {
  return (degrees * Math.PI) / 180;
};

const getPointOnCircle = (cx: number, cy: number, radius: number, angle: number) => {
  return {
    x: cx + radius * Math.cos(degToRad(angle)),
    y: cy + radius * Math.sin(degToRad(angle)),
  };
};

enum FourDpType {
  Ac = "Ac",
  Ftp = "Ftp",
  Map = "Map",
  Nm = "Nm",
}

type FourDpChartValuesType = {
  Nm: {
    value: number | undefined;
    graphValue: number;
    status: string;
  };
  Ac: {
    value: number | undefined;
    graphValue: number;
    status: string;
  };
  Map: {
    value: number | undefined;
    graphValue: number;
    status: string;
  };
  Ftp: {
    value: number | undefined;
    graphValue: number;
    status: string;
  };
};

interface Props {
  values: FourDpChartValuesType;
  riderIconUrl?: string;
  iconRef?: RefObject<SVGImageElement>;
  arcRef?: RefObject<SVGPathElement>;
}

export const RiderTypeGraph: React.FC<Props> = ({ values, riderIconUrl, iconRef, arcRef }) => {
  const theme = useTheme();
  const riderIconRadius = 12;
  const riderIconPadding = 2;
  const fourDpAngleInDegrees = 60;
  const backgroundAngleInDegrees = 180;
  const center = { x: 50, y: 50 };

  const bounds = {
    x: 0,
    y: 0,
    width: center.x * 2,
    height: center.y,
  };

  const start = {
    x: center.x + riderIconRadius,
    y: center.y,
  };

  const minRadius = riderIconRadius;
  const maxRadius = bounds.width - center.x - 2;
  const radiusStep = (maxRadius - minRadius) / 100;

  const backgroundBigCurve = getPointOnCircle(
    center.x,
    center.y,
    maxRadius,
    backgroundAngleInDegrees
  );

  const backgroundLittleCurve = getPointOnCircle(
    center.x,
    center.y,
    minRadius,
    backgroundAngleInDegrees
  );

  const fourDpLittleCurve = getPointOnCircle(center.x, center.y, minRadius, fourDpAngleInDegrees);

  const iconBounds = {
    x: center.x - riderIconRadius + riderIconPadding,
    y: center.y - riderIconRadius + riderIconPadding,
    width: (riderIconRadius - riderIconPadding) * 2,
    height: (riderIconRadius - riderIconPadding) * 2,
  };

  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`${bounds.x} ${bounds.y} ${bounds.width} ${bounds.height}`}
      overflow="visible"
    >
      <Arc
        start={start}
        center={center}
        bigCurve={backgroundBigCurve}
        bigRadius={maxRadius}
        littleCurve={backgroundLittleCurve}
        littleRadius={minRadius}
        color={theme.palette.background.default}
        innerRef={arcRef}
      />
      {Object.keys(values)
        .filter((key) => key !== "Ftp")
        .map((key, index) => {
          const type = key as FourDpType;
          const value = Math.min(Math.max(values[type]?.graphValue || 0.01, 0.01), 1);
          const radius = minRadius + value * 100 * radiusStep;
          const fourDpBigCurve = getPointOnCircle(center.x, center.y, radius, fourDpAngleInDegrees);

          // we need that for redesignr color matching
          const colorType = `4dp${key}` as "4dpMap" | "4dpNm" | "4dpAc";

          return (
            <Arc
              key={type}
              start={start}
              center={center}
              bigRadius={radius}
              bigCurve={fourDpBigCurve}
              littleRadius={minRadius}
              littleCurve={fourDpLittleCurve}
              color={theme.palette[colorType].main}
              rotationOffset={fourDpAngleInDegrees * index}
            />
          );
        })}
      {riderIconUrl && <image ref={iconRef} href={riderIconUrl} {...iconBounds} />}
    </svg>
  );
};
