import { useAnalyticsContext } from "@WahooFitness/wahoo-offline-mfe";
import { Box } from "@mui/material";
import { PropsWithChildren, useMemo } from "react";

/**
 * This is a helper component that will wrap its children in a red border if
 * the feature is in development according to the Firebase Remote Config.
 *
 * @param featureId The feature ID to check for in the remote config object.
 */
const FeatureInDev = ({ featureId, children }: PropsWithChildren<{ featureId: string }>) => {
  const { getRemoteConfigObject } = useAnalyticsContext();
  const featuresInDev = useMemo(
    () => getRemoteConfigObject("FEATURES_IN_DEV"),
    [getRemoteConfigObject]
  );

  if (featuresInDev?.[featureId] === true) {
    return <Box border="1px solid red">{children}</Box>;
  } else {
    return <>{children}</>;
  }
};

export default FeatureInDev;
