import { Box } from "@mui/material";
import Plan1Image from "@/assets/Plan01.png";
import Plan2Image from "@/assets/Plan02.png";
import Plan3Image from "@/assets/Plan03.png";
import Plan4Image from "@/assets/Plan04.png";
import Plan5Image from "@/assets/Plan05.png";

const TrainingPlansCarousel = () => {
  return (
    <Box
      width="100%"
      height={228}
      overflow="hidden"
      position="relative"
      sx={{
        mask: "linear-gradient(to right, transparent, white 25%, white 75%, transparent)",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap={1}
        width="max-content"
        sx={{
          "@keyframes scroll": {
            to: { transform: "translate(calc(-50% - 4px))" },
          },
          animation: "scroll 40s forwards linear infinite",
        }}
      >
        <img height={228} width={158} src={Plan1Image} />
        <img height={228} width={158} src={Plan2Image} />
        <img height={228} width={158} src={Plan3Image} />
        <img height={228} width={158} src={Plan4Image} />
        <img height={228} width={158} src={Plan5Image} />
        <img height={228} width={158} src={Plan1Image} />
        <img height={228} width={158} src={Plan2Image} />
        <img height={228} width={158} src={Plan3Image} />
        <img height={228} width={158} src={Plan4Image} />
        <img height={228} width={158} src={Plan5Image} />
      </Box>
    </Box>
  );
};

export default TrainingPlansCarousel;
