import { useMemo } from "react";
import { DateTimeFormatter } from "@WahooFitness/unit-convertr-ts";
import { useLingui } from "@lingui/react";

export function useDateTimeFormatter() {
  const { i18n } = useLingui();
  const dateTimeFormatter = useMemo(() => {
    // @ts-expect-error UnitFormatter is not typed
    return new DateTimeFormatter(i18n);
  }, [i18n]);

  return { dateTimeFormatter };
}
