import { useCallback, useEffect, useState } from "react";
import useMaps, { TilePackState } from "./useMaps";
import { WSMBoltTilePack } from "@WahooFitness/wsm-native/dist/esm/types/bolt_map";

const useEditMaps = (appToken: string, tilePacks: WSMBoltTilePack[]) => {
  const [editing, setEditing] = useState(false);

  const enableEditing = useCallback(() => {
    setEditing(true);
  }, []);

  const disableEditing = useCallback(() => {
    setEditing(false);
  }, []);

  const [mapsToDelete, setMapsToDelete] = useState<number[]>([]);

  const handleMapSelect = useCallback((selected: boolean, tilePackId: number) => {
    if (selected) {
      setMapsToDelete((prev) => [...prev, tilePackId]);
    } else {
      setMapsToDelete((prev) => prev.filter((id) => id !== tilePackId));
    }
  }, []);

  // This keeps track of tile packs for which the delete button has been clicked, but a new state has not been received yet.
  // This is used to reduce the delay between clicking the button and the UI updating.
  const [pendingDeleteTilePackIds, setPendingDeleteTilePackIds] = useState<number[]>([]);

  useEffect(() => {
    for (const tilePackId of pendingDeleteTilePackIds) {
      const tilePack = tilePacks.find((tilePack) => tilePack.id === tilePackId);
      if (
        tilePack &&
        (tilePack.state === TilePackState.REQUESTED_DELETE || tilePack.installedSize === 0)
      ) {
        setPendingDeleteTilePackIds((prev) => prev.filter((id) => id !== tilePackId));
      }
    }
  }, [pendingDeleteTilePackIds, tilePacks]);

  const { sendDeleteMapTilePack } = useMaps(appToken);

  const onDeleteClick = useCallback(() => {
    mapsToDelete.forEach(sendDeleteMapTilePack);
    setMapsToDelete([]);
    setPendingDeleteTilePackIds((prev) => [...prev, ...mapsToDelete]);
    disableEditing();
  }, [disableEditing, mapsToDelete, sendDeleteMapTilePack]);

  return {
    editing,
    enableEditing,
    disableEditing,
    handleMapSelect,
    onDeleteClick,
    pendingDeleteTilePackIds,
  };
};

export default useEditMaps;
