import React from "react";
import { Button, Typography } from "@mui/material";
import { Trans } from "@lingui/macro";
import { postLogOut } from "@WahooFitness/wahoo-offline-mfe";

const LogOutButton: React.FC = () => {
  return (
    <Button
      size="large"
      onClick={() => {
        postLogOut();
      }}
      fullWidth
      variant="outlined"
      color="error"
    >
      <Typography variant="ui-base-medium">
        <Trans>Log out</Trans>
      </Typography>
    </Button>
  );
};

export default LogOutButton;
