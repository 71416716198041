import { Trans, t } from "@lingui/macro";
import { Meter } from "@carbon/icons-react";
import OnboardingHeader from "@/components/Onboarding/OnboardingHeader";
import { Button } from "@mui/material";
import SetUpPacesDrawer from "./SetUpPacesDrawer";
import { Box } from "@mui/system";
import useSetUpPaces from "./useSetUpPaces";

function SetUpPaces() {
  const {
    setupPacesDrawerOpen,
    handleOpen,
    handleClose,
    handleSkip,
    yourPaceZones,
    setYourPaceZones,
    showResultPage,
    setShowResultPage,
    handleBackFromResult,
    handleSave,
  } = useSetUpPaces({ closeAfterSave: false });

  return (
    <>
      <OnboardingHeader
        Icon={Meter}
        title={t`Let’s set up your paces`}
        subtitle={t`Setting up your paces will help us ensure your workouts and training data are tailored to you.`}
      />
      <SetUpPacesDrawer
        open={setupPacesDrawerOpen}
        handleClose={handleClose}
        yourPaceZones={yourPaceZones}
        setYourPaceZones={setYourPaceZones}
        showResultPage={showResultPage}
        setShowResultPage={setShowResultPage}
        handleBackFromResult={handleBackFromResult}
        handleSave={handleSave}
      />
      <Box
        display="flex"
        flexDirection="column"
        rowGap={1}
        p={3}
        mt="auto"
        width="100%"
        maxWidth={"sm"}
      >
        <Button variant="contained" size="large" fullWidth onClick={handleOpen}>
          <Trans>Set Up Paces</Trans>
        </Button>
        <Button variant="contained" color="secondary" size="large" fullWidth onClick={handleSkip}>
          <Trans>Skip</Trans>
        </Button>
      </Box>
    </>
  );
}

export default SetUpPaces;
