import NavHeader from "@/components/NavHeader";
import { t } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import {
  WorkoutAlertIntervalUnitEnum,
  WorkoutAlertIntervalUnitTypeEnum,
  WorkoutAlertTriggerTypeEnum,
  WorkoutAlertType,
} from "@WahooFitness/cloud-client-ts/Types";
import {
  ChevronRightIcon,
  MenuList,
  MenuListItemType,
  MenuListItemVariant,
} from "@WahooFitness/redesignr";
import { useCallback, useMemo } from "react";

const AlertTriggerSelector = ({
  alert,
  backAction,
  onSelection,
  height,
}: {
  alert: WorkoutAlertType;
  backAction: () => void;
  onSelection: (alert: WorkoutAlertType) => void;
  height?: number | string;
}) => {
  const handleSelection = useCallback(
    (trigger_type: number) => {
      let interval: number | undefined = undefined;
      let interval_unit: WorkoutAlertIntervalUnitEnum | undefined = undefined;
      let interval_unit_type: WorkoutAlertIntervalUnitTypeEnum | undefined = undefined;
      if (trigger_type === alert.trigger_type) {
        // this only sets the interval data in the copy of the alert sent to AlertIntervalEditor
        // if the trigger type isn't changed. This prevents it from showing a duration interval
        // as distance and vice versa
        interval = alert.interval;
        interval_unit = alert.interval_unit;
        interval_unit_type = alert.interval_unit_type;
      }
      onSelection({
        ...alert,
        trigger_type,
        interval,
        interval_unit,
        interval_unit_type,
        workout_metric_type_ids: [...alert.workout_metric_type_ids],
      });
    },
    [alert, onSelection]
  );

  const triggerOptions = useMemo(
    () =>
      [
        {
          trigger_type: WorkoutAlertTriggerTypeEnum.duration,
          name: t`Duration`,
          testId: "selectDurationTrigger",
        },
        {
          trigger_type: WorkoutAlertTriggerTypeEnum.distance,
          name: t`Distance`,
          testId: "selectDistanceTrigger",
        },
        {
          trigger_type: WorkoutAlertTriggerTypeEnum.started,
          name: t`Started`,
          testId: "selectStartedTrigger",
        },
        { trigger_type: WorkoutAlertTriggerTypeEnum.lap, name: t`Lap`, testId: "selectLapTrigger" },
        {
          trigger_type: WorkoutAlertTriggerTypeEnum.paused,
          name: t`Paused`,
          testId: "selectPausedTrigger",
        },
        {
          trigger_type: WorkoutAlertTriggerTypeEnum.resumed,
          name: t`Resumed`,
          testId: "selectResumedTrigger",
        },
      ].map(
        (option) =>
          ({
            id: option.trigger_type.toString(),
            content: option.name,
            variant: MenuListItemVariant.Action,
            actionComponent: <ChevronRightIcon data-testid={option.testId} />,
            action: () => handleSelection(option.trigger_type),
          }) as MenuListItemType
      ),
    [handleSelection]
  );

  return (
    <Box height={height} display="flex" flexDirection="column" overflow="hidden">
      <NavHeader
        title={t`Trigger`}
        backText={t`Close`}
        backAction={backAction}
        elevation={1}
        disableBoxShadow
      />
      <Box
        width="100%"
        maxWidth="sm"
        display="flex"
        flexDirection="column"
        alignItems="center"
        overflow="auto"
        px={2}
        gap={1}
      >
        <Typography
          variant="prose-md-medium"
          textAlign="center"
        >{t`What do you want to trigger the alert?`}</Typography>
        <Box width="100%">
          <MenuList listItems={triggerOptions} />
        </Box>
      </Box>
    </Box>
  );
};

export default AlertTriggerSelector;
