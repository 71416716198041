import { useCloudContext, useConfigContext, useOfflineSWR } from "@WahooFitness/wahoo-offline-mfe";
import { AssociatedDevices } from "@WahooFitness/cloud-client-ts";
import { useEffect, useMemo } from "react";
import { ProductTypeEnum } from "@WahooFitness/cloud-client-ts/Types";
import { useWsmPlugin } from "./useWsmPlugin";

const elementProductTypes = [
  ProductTypeEnum.ELEMNT_BOLT,
  ProductTypeEnum.ELEMNT_BOLT2,
  ProductTypeEnum.ELEMNT_ROAM,
  ProductTypeEnum.ELEMNT_ROAM2,
  ProductTypeEnum.ELEMNT_ACE,
];

const useAssociatedDevices = () => {
  const { wahooToken } = useConfigContext();
  const { getCloudClient } = useCloudContext();

  const AssociatedDevicesClient = useMemo(
    () => getCloudClient(AssociatedDevices),
    [getCloudClient]
  );
  const {
    data: associatedDevices,
    isLoading,
    error,
  } = useOfflineSWR(
    () => (wahooToken ? ["associated_devices", wahooToken] : undefined),
    ([_, wahooToken]) => AssociatedDevicesClient.get(wahooToken)
  );

  const { hasBoltPaired, hasRoamPaired, hasAcePaired, checkPairedDeviceTypes } = useWsmPlugin();

  useEffect(() => {
    checkPairedDeviceTypes();
  }, [checkPairedDeviceTypes]);

  const associatedElemntTypes = useMemo(() => {
    return associatedDevices
      ?.filter((device) => elementProductTypes.includes(device.product_type as ProductTypeEnum))
      .map((device) => device.product_type as ProductTypeEnum);
  }, [associatedDevices]);

  const hasAssociatedBolt = useMemo(
    () =>
      associatedElemntTypes?.includes(ProductTypeEnum.ELEMNT_BOLT) ||
      associatedElemntTypes?.includes(ProductTypeEnum.ELEMNT_BOLT2) ||
      hasBoltPaired,
    [associatedElemntTypes, hasBoltPaired]
  );

  const hasAssociatedRoam = useMemo(
    () =>
      associatedElemntTypes?.includes(ProductTypeEnum.ELEMNT_ROAM) ||
      associatedElemntTypes?.includes(ProductTypeEnum.ELEMNT_ROAM2) ||
      hasRoamPaired,
    [associatedElemntTypes, hasRoamPaired]
  );

  const hasAssociatedAce = useMemo(
    () => associatedElemntTypes?.includes(ProductTypeEnum.ELEMNT_ACE) || hasAcePaired,
    [associatedElemntTypes, hasAcePaired]
  );

  const hasAssociatedElemnt = useMemo(
    () => hasAssociatedBolt || hasAssociatedRoam || hasAssociatedAce,
    [hasAssociatedBolt, hasAssociatedRoam, hasAssociatedAce]
  );

  return {
    associatedDevices,
    associatedDevicesIsLoading: isLoading,
    associatedDevicesError: error,
    hasAssociatedBolt,
    hasAssociatedRoam,
    hasAssociatedAce,
    hasAssociatedElemnt,
  };
};

export default useAssociatedDevices;
