import { Box, Button, Dialog, Drawer, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useMemo } from "react";
import NavHeader from "../../NavHeader";
import { t, Trans } from "@lingui/macro";
import { ArrowUpRight } from "@carbon/icons-react";
import { useAnalyticsContext } from "@WahooFitness/wahoo-offline-mfe";
import { publish } from "@ionic/portals";
import TrainingPlansCarousel from "./TrainingPlansCarousel";

const TrainingPlansDialog = ({ open, onClose }: { open: boolean; onClose?: () => void }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const Container = useMemo(() => (isXs ? Drawer : Dialog), [isXs]);

  const { getRemoteConfigString } = useAnalyticsContext();
  const trainingPlansLink = useMemo(
    () =>
      getRemoteConfigString("SYSTM_TRAINING_PLANS_LINK") ||
      "https://systm.wahoofitness.com/training-plans",
    [getRemoteConfigString]
  );

  const onLaunchSystm = useCallback(() => {
    publish({ topic: "openLinkExternally", data: { url: trainingPlansLink } });
  }, [trainingPlansLink]);

  return (
    <Container
      open={open}
      anchor={isXs ? "bottom" : undefined}
      elevation={4}
      PaperProps={{ elevation: 4 }}
    >
      <NavHeader
        title={t`Training Plans`}
        closeIcon
        backAction={onClose}
        disableBoxShadow
        hasDivider
      />
      <Box display="flex" flexDirection="column" py={2} gap={3}>
        <TrainingPlansCarousel />
        <Box display="flex" flexDirection="column" gap={1} px={2}>
          <Typography variant="prose-md-bold">
            <Trans>Elevate your training with Wahoo SYSTM training plans</Trans>
          </Typography>
          <Typography variant="prose-sm">
            <Trans>
              Unlock access to hundreds of powerful training plans crafted by the Wahoo Sports
              Science Team—designed to fuel your journey and reach your goals! Available exclusively
              in the Wahoo SYSTM app.
            </Trans>
          </Typography>
        </Box>
        <Box px={2} width="100%">
          <Button
            variant="contained"
            endIcon={<ArrowUpRight size={24} />}
            onClick={onLaunchSystm}
            size="large"
            fullWidth
          >
            <Trans>Launch Wahoo SYSTM app</Trans>
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default TrainingPlansDialog;
