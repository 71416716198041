import { Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import { MenuList, MenuListItemType } from "@WahooFitness/redesignr";

interface Props {
  sportOptionListItems: MenuListItemType[];
}

function MainSportQuestion({ sportOptionListItems }: Props) {
  return (
    <Box mx={1} display="flex" flexDirection="column">
      <Box mx={4} display="flex" flexDirection="column">
        <Box>
          <Typography variant="prose-base-medium">
            <Trans>What is your main sport?</Trans>
          </Typography>
        </Box>
        <Box>
          <Typography variant="prose-sm" color="text.secondary">
            <Trans>Select all that apply.</Trans>
          </Typography>
        </Box>
      </Box>
      <MenuList listItems={sportOptionListItems} />
    </Box>
  );
}

export default MainSportQuestion;
