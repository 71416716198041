import EditWorkoutPage from "@/components/WorkoutProfiles/WorkoutPages/EditWorkoutPage";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import { useMemo } from "react";
import { useParams } from "react-router";

const EditWorkoutPagePage = () => {
  const params = useParams();
  const { workoutProfiles } = useWorkoutProfilesContext();
  const profileId = Number(params.profileId || 0);
  const profile = useMemo(
    () => workoutProfiles.find((wp) => wp.id === profileId),
    [profileId, workoutProfiles]
  );
  const pageId = Number(params.pageId || 0);
  return <EditWorkoutPage profile={profile} pageId={pageId} />;
};

export default EditWorkoutPagePage;
