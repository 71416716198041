import { Trans } from "@lingui/macro";
import { Box, Button, Typography } from "@mui/material";
import MapList from "./MapList";
import ErrorBoundary from "@/components/ErrorBoundary";
import useUpdateMaps from "./useUpdateMaps";
import { useMemo } from "react";
import useMaps, { TilePackState } from "./useMaps";

interface UpdateMapsEmbedProps {
  appToken: string;
}

const UpdateMapsEmbed = ({ appToken }: UpdateMapsEmbedProps) => {
  const { onUpdateAllClick, isLoading, sortedTilePacks, tilePacksAreUpdating } =
    useUpdateMaps(appToken);

  const hasUpdates = useMemo(
    () =>
      sortedTilePacks.some(
        (tilePack) => tilePack.state === TilePackState.SAVED && tilePack.upgradeSize > 0
      ),
    [sortedTilePacks]
  );

  const {
    mapPack,
    mapPackIsLoading,
    mapsSyncError,
    retrySync,
    sendDownloadMapTilePack,
    sendUpgradeMapTilePack,
  } = useMaps(appToken);

  return (
    <ErrorBoundary
      error={mapsSyncError}
      isLoading={isLoading || mapPackIsLoading}
      onReload={retrySync}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Button size="large" variant="contained" onClick={onUpdateAllClick} disabled={!hasUpdates}>
          <Trans>Update all</Trans>
        </Button>
        <Typography variant="prose-base" textAlign="center">
          {tilePacksAreUpdating ? (
            <Trans>
              Your ELEMNT must remain connected to a Wi-Fi network to update and download maps.
            </Trans>
          ) : (
            <Trans>
              Your ELEMNT must be connected to a Wi-Fi network to update and download maps.
            </Trans>
          )}
        </Typography>
      </Box>
      {sortedTilePacks.length > 0 ? (
        <MapList
          appToken={appToken}
          editing={false}
          onUpdateTilePack={sendUpgradeMapTilePack}
          onAddTilePack={sendDownloadMapTilePack}
          tilePacks={sortedTilePacks}
          wifiNetworkCount={mapPack?.wifiNwCount || 0}
        />
      ) : (
        <Typography textAlign="center">All maps are up to date.</Typography>
      )}
    </ErrorBoundary>
  );
};

export default UpdateMapsEmbed;
