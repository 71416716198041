import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { WorkoutProfiles } from "@WahooFitness/cloud-client-ts";
import {
  WorkoutProfileResponseType,
  WorkoutProfileType,
} from "@WahooFitness/cloud-client-ts/Types";
import {
  useDialogContext,
  useSnackbarContext,
  useConfigContext,
  useCloudContext,
  useNativeMessagingContext,
  useOfflineSWR,
  usePubSubContext,
  useUserContext,
} from "@WahooFitness/wahoo-offline-mfe";
import { t } from "@lingui/macro";
import { debounce, DebouncedFunc } from "lodash";
import { getWorkoutPagesData } from "@/services/getWorkoutPagesData";

interface IWorkoutProfilesContextProps {
  workoutProfiles: WorkoutProfileResponseType[];
  workoutProfilesLoading: boolean;
  workoutProfileNames: string[];
  createProfile: (
    workoutTypeId: number,
    name: string,
    rest?: any
  ) => Promise<WorkoutProfileResponseType | undefined>;
  copyProfile: (profileId: number) => Promise<WorkoutProfileResponseType | undefined>;
  updateProfile: (
    workoutProfileId: number,
    field: keyof WorkoutProfileType,
    value: any
  ) => Promise<boolean>;
  updateProfileWithObject: (
    workoutProfileId: number,
    update: Partial<WorkoutProfileType>
  ) => Promise<boolean>;
  workoutProfilesError?: unknown;
  deleteProfile: (profileId: number) => Promise<void>;
  resetProfile: (profileId: number) => Promise<WorkoutProfileResponseType>;
  mutateProfileState: (profile: WorkoutProfileResponseType) => void;
  refetchProfiles: () => void;
  selectedAssociationList: number[] | undefined;
  setSelectedAssociationList: (associationList?: number[]) => void;
  updateZoneSetAssociations: (
    profileField: keyof WorkoutProfileType,
    zoneSetId: number,
    primaryZoneSetId: number | undefined,
    callback?: () => void
  ) => Promise<void>;
  getWorkoutProfile: (workoutProfileId: number) => WorkoutProfileResponseType | undefined;
  saveWorkoutPagesDebounce: DebouncedFunc<
    (profileId: number, pagesBuffer: Buffer) => Promise<void>
  >;
  saveWorkoutPagesImmediately: (profileId: number, pagesBuffer: Buffer) => Promise<void>;
  disablePubSubUpdates: () => void;
  enablePubSubUpdates: () => void;
  mutateWorkoutProfiles: () => void;
}

export const WorkoutProfilesContext = createContext<IWorkoutProfilesContextProps | undefined>(
  undefined
);

export const WorkoutProfilesContextProvider = ({ children }: any) => {
  const { wahooToken } = useConfigContext();
  const { handleClose } = useDialogContext();
  const { enqueueSnackbar } = useSnackbarContext();
  const { getCloudClient } = useCloudContext();
  const { addRefreshListener, removeRefreshListener } = useNativeMessagingContext();

  // state
  const [selectedAssociationList, setSelectedAssociationList] = useState<number[] | undefined>(
    undefined
  );

  // Data Fetching
  const workoutProfilesClient = useMemo(() => getCloudClient(WorkoutProfiles), [getCloudClient]);

  const {
    data: workoutProfilesData,
    error: workoutProfilesError,
    isLoading: workoutProfilesLoading,
    mutate: mutateWorkoutProfiles,
  } = useOfflineSWR(["workoutProfiles", wahooToken], ([_key, token]) =>
    workoutProfilesClient.get(token, { loadWorkoutAlerts: true })
  );

  useEffect(() => {
    const listenerKey = "reloadWorkoutProfiles";
    addRefreshListener(listenerKey, mutateWorkoutProfiles);
    return () => removeRefreshListener(listenerKey);
  }, [addRefreshListener, mutateWorkoutProfiles, removeRefreshListener]);

  const deleteProfile = useCallback(
    async (profileId: number) => {
      try {
        await workoutProfilesClient.delete(profileId, wahooToken);
        mutateWorkoutProfiles();
        handleClose();
      } catch (err) {
        enqueueSnackbar({
          message: t`An error occurred when deleting your profile.`,
          severity: "error",
        });
      }
    },
    [workoutProfilesClient, wahooToken, mutateWorkoutProfiles, handleClose, enqueueSnackbar]
  );

  const saveWorkoutPagesImmediately = useCallback(
    async (profileId: number, pagesBuffer: Buffer) => {
      try {
        await workoutProfilesClient.updateWorkoutProfileWorkoutPagesFile(
          profileId,
          pagesBuffer,
          wahooToken
        );
        mutateWorkoutProfiles();
      } catch (err) {
        enqueueSnackbar({
          message: t`An error occurred while updating your display pages.`,
          severity: "error",
        });
      }
    },
    [enqueueSnackbar, mutateWorkoutProfiles, wahooToken, workoutProfilesClient]
  );

  const saveWorkoutPagesDebounce = useMemo(
    () => debounce(saveWorkoutPagesImmediately, 5000),
    [saveWorkoutPagesImmediately]
  );

  const generateName = useCallback(
    (name: string): string => {
      const tempName = name;
      if (workoutProfilesData?.some((wp) => wp.name === tempName)) {
        const tempName = `${name} (copy)`;
        return generateName(tempName);
      }
      return tempName;
    },
    [workoutProfilesData]
  );

  const createProfile = useCallback(
    async (
      workoutTypeId: number,
      name: string,
      rest?: any,
      workout_pages_file?: { url: string }
    ) => {
      let newProfile: WorkoutProfileResponseType;
      try {
        const newName = generateName(name);
        // cloud will throw an error if a parameter gets sent as null, setting to undefined does not send them.
        if (rest) {
          Object.keys(rest).forEach((key) => (rest[key] = rest[key] ?? undefined));
        }
        newProfile = await workoutProfilesClient.createWorkoutProfile(
          {
            ...rest,
            name: newName,
            workout_type_id: workoutTypeId,
          },
          wahooToken
        );
        if (workout_pages_file?.url !== undefined) {
          const oldPages = await getWorkoutPagesData(workout_pages_file.url);
          await workoutProfilesClient.updateWorkoutProfileWorkoutPagesFile(
            newProfile.id,
            oldPages,
            wahooToken
          );
        }

        newProfile.workout_alerts = [];
        const newWorkoutProfiles: WorkoutProfileResponseType[] = [...(workoutProfilesData || [])];
        newWorkoutProfiles.push(newProfile);
        mutateWorkoutProfiles(newWorkoutProfiles, {
          revalidate: false,
        });
        return newProfile;
      } catch (err) {
        enqueueSnackbar({
          message: t`An error occurred when saving your profile.`,
          severity: "error",
        });
      }
    },
    [
      generateName,
      workoutProfilesClient,
      wahooToken,
      workoutProfilesData,
      mutateWorkoutProfiles,
      enqueueSnackbar,
    ]
  );

  const copyProfile = useCallback(
    async (profileId: number) => {
      const existingProfile = workoutProfilesData?.find((profile) => profile.id === profileId);
      if (existingProfile) {
        const {
          workout_type_id,
          name,
          id: _id,
          workout_pages_file,
          permanent: _, // if we are copying a profile, it should not be permanent
          ...rest
        } = existingProfile;
        return createProfile(Number(workout_type_id), name, rest, workout_pages_file);
      }
    },
    [createProfile, workoutProfilesData]
  );

  const mutateProfileState = useCallback(
    (newProfile: WorkoutProfileResponseType) => {
      const newWorkoutProfiles: WorkoutProfileResponseType[] = [...(workoutProfilesData || [])];
      const existingProfileIndex =
        workoutProfilesData?.findIndex((profile) => profile.id === newProfile.id) ?? -1;
      if (existingProfileIndex >= 0) {
        newWorkoutProfiles[existingProfileIndex] = newProfile;
      } else {
        newWorkoutProfiles.push(newProfile);
        enqueueSnackbar({
          message: t`Workout Profile added!`,
          severity: "success",
        });
      }
      mutateWorkoutProfiles(newWorkoutProfiles, { revalidate: false });
    },
    [enqueueSnackbar, workoutProfilesData, mutateWorkoutProfiles]
  );

  const updateProfile = useCallback(
    async (profileId: number, field: keyof WorkoutProfileType, value: any) => {
      const existingProfile: WorkoutProfileResponseType | undefined = workoutProfilesData?.find(
        (profile) => profile.id === profileId
      );
      if (existingProfile) {
        try {
          const updatedProfile = await workoutProfilesClient.updateWorkoutProfile(
            existingProfile.id,
            { [field]: value },
            wahooToken
          );
          // the endpoint for updating workout profiles doesn't return alerts,
          // but it also can't be used to update alerts, so they should be the same as before the update.
          updatedProfile.workout_alerts = existingProfile.workout_alerts;
          mutateProfileState(updatedProfile);
          return true;
        } catch (err) {
          enqueueSnackbar({
            message: t`An error occurred when updating your profile.`,
            severity: "error",
          });
        }
      }
      return false;
    },
    [mutateProfileState, enqueueSnackbar, wahooToken, workoutProfilesClient, workoutProfilesData]
  );

  const updateProfileWithObject = useCallback(
    async (profileId: number, update: Partial<WorkoutProfileType>) => {
      const existingProfile: WorkoutProfileResponseType | undefined = workoutProfilesData?.find(
        (profile) => profile.id === profileId
      );
      if (existingProfile) {
        try {
          const updatedProfile = await workoutProfilesClient.updateWorkoutProfile(
            existingProfile.id,
            update,
            wahooToken
          );
          // the endpoint for updating workout profiles doesn't return alerts,
          // but it also can't be used to update alerts, so they should be the same as before the update.
          updatedProfile.workout_alerts = existingProfile.workout_alerts;
          mutateProfileState(updatedProfile);
          return true;
        } catch (err) {
          enqueueSnackbar({
            message: t`An error occurred when updating your profile.`,
            severity: "error",
          });
        }
      }
      return false;
    },
    [mutateProfileState, enqueueSnackbar, wahooToken, workoutProfilesClient, workoutProfilesData]
  );

  const resetProfile = useCallback(
    async (profileId: number) => {
      const updatedProfile = await workoutProfilesClient.reset(profileId, wahooToken);
      updatedProfile.workout_profile.workout_alerts = [];
      mutateProfileState(updatedProfile.workout_profile);
      return updatedProfile.workout_profile;
    },
    [mutateProfileState, wahooToken, workoutProfilesClient]
  );

  const refetchProfiles = useCallback(() => mutateWorkoutProfiles(), [mutateWorkoutProfiles]);

  const updateZoneSetAssociations = useCallback(
    async (
      profileField: keyof WorkoutProfileType,
      zoneSetId: number,
      primaryZoneSetId: number | undefined,
      callback = () => {}
    ) => {
      if (selectedAssociationList) {
        for (const profile of workoutProfilesData || []) {
          // if it's selected and it's already associated, do nothing
          // if it's selected and it's not yet associated, associate it
          if (selectedAssociationList.includes(profile.id) && profile[profileField] !== zoneSetId) {
            await updateProfile(profile.id, profileField, zoneSetId);
          }
          // if it's not selected but is associated with this zone set, change the association to the default
          if (
            !selectedAssociationList.includes(profile.id) &&
            profile[profileField] === zoneSetId
          ) {
            await updateProfile(profile.id, profileField, primaryZoneSetId);
          }
        }
        refetchProfiles();
      }
      callback();
    },
    [refetchProfiles, selectedAssociationList, updateProfile, workoutProfilesData]
  );

  const workoutProfileNames = useMemo(
    () => workoutProfilesData?.map((profile) => profile.name) || [],
    [workoutProfilesData]
  );

  const getWorkoutProfile = useCallback(
    (workoutProfileId: number) => {
      return workoutProfilesData?.find((wp) => wp.id === workoutProfileId);
    },
    [workoutProfilesData]
  );

  const { subscribeToWorkoutProfileUpdates } = usePubSubContext();
  const { currentUser, userIsLoading } = useUserContext();

  const [ignorePubSub, setIgnorePubSub] = useState(false);

  const disablePubSubUpdates = useCallback(() => {
    setIgnorePubSub(true);
  }, []);

  const enablePubSubUpdates = useCallback(() => {
    setIgnorePubSub(false);
  }, []);

  useEffect(() => {
    if (!userIsLoading && currentUser) {
      const subscription = subscribeToWorkoutProfileUpdates(currentUser.id, () => {
        if (!ignorePubSub) {
          refetchProfiles();
        }
      });
      return () => subscription.unsubscribe();
    }
    return () => {};
  }, [currentUser, ignorePubSub, refetchProfiles, subscribeToWorkoutProfileUpdates, userIsLoading]);

  return (
    <WorkoutProfilesContext.Provider
      value={{
        workoutProfiles: workoutProfilesData || [],
        workoutProfilesLoading: workoutProfilesLoading || userIsLoading,
        workoutProfileNames,
        createProfile,
        copyProfile,
        updateProfile,
        updateProfileWithObject,
        workoutProfilesError,
        deleteProfile,
        mutateProfileState,
        resetProfile,
        refetchProfiles,
        selectedAssociationList,
        setSelectedAssociationList,
        updateZoneSetAssociations,
        getWorkoutProfile,
        saveWorkoutPagesImmediately,
        saveWorkoutPagesDebounce,
        disablePubSubUpdates,
        enablePubSubUpdates,
        mutateWorkoutProfiles,
      }}
    >
      {children}
    </WorkoutProfilesContext.Provider>
  );
};
