import { useCallback, useMemo, useState } from "react";
import {
  defaultWorkoutFilterParams,
  FilterParams,
  MAX_DURATION_FILTER,
  MAX_TSS_FILTER,
} from "../FilterBar/FilterParams";
import { t } from "@lingui/macro";
import { PlanGroupLobbyCardType } from "@WahooFitness/redesignr";
import { SortDirection, SortKey, SortParams } from "../FilterBar/SortParams";

type ExtendedPlanGroupLobbyCardType = PlanGroupLobbyCardType & {
  duration: number;
  tss: number;
  createdAt: string;
};

function useWorkoutsFilters(workoutsList: ExtendedPlanGroupLobbyCardType[]) {
  const [sortParams, setSortParams] = useState<SortParams>({
    sortKey: SortKey.ALPHA,
    sortDir: SortDirection.ASC,
  });

  const [filterParams, setFilterParams] = useState<FilterParams>(defaultWorkoutFilterParams);
  const updateSort = useCallback((newSortParams: SortParams) => {
    setSortParams(newSortParams);
  }, []);

  function updateFilter(newFilterParams: Partial<FilterParams>) {
    setFilterParams((currentFilter) => ({ ...currentFilter, ...newFilterParams }));
  }

  function clearFilterParam(filterKey: keyof FilterParams) {
    setFilterParams((currentFilter) => ({ ...currentFilter, [filterKey]: undefined }));
  }

  function clearAllFilters() {
    setFilterParams(defaultWorkoutFilterParams);
  }

  const sortOptions = useMemo(
    () => [
      { id: SortKey.DATE, label: t`Date` },
      { id: SortKey.ALPHA, label: t`A-Z` },
      { id: SortKey.DURATION, label: t`Duration` },
      { id: SortKey.TSS, label: t`TSS®` },
    ],
    []
  );

  const sortDirections = useMemo(
    () => [
      { id: SortDirection.ASC, label: t`Ascending` },
      { id: SortDirection.DESC, label: t`Descending` },
    ],
    []
  );

  const setSortKey = useCallback(
    (newKey: string) => {
      updateSort({
        sortDir: sortParams?.sortDir || SortDirection.DESC,
        sortKey: newKey as SortKey,
      });
    },
    [sortParams?.sortDir, updateSort]
  );

  const setSortDirection = useCallback(
    (newDirection: string) => {
      updateSort({
        sortDir: newDirection as SortDirection,
        sortKey: sortParams?.sortKey || SortKey.DATE,
      });
    },
    [sortParams?.sortKey, updateSort]
  );

  const filteredSortedWorkoutList = useMemo(() => {
    let tempWorkoutsList = [...workoutsList];
    if (filterParams?.search && filterParams.search.length) {
      tempWorkoutsList = tempWorkoutsList.filter((workout) =>
        workout.title.toLocaleLowerCase().includes(filterParams.search!.toLocaleLowerCase())
      );
    }
    if (filterParams.duration?.min_m && filterParams.duration?.min_m > 0) {
      tempWorkoutsList = tempWorkoutsList.filter(
        (workout) => !workout.duration || workout.duration > filterParams.duration!.min_m
      );
    }
    if (filterParams.duration?.max_m && filterParams.duration?.max_m < MAX_DURATION_FILTER) {
      tempWorkoutsList = tempWorkoutsList.filter(
        (workout) => !workout.duration || workout.duration < filterParams.duration!.max_m
      );
    }
    if (filterParams.tss?.min_m && filterParams.tss?.min_m > 0) {
      tempWorkoutsList = tempWorkoutsList.filter(
        (workout) => !workout.tss || workout.tss > filterParams.tss!.min_m
      );
    }
    if (filterParams.tss?.max_m && filterParams.tss?.max_m < MAX_TSS_FILTER) {
      tempWorkoutsList = tempWorkoutsList.filter(
        (workout) => !workout.tss || workout.tss < filterParams.tss!.max_m
      );
    }
    const familyFilterIsDefaultAny =
      filterParams.family?.toString() === defaultWorkoutFilterParams.family.toString();
    if (filterParams.family && filterParams.family.length && !familyFilterIsDefaultAny) {
      tempWorkoutsList = tempWorkoutsList.filter(
        (workout) =>
          workout.workoutFamily !== undefined &&
          filterParams.family?.includes(workout.workoutFamily)
      );
    }
    if (sortParams.sortKey && sortParams.sortDir) {
      switch (sortParams.sortKey) {
        case SortKey.ALPHA:
          tempWorkoutsList = tempWorkoutsList.sort((a, b) => {
            if (a.title.toLocaleLowerCase() < b.title.toLocaleLowerCase()) {
              return -1;
            }
            if (a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase()) {
              return 1;
            }
            return 0;
          });
          break;
        case SortKey.DATE:
          tempWorkoutsList = tempWorkoutsList.sort(
            (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
          break;
        case SortKey.DURATION:
          tempWorkoutsList = tempWorkoutsList.sort((a, b) => (a.duration || 0) - (b.duration || 0));
          break;
        case SortKey.TSS:
          tempWorkoutsList = tempWorkoutsList.sort((a, b) => (a.tss || 0) - (b.tss || 0));
          break;
      }
      if (sortParams.sortDir === SortDirection.DESC) {
        tempWorkoutsList = tempWorkoutsList.reverse();
      }
    }
    return tempWorkoutsList;
  }, [
    workoutsList,
    filterParams.search,
    filterParams.duration,
    filterParams.tss,
    filterParams.family,
    sortParams.sortKey,
    sortParams.sortDir,
  ]);

  const filtersApplied = useMemo(
    () => JSON.stringify(filterParams) !== JSON.stringify(defaultWorkoutFilterParams),
    [filterParams]
  );

  return {
    sortParams,
    filterParams,
    updateSort,
    updateFilter,
    clearFilterParam,
    clearAllFilters,
    filtersApplied,
    sortOptions,
    sortDirections,
    setSortKey,
    setSortDirection,
    filteredSortedWorkoutList,
  };
}

export default useWorkoutsFilters;
