import { useHeaderContext } from "@/hooks/useHeaderContext";
import { t } from "@lingui/macro";
import { Box } from "@mui/material";
import { useEffect, useMemo } from "react";
import useNavUpOnDeviceDisconnect from "../useNavUpOnDeviceDisconnect";
import UpdateMapsEmbed from "./UpdateMapsEmbed";

const UpdateMaps = ({ appToken = "" }: { appToken: string }) => {
  const { setNavHeader } = useHeaderContext();

  const headerProps = useMemo(
    () => ({
      title: t`Update maps`,
    }),
    []
  );

  useEffect(() => {
    setNavHeader(headerProps);
  });

  useNavUpOnDeviceDisconnect(appToken);

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column" p={2} gap={4}>
      <UpdateMapsEmbed appToken={appToken} />
    </Box>
  );
};

export default UpdateMaps;
