import NavHeader from "@/components/NavHeader";
import {
  convertKilometersToMeters,
  convertMetersToKilometers,
  convertMilesToYards,
  convertYardsToMiles,
} from "@/services/convertUnits";
import { truncateToTwoDecimalPlaces } from "@/services/truncateNumbers";
import { ChevronLeft, ChevronRight } from "@carbon/icons-react";
import { t } from "@lingui/macro";
import { Box, Button, TextField, Typography } from "@mui/material";
import {
  ImperialMetricDisplayPreference,
  WorkoutAlertIntervalUnitEnum,
  WorkoutAlertIntervalUnitTypeEnum,
  WorkoutAlertTriggerTypeEnum,
  WorkoutAlertType,
} from "@WahooFitness/cloud-client-ts/Types";
import { MenuList, MenuListItemVariant } from "@WahooFitness/redesignr";
import {
  ChangeEvent,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

const isInvalidNumberChar = (value: string) => {
  return ["-", "e", "!"].includes(value);
};

const identity = (val: number) => val;

const DurationInput = ({
  value,
  onChange,
}: {
  value: number;
  onChange: (value: number) => void;
}) => {
  useEffect(() => {
    onChange(value);
  }, [onChange, value]);
  const [currentMinutesValue, setCurrentMinutesValue] = useState<string | undefined>(
    Math.floor(value / 60).toString()
  );
  const [currentSecondsValue, setCurrentSecondsValue] = useState<string | undefined>(
    (value % 60 || "").toString()
  );
  const handleMinutesChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setCurrentMinutesValue(newValue);
      onChange(+newValue * 60 + +(currentSecondsValue || "0"));
    },
    [currentSecondsValue, onChange]
  );
  const handleSecondsChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (newValue.includes("-") || newValue.includes("e") || newValue.includes("!")) {
        return;
      }
      setCurrentSecondsValue(newValue);
      if (+newValue < 0 || +newValue > 59) {
        onChange(0);
        return;
      }
      onChange(+(currentMinutesValue || "0") * 60 + +newValue);
    },
    [currentMinutesValue, onChange]
  );
  const handleBlur = useCallback(() => {
    setCurrentMinutesValue((minutes) => minutes && Math.round(+minutes).toString());
    setCurrentSecondsValue((seconds) => seconds && Math.round(+seconds).toString());
  }, []);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
    if (isInvalidNumberChar(event.key)) {
      event.preventDefault();
    }
  }, []);

  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" gap={1}>
      <Box>
        <Typography variant="prose-sm">{t`Every`}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" gap={0.5} alignItems="center">
        <Box width={60}>
          <TextField
            size="small"
            type="number"
            value={currentMinutesValue ?? ""}
            onChange={handleMinutesChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              sx: {
                "::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "-moz-appearance": "textfield",
              },
              "data-testid": "minutesInput",
            }}
          />
        </Box>
        <Typography variant="prose-sm" color="text.secondary">{t`minute`}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" gap={0.5} alignItems="center">
        <Box width={60}>
          <TextField
            size="small"
            type="number"
            value={currentSecondsValue ?? ""}
            onChange={handleSecondsChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              sx: {
                "::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "-moz-appearance": "textfield",
              },
              "data-testid": "secondsInput",
            }}
          />
        </Box>
        <Typography variant="prose-sm" color="text.secondary">{t`seconds`}</Typography>
      </Box>
    </Box>
  );
};

const DistanceInput = ({
  value,
  onChange,
  unitPreference = ImperialMetricDisplayPreference.metric,
}: {
  value: number;
  onChange: (value: number) => void;
  unitPreference?: ImperialMetricDisplayPreference;
}) => {
  const [toLargeUnit, toSmallUnit] = useMemo(() => {
    switch (unitPreference) {
      case ImperialMetricDisplayPreference.imperial:
        return [convertYardsToMiles, convertMilesToYards];
      case ImperialMetricDisplayPreference.metric:
        return [convertMetersToKilometers, convertKilometersToMeters];
      default:
        return [identity, identity];
    }
  }, [unitPreference]);

  const [currentValue, setCurrentValue] = useState<number | undefined>(toLargeUnit(value));
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = +event.target.value;
      setCurrentValue(event.target.value === "" ? undefined : newValue);
      onChange(toSmallUnit(newValue));
    },
    [onChange, toSmallUnit]
  );

  const handleBlur = useCallback(() => {
    setCurrentValue((value) => value && +truncateToTwoDecimalPlaces(value));
  }, []);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
    if (isInvalidNumberChar(event.key)) {
      event.preventDefault();
    }
  }, []);

  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" gap={1}>
      <Box>
        <Typography variant="prose-sm">{t`Every`}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" gap={0.5} alignItems="center">
        <Box width={60}>
          <TextField
            size="small"
            type="number"
            value={currentValue}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            inputProps={{
              inputMode: "decimal",
              pattern: "[0-9]*",
              sx: {
                "::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "-moz-appearance": "textfield",
              },
              "data-testid": "distanceInput",
            }}
          />
        </Box>
        <Typography variant="prose-sm" color="text.secondary">
          {unitPreference === ImperialMetricDisplayPreference.metric ? t`km` : t`miles`}
        </Typography>
      </Box>
    </Box>
  );
};

const AlertIntervalEditor = ({
  alert,
  backAction,
  onNext,
  unitPreference,
  height,
}: {
  alert: WorkoutAlertType;
  backAction: () => void;
  onNext: (alert: WorkoutAlertType) => void;
  unitPreference: ImperialMetricDisplayPreference;
  height?: number | string;
}) => {
  const getDefaultValue = useCallback(() => {
    switch (alert.trigger_type) {
      case WorkoutAlertTriggerTypeEnum.duration:
        return 600; // 10 minutes in seconds
      case WorkoutAlertTriggerTypeEnum.distance:
        if (unitPreference === ImperialMetricDisplayPreference.imperial) {
          return 1760; // 1 mile in yards
        }
        return 1000; // 1 km in meters
    }
    // should not be hit
    return 1;
  }, [alert.trigger_type, unitPreference]);

  const [value, setValue] = useState(alert.interval || getDefaultValue());
  const [nextDisabled, setNextDisabled] = useState(false);

  const handleNext = useCallback(() => {
    let interval_unit: WorkoutAlertIntervalUnitEnum;
    switch (unitPreference) {
      case ImperialMetricDisplayPreference.imperial:
        interval_unit = WorkoutAlertIntervalUnitEnum.imperial;
        break;
      case ImperialMetricDisplayPreference.metric:
        interval_unit = WorkoutAlertIntervalUnitEnum.metric;
        break;
      default:
        interval_unit = WorkoutAlertIntervalUnitEnum.metric;
    }

    let interval_unit_type: WorkoutAlertIntervalUnitTypeEnum;
    switch (alert.trigger_type) {
      case WorkoutAlertTriggerTypeEnum.duration:
        interval_unit_type = WorkoutAlertIntervalUnitTypeEnum.sec;
        break;
      case WorkoutAlertTriggerTypeEnum.distance:
        interval_unit_type = WorkoutAlertIntervalUnitTypeEnum.m_yd;
        break;
      default:
        interval_unit_type = WorkoutAlertIntervalUnitTypeEnum.m_yd;
    }
    alert.interval = value;
    alert.interval_unit = interval_unit;
    alert.interval_unit_type = interval_unit_type;
    onNext({
      ...alert,
      workout_metric_type_ids: [...alert.workout_metric_type_ids],
    });
  }, [alert, onNext, unitPreference, value]);

  const valueCap = useMemo(() => {
    switch (alert.trigger_type) {
      case WorkoutAlertTriggerTypeEnum.duration:
        return 600059; //10,000 minutes and 59 seconds
      case WorkoutAlertTriggerTypeEnum.distance:
        return unitPreference === ImperialMetricDisplayPreference.imperial ? 17600000 : 10000000;
    }
    return 0;
  }, [alert.trigger_type, unitPreference]);

  const validateInput = useCallback(
    (val: number) => {
      return val > 0 && val <= valueCap;
    },
    [valueCap]
  );

  const handleChange = useCallback(
    (newValue: number) => {
      const inputValid = validateInput(newValue);
      setNextDisabled(!inputValid);
      if (inputValid) {
        setValue(newValue);
      }
    },
    [validateInput]
  );

  const inputElement = useMemo(() => {
    switch (alert.trigger_type) {
      case WorkoutAlertTriggerTypeEnum.duration:
        return <DurationInput value={value} onChange={handleChange} />;
      case WorkoutAlertTriggerTypeEnum.distance:
        return (
          <DistanceInput value={value} unitPreference={unitPreference} onChange={handleChange} />
        );
    }
  }, [alert.trigger_type, handleChange, unitPreference, value]);

  return (
    <Box height={height} display="flex" flexDirection="column" overflow="hidden">
      <NavHeader
        title={t`Interval`}
        backAction={backAction}
        backText={
          <Box display="flex" flexDirection="row" gap={1}>
            <ChevronLeft height={20} width={20} />
            <Box>{t`Back`}</Box>
          </Box>
        }
        headerAction={
          <Button
            variant="text"
            onClick={handleNext}
            color="info"
            disabled={nextDisabled}
            data-testid="nextButton"
          >
            <Box display="flex" flexDirection="row" gap={1} alignItems="center">
              {t`Next`}
              <ChevronRight height={24} width={24} />
            </Box>
          </Button>
        }
        disableBoxShadow
        elevation={1}
      />
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth="sm"
        alignItems="center"
        overflow="auto"
        flexGrow={1}
        p={2}
        gap={3}
      >
        <Typography
          variant="prose-md-medium"
          textAlign="center"
        >{t`When do you want to play the alert?`}</Typography>
        <MenuList
          listItems={[
            { id: "input", secondaryContent: inputElement, variant: MenuListItemVariant.NoAction },
          ]}
          disableGutters
          width="100%"
        />
      </Box>
    </Box>
  );
};

export default AlertIntervalEditor;
