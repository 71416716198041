import { Zone, ZoneSet } from "./useZones";

const mapValueByZoneSetId = <T>(
  zoneSets: Record<number, ZoneSet>,
  generator: (zoneSet: ZoneSet, zoneSetId: number) => T
) => {
  const valueByZoneSetId = {} as Record<number, T>;
  for (const zoneSetId in zoneSets) {
    const zoneSet = zoneSets[zoneSetId];
    valueByZoneSetId[zoneSetId] = generator(zoneSet, +zoneSetId);
  }
  return valueByZoneSetId;
};

const copyZones = (zoneSets: Record<number, ZoneSet>) => {
  const newZoneSets = {} as Record<number, ZoneSet>;
  for (const zoneId in zoneSets) {
    newZoneSets[zoneId] = {
      ...zoneSets[zoneId],
      zones: [...zoneSets[zoneId].zones.map((zone: Zone) => ({ ...zone }))],
    };
  }
  return newZoneSets;
};

export { mapValueByZoneSetId, copyZones };
