import { Box, Button } from "@mui/material";
import { Trans } from "@lingui/macro";
import { useCallback } from "react";

const NewOrExistingToggle = ({
  newOrExistingToggle,
  setNewOrExistingToggle,
}: {
  newOrExistingToggle: "newProfile" | "existingProfile";
  setNewOrExistingToggle: (newToggleState: "newProfile" | "existingProfile") => void;
}) => {
  const clickNew = useCallback(() => {
    setNewOrExistingToggle("newProfile");
  }, [setNewOrExistingToggle]);

  const clickDuplicate = useCallback(() => {
    setNewOrExistingToggle("existingProfile");
  }, [setNewOrExistingToggle]);

  return (
    <Box px={2} pt={2} width="100%" display="flex" flexDirection="row" gap={1}>
      <Button
        sx={{ padding: 3 }}
        fullWidth
        variant={newOrExistingToggle === "newProfile" ? "contained" : "text"}
        onClick={clickNew}
      >
        <Trans>New profile</Trans>
      </Button>
      <Button
        sx={{ padding: 3 }}
        fullWidth
        variant={newOrExistingToggle === "existingProfile" ? "contained" : "text"}
        onClick={clickDuplicate}
      >
        <Trans>Duplicate profile</Trans>
      </Button>
    </Box>
  );
};
export default NewOrExistingToggle;
