import FullScreenDialog from "@/components/FullScreenDialog";
import { ReactRouterLinkWrapper } from "@/components/MenuList/ReactRouterLinkWrapper";
import { hasMinProfile } from "@/services/profileService";
import {
  ImperialMetricDisplayPreference,
  Profile,
  WahooWorkoutType,
  WorkoutProfileResponseType,
} from "@WahooFitness/cloud-client-ts/Types";
import {
  MenuListItemVariant,
  SettingsOption,
  WorkoutProfileSettingsSection,
} from "@WahooFitness/redesignr";
import { useAnalyticsContext, useUserContext } from "@WahooFitness/wahoo-offline-mfe";
import { t } from "@lingui/macro";
import React, { useCallback, useMemo, useState } from "react";
import AlertList, { EditButton } from "../Alerts/AlertList";
import useEditWorkoutProfile from "./useEditWorkoutProfile";
import { useLingui } from "@lingui/react";

interface Props {
  profile?: WorkoutProfileResponseType;
  hasOwnHeader?: boolean;
  hasElemntPaired?: boolean;
}

const WorkoutSettingsSection: React.FC<Props> = ({
  profile,
  hasOwnHeader,
  hasElemntPaired = false,
}) => {
  const { currentUser } = useUserContext();
  const { handleFieldChange, handleAutoLapChange } = useEditWorkoutProfile(profile);
  const { getRemoteConfigString } = useAnalyticsContext();
  const distanceUnit =
    currentUser?.display_preference?.speed_distance ?? ImperialMetricDisplayPreference.metric;

  const userAppProfile = useMemo(() => currentUser?.app?.profile, [currentUser?.app?.profile]);

  const workoutAlertsMinProfile = getRemoteConfigString("WORKOUT_ALERTS_MIN_PROFILE") ?? "alpha";

  const workoutProfileSettingsOptions = useMemo(
    () => ({
      [SettingsOption.COUNTDOWN]: () => true,
      [SettingsOption.AUDIO_ANNOUNCEMENTS]: () =>
        hasMinProfile(workoutAlertsMinProfile as Profile, userAppProfile),
      [SettingsOption.AUTO_PAUSE]: (profile: WorkoutProfileResponseType) =>
        profile.workout_type_family_id !== 9,
      [SettingsOption.AUTO_LAP]: (profile: WorkoutProfileResponseType) =>
        profile.workout_type_id === undefined ||
        ![WahooWorkoutType.SwimmingLap].includes(profile.workout_type_id),
      [SettingsOption.GPS_ENABLED]: (profile: WorkoutProfileResponseType) =>
        profile.workout_type_location_id !== 0,
    }),
    [userAppProfile, workoutAlertsMinProfile]
  );

  const [alertsDialogOpen, setAlertsDialogOpen] = useState(false);

  const openAlertsDialog = useCallback(() => {
    setAlertsDialogOpen(true);
  }, []);

  const closeAlertsDialog = useCallback(() => {
    setAlertsDialogOpen(false);
  }, []);

  const [editingAlerts, setEditingAlerts] = useState(false);

  const toggleEditingAlerts = useCallback(() => {
    setEditingAlerts((value) => !value);
  }, []);

  const { i18n } = useLingui();

  const alertHeaderProps = useMemo(
    () => ({
      title: t`Alerts`,
      headerAction: <EditButton editing={editingAlerts} toggleEditing={toggleEditingAlerts} />,
      backAction: closeAlertsDialog,
    }),
    [closeAlertsDialog, editingAlerts, toggleEditingAlerts]
  );

  if (!profile) {
    return null;
  }

  return (
    <>
      <WorkoutProfileSettingsSection
        initialWorkoutProfileSettingsConfig={profile}
        onFieldChange={handleFieldChange}
        onAutoLapChange={handleAutoLapChange}
        userDistanceDisplayPreference={distanceUnit}
        workoutProfileSettingsOptions={workoutProfileSettingsOptions}
        // @ts-expect-error Not an issue. Comes from lingui version mismatch, even though the types are the same.
        i18nContext={i18n}
        alertsConfigVariant={
          hasOwnHeader ? MenuListItemVariant.Action : MenuListItemVariant.InternalLink
        }
        alertsConfigPath={`/workout-profiles/${profile.id}/alerts`}
        alertsConfigAction={openAlertsDialog}
        linkComponent={ReactRouterLinkWrapper}
        hasElemntPaired={hasElemntPaired}
        title={hasElemntPaired ? t`Workout settings` : undefined}
      />
      <FullScreenDialog
        slideDirection="up"
        open={alertsDialogOpen}
        handleClose={closeAlertsDialog}
        headerProps={alertHeaderProps}
      >
        <AlertList hasOwnHeader workoutProfileId={profile.id} editing={editingAlerts} />
      </FullScreenDialog>
    </>
  );
};

export default WorkoutSettingsSection;
