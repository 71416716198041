import { Box, List, ListItem, Paper } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import ErrorBoundary from "../ErrorBoundary";
import AboutYou from "./AboutYou/AboutYou";
import UserCard from "./UserCard";
import { t } from "@lingui/macro";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import { PullToRefresh, useUserContext } from "@WahooFitness/wahoo-offline-mfe";

const User = () => {
  const { currentUser, userIsLoading, userError, mutateUser } = useUserContext();
  const { setNavHeader } = useHeaderContext();

  const handleReload = useCallback(() => {
    mutateUser();
  }, [mutateUser]);

  const headerProps = useMemo(() => ({ title: t`Profile` }), []);
  useEffect(() => {
    setNavHeader(headerProps);
  });

  return (
    <ErrorBoundary error={userError} isLoading={userIsLoading} onReload={handleReload}>
      <PullToRefresh onRefresh={handleReload} />
      {currentUser && (
        <Box p={2}>
          <List disablePadding component={Box} display="flex" flexDirection="column" gap={1}>
            <ListItem component={Paper} sx={{ p: 2 }}>
              <Box display="flex" flexDirection="column" gap={4} width="100%">
                <UserCard user={currentUser} />
                <AboutYou user={currentUser} />
              </Box>
            </ListItem>
          </List>
        </Box>
      )}
    </ErrorBoundary>
  );
};

export default User;
