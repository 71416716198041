import {
  Box,
  Button,
  FilledInput,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { ZoneSet } from "./useZones";
import { Trans, t } from "@lingui/macro";
import { InfoIcon } from "@WahooFitness/redesignr";
import { useDialogContext } from "@WahooFitness/wahoo-offline-mfe";
import { useUnitFormatter } from "@/hooks/useUnitFormatter";
import FlexibleDurationInput from "../StyledComponents/FlexibleDurationInput";

const AutoCalculate = ({
  variant,
  editable,
  zoneSet,
  disabled,
  zoneCount,
  maxValue = Infinity,
  handleAutoCalcZones,
}: {
  defaultValue?: number;
  variant: "ftp" | "thr" | "threshold_speed";
  editable: boolean;
  zoneSet: ZoneSet;
  disabled?: boolean;
  zoneCount: number;
  maxValue?: number;
  handleAutoCalcZones: (zoneSet: ZoneSet, params: Record<string, number>) => Promise<void>;
}) => {
  const { convertPaceToMetersPerSecond, unitFormatter } = useUnitFormatter();
  const [value, setValue] = useState<number | undefined>(undefined);
  const [displayValue, setDisplayValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    switch (variant) {
      case "ftp":
        setValue(zoneSet.ftp);
        setDisplayValue(zoneSet.ftp?.toString());
        break;
      case "thr":
        setValue(zoneSet.threshold_heart_rate);
        setDisplayValue(zoneSet.threshold_heart_rate?.toString());
        break;
      case "threshold_speed": {
        setValue(zoneSet.threshold_speed);
        zoneSet.threshold_speed &&
          setDisplayValue(unitFormatter?.formatRunningPace(zoneSet.threshold_speed).rawValue);
      }
    }
  }, [variant, zoneSet.ftp, zoneSet.threshold_heart_rate, zoneSet.threshold_speed, unitFormatter]);

  const handleValueChange = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDisplayValue(ev.target.value ?? undefined);
    if (variant === "threshold_speed") {
      const metersPerSecond = convertPaceToMetersPerSecond(ev.target.value);
      setValue(metersPerSecond);
    } else if (!isNaN(+ev.target.value)) {
      setValue(+ev.target.value);
    }
  };

  const handleClickButton = useCallback(async () => {
    if (value && value > 0) {
      const paramName = variant === "thr" ? "threshold_heart_rate" : variant;
      await handleAutoCalcZones(zoneSet, { [paramName]: value, zone_count: zoneCount });
      setValue(Math.round(value));
    }
  }, [handleAutoCalcZones, value, zoneSet, variant, zoneCount]);

  const { setDialog, handleClose } = useDialogContext();

  const navigateToHelpPage = useCallback(() => {
    window.open("https://wahoosystm.com/THRLearnMore");
  }, []);

  const handleClickInfo = useCallback(() => {
    setDialog({
      open: true,
      actions: [
        { text: t`Learn more`, color: "info", action: navigateToHelpPage },
        { text: t`Done`, action: handleClose },
      ],
      title: t`What is THR?`,
      body: t`THR (Threshold Heart Rate) represents the maximal sustainable heart rate for steady-rate efforts lasting 30-75 minutes.`,
    });
  }, [handleClose, navigateToHelpPage, setDialog]);

  const variantName = useMemo(() => {
    switch (variant) {
      case "ftp":
        return t`FTP`;
      case "thr":
        return t`THR`;
      default:
        return t`Threshold pace`;
    }
  }, [variant]);
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={1}
      justifyContent={editable ? "flex-start" : "space-between"}
      alignItems="center"
      width="100%"
      py={0.5}
    >
      {editable ? (
        <>
          <Box width="50%">
            {variant === "threshold_speed" ? (
              <FormControl fullWidth variant="outlined">
                <FilledInput
                  placeholder={t`mm:ss`}
                  hiddenLabel
                  fullWidth
                  disableUnderline
                  value={displayValue}
                  onChange={handleValueChange}
                  size="medium"
                  inputComponent={FlexibleDurationInput as any}
                  inputProps={{
                    inputMode: "numeric",
                    "data-testid": "autocalcTextInput",
                  }}
                  aria-describedby="pace-helper-text"
                />
              </FormControl>
            ) : (
              <TextField
                variant="outlined"
                type="number"
                value={displayValue}
                onChange={handleValueChange}
                label={variantName}
                size="medium"
                InputLabelProps={{ shrink: value !== undefined }}
                inputProps={{ "data-testid": "autocalcTextInput" }}
                fullWidth
                helperText={
                  value !== undefined && (+value > maxValue || +value < 1)
                    ? t`Enter a value between 1 and ${maxValue}`
                    : undefined
                }
                error={value !== undefined && (+value > maxValue || +value < 1)}
              />
            )}
          </Box>
          <Box width="50%">
            <Button
              variant="outlined"
              size="large"
              fullWidth
              onClick={handleClickButton}
              disabled={disabled || !value || value <= 0 || value > maxValue}
              data-testid="autocalculateButton"
            >
              <Trans>Auto calculate</Trans>
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">
            <Typography variant="ui-base">{variantName}</Typography>
            {variant === "thr" && (
              <IconButton onClick={handleClickInfo} size="small">
                <InfoIcon color="info" />
              </IconButton>
            )}
          </Box>
          <Typography variant="ui-base">{displayValue}</Typography>
        </>
      )}
    </Box>
  );
};

export default AutoCalculate;
