import { Box, Button } from "@mui/material";
import { PropsWithChildren, ReactElement } from "react";
import PageLoadError from "./PageLoadError";
import WarmingUp from "./WarmUp";
import { Trans } from "@lingui/macro";

type Props = {
  error?: any;
  isLoading: boolean;
  fallback?: ReactElement;
  onReload?: () => void;
};

const ErrorBoundary = ({
  children,
  error,
  isLoading,
  fallback = <PageLoadError isLoading={isLoading} />,
  onReload,
}: PropsWithChildren<Props>) => {
  if (error) {
    return (
      <>
        {fallback}
        {onReload && (
          <Box mx={4} my={3}>
            <Button size="large" variant="contained" onClick={onReload} fullWidth>
              <Trans>Reload</Trans>
            </Button>
          </Box>
        )}
      </>
    );
  }

  if (isLoading) {
    return <WarmingUp noTopSpacing fullHeight />;
  }

  return <>{children}</>;
};

export default ErrorBoundary;
