import { t } from "@lingui/macro";
import SlidingDrawer from "../StyledComponents/SlidingDrawer";
import { PropsWithChildren, useCallback, useMemo } from "react";
import {
  ChevronRightIcon,
  MenuList,
  MenuListItemType,
  MenuListItemVariant,
} from "@WahooFitness/redesignr";
import { Box } from "@mui/material";
import { postEvent, postRoute } from "@WahooFitness/wahoo-offline-mfe";
import { useTheme } from "@mui/system";
import { ReactRouterLinkWrapper } from "../MenuList/ReactRouterLinkWrapper";

interface AddRouteDrawerProps {
  open: boolean;
  handleClose: () => void;
}

function AddRouteDrawer({ open, handleClose }: PropsWithChildren<AddRouteDrawerProps>) {
  const { palette } = useTheme();

  const handleImportRoute = useCallback(() => {
    postEvent("importRoute", {});
    handleClose();
  }, [handleClose]);

  const handleCreateFromHistory = useCallback(() => {
    postRoute("history");
    handleClose();
  }, [handleClose]);

  const listItems: MenuListItemType[] = useMemo(
    () => [
      {
        id: "importRoute",
        content: t`Import a file`,
        secondaryContent: t`Supported file types: .fit, .gpx, and .tcx`,
        variant: MenuListItemVariant.Action,
        action: handleImportRoute,
        actionComponent: <ChevronRightIcon sx={{ color: palette.text.secondary }} />,
      },
      {
        id: "createFromHistory",
        content: t`Create from history`,
        secondaryContent: t`Turn a previous ride into a route`,
        variant: MenuListItemVariant.Action,
        action: handleCreateFromHistory,
        actionComponent: <ChevronRightIcon sx={{ color: palette.text.secondary }} />,
      },
      {
        id: "authorizedApps",
        content: t`Authorized apps`,
        secondaryContent: t`Sync your routes from your preferred route providers`,
        linkLocation: `/third-party-sharing`,
        variant: MenuListItemVariant.InternalLink,
        linkComponent: ReactRouterLinkWrapper,
      },
      {
        id: "publicRoutes",
        content: t`Public routes`,
        secondaryContent: t`Find a nearby route...`,
        linkLocation: `/routes/public`,
        variant: MenuListItemVariant.InternalLink,
        linkComponent: ReactRouterLinkWrapper,
      },
    ],
    [handleCreateFromHistory, handleImportRoute, palette.text.secondary]
  );
  return (
    <SlidingDrawer
      open={open}
      title={t`Add a route`}
      handleOpen={() => {}}
      handleClose={handleClose}
      centerTitle
      hidePuller
    >
      <Box py={2} px={1}>
        {listItems.map((item) => (
          <MenuList key={item.id} listItems={[item]} />
        ))}
      </Box>
    </SlidingDrawer>
  );
}

export default AddRouteDrawer;
