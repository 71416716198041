import { MenuListItemType, MenuListItemVariant } from "@WahooFitness/redesignr";
import { t } from "@lingui/macro";
import { useCallback, useMemo, useState } from "react";
import { useTheme } from "@mui/system";
import { Checkmark } from "@carbon/icons-react";
import { useUserContext, useConfigContext, useCloudContext } from "@WahooFitness/wahoo-offline-mfe";
import { PaceZoneAlgorithmLookups } from "@WahooFitness/cloud-client-ts";
import {
  PaceZoneAlgorithmLookupsResponseType,
  PaceZoneSurveyAlgorithmType,
} from "@WahooFitness/cloud-client-ts/Types";

function useNoBestKnownTimesInput(
  setPaceResultsAvailable: (value: boolean) => void,
  setYourPaceZones: (paceZones: PaceZoneAlgorithmLookupsResponseType) => void
) {
  const { palette } = useTheme();
  const { currentUser } = useUserContext();
  const { wahooToken } = useConfigContext();
  const { getCloudClient } = useCloudContext();
  const paceZoneAlgorithmLookupsClient = useMemo(
    () => getCloudClient(PaceZoneAlgorithmLookups),
    [getCloudClient]
  );

  const averageTimeOptions = useMemo(
    () => [
      { name: t`Less than 0.5 hours per week`, value: 0 },
      { name: t`0.5 - 2 hours per week`, value: 1 },
      { name: t`2 - 6 hours per week`, value: 2 },
      { name: t`6 - 10 hours per week`, value: 3.5 },
      { name: t`More than 10 hours per week`, value: 6 },
    ],
    []
  );

  const averageIntensityOptions = useMemo(
    () => [
      { name: t`No high intensity`, value: 0.9 },
      { name: t`25% of the time`, value: 1 },
      { name: t`50% of time`, value: 1.4 },
      { name: t`75% of time`, value: 1.7 },
      { name: t`All high intensity`, value: 2.2 },
    ],
    []
  );

  const moderate5kmOptions = useMemo(
    () => [
      { name: t`Walking all of it`, value: 1 },
      { name: t`Walking the majority of it`, value: 2 },
      { name: t`Jogging half and walking half`, value: 4 },
      { name: t`Jogging all of it`, value: 7 },
      { name: t`Running half and jogging half`, value: 10 },
      { name: t`Running all of it`, value: 13 },
    ],
    []
  );

  const [averageTimeAnswer, setAverageTimeAnswer] = useState<number | undefined>(undefined);
  const [averageIntensityAnswer, setAverageIntensityAnswer] = useState<number | undefined>(
    undefined
  );
  const [moderate5kmAnswer, setModerate5kmAnswer] = useState<number | undefined>(undefined);

  const nextDisabled = useMemo(
    () =>
      averageTimeAnswer === undefined ||
      !averageIntensityAnswer ||
      !moderate5kmAnswer ||
      !currentUser ||
      !currentUser.birth ||
      !currentUser.gender === undefined ||
      !currentUser.height ||
      !currentUser.weight,
    [averageIntensityAnswer, averageTimeAnswer, currentUser, moderate5kmAnswer]
  );

  const averageTimeListItems: MenuListItemType[] = useMemo(
    () =>
      averageTimeOptions.map(({ name, value }) => ({
        id: value.toString(),
        variant: MenuListItemVariant.Action,
        content: name,
        action: () => setAverageTimeAnswer(value),
        actionComponent:
          averageTimeAnswer === value ? <Checkmark size={24} color={palette.info.main} /> : null,
      })),
    [averageTimeAnswer, averageTimeOptions, palette.info.main]
  );

  const averageIntensityListItems: MenuListItemType[] = useMemo(
    () =>
      averageIntensityOptions.map(({ name, value }) => ({
        id: value.toString(),
        variant: MenuListItemVariant.Action,
        content: name,
        action: () => setAverageIntensityAnswer(value),
        actionComponent:
          averageIntensityAnswer === value ? (
            <Checkmark size={24} color={palette.info.main} />
          ) : null,
      })),
    [averageIntensityAnswer, averageIntensityOptions, palette.info.main]
  );

  const moderate5kmListItems: MenuListItemType[] = useMemo(
    () =>
      moderate5kmOptions.map(({ name, value }) => ({
        id: value.toString(),
        variant: MenuListItemVariant.Action,
        content: name,
        action: () => setModerate5kmAnswer(value),
        actionComponent:
          moderate5kmAnswer === value ? <Checkmark size={24} color={palette.info.main} /> : null,
      })),
    [moderate5kmAnswer, moderate5kmOptions, palette.info.main]
  );

  const lookupPaceZones = useCallback(
    async (paceZoneSurveyAlgorithm: PaceZoneSurveyAlgorithmType) => {
      if (wahooToken) {
        const paceZoneAlgorithmLookupsResult = await paceZoneAlgorithmLookupsClient.postSurveyBased(
          wahooToken,
          paceZoneSurveyAlgorithm
        );
        setYourPaceZones(paceZoneAlgorithmLookupsResult);
      }
    },
    [paceZoneAlgorithmLookupsClient, setYourPaceZones, wahooToken]
  );

  const getAge = useCallback((birthDateString: string): number => {
    const birthDate = new Date(birthDateString);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    return m < 0 || (m === 0 && today.getDate() < birthDate.getDate()) ? age - 1 : age;
  }, []);

  const handlePaceLookup = useCallback(() => {
    const convertedValues: PaceZoneSurveyAlgorithmType = {
      q1: averageTimeAnswer,
      q2: averageIntensityAnswer,
      q3: moderate5kmAnswer,
      gender: currentUser?.gender ?? undefined,
      age: currentUser?.birth ? getAge(currentUser?.birth) : undefined,
      height: currentUser?.height ? Number(currentUser?.height) : undefined,
      weight: currentUser?.weight ? Number(currentUser?.weight) : undefined,
    };
    lookupPaceZones(convertedValues);
  }, [
    averageIntensityAnswer,
    averageTimeAnswer,
    currentUser?.birth,
    currentUser?.gender,
    currentUser?.height,
    currentUser?.weight,
    getAge,
    lookupPaceZones,
    moderate5kmAnswer,
  ]);

  const handleApply = useCallback(() => {
    handlePaceLookup();
    setPaceResultsAvailable(true);
  }, [handlePaceLookup, setPaceResultsAvailable]);

  return {
    averageTimeListItems,
    averageIntensityListItems,
    moderate5kmListItems,
    currentUser,
    handleApply,
    nextDisabled,
  };
}

export default useNoBestKnownTimesInput;
