import { WarningAlt } from "@carbon/icons-react";
import { Box, Typography } from "@mui/material";
import WarmingUp from "./WarmUp";
import { Trans } from "@lingui/macro";

const PageLoadError = ({ isLoading = false }: { isLoading?: boolean }) => {
  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      {isLoading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="fixed"
          height="100%"
          width="100%"
        >
          <WarmingUp noTopSpacing />
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        gap={1}
      >
        <Typography color={"warning.main"}>
          <WarningAlt height={30} width={30} />
        </Typography>
        <Typography variant="prose-md">
          <Trans>Page failed to load</Trans>
        </Typography>
      </Box>
    </Box>
  );
};

export default PageLoadError;
