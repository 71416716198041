import { Trans } from "@lingui/macro";
import { Button, Divider, SelectChangeEvent } from "@mui/material";
import { Box } from "@mui/system";
import { ChangeEvent, useCallback } from "react";
import BestKnownTimesInputDuration from "./BestKnownTimesInputDuration";
import BestKnownTimesInputDistance from "./BestKnownTimesInputDistance";
import { DistanceOption } from "./useBestKnownTimesInput";

type BestKnownTimesInputFieldsProps = {
  option: string;
  index: number;
  handleDistanceChange: (event: SelectChangeEvent, index: number) => void;
  handleTimeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleRemove: (index: number) => void;
  distanceOptions: DistanceOption[];
  bestKnownTimeValues: Record<string, string>;
  displayedDistanceOptions: string[];
};

const BestKnownTimesInputFields: React.FC<BestKnownTimesInputFieldsProps> = ({
  option,
  index,
  handleDistanceChange,
  handleTimeChange,
  handleRemove,
  distanceOptions,
  bestKnownTimeValues,
  displayedDistanceOptions,
}: BestKnownTimesInputFieldsProps) => {
  const handleRemoveCallback = useCallback(() => {
    handleRemove(index);
  }, [index, handleRemove]);
  return (
    <>
      <Box key={option} display="flex" flexDirection="column" rowGap={1} p={2}>
        <BestKnownTimesInputDistance
          option={option}
          index={index}
          handleDistanceChange={handleDistanceChange}
          distanceOptions={distanceOptions}
          displayedDistanceOptions={displayedDistanceOptions}
        />
        <BestKnownTimesInputDuration
          option={option}
          bestKnownTimeValues={bestKnownTimeValues}
          handleTimeChange={handleTimeChange}
        />
        {displayedDistanceOptions.length > 1 && (
          <Button color="error" onClick={handleRemoveCallback}>
            <Trans>Remove</Trans>
          </Button>
        )}
      </Box>
      <Divider />
    </>
  );
};

export default BestKnownTimesInputFields;
