import DurationInput from "@/components/StyledComponents/DurationInput";
import { t, Trans } from "@lingui/macro";
import { FilledInput, FormControl, InputLabel } from "@mui/material";
import { ChangeEvent } from "react";

type BestKnownTimesInputDurationProps = {
  option: string;
  bestKnownTimeValues: Record<string, string>;
  handleTimeChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const BestKnownTimesInputDuration = ({
  option,
  bestKnownTimeValues,
  handleTimeChange,
}: BestKnownTimesInputDurationProps) => {
  return (
    <FormControl fullWidth variant="standard">
      <InputLabel shrink sx={{ ml: 1.5, mt: 1 }}>
        <Trans>Time</Trans>
      </InputLabel>
      <FilledInput
        placeholder={t`h:mm:ss`}
        name={option}
        value={bestKnownTimeValues[option as keyof typeof bestKnownTimeValues]}
        onChange={handleTimeChange}
        inputComponent={DurationInput as any}
        inputProps={{ inputMode: "numeric" }}
      />
    </FormControl>
  );
};

export default BestKnownTimesInputDuration;
