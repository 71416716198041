import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

const useConditionalNavigateUp = (
  key: string,
  callback: (...params: any[]) => boolean,
  params: any[]
) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (callback(...params) || location.state?.[key]) {
      navigate("..", { state: { [key]: true }, relative: "path", replace: true });
    }
  }, [callback, key, location.state, navigate, params]);
};

export default useConditionalNavigateUp;
