import React, { useCallback, useMemo, useState } from "react";
import {
  WahooWorkoutTypeFamily,
  WorkoutProfileResponseType,
} from "@WahooFitness/cloud-client-ts/Types";
import { MenuList, MenuListItemType, MenuListItemVariant } from "@WahooFitness/redesignr";
import useZones from "@/components/Zones/useZones";
import { ChevronRightIcon } from "@WahooFitness/redesignr/Icons";
import ZoneSelect from "./ZoneSelect";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import { t } from "@lingui/macro";
import { ReactRouterLinkWrapper } from "@/components/MenuList/ReactRouterLinkWrapper";

interface Props {
  profile?: WorkoutProfileResponseType;
}

const ZonesSection: React.FC<Props> = ({ profile }) => {
  const { zoneSets: powerZoneSets, isLoading: powerIsLoading } = useZones("power");
  const { zoneSets: hrZoneSets, isLoading: hrIsLoading } = useZones("heartRate");
  const { zoneSets: paceZoneSets, isLoading: paceIsLoading } = useZones("speed");

  const { updateProfile } = useWorkoutProfilesContext();

  const { powerZoneName, hrZoneName, paceZoneName } = useMemo(() => {
    const powerZoneName = profile?.power_zone_id ? powerZoneSets[profile.power_zone_id]?.name : "";
    const hrZoneName = profile?.heart_rate_zone_id
      ? hrZoneSets[profile.heart_rate_zone_id]?.name
      : "";
    const primaryPaceZoneName = Object.values(paceZoneSets).find(
      (zoneSet) => !!zoneSet.primary
    )?.name;
    const paceZoneName = profile?.pace_zone_id
      ? paceZoneSets[profile.pace_zone_id]?.name
      : primaryPaceZoneName;
    return { powerZoneName, hrZoneName, paceZoneName };
  }, [hrZoneSets, paceZoneSets, powerZoneSets, profile]);

  const [openZoneDrawer, setOpenZoneDrawer] = useState<boolean>(false);
  const [zoneDrawerMode, setZoneDrawerMode] = useState<"hr" | "power" | "speed">("hr");

  const sectionItems = useMemo(() => {
    const baseItems: MenuListItemType[] = [
      {
        id: "heartRateZone",
        content: t`Heart rate zone`,
        secondaryContent: hrZoneName,
        variant: MenuListItemVariant.Action,
        actionComponent: <ChevronRightIcon sx={{ color: "action.active" }} />,
        action: () => {
          setZoneDrawerMode("hr");
          setOpenZoneDrawer(true);
        },
      },
      {
        id: "powerZone",
        content: t`Power zone`,
        secondaryContent: powerZoneName,
        variant: MenuListItemVariant.Action,
        actionComponent: <ChevronRightIcon sx={{ color: "action.active" }} />,
        action: () => {
          setZoneDrawerMode("power");
          setOpenZoneDrawer(true);
        },
      },
    ];
    const noPaceZonesListItem = {
      id: "noPaceZones",
      content: t`Running pace zone`,
      secondaryContent: t`Click here to create pace zones`,
      variant: MenuListItemVariant.InternalLink,
      linkLocation: "/pace-zones",
      linkComponent: ReactRouterLinkWrapper,
    };
    const paceZoneItem = {
      id: "paceZone",
      content: t`Running pace zone`,
      secondaryContent: paceZoneName,
      variant: MenuListItemVariant.Action,
      actionComponent: <ChevronRightIcon sx={{ color: "action.active" }} />,
      action: () => {
        setZoneDrawerMode("speed");
        setOpenZoneDrawer(true);
      },
    };
    const renderedPaceZoneListItem = paceZoneName ? paceZoneItem : noPaceZonesListItem;
    if (profile?.workout_type_family_id === WahooWorkoutTypeFamily.Running) {
      baseItems.push(renderedPaceZoneListItem);
    }
    return baseItems;
  }, [hrZoneName, powerZoneName, paceZoneName, profile?.workout_type_family_id]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const zoneId = +(event.target as HTMLInputElement).value;
      setOpenZoneDrawer(false);
      if (!profile) {
        return;
      }
      if (zoneDrawerMode === "hr") {
        updateProfile(profile.id, "heart_rate_zone_id", zoneId);
      } else if (zoneDrawerMode === "power") {
        updateProfile(profile.id, "power_zone_id", zoneId);
      } else if (zoneDrawerMode === "speed") {
        updateProfile(profile.id, "pace_zone_id", zoneId);
      }
    },
    [profile, updateProfile, zoneDrawerMode]
  );

  const zoneDrawerValues = useMemo(
    () => ({
      hr: {
        title: t`Heart rate zone`,
        zoneChoice: profile?.heart_rate_zone_id,
        zoneSets: hrZoneSets,
      },
      power: {
        title: t`Power zone`,
        zoneChoice: profile?.power_zone_id,
        zoneSets: powerZoneSets,
      },
      speed: {
        title: `Running pace zone`,
        zoneChoice: profile?.pace_zone_id,
        zoneSets: paceZoneSets,
      },
    }),
    [
      hrZoneSets,
      paceZoneSets,
      powerZoneSets,
      profile?.heart_rate_zone_id,
      profile?.pace_zone_id,
      profile?.power_zone_id,
    ]
  );

  if (powerIsLoading || hrIsLoading || paceIsLoading) {
    return null;
  }

  return (
    <>
      <MenuList title={t`Zones`} listItems={sectionItems} />
      <ZoneSelect
        title={zoneDrawerValues[zoneDrawerMode].title}
        open={openZoneDrawer}
        setOpen={setOpenZoneDrawer}
        handleChange={handleChange}
        zoneChoice={zoneDrawerValues[zoneDrawerMode].zoneChoice}
        zoneSets={zoneDrawerValues[zoneDrawerMode].zoneSets}
      />
    </>
  );
};

export default ZonesSection;
