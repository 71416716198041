import { Close, Search } from "@carbon/icons-react";
import { t } from "@lingui/macro";
import { Box, InputAdornment, TextField, useTheme } from "@mui/material";
import { ChangeEventHandler, useCallback } from "react";
import { FilterParams } from "./FilterBar/FilterParams";

function SearchBar({
  searchTerm,
  updateFilter,
}: {
  searchTerm?: string;
  updateFilter: (value: FilterParams) => void;
}) {
  const { palette } = useTheme();
  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (ev) => {
      updateFilter({ search: ev.target.value });
    },
    [updateFilter]
  );
  const clearSearch = useCallback(() => {
    updateFilter({ search: "" });
  }, [updateFilter]);
  return (
    <Box p={2}>
      <TextField
        fullWidth
        placeholder={t`Search`}
        value={searchTerm}
        onChange={handleSearchChange}
        variant="outlined"
        sx={{
          background: palette.background.paper,
          borderRadius: 1,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search size={24} />
            </InputAdornment>
          ),
          endAdornment: searchTerm ? (
            <InputAdornment position="end">
              <Close size={24} onClick={clearSearch} />
            </InputAdornment>
          ) : undefined,
        }}
      />
    </Box>
  );
}

export default SearchBar;
