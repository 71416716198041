import ErrorBoundary from "@/components/ErrorBoundary";
import { UpdateNow } from "@carbon/icons-react";
import { t, Trans } from "@lingui/macro";
import { Alert, Box, Button, CircularProgress, Typography } from "@mui/material";
import MapList from "./MapList";
import { formatByteCount } from "@/services/formatByteCount";
import { useMemo, useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import usePairedDevice from "@/hooks/usePairedDevice";
import useSWR from "swr";
import useMaps, { TilePackState } from "./useMaps";
import useTilePackNames from "./useTilePackNames";
import noop from "@/services/noop";

export interface MapsEmbedProps {
  appToken: string;
  onUpdateAllClick: () => void;
  editing?: boolean;
  onMapSelect?: (checked: boolean, tilePackId: number) => void;
  pendingDeleteTilePackIds?: number[];
  onFolderClick?: (tilePackId: number) => void;
  onAddMapsClick?: () => void;
  onMapsUpToDate?: () => void;
}

const MapsEmbed = ({
  appToken,
  onUpdateAllClick,
  editing,
  onMapSelect,
  pendingDeleteTilePackIds,
  onFolderClick,
  onAddMapsClick,
  onMapsUpToDate = noop,
}: MapsEmbedProps) => {
  const navigate = useNavigate();

  const handleAddMapsClick = useCallback(() => {
    onAddMapsClick ? onAddMapsClick() : navigate(`/device-config/${appToken}/maps/add`);
  }, [appToken, navigate, onAddMapsClick]);

  const {
    mapPack,
    mapsSynced,
    mapsSyncError,
    retrySync,
    mapPackIsLoading,
    getAllActiveTilePacks,
    sendDownloadMapTilePack,
    addMapUpdateListener,
    removeMapUpdateListener,
    allMapsAreInstalled,
  } = useMaps(appToken);

  const availableDisplay = useMemo(
    () => formatByteCount(mapPack?.availableSpace || 0),
    [mapPack?.availableSpace]
  );

  const updatesAvailable = useMemo(() => mapPack?.mapUpgradeRequired, [mapPack]);

  useEffect(() => {
    if (mapsSynced && !updatesAvailable) {
      onMapsUpToDate();
    }
  }, [mapsSynced, onMapsUpToDate, updatesAvailable]);

  const { device, deviceLoading } = usePairedDevice(appToken);
  const tilePacks = useMemo(() => mapPack?.tilePacks, [mapPack]);
  const {
    data: activeTilePacks,
    isLoading: activeTilePacksAreLoading,
    mutate: mutateActiveTilePacks,
  } = useSWR(
    () => (mapsSynced ? ["activeTilePacks"] : undefined),
    () => getAllActiveTilePacks()
  );

  useEffect(() => {
    if (!mapsSynced) return;
    addMapUpdateListener("MapsEmbed", () => {
      mutateActiveTilePacks();
    });
    return () => removeMapUpdateListener("MapsEmbed");
  }, [addMapUpdateListener, mapsSynced, mutateActiveTilePacks, removeMapUpdateListener]);

  const downloading = useMemo(
    () =>
      !activeTilePacksAreLoading &&
      activeTilePacks?.some((tilePack) =>
        [
          TilePackState.DOWNLOADING,
          TilePackState.QUEUED,
          TilePackState.REQUESTED_DOWNLOAD,
        ].includes(tilePack.state)
      ),
    [activeTilePacks, activeTilePacksAreLoading]
  );

  const { tilePackNameTranslations } = useTilePackNames();

  const installedTilePacks = useMemo(
    () =>
      tilePacks
        ?.filter((tilePack) => tilePack.installedSize > 0)
        // Sort US (id 209) first, then the rest in alphabetical order.
        .sort((a, b) => {
          if (a.id === 209) return -1;
          if (b.id === 209) return 1;
          return tilePackNameTranslations[a.id].localeCompare(tilePackNameTranslations[b.id]);
        }),
    [tilePackNameTranslations, tilePacks]
  );

  const alertProps = useMemo(() => {
    if (downloading) {
      return {
        severity: "info" as const,
        icon: <CircularProgress size={22} />,
        action: (
          <Button size="small" color="inherit" onClick={onUpdateAllClick}>
            <Trans>View</Trans>
          </Button>
        ),
        children: <Trans>Downloading...</Trans>,
        variant: "filled" as const,
      };
    }
    if (updatesAvailable) {
      return {
        severity: "warning" as const,
        icon: <UpdateNow size={22} />,
        action: (
          <Button size="small" color="inherit" onClick={onUpdateAllClick}>
            <Trans>Update</Trans>
          </Button>
        ),
        children: <Trans>Map updates available</Trans>,
        variant: "filled" as const,
      };
    }
    return undefined;
  }, [downloading, onUpdateAllClick, updatesAvailable]);

  return (
    <ErrorBoundary
      error={mapsSyncError}
      isLoading={(!mapsSynced || mapPackIsLoading || !!deviceLoading) && !mapsSyncError}
      onReload={retrySync}
    >
      <Box display="flex" flexDirection="column" gap={3} width="100%" overflow="scroll">
        {alertProps && <Alert {...alertProps} />}
        <MapList
          appToken={appToken}
          editing={!!editing}
          onMapSelect={onMapSelect}
          onUpdateTilePack={sendDownloadMapTilePack}
          tilePacks={installedTilePacks || []}
          pendingDeleteTilePackIds={pendingDeleteTilePackIds}
          title={t`Maps on ${device?.displayName}`}
          onFolderClick={onFolderClick}
          wifiNetworkCount={mapPack?.wifiNwCount || 0}
        />
        <Button
          variant="contained"
          size="large"
          fullWidth
          onClick={handleAddMapsClick}
          disabled={allMapsAreInstalled}
        >
          <Trans>Add map</Trans>
        </Button>
      </Box>
      <Box display="flex" flexDirection="column" width="100%" alignItems="center" gap={1} mt={2}>
        <Typography variant="ui-sm" color="text.secondary">
          <Trans>{availableDisplay} available</Trans>
        </Typography>
      </Box>
    </ErrorBoundary>
  );
};

export default MapsEmbed;
