import { AutoSelectTextField } from "@WahooFitness/redesignr";
import { Box } from "@mui/material";
import { ChangeEventHandler, FocusEventHandler, useCallback, useEffect, useState } from "react";
import { t } from "@lingui/macro";

type Props = {
  value?: string;
  onBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  label: string;
  testIdPrefix?: string;
};

const NameInput = ({ value = "", onBlur, label, testIdPrefix = "" }: Props) => {
  const [currentValue, setCurrentValue] = useState<string>("");

  const [nameValid, setNameValid] = useState<boolean>(true);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      if (!nameValid) {
        setCurrentValue(value);
      } else {
        onBlur && onBlur(event);
      }
    },
    [nameValid, onBlur, value]
  );

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      setNameValid(event.target.value.length <= 190);

      setCurrentValue(event.target.value);
    },
    []
  );

  return (
    <Box display="flex" flexDirection="column">
      <AutoSelectTextField
        label={label}
        size="medium"
        value={currentValue}
        variant="outlined"
        onBlur={handleBlur}
        onChange={handleChange}
        error={!nameValid}
        helperText={!nameValid && t`Enter 190 characters or less`}
        inputProps={{ "data-testid": `${testIdPrefix}.nameInput` }}
      />
    </Box>
  );
};

export default NameInput;
