import { Trans } from "@lingui/macro";
import { Paper, Button } from "@mui/material";
import { Box } from "@mui/system";
import BestKnownTimesInputFields from "./BestKnownTimesInputFields";
import useBestKnownTimesInput from "./useBestKnownTimesInput";
import { PaceZoneAlgorithmLookupsResponseType } from "@WahooFitness/cloud-client-ts/Types";

type BestKnownTimeInputProps = {
  setPaceResultsAvailable: (value: boolean) => void;
  setYourPaceZones: (paceZones: PaceZoneAlgorithmLookupsResponseType) => void;
};

const BestKnownTimesInput = ({
  setPaceResultsAvailable,
  setYourPaceZones,
}: BestKnownTimeInputProps) => {
  const {
    distanceOptions,
    displayedDistanceOptions,
    bestKnownTimeValues,
    handleDistanceChange,
    handleTimeChange,
    handleRemove,
    handleAddAnother,
    handleApply,
    nextDisabled,
  } = useBestKnownTimesInput(setPaceResultsAvailable, setYourPaceZones);

  return (
    <>
      <Paper elevation={4} sx={{ m: 3 }}>
        <Box display="flex" flexDirection="column">
          {displayedDistanceOptions.map((option, index) => (
            <BestKnownTimesInputFields
              key={option}
              option={option}
              index={index}
              handleDistanceChange={handleDistanceChange}
              handleTimeChange={handleTimeChange}
              handleRemove={handleRemove}
              distanceOptions={distanceOptions}
              bestKnownTimeValues={bestKnownTimeValues}
              displayedDistanceOptions={displayedDistanceOptions}
            />
          ))}
          <Box p={2}>
            <Button
              size="large"
              variant="outlined"
              color="info"
              fullWidth
              disabled={displayedDistanceOptions.length === 4}
              onClick={handleAddAnother}
            >
              <Trans>Add another</Trans>
            </Button>
          </Box>
        </Box>
      </Paper>
      <Box display="flex" p={3} justifyContent="center" maxWidth={"sm"}>
        <Button
          size="large"
          disabled={nextDisabled}
          variant="contained"
          fullWidth
          onClick={handleApply}
        >
          <Trans>Next</Trans>
        </Button>
      </Box>
    </>
  );
};
export default BestKnownTimesInput;
