import { WalkthroughVideoPlayer } from "@WahooFitness/redesignr";
import { Trans } from "@lingui/macro";
import { Box, Button } from "@mui/material";
import useGuidedVideo from "./useVideoPlayer";
import { useLingui } from "@lingui/react";

function VideoPlayer() {
  const { currentVideo, forwardNav } = useGuidedVideo();
  const { i18n } = useLingui();

  if (!currentVideo) {
    return null;
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={4} alignItems="center" overflow="auto">
        <WalkthroughVideoPlayer
          videoUrl={currentVideo.url}
          steps={currentVideo.steps}
          // @ts-expect-error Not an issue. Comes from lingui version mismatch, even though the types are the same.
          i18nContext={i18n}
          incomingSelectedLanguage="en"
        />
      </Box>
      <Box p={3} width="100%" mt="auto" maxWidth="sm">
        <Button variant="contained" size="large" fullWidth onClick={forwardNav}>
          <Trans>Next</Trans>
        </Button>
      </Box>
    </>
  );
}

export default VideoPlayer;
