import { Box, Typography, Chip } from "@mui/material";
import { RedesignrIconType } from "@WahooFitness/redesignr";

const TemplateChipList = ({
  templateData,
  handleOpen,
}: {
  templateData: {
    workoutFamily: string;
    workoutTypes: { name: string; id: number; icon: RedesignrIconType }[];
  }[];
  handleOpen: (workoutTypeId: number, name: string) => void;
}) => {
  return (
    <Box mx={2} py={2}>
      {templateData.map((item) => {
        return (
          <Box key={item.workoutFamily} pb={2}>
            <Typography>{item.workoutFamily}</Typography>
            <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1} my={1}>
              {item.workoutTypes.map((item) => {
                return (
                  <Chip
                    key={item.id}
                    icon={<item.icon />}
                    label={item.name}
                    onClick={() => handleOpen(item.id, item.name)}
                  />
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
export default TemplateChipList;
