import { t } from "@lingui/macro";
import { useMemo } from "react";
import { crux_bolt_display_page_type_e } from "@WahooFitness/crux-js-display-cfg";

const useWorkoutPageDisplayStrings = () => {
  const workoutPageTranslations = useMemo(
    () =>
      ({
        [crux_bolt_display_page_type_e.BOLTAPP_WORKOUT]: t`Workout data`,
        [crux_bolt_display_page_type_e.BOLTAPP_LAP]: t`Lap`,
        [crux_bolt_display_page_type_e.BOLTAPP_ELEVATION]: t`Elevation`,
        [crux_bolt_display_page_type_e.BOLTAPP_MAP]: t`Map`,
        [crux_bolt_display_page_type_e.BOLTAPP_KICKR]: t`KICKR control`,
        [crux_bolt_display_page_type_e.BOLTAPP_ZOOM]: t`Zoom`,
        [crux_bolt_display_page_type_e.BOLTAPP_GRAPH]: t`Graph`,
        [crux_bolt_display_page_type_e.BOLTAPP_SEGMENT]: t`Strava Live Segments`,
        [crux_bolt_display_page_type_e.BOLTAPP_PLANNED_WORKOUT]: t`Planned workouts`,
        [crux_bolt_display_page_type_e.BOLTAPP_PEDAL_MONITOR]: t`Pedaling efficiency`,
        [crux_bolt_display_page_type_e.BOLTAPP_MULTISPORT]: t`Multisport transition`,
        [crux_bolt_display_page_type_e.BOLTAPP_TRAIL]: t`Nearby Trails`,
        [crux_bolt_display_page_type_e.BOLTAPP_HEADWIND]: t`KICKR HEADWIND fan control`,
        [crux_bolt_display_page_type_e.BOLTAPP_CLIMB]: t`Summit Segments`,
        [crux_bolt_display_page_type_e.BOLTAPP_MUSIC]: t`Music control`,
        [crux_bolt_display_page_type_e.BOLTAPP_GOPRO]: t`GoPro control`,
      }) as Record<number, string>,
    []
  );
  const workoutPageDescriptions = useMemo(
    () =>
      ({
        [crux_bolt_display_page_type_e.BOLTAPP_LAP]: t`Displays after first lap`,
        [crux_bolt_display_page_type_e.BOLTAPP_ELEVATION]: t`+ Summit Segments`,
        [crux_bolt_display_page_type_e.BOLTAPP_KICKR]: t`Displays when KICKR is moving`,
        [crux_bolt_display_page_type_e.BOLTAPP_PLANNED_WORKOUT]: t`Displays when a workout is selected`,
        [crux_bolt_display_page_type_e.BOLTAPP_SEGMENT]: t`Displays if Strava Live Segments is enabled`,
        [crux_bolt_display_page_type_e.BOLTAPP_MUSIC]: t`Displays when music is playing`,
        [crux_bolt_display_page_type_e.BOLTAPP_MULTISPORT]: t`Displays when in multisport mode`,
        [crux_bolt_display_page_type_e.BOLTAPP_GOPRO]: t`Displays when GoPro is connected`,
        [crux_bolt_display_page_type_e.BOLTAPP_PEDAL_MONITOR]: t`Displays when a Shimano or Pioneer power meter is connected`,
        [crux_bolt_display_page_type_e.BOLTAPP_CLIMB]: t`Displays if Summit Segments is enabled and you are on a climb`,
      }) as Record<number, string>,
    []
  );
  return { workoutPageTranslations, workoutPageDescriptions };
};

export default useWorkoutPageDisplayStrings;
