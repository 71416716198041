import LEDSettings from "@/components/WorkoutProfiles/LEDs/LEDSettings";
import { useParams } from "react-router";

const LEDSettingsPage = () => {
  const params = useParams();

  return <LEDSettings workoutProfileId={+(params.profileId || 0)} />;
};

export default LEDSettingsPage;
