import { useWsmPlugin } from "@/hooks/useWsmPlugin";
import { formatByteCount } from "@/services/formatByteCount";
import { t } from "@lingui/macro";
import { useDialogContext } from "@WahooFitness/wahoo-offline-mfe";
import { useCallback, useEffect, useMemo, useState } from "react";
import useSWR from "swr";

export const enum TilePackState {
  NONE = 0,
  SAVED = 1,
  FAILED = 2,
  DOWNLOADING = 3,
  QUEUED = 4,
  LOADING = 5,
  REQUESTED_DOWNLOAD = 100,
  REQUESTED_DELETE = 101,
}

const mapUpdateListeners: Record<string, (appToken: string) => void> = {};

const useMaps = (appToken: string, skipMapSync?: boolean) => {
  const { sensorManagerPlugin } = useWsmPlugin();

  const addMapUpdateListener = useCallback((key: string, listener: (appToken: string) => void) => {
    mapUpdateListeners[key] = listener;
  }, []);

  const removeMapUpdateListener = useCallback((key: string) => {
    delete mapUpdateListeners[key];
  }, []);

  const [mapsSynced, setMapsSynced] = useState(false);
  const [mapsSyncError, setMapsSyncError] = useState(false);

  const { mutate: retrySync } = useSWR(
    () => (skipMapSync ? undefined : ["mapSync", appToken]),
    ([_, appToken]) => {
      sensorManagerPlugin?.bcCheckStartMapTilePackSync({ appToken });
      setMapsSynced(false);
      setMapsSyncError(false);
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
    }
  );

  useEffect(() => {
    const listener = sensorManagerPlugin?.addListener(
      "boltMapPackChangeEvent",
      ({ appToken: eventAppToken }: { appToken: string }) => {
        eventAppToken === appToken &&
          Object.values(mapUpdateListeners).forEach((listener) => listener(appToken));
      }
    );
    return () => {
      (async () => (await listener)?.remove())();
    };
  }, [appToken, sensorManagerPlugin]);

  useEffect(() => {
    const updateSyncStatus = (startTimeStamp: number) => {
      if (skipMapSync) return;
      const timeOutDuration = 10000;
      if (Date.now() - startTimeStamp > timeOutDuration) {
        setMapsSyncError(true);
        return;
      }
      sensorManagerPlugin?.bcIsMapPackSynced({ appToken }).then((result) => {
        setMapsSynced(result.synced);
        if (!result.synced) {
          // The bike computer might not have been in a state where it could start a sync.
          // If the sync did not start, this will start it. If it already started, it will do nothing.
          sensorManagerPlugin?.bcCheckStartMapTilePackSync({ appToken });
        }
      });
    };
    if (!mapsSynced && !mapsSyncError) {
      const startTimestamp = Date.now();
      updateSyncStatus(startTimestamp);
      const interval = setInterval(() => updateSyncStatus(startTimestamp), 500);
      return () => clearInterval(interval);
    }
  }, [
    addMapUpdateListener,
    appToken,
    mapsSyncError,
    mapsSynced,
    removeMapUpdateListener,
    sensorManagerPlugin,
    skipMapSync,
  ]);

  const getTilePack = useCallback(
    async (tilePackId: number) =>
      (await sensorManagerPlugin?.bcGetTilePack({ appToken, tilePackId }))?.tilePack,
    [appToken, sensorManagerPlugin]
  );

  const getMapPack = useCallback(
    () => sensorManagerPlugin?.bcGetMapPack({ appToken }),
    [appToken, sensorManagerPlugin]
  );

  const sendUpgradeMapTilePack = useCallback(
    async (tilePackId?: number) => {
      if (tilePackId === undefined) {
        (await getMapPack())?.tilePacks.forEach((tilePack) => {
          sensorManagerPlugin?.bcSendUpgradeMapTilePack({ appToken, tilePackId: tilePack.id });
        });
        return;
      }
      sensorManagerPlugin?.bcSendUpgradeMapTilePack({ appToken, tilePackId });
    },
    [appToken, getMapPack, sensorManagerPlugin]
  );

  const sendDownloadMapTilePack = useCallback(
    (tilePackId: number) => {
      sensorManagerPlugin?.bcSendDownloadMapTilePack({ appToken, tilePackId });
    },
    [appToken, sensorManagerPlugin]
  );

  const sendDeleteMapTilePack = useCallback(
    (tilePackId: number) => {
      sensorManagerPlugin?.bcSendDeleteMapTilePack({ appToken, tilePackId });
    },
    [appToken, sensorManagerPlugin]
  );

  const getAllActiveTilePacks = useCallback(
    async () => (await sensorManagerPlugin?.bcGetAllActiveTilePacks({ appToken }))?.tilePacks,
    [appToken, sensorManagerPlugin]
  );

  const {
    data: mapPack,
    isLoading: mapPackIsLoading,
    mutate: mutateMapPack,
  } = useSWR(
    () => (mapsSynced || skipMapSync ? ["mapPack"] : undefined),
    () => getMapPack()
  );

  useEffect(() => {
    addMapUpdateListener("useMaps", () => mutateMapPack());
    return () => removeMapUpdateListener("useMaps");
  }, [addMapUpdateListener, mutateMapPack, removeMapUpdateListener]);

  const { setDialog, handleClose } = useDialogContext();

  const showSpaceDialog = useCallback(
    (requiredSpace: number, availableSpace: number) => {
      const requiredSpaceDisplay = formatByteCount(requiredSpace);
      const availableSpaceDisplay = formatByteCount(availableSpace);
      const differenceDisplay = formatByteCount(requiredSpace - availableSpace);
      setDialog({
        open: true,
        title: t`Space required`,
        body: t`The requested action requires ${requiredSpaceDisplay} of free space, but only ${availableSpaceDisplay} is available. Please free up ${differenceDisplay} and try again.`,
        actions: [
          {
            text: t`Ok`,
            action: () => {
              handleClose();
            },
          },
        ],
      });
    },
    [handleClose, setDialog]
  );

  const allMapsAreInstalled = useMemo(() => {
    return mapPack?.uninstalledTilePackCount === 0;
  }, [mapPack?.uninstalledTilePackCount]);

  return {
    mapsSynced,
    mapsSyncError,
    retrySync,
    mapPack,
    mapPackIsLoading,
    mutateMapPack,
    getTilePack,
    sendUpgradeMapTilePack,
    sendDownloadMapTilePack,
    sendDeleteMapTilePack,
    addMapUpdateListener,
    removeMapUpdateListener,
    getAllActiveTilePacks,
    showSpaceDialog,
    allMapsAreInstalled,
  };
};

export default useMaps;
