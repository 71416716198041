import { UserWorkoutBikingProfiles } from "@WahooFitness/cloud-client-ts";

import { useEffect, useMemo } from "react";
import {
  useConfigContext,
  useOfflineSWR,
  useCloudContext,
  useNativeMessagingContext,
} from "@WahooFitness/wahoo-offline-mfe";

const useRiderType = () => {
  const { wahooToken } = useConfigContext();
  const { getCloudClient } = useCloudContext();
  const { addRefreshListener, removeRefreshListener } = useNativeMessagingContext();
  const userWorkoutBikingProfilesClient = useMemo(
    () => getCloudClient(UserWorkoutBikingProfiles),
    [getCloudClient]
  );

  const {
    data: userWorkoutBikingProfilesData,
    error: userWorkoutBikingProfilesError,
    isLoading: userWorkoutBikingProfilesLoading,
    mutate: mutateWorkoutBikingProfile,
  } = useOfflineSWR(["userWorkoutBikingProfiles", wahooToken], ([_key, token]) =>
    userWorkoutBikingProfilesClient.get(token)
  );

  useEffect(() => {
    const listenerKey = "reloadWorkoutBikingProfiles";
    addRefreshListener(listenerKey, mutateWorkoutBikingProfile);
    return () => removeRefreshListener(listenerKey);
  }, [addRefreshListener, mutateWorkoutBikingProfile, removeRefreshListener]);

  // additional logic needed once we support other sports; location 0 = indoor
  const indoorUserWorkoutBikingProfilesData = useMemo(
    () => userWorkoutBikingProfilesData?.find((profile) => profile.workout_type_location_id === 0),
    [userWorkoutBikingProfilesData]
  );

  const indoorRiderTypeName = useMemo(
    () => indoorUserWorkoutBikingProfilesData?.rider_type?.name,
    [indoorUserWorkoutBikingProfilesData?.rider_type?.name]
  );
  const indoorRiderTypeDescription = useMemo(
    () => indoorUserWorkoutBikingProfilesData?.rider_type?.description,
    [indoorUserWorkoutBikingProfilesData?.rider_type?.description]
  );
  const indoorRiderTypeIconUrl = useMemo(
    () => indoorUserWorkoutBikingProfilesData?.rider_type?.icon.url,
    [indoorUserWorkoutBikingProfilesData?.rider_type?.icon.url]
  );
  const indoorRiderTypeWeaknessName = useMemo(
    () => indoorUserWorkoutBikingProfilesData?.rider_weakness?.name,
    [indoorUserWorkoutBikingProfilesData?.rider_weakness?.name]
  );
  const indoorRiderTypeWeaknessSummary = useMemo(
    () => indoorUserWorkoutBikingProfilesData?.rider_weakness?.weakness_summary,
    [indoorUserWorkoutBikingProfilesData?.rider_weakness?.weakness_summary]
  );
  const indoorRiderTypeWeaknessDescription = useMemo(
    () => indoorUserWorkoutBikingProfilesData?.rider_weakness?.weakness_description,
    [indoorUserWorkoutBikingProfilesData?.rider_weakness?.weakness_description]
  );
  const indoorRiderTypeStrengthName = useMemo(
    () => indoorUserWorkoutBikingProfilesData?.rider_weakness?.strength_name,
    [indoorUserWorkoutBikingProfilesData?.rider_weakness?.strength_name]
  );
  const indoorRiderTypeStrengthSummary = useMemo(
    () => indoorUserWorkoutBikingProfilesData?.rider_weakness?.strength_summary,
    [indoorUserWorkoutBikingProfilesData?.rider_weakness?.strength_summary]
  );
  const indoorRiderTypeStrengthDescription = useMemo(
    () => indoorUserWorkoutBikingProfilesData?.rider_weakness?.strength_description,
    [indoorUserWorkoutBikingProfilesData?.rider_weakness?.strength_description]
  );
  const indoorPowerNM = useMemo(
    () => indoorUserWorkoutBikingProfilesData?.power_5s,
    [indoorUserWorkoutBikingProfilesData?.power_5s]
  );
  const indoorPowerAC = useMemo(
    () => indoorUserWorkoutBikingProfilesData?.power_1m,
    [indoorUserWorkoutBikingProfilesData?.power_1m]
  );
  const indoorPowerMAP = useMemo(
    () => indoorUserWorkoutBikingProfilesData?.power_5m,
    [indoorUserWorkoutBikingProfilesData?.power_5m]
  );
  const indoorPowerFTP = useMemo(
    () => indoorUserWorkoutBikingProfilesData?.power_20m,
    [indoorUserWorkoutBikingProfilesData?.power_20m]
  );

  return {
    userWorkoutBikingProfilesData,
    userWorkoutBikingProfilesError,
    userWorkoutBikingProfilesLoading,
    mutateWorkoutBikingProfile,
    indoorRiderTypeName,
    indoorRiderTypeIconUrl,
    indoorRiderTypeDescription,
    indoorRiderTypeWeaknessName,
    indoorRiderTypeWeaknessSummary,
    indoorRiderTypeWeaknessDescription,
    indoorRiderTypeStrengthName,
    indoorRiderTypeStrengthSummary,
    indoorRiderTypeStrengthDescription,
    indoorPowerNM,
    indoorPowerAC,
    indoorPowerMAP,
    indoorPowerFTP,
  };
};

export default useRiderType;
