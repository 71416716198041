import { Layers, Minimize } from "@carbon/icons-react";
import { Trans } from "@lingui/macro";
import { GpsFixed } from "@mui/icons-material";
import { Box, Fab, Tooltip } from "@mui/material";

type MapToolbarProps = {
  handleCloseAll: () => void;
  handleMapOptionsOpen: () => void;
  handleReset: () => void;
  topOffset: number;
};
function MapToolbar({
  handleCloseAll,
  handleMapOptionsOpen,
  handleReset,
  topOffset,
}: MapToolbarProps) {
  return (
    <Box
      sx={{ position: "absolute", right: 0, top: topOffset ?? 0 }}
      display="flex"
      flexDirection="column"
    >
      <Tooltip title={<Trans>Close</Trans>} placement="left">
        <Fab
          size="small"
          color="primary"
          aria-label="close"
          onClick={handleCloseAll}
          sx={{ margin: 1 }}
        >
          <Minimize size={24} />
        </Fab>
      </Tooltip>
      <Tooltip title={<Trans>Map Options</Trans>} placement="left">
        <Fab
          size="small"
          color="primary"
          aria-label="mapOptions"
          onClick={handleMapOptionsOpen}
          sx={{ margin: 1 }}
        >
          <Layers size={24} />
        </Fab>
      </Tooltip>
      <Tooltip title={<Trans>Reset View</Trans>} placement="left">
        <Fab
          size="small"
          color="primary"
          aria-label="resetView"
          onClick={handleReset}
          sx={{ margin: 1 }}
        >
          <GpsFixed />
        </Fab>
      </Tooltip>
    </Box>
  );
}

export default MapToolbar;
