import SlidingDrawer from "@/components/StyledComponents/SlidingDrawer";
import { ZoneSet } from "@/components/Zones/useZones";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import { Dispatch, SetStateAction } from "react";

interface ZoneSelectProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title: string;
  zoneChoice: number | undefined;
  zoneSets: Record<number, ZoneSet>;
}

const ZoneSelect = ({
  open,
  setOpen,
  handleChange,
  title,
  zoneChoice,
  zoneSets,
}: ZoneSelectProps) => {
  return (
    <SlidingDrawer
      open={open}
      title={title}
      handleOpen={() => {}}
      handleClose={() => setOpen(false)}
      footer={<></>}
      zIndex={1301}
    >
      <FormControl>
        <RadioGroup value={zoneChoice} name="radio-buttons-group" onChange={handleChange}>
          {Object.values(zoneSets).map((zoneSet) => {
            return (
              <FormControlLabel
                key={zoneSet.id}
                value={zoneSet.id}
                control={<Radio />}
                label={<Typography variant="ui-md-medium">{zoneSet.name}</Typography>}
                sx={{ mt: 1, ml: 0.5 }}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </SlidingDrawer>
  );
};

export default ZoneSelect;
