import { Trans } from "@lingui/macro";
import { SelectChangeEvent, FormControl, InputLabel, Select } from "@mui/material";
import { DistanceOption } from "./useBestKnownTimesInput";
import { useCallback } from "react";

type BestKnownTimesInputDistanceProps = {
  option: string;
  index: number;
  handleDistanceChange: (event: SelectChangeEvent, index: number) => void;
  distanceOptions: DistanceOption[];
  displayedDistanceOptions: string[];
};

const BestKnownTimesInputDistance = ({
  option,
  index,
  handleDistanceChange,
  distanceOptions,
  displayedDistanceOptions,
}: BestKnownTimesInputDistanceProps) => {
  const handleDistanceChangeCallback = useCallback(
    (event: SelectChangeEvent) => {
      handleDistanceChange(event, index);
    },
    [handleDistanceChange, index]
  );
  return (
    <FormControl fullWidth variant="standard">
      <InputLabel sx={{ ml: 1.5, mt: 1 }}>
        <Trans>Distance</Trans>
      </InputLabel>
      <Select native variant="filled" value={option} onChange={handleDistanceChangeCallback}>
        {distanceOptions.map(({ key, name }) => (
          <option
            key={key}
            value={key}
            disabled={displayedDistanceOptions.includes(key) && key !== option}
          >
            {name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default BestKnownTimesInputDistance;
