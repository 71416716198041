export enum FlaggedFeature {
  ElemntConfig,
  AutoShareSummary,
  PaceZones,
  ElemntMultisportPage,
  ElemntPedalsPage,
  ElemntPlannedWorkouts,
  LocationSharing,
  StravaLiveSegments,
  ElemntAlertCountWarning,
}
