import MapRegion from "@/components/Elemnt/Maps/MapRegion";
import { useMemo } from "react";
import { useParams } from "react-router";

const MapRegionPage = () => {
  const { appToken = "", tilePackId: rawTilePackId } = useParams();
  const tilePackId = useMemo(() => +(rawTilePackId || 0), [rawTilePackId]);
  return <MapRegion appToken={appToken} tilePackId={tilePackId} />;
};

export default MapRegionPage;
