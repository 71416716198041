import { useCallback } from "react";

/**
 * This hook provides utility functions to convert units.
 * @returns An object containing functions to convert units.
 */
const useConvertUnits = () => {
  /**
   * This function converts a duration string to seconds.
   * @param value The duration string to convert.
   * @returns The duration in seconds.
   */
  const convertToSeconds = useCallback((time: string): number => {
    const timeParts = time.split(":").map(Number);
    if (timeParts.some(isNaN)) {
      return 0;
    }
    if (timeParts.length === 3) {
      const [hours, minutes, seconds] = timeParts;
      return hours * 3600 + minutes * 60 + seconds;
    } else if (timeParts.length === 2) {
      const [minutes, seconds] = timeParts;
      return minutes * 60 + seconds;
    } else {
      return timeParts[0];
    }
  }, []);

  return {
    convertToSeconds,
  };
};

export default useConvertUnits;
