import { useRoutesContext } from "@/hooks/useRoutesContext";
import noop from "@/services/noop";
import { CloseLarge } from "@carbon/icons-react";
import { Trans } from "@lingui/macro";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { BottomSheet } from "@WahooFitness/redesignr";
import { postEvent } from "@WahooFitness/wahoo-offline-mfe";
import { WSMRoute } from "@WahooFitness/wsm-native/dist/esm/types/route";
import { useCallback, useMemo, useState } from "react";

export interface ShareMenuProps {
  open?: boolean;
  onClose: () => void;
  route: WSMRoute;
  zIndex?: number;
}

enum SharePage {
  pickShareType,
  makePublic,
}

enum ShareDuration {
  oneHour,
  _24Hours,
}

const ShareDrawer = ({ open, onClose, route, zIndex }: ShareMenuProps) => {
  const [page, setPage] = useState<SharePage>(SharePage.pickShareType);

  const [shareDuration, setShareDuration] = useState<ShareDuration>(ShareDuration.oneHour);

  const handleClose = useCallback(() => {
    setPage(SharePage.pickShareType);
    onClose();
  }, [onClose]);

  const { shareRoutePublicly } = useRoutesContext();
  const handleShareRoutePublicly = useCallback(() => {
    const now = new Date();
    let expiresAt;
    switch (shareDuration) {
      case ShareDuration.oneHour:
        expiresAt = new Date(now.getTime() + 60 * 60 * 1000);
        break;
      case ShareDuration._24Hours:
        expiresAt = new Date(now.getTime() + 24 * 60 * 60 * 1000);
        break;
    }
    shareRoutePublicly(route, expiresAt);
    handleClose();
  }, [shareDuration, shareRoutePublicly, route, handleClose]);

  const handleShareRouteToContacts = useCallback(() => {
    postEvent("shareRoute", { routeId: `${route.id}` });
    handleClose();
  }, [handleClose, route.id]);

  const content = useMemo(() => {
    switch (page) {
      case SharePage.pickShareType:
        return (
          <Stack direction="column" gap={1} p={2}>
            <Paper onClick={handleShareRouteToContacts} elevation={4}>
              <Typography variant="ui-lg-bold" p={2}>
                <Trans>Share...</Trans>
              </Typography>
            </Paper>
            <Paper onClick={() => setPage(SharePage.makePublic)} elevation={4}>
              <Typography variant="ui-lg-bold" p={2}>
                <Trans>Share as public route</Trans>
              </Typography>
            </Paper>
          </Stack>
        );
      case SharePage.makePublic:
        return (
          <Stack direction="column" gap={2} px={2} pb={1}>
            <Box display="flex" flexDirection="column">
              <Typography variant="prose-base" py={1}>
                <Trans>Duration</Trans>
              </Typography>
              <ToggleButtonGroup
                value={shareDuration}
                onChange={(_, value) => setShareDuration(value)}
                exclusive
                fullWidth
                size="small"
              >
                <ToggleButton value={ShareDuration.oneHour}>
                  <Trans>1 hour</Trans>
                </ToggleButton>
                <ToggleButton value={ShareDuration._24Hours}>
                  <Trans>24 hours</Trans>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Typography variant="prose-sm" textAlign="center">
              <Trans>
                This route wil be available for the specified duration to anyone within 10 km of the
                route start via the Wahoo App. You can stop sharing at anytime.
              </Trans>
            </Typography>
            <Button fullWidth onClick={handleShareRoutePublicly} variant="contained" size="large">
              <Trans>Share route</Trans>
            </Button>
          </Stack>
        );
    }
  }, [handleShareRoutePublicly, handleShareRouteToContacts, page, shareDuration]);

  return (
    <BottomSheet open={open} onClose={handleClose} onOpen={noop} sx={{ zIndex: zIndex ?? 0 }}>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box width={44} />
        <Typography
          variant="ui-md-medium"
          component="div"
          textAlign="center"
          overflow="hidden"
          textOverflow="ellipsis"
          sx={{ display: "block" }}
          noWrap
        >
          {route.name}
        </Typography>
        <IconButton onClick={handleClose} color="primary" size="medium">
          <CloseLarge />
        </IconButton>
      </Box>
      {content}
    </BottomSheet>
  );
};

export default ShareDrawer;
