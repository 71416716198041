import { useContext } from "react";
import { WorkoutProfilesContext } from "../contexts/WorkoutProfilesContext";

export const useWorkoutProfilesContext = () => {
  const context = useContext(WorkoutProfilesContext);
  if (!context) {
    throw new Error(
      "useWorkoutProfilesContext must be used within a WorkoutProfilesContextProvider"
    );
  }
  return context;
};
