import useConditionalNavigateUp from "@/hooks/useConditionalNavigateUp";
import usePairedDevice from "@/hooks/usePairedDevice";
import { useEffect } from "react";

const useNavUpOnDeviceDisconnect = (appToken: string) => {
  const { deviceLoading, isDeviceConnected, reloadDevice } = usePairedDevice(appToken);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;
    if (!deviceLoading) {
      interval = setInterval(reloadDevice, 5000);
      return () => {
        interval && clearInterval(interval);
      };
    }
  }, [deviceLoading, reloadDevice]);

  useConditionalNavigateUp("deviceDisconnected", () => !deviceLoading && !isDeviceConnected, [
    deviceLoading,
    isDeviceConnected,
  ]);
};

export default useNavUpOnDeviceDisconnect;
