const DefaultMapImage = () => {
  return (
    <svg
      width="108"
      height="128"
      viewBox="0 0 108 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 3613">
        <rect id="Rectangle 1" width="108" height="128" fill="#454749" />
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.6417 36H82.2071C82.7594 36 83.2071 36.4477 83.2071 37V39.5L83 39.2929L75 47.2929L74.2697 48.0232L75.0232 48.7295L83.0232 56.2295L83.2071 56.0333V75.484L78.4419 70.3217L77.736 69.5569L77 70.2929L73 74.2929L72.2929 75L73 75.7071L83 85.7071L83.2071 85.5V92C83.2071 92.5523 82.7594 93 82.2071 93H74.6213L53.9142 72.2929L53.2433 71.622L52.5381 72.2567L48.2209 76.1422L40.891 69.2705L40.1847 68.6082L39.5 69.2929L33.5 75.2929L32.7929 76L33.5 76.7071L49.7929 93H39.1213L26.2071 80.0858V67.4142L33.9142 59.7071L34.6213 59L33.9142 58.2929L26.2071 50.5858V42.9142L41.5 58.2071L42.1922 58.8993L42.899 58.222L54.899 46.722L55.6365 46.0152L54.9142 45.2929L45.6213 36H58.7726L65.4761 43.1823L66.2071 43.9656L66.9382 43.1823L73.6417 36ZM24.2071 40.5V37C24.2071 35.3431 25.5503 34 27.2071 34H82.2071C83.864 34 85.2071 35.3431 85.2071 37V92C85.2071 93.6569 83.864 95 82.2071 95H76.6213H74.2071H52.2071H51.7929H27.2071C25.5503 95 24.2071 93.6569 24.2071 92V40.9142L24 40.7071L24.2071 40.5ZM56.6899 51.389C58.8165 49.2625 61.6997 48.0662 64.7071 48.0625C67.7146 48.0662 70.5978 49.2625 72.7243 51.389C74.8509 53.5156 76.0472 56.3988 76.0509 59.4062C76.0546 61.8629 75.2522 64.2529 73.7669 66.2096L73.7653 66.2121C73.7653 66.2121 73.4556 66.6189 73.4099 66.6739L64.7071 76.9375L56.008 66.6783C55.9583 66.6193 55.649 66.2125 55.649 66.2125C54.1628 64.255 53.3598 61.864 53.3634 59.4062C53.367 56.3988 54.5634 53.5156 56.6899 51.389ZM62.4154 62.8361C63.0938 63.2893 63.8913 63.5312 64.7071 63.5312C65.8008 63.53 66.8493 63.095 67.6226 62.3217C68.3959 61.5484 68.8309 60.4999 68.8321 59.4062C68.8321 58.5904 68.5902 57.7929 68.137 57.1145C67.6837 56.4362 67.0395 55.9075 66.2857 55.5952C65.532 55.283 64.7026 55.2013 63.9024 55.3605C63.1022 55.5197 62.3672 55.9125 61.7903 56.4894C61.2134 57.0663 60.8206 57.8013 60.6614 58.6015C60.5022 59.4017 60.5839 60.2311 60.8961 60.9848C61.2084 61.7386 61.7371 62.3828 62.4154 62.8361Z"
          fill="white"
          fillOpacity="0.38"
        />
      </g>
    </svg>
  );
};

export default DefaultMapImage;
