import {
  SignalStrength0Icon,
  SignalStrength1Icon,
  SignalStrength2Icon,
  SignalStrength3Icon,
  SignalStrengthFullIcon,
} from "@WahooFitness/redesignr";
import { Typography } from "@mui/material";

const maxDbm = -35;
const minDbm = -75;
const signalThresholds = [0.0, 0.25, 0.5, 0.75];

const SignalStrengthIcon = ({ rssi }: { rssi?: number }) => {
  if (rssi === undefined) {
    return undefined;
  }
  const signalStrength = (rssi - minDbm) / (maxDbm - minDbm);
  if (signalStrength <= signalThresholds[0]) {
    return (
      <Typography color="divider">
        <SignalStrength0Icon />
      </Typography>
    );
  }
  if (signalStrength < signalThresholds[1]) {
    return <SignalStrength1Icon color="success" />;
  }
  if (signalStrength < signalThresholds[2]) {
    return <SignalStrength2Icon color="success" />;
  }
  if (signalStrength < signalThresholds[3]) {
    return <SignalStrength3Icon color="success" />;
  }
  return <SignalStrengthFullIcon color="success" />;
};

export default SignalStrengthIcon;
