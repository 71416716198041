import { useMemo } from "react";
import { useAnalyticsContext, useUserContext } from "@WahooFitness/wahoo-offline-mfe";
import { Profile } from "@WahooFitness/cloud-client-ts/Types";
import { hasMinProfile } from "@/services/profileService";

const useSuggestedWorkoutsSetting = () => {
  const { currentUser } = useUserContext();
  const { getRemoteConfigString } = useAnalyticsContext();

  const userAppProfile = useMemo(() => currentUser?.app?.profile, [currentUser?.app?.profile]);
  const suggestedWorkoutsMinProfile =
    getRemoteConfigString("SUGGESTED_WORKOUTS_MIN_PROFILE") ?? "dev";
  const showSuggestedWorkouts = hasMinProfile(
    suggestedWorkoutsMinProfile as Profile,
    userAppProfile
  );

  return {
    showSuggestedWorkouts,
  };
};

export default useSuggestedWorkoutsSetting;
