import { t } from "@lingui/macro";

export enum ByteConversionType {
  B = 0,
  KB = 1,
  MB = 2,
  GB = 3,
}

const units = {
  [ByteConversionType.B]: () => t`B`,
  [ByteConversionType.KB]: () => t`KB`,
  [ByteConversionType.MB]: () => t`MB`,
  [ByteConversionType.GB]: () => t`GB`,
};

const conversionFactor = 1024;

export const getConversionType = (byteCount: number): ByteConversionType => {
  for (const conversionType of [
    ByteConversionType.B,
    ByteConversionType.KB,
    ByteConversionType.MB,
  ]) {
    if (byteCount < conversionFactor ** (conversionType + 1)) {
      return conversionType;
    }
  }
  return ByteConversionType.GB;
};

export const formatByteCount = (byteCount: number, to?: ByteConversionType): string => {
  const conversionType = to ?? getConversionType(byteCount);
  return `${(byteCount / conversionFactor ** conversionType).toFixed(2)} ${units[conversionType]()}`;
};
