import { useCallback, useEffect, useMemo } from "react";
import { useWsmPlugin } from "./useWsmPlugin";
import {
  WSMCloudSyncState,
  WSMCloudSyncType,
} from "@WahooFitness/wsm-native/dist/esm/types/cloud_sync_event";

const useCloudSyncListener = () => {
  const { sensorManagerPlugin } = useWsmPlugin();

  const listeners = useMemo(
    () => new Set<(type: WSMCloudSyncType, state: WSMCloudSyncState, reason: string) => void>(),
    []
  );

  const addListener = useCallback(
    (listener: (type: WSMCloudSyncType, state: WSMCloudSyncState, reason: string) => void) => {
      listeners.add(listener);
      return listener;
    },
    [listeners]
  );

  const removeListener = useCallback(
    (listener: (type: WSMCloudSyncType, state: WSMCloudSyncState, reason: string) => void) => {
      listeners.delete(listener);
    },
    [listeners]
  );

  useEffect(() => {
    const listener = sensorManagerPlugin?.addListener("cloudSyncStateChanged", (event) => {
      listeners.forEach((listener) => listener(event.type, event.state, event.reason));
    });
    return () => {
      (async () => (await listener)?.remove())();
    };
  }, [listeners, sensorManagerPlugin]);

  return { addListener, removeListener };
};

export default useCloudSyncListener;
