import {
  ChevronRightIcon,
  MenuList,
  MenuListItemType,
  MenuListItemVariant,
} from "@WahooFitness/redesignr";
import React from "react";
import { t } from "@lingui/macro";

const CustomizeProfileSection: React.FC = () => {
  const sectionItems: MenuListItemType[] = [
    {
      id: "structuredWorkouts",
      content: t`Structured workouts`,
      variant: MenuListItemVariant.Action,
      action: () => {
        console.log("clicked SW");
      },
      actionComponent: <ChevronRightIcon />,
      hasDivider: false,
    },
  ];
  return <MenuList title={t`Customize profile`} listItems={sectionItems} />;
};

export default CustomizeProfileSection;
