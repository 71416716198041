import { t } from "@lingui/macro";
import { Box } from "@mui/material";
import SortChip from "./SortChip";
import FamilyFilterChip from "./FamilyFilterChip";
import { SortDirectionOption, SortOption, SortParams } from "./SortParams";
import {
  MAX_DURATION_FILTER,
  FilterParams,
  MAX_TSS_FILTER,
  MAX_DISTANCE_FILTER,
  MAX_ELEVATION_FILTER,
} from "./FilterParams";
import RangeFilterChip from "./RangeFilterChip";

interface FilterBarProps {
  sortParams: SortParams;
  sortOptions: Array<SortOption>;
  sortDirections: Array<SortDirectionOption>;
  setSortKey: (key: string) => void;
  setSortDirection: (key: string) => void;
  filterParams: FilterParams;
  updateFilter: (newFilterParams: Partial<FilterParams>) => void;
  defaultFilterParams: FilterParams;
}

function FilterBar({
  sortParams,
  sortDirections,
  sortOptions,
  setSortDirection,
  setSortKey,
  filterParams,
  updateFilter,
  defaultFilterParams,
}: FilterBarProps) {
  return (
    <Box px={2} py={1} display="flex" overflow="scroll" gap={2}>
      <SortChip
        sortParams={sortParams}
        sortDirections={sortDirections}
        sortOptions={sortOptions}
        setSortDirection={setSortDirection}
        setSortKey={setSortKey}
      />
      <FamilyFilterChip filterParams={filterParams} updateFilter={updateFilter} />
      {defaultFilterParams.distance && (
        <RangeFilterChip
          max={MAX_DISTANCE_FILTER}
          param="distance"
          label={t`Distance`}
          defaultFilterParams={defaultFilterParams}
          filterParams={filterParams}
          updateFilter={updateFilter}
        />
      )}
      {defaultFilterParams.elevation_gain && (
        <RangeFilterChip
          max={MAX_ELEVATION_FILTER}
          param="elevation_gain"
          label={t`Elevation`}
          defaultFilterParams={defaultFilterParams}
          filterParams={filterParams}
          updateFilter={updateFilter}
        />
      )}
      {defaultFilterParams.duration && (
        <RangeFilterChip
          max={MAX_DURATION_FILTER}
          param="duration"
          label={t`Duration`}
          defaultFilterParams={defaultFilterParams}
          filterParams={filterParams}
          updateFilter={updateFilter}
        />
      )}
      {defaultFilterParams.tss && (
        <RangeFilterChip
          max={MAX_TSS_FILTER}
          param="tss"
          label={t`TSS®`}
          defaultFilterParams={defaultFilterParams}
          filterParams={filterParams}
          updateFilter={updateFilter}
        />
      )}
    </Box>
  );
}

export default FilterBar;
