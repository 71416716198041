import { getEnvironmentConfig } from "@/services/getEnvironmentConfig";
import { FitnessApps } from "@WahooFitness/cloud-client-ts";
import { useCloudContext, useConfigContext, useOfflineSWR } from "@WahooFitness/wahoo-offline-mfe";
import { useCallback, useMemo } from "react";
import WahooLogo from "@/assets/WahooLogo.png";

function useRouteDetails() {
  const { getCloudClient } = useCloudContext();
  const { wahooToken } = useConfigContext();
  const fitnessAppsClient = useMemo(() => getCloudClient(FitnessApps), [getCloudClient]);

  const {
    data: fitnessAppData,
    // _error: fitnessAppDataError,
    // _isLoading: fitnessAppDataIsLoading,
  } = useOfflineSWR(["fitnessApps", wahooToken], ([_key, token]) =>
    fitnessAppsClient.getAll(token)
  );

  const getProviderIconImgUrl = useCallback(
    (fitness_app_id: number | undefined, route_provider_type: number | undefined) => {
      if (route_provider_type === 12) {
        return WahooLogo;
      }
      return fitness_app_id
        ? fitnessAppData?.find((app) => app.id === fitness_app_id)?.icon?.thumb?.url
        : undefined;
    },
    [fitnessAppData]
  );

  const { environment } = useConfigContext();
  const mapboxToken = useMemo(() => getEnvironmentConfig(environment).mapboxToken, [environment]);

  const getLocation = useCallback(
    async (longitude: number, latitude: number): Promise<string | undefined> => {
      if (!mapboxToken) {
        console.error("Mapbox token not found");
        return undefined;
      }
      const url = `https://api.mapbox.com/search/geocode/v6/reverse?longitude=${longitude}&latitude=${latitude}&access_token=${mapboxToken}&types=place,region&limit=1`;
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        const placeLocation = data.features?.[0]?.properties.context.place.name || "";
        const regionLocation = data.features?.[0]?.properties.context.region.name || "";
        const location = `${placeLocation}, ${regionLocation}`;
        return location;
      }
      return undefined;
    },
    [mapboxToken]
  );

  return {
    getProviderIconImgUrl,
    getLocation,
  };
}

export default useRouteDetails;
