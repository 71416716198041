import { RouteSentStatus } from "@/contexts/RouteSentStatus";
import { useRoutesContext } from "@/hooks/useRoutesContext";
import { useUnitFormatter } from "@/hooks/useUnitFormatter";
import { getWorkoutTypeFamilyIcon } from "@/services/getWorkoutTypeIcon";
import { CheckmarkFilled, Mountain, WarningFilled } from "@carbon/icons-react";
import { t, Trans } from "@lingui/macro";
import { Button, CircularProgress, Paper, Stack, Typography, useTheme } from "@mui/material";
import { Box, useTheme as systemTheme } from "@mui/system";
import { formatDuration } from "@WahooFitness/unit-convertr-ts";
import { useUserContext } from "@WahooFitness/wahoo-offline-mfe";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import DefaultMapImage from "./DefaultMapImage";

interface RouteCardProps {
  name: string;
  distance?: number;
  ascent?: number;
  workoutTypeFamily?: number;
  providerIconImgUrl?: string;
  routeFitUrl?: string;
  routeId: number;
  providerType?: number;
  providerId?: string;
  fitnessAppId?: number;
  expiresAt?: Date;
  userId?: number;
  fromPublicRouteList?: boolean;
  token?: string;
}

const RouteCard = ({
  name,
  distance,
  ascent,
  workoutTypeFamily,
  providerIconImgUrl,
  routeFitUrl,
  routeId,
  providerType,
  providerId,
  fitnessAppId,
  expiresAt,
  userId,
  fromPublicRouteList,
  token,
}: RouteCardProps) => {
  const { palette: systemPalette } = systemTheme();
  const { palette: colorPalette } = useTheme();
  const { sendRouteToConnectedDevices, selectedProviderId, selectedRouteSentState } =
    useRoutesContext();
  const { formatDistance, formatElevation } = useUnitFormatter();
  const WorkoutTypeIcon = getWorkoutTypeFamilyIcon(workoutTypeFamily);
  const navigate = useNavigate();
  const handleRouteClick = useCallback(() => {
    navigate(`/routes/${token}`, { state: { public: !!fromPublicRouteList } });
  }, [fromPublicRouteList, navigate, token]);
  const mode = useMemo(() => systemPalette.mode, [systemPalette.mode]);

  const routeSentStatus = useMemo(() => {
    if (providerId && selectedProviderId === providerId) {
      return selectedRouteSentState;
    }
    return RouteSentStatus.UNSENT;
  }, [providerId, selectedProviderId, selectedRouteSentState]);

  const handleSelectRoute = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (providerType !== undefined && providerId && fitnessAppId) {
        sendRouteToConnectedDevices(providerType, providerId, fitnessAppId);
      }
    },
    [fitnessAppId, providerId, providerType, sendRouteToConnectedDevices]
  );

  const routeStatusIcon = useMemo(() => {
    switch (routeSentStatus) {
      case RouteSentStatus.UNSENT:
        return undefined;
      case RouteSentStatus.PENDING:
        return <CircularProgress size={16} color="info" />;
      case RouteSentStatus.SUCCESS:
        return <CheckmarkFilled size={16} color={colorPalette.success.main} />;
      case RouteSentStatus.FAILED:
        return <WarningFilled size={16} color={colorPalette.warning.main} />;
    }
  }, [routeSentStatus, colorPalette.success.main, colorPalette.warning.main]);

  const buttonText = useMemo(() => {
    switch (routeSentStatus) {
      case RouteSentStatus.UNSENT:
        return t`Select route`;
      case RouteSentStatus.PENDING:
        return t`Sending to ELEMNT...`;
      case RouteSentStatus.SUCCESS:
        return t`Success!`;
      case RouteSentStatus.FAILED:
        return t`Failed to send to ELEMNT`;
    }
  }, [routeSentStatus]);

  const { pendingRemovedPublicRouteIds } = useRoutesContext();

  const timeRemaining = useMemo(() => {
    if (!expiresAt || pendingRemovedPublicRouteIds.includes(routeId)) return "";
    const currentTime = new Date();
    const remainingSeconds = Math.max(0, expiresAt.getTime() - currentTime.getTime()) / 1000;
    return remainingSeconds ? formatDuration(remainingSeconds, "pretty") : "";
  }, [expiresAt, pendingRemovedPublicRouteIds, routeId]);

  const { currentUser } = useUserContext();

  return (
    <Box
      display="flex"
      flexDirection="row"
      component={Paper}
      width="100%"
      position="relative"
      onClick={handleRouteClick}
    >
      <Box display="flex" flexDirection="column">
        {routeFitUrl ? (
          <img
            style={{
              width: 108,
              height: 132,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
            }}
            src={`https://visualizr.wahooligan.com/route?url=${routeFitUrl}&width=108&height=132&theme=${mode}`}
          />
        ) : (
          <DefaultMapImage />
        )}
        {providerIconImgUrl && (
          <img
            style={{
              width: 20,
              height: 20,
              borderRadius: 4,
              position: "absolute",
              top: 8,
              left: 8,
            }}
            src={providerIconImgUrl}
          />
        )}
        {timeRemaining && userId === currentUser?.id && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="warning.main"
            position="absolute"
            height={16}
            width={108}
            bottom={0}
            left={0}
            style={{ borderBottomLeftRadius: 8 }}
          >
            <Typography variant="ui-3xs-medium" color="warning.contrastText" textAlign="center">
              <Trans>{`${timeRemaining} remaining`}</Trans>
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="column" rowGap={1} m={2} width="100%">
        <Typography
          height="38px"
          variant="ui-base-medium"
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 2,
            wordBreak: "break-word",
          }}
        >
          {name}
        </Typography>
        <Box display="flex" columnGap={4} height="16px">
          <Box display="flex" flexDirection="row" columnGap={0.5} alignItems="center">
            <WorkoutTypeIcon
              sx={{ color: systemPalette.text.secondary, width: "16px", height: "16px" }}
            />
            <Typography variant="ui-xs">{formatDistance(distance ?? 0)}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" columnGap={0.5} alignItems="center">
            <Mountain width="14px" height="14px" color={systemPalette.text.secondary} />
            <Typography variant="ui-xs">{formatElevation(ascent ?? 0)}</Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          fullWidth
          onClick={handleSelectRoute}
        >
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            {routeStatusIcon}
            <Typography variant="prose-xs-medium">{buttonText}</Typography>
          </Stack>
        </Button>
      </Box>
    </Box>
  );
};

export default RouteCard;
