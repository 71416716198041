import ErrorBoundary from "@/components/ErrorBoundary";
import MapList from "./MapList";
import useMaps from "./useMaps";
import { useEffect, useMemo } from "react";
import useSWR from "swr";
import { WSMBoltTilePack } from "@WahooFitness/wsm-native/dist/esm/types/bolt_map";
import useTilePackNames from "./useTilePackNames";

interface MapRegionEmbedProps {
  appToken: string;
  tilePackId: number;
  onTilePackLoad?: (tilePack: WSMBoltTilePack) => void;
  editing?: boolean;
  handleMapSelect?: (checked: boolean, tilePackId: number) => void;
  pendingDeleteTilePackIds?: number[];
  onFolderClick?: (tilePackId: number) => void;
}

const MapRegionEmbed = ({
  appToken,
  tilePackId,
  onTilePackLoad,
  editing,
  handleMapSelect,
  pendingDeleteTilePackIds,
  onFolderClick,
}: MapRegionEmbedProps) => {
  const {
    mapPack,
    mapsSynced,
    mapsSyncError,
    retrySync,
    getTilePack,
    sendDownloadMapTilePack,
    addMapUpdateListener,
    removeMapUpdateListener,
  } = useMaps(appToken);

  const {
    data: tilePack,
    isLoading: tilePacksAreLoading,
    mutate: mutateTilePacks,
  } = useSWR(
    () => (mapsSynced ? ["tilePack", tilePackId] : undefined),
    async () => getTilePack(tilePackId)
  );

  useEffect(() => {
    tilePack && onTilePackLoad?.(tilePack);
  }, [onTilePackLoad, tilePack]);

  const tilePacks = useMemo(() => tilePack?.tilePacks || [], [tilePack]);

  const { tilePackNameTranslations } = useTilePackNames();

  const installedTilePacks = useMemo(
    () =>
      tilePacks
        ?.filter((tilePack) => tilePack.installedSize > 0)
        .sort((a, b) =>
          tilePackNameTranslations[a.id].localeCompare(tilePackNameTranslations[b.id])
        ),
    [tilePackNameTranslations, tilePacks]
  );

  useEffect(() => {
    if (!mapsSynced) return;
    const key = `MapRegionEmbed ${tilePackId}`;
    addMapUpdateListener(key, () => {
      mutateTilePacks();
    });
    return () => removeMapUpdateListener(key);
  }, [addMapUpdateListener, mapsSynced, mutateTilePacks, removeMapUpdateListener, tilePackId]);

  return (
    <ErrorBoundary error={mapsSyncError} isLoading={tilePacksAreLoading} onReload={retrySync}>
      <MapList
        appToken={appToken}
        editing={!!editing}
        onMapSelect={handleMapSelect}
        onUpdateTilePack={sendDownloadMapTilePack}
        tilePacks={installedTilePacks || []}
        pendingDeleteTilePackIds={pendingDeleteTilePackIds}
        onFolderClick={onFolderClick}
        wifiNetworkCount={mapPack?.wifiNwCount || 0}
      />
    </ErrorBoundary>
  );
};

export default MapRegionEmbed;
