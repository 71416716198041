import ErrorBoundary from "@/components/ErrorBoundary";
import { Trans, t } from "@lingui/macro";
import { Box, Link, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import {
  BlueSwitch,
  MenuList,
  MenuListItemType,
  MenuListItemVariant,
} from "@WahooFitness/redesignr";
import { useAnalyticsContext } from "@WahooFitness/wahoo-offline-mfe";

const SummitSegmentSettings = ({ workoutProfileId }: { workoutProfileId: number }) => {
  const { palette } = useTheme();
  const { getRemoteConfigString } = useAnalyticsContext();

  const climbSizeUrl = useMemo(
    () => getRemoteConfigString("SUMMIT_SEGMENT_SETTINGS_CLIMB_SIZE_URL"),
    [getRemoteConfigString]
  );

  const learnMoreUrl = useMemo(
    () => getRemoteConfigString("SUMMIT_SEGMENT_SETTINGS_LEARN_MORE_URL"),
    [getRemoteConfigString]
  );

  const { setNavHeader } = useHeaderContext();
  const headerProps = useMemo(
    () => ({
      title: t`Summit Segments`,
    }),
    []
  );

  useEffect(() => {
    setNavHeader(headerProps);
  });

  const { getWorkoutProfile, workoutProfilesLoading, workoutProfilesError, updateProfile } =
    useWorkoutProfilesContext();

  const workoutProfile = useMemo(
    () => getWorkoutProfile(workoutProfileId),
    [getWorkoutProfile, workoutProfileId]
  );

  const handleChangeClimbsSensitivity = useCallback(
    (_: any, value: number) => {
      if (value !== null && value !== undefined && workoutProfile) {
        updateProfile(workoutProfile.id, "climbs_sensitivity", value);
      }
    },
    [updateProfile, workoutProfile]
  );

  const firstSection = useMemo(() => {
    const menuListItems: MenuListItemType[] = [
      {
        id: "climbs_enabled",
        content: t`Enable Summit Segments`,
        variant: MenuListItemVariant.Action,
        actionComponent: (
          <BlueSwitch checked={workoutProfile?.climbs_enabled} data-testid="climbs_enabled" />
        ),
        action: () => {
          if (workoutProfile) {
            updateProfile(workoutProfile.id, "climbs_enabled", !workoutProfile.climbs_enabled);
          }
        },
      },
    ];
    if (!workoutProfile?.climbs_enabled) {
      return menuListItems;
    }
    menuListItems.push(
      ...[
        {
          id: "climbs_routes_only",
          content: t`Routes only`,
          secondaryContent: t`If enabled, you will only get Summit Segments when following a route.`,
          variant: MenuListItemVariant.Action,
          actionComponent: (
            <BlueSwitch
              checked={workoutProfile?.climbs_routes_only}
              data-testid="climbs_routes_only"
            />
          ),
          action: () => {
            if (workoutProfile) {
              updateProfile(
                workoutProfile.id,
                "climbs_routes_only",
                !workoutProfile.climbs_routes_only
              );
            }
          },
        },
        {
          id: "climbs_sensitivity",
          content: t`Climb Detection`,
          secondaryContent: (
            <Box display="flex" flexDirection="column" rowGap={1}>
              <ToggleButtonGroup
                exclusive
                fullWidth
                value={workoutProfile?.climbs_sensitivity}
                onChange={handleChangeClimbsSensitivity}
                data-testid="climbs_sensitivity"
              >
                <ToggleButton value={0}>
                  <Trans>All climbs</Trans>
                </ToggleButton>
                <ToggleButton value={1}>
                  <Trans>Med & Lg</Trans>
                </ToggleButton>
                <ToggleButton value={2}>
                  <Trans>Large</Trans>
                </ToggleButton>
              </ToggleButtonGroup>
              <Link href={climbSizeUrl} target="_blank">
                <Typography color={palette.info.main} variant="prose-xs">
                  <Trans>What determines ELEMNT&apos;s climb size?</Trans>
                </Typography>
              </Link>
            </Box>
          ),
          variant: MenuListItemVariant.Accordion,
          expanded: true,
          actionComponent: <></>,
        },
      ]
    );
    return menuListItems;
  }, [
    climbSizeUrl,
    handleChangeClimbsSensitivity,
    palette.info.main,
    updateProfile,
    workoutProfile,
  ]);

  const secondSection = useMemo(
    () => [
      {
        id: "climbs_auto_page_change",
        content: t`Automatic page change`,
        secondaryContent: t`Will change to the Summit Segments Live page at the start of each climb.`,
        variant: MenuListItemVariant.Action,
        actionComponent: (
          <BlueSwitch
            checked={workoutProfile?.climbs_auto_page_change}
            data-testid="climbs_auto_page_change"
          />
        ),
        action: () => {
          if (workoutProfile) {
            updateProfile(
              workoutProfile.id,
              "climbs_auto_page_change",
              !workoutProfile.climbs_auto_page_change
            );
          }
        },
      },
      {
        id: "climbs_alerts_on_other_pages",
        content: t`Alerts on other pages`,
        secondaryContent: t`Keeps you updated on your climb progress while not on the Summit Segments Live page.`,
        variant: MenuListItemVariant.Action,
        actionComponent: (
          <BlueSwitch
            checked={workoutProfile?.climbs_alerts_on_other_pages}
            data-testid="climbs_alerts_on_other_pages"
          />
        ),
        action: () => {
          if (workoutProfile) {
            updateProfile(
              workoutProfile.id,
              "climbs_alerts_on_other_pages",
              !workoutProfile.climbs_alerts_on_other_pages
            );
          }
        },
      },
      {
        id: "climbs_during_plan",
        content: t`Enabled during planned workouts`,
        variant: MenuListItemVariant.Action,
        actionComponent: (
          <BlueSwitch
            checked={workoutProfile?.climbs_during_plan}
            data-testid="climbs_during_plan"
          />
        ),
        action: () => {
          if (workoutProfile) {
            updateProfile(
              workoutProfile.id,
              "climbs_during_plan",
              !workoutProfile.climbs_during_plan
            );
          }
        },
      },
      {
        id: "climbs_learn_more",
        content: t`Learn more`,
        variant: MenuListItemVariant.ExternalLink,
        linkLocation: learnMoreUrl,
      },
    ],
    [learnMoreUrl, updateProfile, workoutProfile]
  );

  return (
    <ErrorBoundary isLoading={workoutProfilesLoading} error={workoutProfilesError}>
      <Box
        maxWidth="sm"
        width="100%"
        height="100%"
        overflow="scroll"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box margin={1} textAlign="center">
          <Typography variant="prose-sm" color={palette.text.secondary}>
            <Trans>Settings only apply to ELEMNTs that have Summit Segments.</Trans>
          </Typography>
        </Box>
        <Box width="100%">
          <MenuList listItems={firstSection} />
        </Box>
        {workoutProfile?.climbs_enabled && (
          <Box width="100%">
            <MenuList listItems={secondSection} />
          </Box>
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default SummitSegmentSettings;
