const TheDarkPlacePlanGraph = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2936 38"
    preserveAspectRatio="none"
    height="38"
    width="372"
    shapeRendering="crispEdges"
  >
    <rect x="0" y="31.666666666666668" width="21" height="6.333333333333333" fill="#5fdfff"></rect>
    <rect x="21" y="31.03333333333333" width="133" height="6.966666666666667" fill="#5fdfff"></rect>
    <rect x="154" y="30.4" width="69" height="7.6000000000000005" fill="#5fdfff"></rect>
    <rect
      x="223"
      y="29.133333333333333"
      width="117"
      height="8.866666666666667"
      fill="#5fdfff"
    ></rect>
    <rect x="340" y="28.5" width="117" height="9.5" fill="#5fdfff"></rect>
    <rect
      x="457"
      y="27.866666666666667"
      width="90"
      height="10.133333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="547"
      y="20.583333333333336"
      width="31"
      height="17.416666666666664"
      fill="#ffc500"
    ></rect>
    <rect
      x="578"
      y="31.666666666666668"
      width="92"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="670"
      y="29.133333333333333"
      width="61"
      height="8.866666666666667"
      fill="#5fdfff"
    ></rect>
    <rect
      x="731"
      y="27.866666666666667"
      width="30"
      height="10.133333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="761"
      y="17.733333333333334"
      width="10"
      height="20.266666666666666"
      fill="#fb8b04"
    ></rect>
    <rect
      x="771"
      y="31.666666666666668"
      width="61"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect x="832" y="22.8475" width="241" height="15.1525" fill="#ffc500"></rect>
    <rect
      x="1073"
      y="31.666666666666668"
      width="181"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
    <rect
      x="1254"
      y="23.195833333333333"
      width="36"
      height="14.804166666666667"
      fill="#ffc500"
    ></rect>
    <rect
      x="1290"
      y="22.673333333333332"
      width="35"
      height="15.326666666666666"
      fill="#ffc500"
    ></rect>
    <rect
      x="1325"
      y="23.195833333333333"
      width="33"
      height="14.804166666666667"
      fill="#ffc500"
    ></rect>
    <rect
      x="1358"
      y="23.195833333333333"
      width="30"
      height="14.804166666666667"
      fill="#ffc500"
    ></rect>
    <rect
      x="1388"
      y="22.673333333333332"
      width="17"
      height="15.326666666666666"
      fill="#ffc500"
    ></rect>
    <rect
      x="1405"
      y="22.325000000000003"
      width="47"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect x="1452" y="21.8025" width="40" height="16.1975" fill="#ffc500"></rect>
    <rect x="1492" y="32.3" width="182" height="5.7" fill="#5fdfff"></rect>
    <rect
      x="1674"
      y="21.628333333333334"
      width="19"
      height="16.371666666666666"
      fill="#ffc500"
    ></rect>
    <rect
      x="1693"
      y="23.544166666666666"
      width="105"
      height="14.455833333333334"
      fill="#ffc500"
    ></rect>
    <rect
      x="1798"
      y="23.195833333333333"
      width="20"
      height="14.804166666666667"
      fill="#ffc500"
    ></rect>
    <rect
      x="1818"
      y="22.673333333333332"
      width="41"
      height="15.326666666666666"
      fill="#ffc500"
    ></rect>
    <rect
      x="1859"
      y="21.976666666666667"
      width="43"
      height="16.023333333333333"
      fill="#ffc500"
    ></rect>
    <rect
      x="1902"
      y="17.733333333333334"
      width="15"
      height="20.266666666666666"
      fill="#fb8b04"
    ></rect>
    <rect x="1917" y="32.3" width="181" height="5.7" fill="#5fdfff"></rect>
    <rect
      x="2098"
      y="23.195833333333333"
      width="22"
      height="14.804166666666667"
      fill="#ffc500"
    ></rect>
    <rect
      x="2120"
      y="22.150833333333335"
      width="19"
      height="15.849166666666665"
      fill="#ffc500"
    ></rect>
    <rect
      x="2139"
      y="23.02166666666667"
      width="15"
      height="14.978333333333333"
      fill="#ffc500"
    ></rect>
    <rect
      x="2154"
      y="22.673333333333332"
      width="18"
      height="15.326666666666666"
      fill="#ffc500"
    ></rect>
    <rect
      x="2172"
      y="23.195833333333333"
      width="12"
      height="14.804166666666667"
      fill="#ffc500"
    ></rect>
    <rect
      x="2184"
      y="22.325000000000003"
      width="22"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect
      x="2206"
      y="23.195833333333333"
      width="13"
      height="14.804166666666667"
      fill="#ffc500"
    ></rect>
    <rect
      x="2219"
      y="22.325000000000003"
      width="27"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect
      x="2246"
      y="24.06666666666667"
      width="13"
      height="13.933333333333332"
      fill="#ffc500"
    ></rect>
    <rect
      x="2259"
      y="22.673333333333332"
      width="15"
      height="15.326666666666666"
      fill="#ffc500"
    ></rect>
    <rect
      x="2274"
      y="27.233333333333334"
      width="15"
      height="10.766666666666666"
      fill="#5fdfff"
    ></rect>
    <rect
      x="2289"
      y="22.325000000000003"
      width="54"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect x="2343" y="32.3" width="179" height="5.7" fill="#5fdfff"></rect>
    <rect
      x="2522"
      y="24.06666666666667"
      width="17"
      height="13.933333333333332"
      fill="#ffc500"
    ></rect>
    <rect
      x="2539"
      y="23.544166666666666"
      width="11"
      height="14.455833333333334"
      fill="#ffc500"
    ></rect>
    <rect
      x="2550"
      y="24.06666666666667"
      width="6"
      height="13.933333333333332"
      fill="#ffc500"
    ></rect>
    <rect
      x="2556"
      y="23.544166666666666"
      width="7"
      height="14.455833333333334"
      fill="#ffc500"
    ></rect>
    <rect
      x="2563"
      y="24.06666666666667"
      width="18"
      height="13.933333333333332"
      fill="#ffc500"
    ></rect>
    <rect
      x="2581"
      y="23.544166666666666"
      width="7"
      height="14.455833333333334"
      fill="#ffc500"
    ></rect>
    <rect
      x="2588"
      y="24.06666666666667"
      width="11"
      height="13.933333333333332"
      fill="#ffc500"
    ></rect>
    <rect
      x="2599"
      y="23.544166666666666"
      width="6"
      height="14.455833333333334"
      fill="#ffc500"
    ></rect>
    <rect
      x="2605"
      y="24.06666666666667"
      width="14"
      height="13.933333333333332"
      fill="#ffc500"
    ></rect>
    <rect
      x="2619"
      y="23.544166666666666"
      width="8"
      height="14.455833333333334"
      fill="#ffc500"
    ></rect>
    <rect
      x="2627"
      y="24.06666666666667"
      width="18"
      height="13.933333333333332"
      fill="#ffc500"
    ></rect>
    <rect
      x="2645"
      y="23.544166666666666"
      width="19"
      height="14.455833333333334"
      fill="#ffc500"
    ></rect>
    <rect
      x="2664"
      y="24.06666666666667"
      width="12"
      height="13.933333333333332"
      fill="#ffc500"
    ></rect>
    <rect
      x="2676"
      y="23.544166666666666"
      width="8"
      height="14.455833333333334"
      fill="#ffc500"
    ></rect>
    <rect
      x="2684"
      y="23.195833333333333"
      width="3"
      height="14.804166666666667"
      fill="#ffc500"
    ></rect>
    <rect
      x="2687"
      y="22.673333333333332"
      width="10"
      height="15.326666666666666"
      fill="#ffc500"
    ></rect>
    <rect
      x="2697"
      y="23.195833333333333"
      width="42"
      height="14.804166666666667"
      fill="#ffc500"
    ></rect>
    <rect
      x="2739"
      y="22.673333333333332"
      width="4"
      height="15.326666666666666"
      fill="#ffc500"
    ></rect>
    <rect
      x="2743"
      y="22.325000000000003"
      width="9"
      height="15.674999999999999"
      fill="#ffc500"
    ></rect>
    <rect
      x="2752"
      y="12.666666666666668"
      width="11"
      height="25.333333333333332"
      fill="#fb8b04"
    ></rect>
    <rect
      x="2763"
      y="31.666666666666668"
      width="173"
      height="6.333333333333333"
      fill="#5fdfff"
    ></rect>
  </svg>
);
export default TheDarkPlacePlanGraph;
