import { useHeaderContext } from "@/hooks/useHeaderContext";
import { t } from "@lingui/macro";
import { MenuList } from "@WahooFitness/redesignr";
import { Box } from "@mui/system";
import { useEffect, useMemo } from "react";

const DeviceInfo = ({
  firmwareVersion,
  serialNumber,
}: {
  firmwareVersion: string;
  serialNumber: string;
}) => {
  const { setNavHeader } = useHeaderContext();
  const headerProps = useMemo(() => ({ title: t`Device info` }), []);

  useEffect(() => {
    setNavHeader(headerProps);
  });

  return (
    <Box
      maxWidth="sm"
      width="100%"
      height="100%"
      overflow="scroll"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <MenuList
        width="100%"
        listItems={[
          {
            id: "firmware-version",
            content: t`ELEMNT version`,
            secondaryContent: firmwareVersion,
          },
          {
            id: "serial-number",
            content: t`ELEMNT serial number`,
            secondaryContent: serialNumber,
          },
        ]}
      />
    </Box>
  );
};

export default DeviceInfo;
