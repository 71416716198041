import axios from "axios";

export const getWorkoutPagesData = async (url: string): Promise<Buffer> => {
  const cloudDisplayConfigData = (
    await axios.get(url, {
      responseType: "arraybuffer",
    })
  ).data;
  return Buffer.from(cloudDisplayConfigData);
};
