import { Trans } from "@lingui/macro";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { ImperialMetricDisplayPreference, UserType } from "@WahooFitness/cloud-client-ts/Types";
import { MenuListItemType } from "@WahooFitness/redesignr";
import useAboutYou from "../../../../User/AboutYou/useAboutYou.ts";
import DateOfBirthInput from "@/components/User/AboutYou/DateOfBirthInput.tsx";
import SexInput from "@/components/User/AboutYou/SexInput.tsx";
import HeightInput from "@/components/User/AboutYou/HeightInput.tsx";
import WeightInput from "@/components/User/AboutYou/WeightInput.tsx";

interface Props {
  averageTimeListItems: MenuListItemType[];
  user: UserType;
}

function AboutYouQuestions({ user }: Props) {
  const {
    height,
    weight,
    birthDate,
    handleSexChange,
    handleBirthDateChange,
    handleHeightChange,
    handleWeightChange,
  } = useAboutYou(user);

  return (
    <Box display="flex" flexDirection="column" sx={{ mx: 3 }}>
      <Box mx={2} mb={1}>
        <Typography variant="prose-base-medium">
          <Trans>Tell us a bit about yourself so we can estimate your paces.</Trans>
        </Typography>
      </Box>
      <Paper elevation={4} sx={{ p: 2 }}>
        <Grid container columnSpacing={2} rowSpacing={3} pb={2}>
          <Grid item xs={6}>
            <SexInput value={user.gender} onChange={handleSexChange} />
          </Grid>
          <Grid item xs={6}>
            <DateOfBirthInput value={birthDate} onChange={handleBirthDateChange} />
          </Grid>
          <Grid item xs={6} sm={6}>
            <HeightInput
              value={height}
              unitPreference={
                user.display_preference?.speed_distance ?? ImperialMetricDisplayPreference.metric
              }
              onBlur={handleHeightChange}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <WeightInput
              value={weight}
              unitPreference={
                user.display_preference?.weight ?? ImperialMetricDisplayPreference.metric
              }
              onBlur={handleWeightChange}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default AboutYouQuestions;
