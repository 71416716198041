import {
  convertMetersToKilometers,
  convertMetersToYards,
  convertSecondsToMinutes,
  convertYardsToMeters,
  convertYardsToMiles,
} from "@/services/convertUnits";
import { truncateToTwoDecimalPlaces } from "@/services/truncateNumbers";
import { plural, t } from "@lingui/macro";
import {
  ImperialMetricDisplayPreference,
  WorkoutAlertIntervalUnitEnum,
  WorkoutAlertTriggerTypeEnum,
  WorkoutAlertType,
} from "@WahooFitness/cloud-client-ts/Types";
import { useCallback } from "react";

const useAlertTriggers = (unitPreference: ImperialMetricDisplayPreference) => {
  const getDistanceInDisplayPreferenceUnits = useCallback(
    (interval: number, intervalUnit: WorkoutAlertIntervalUnitEnum) => {
      if (
        unitPreference === ImperialMetricDisplayPreference.imperial &&
        intervalUnit === WorkoutAlertIntervalUnitEnum.metric
      ) {
        return convertMetersToYards(interval);
      } else if (
        unitPreference === ImperialMetricDisplayPreference.metric &&
        intervalUnit === WorkoutAlertIntervalUnitEnum.imperial
      ) {
        return convertYardsToMeters(interval);
      }
      return interval;
    },
    [unitPreference]
  );

  const getTriggerSummaryDisplayString = useCallback(
    (alert: WorkoutAlertType) => {
      switch (alert.trigger_type) {
        case WorkoutAlertTriggerTypeEnum.distance: {
          if (!alert.interval || !alert.interval_unit) {
            return t`Invalid interval. Please remake this alert`;
          }
          let distance = getDistanceInDisplayPreferenceUnits(alert.interval, alert.interval_unit);
          if (unitPreference === ImperialMetricDisplayPreference.metric) {
            distance = convertMetersToKilometers(distance);
            return plural(truncateToTwoDecimalPlaces(distance), {
              one: "Every # km",
              other: "Every # kms",
            });
          } else {
            distance = convertYardsToMiles(distance);
            return plural(truncateToTwoDecimalPlaces(distance), {
              one: "Every # mile",
              other: "Every # miles",
            });
          }
        }
        case WorkoutAlertTriggerTypeEnum.duration: {
          if (!alert.interval) {
            return t`Invalid interval. Please remake this alert`;
          }
          const interval = alert.interval;
          const minutes = Math.floor(convertSecondsToMinutes(interval));
          const seconds = interval % 60;
          const secondsSegment = plural(seconds, { one: "# second", other: "# seconds" });
          const minutesSegment = plural(minutes, { one: "# minute", other: "# minutes" });
          if (!minutes) {
            return t`Every ${secondsSegment}`;
          }
          if (!seconds) {
            return t`Every ${minutesSegment}`;
          }
          return t`Every ${minutesSegment} ${secondsSegment}`;
        }
        case WorkoutAlertTriggerTypeEnum.started:
          return t`Workout: Started`;
        case WorkoutAlertTriggerTypeEnum.lap:
          return t`Workout: Lap`;
        case WorkoutAlertTriggerTypeEnum.paused:
          return t`Workout: Paused`;
        case WorkoutAlertTriggerTypeEnum.resumed:
          return t`Workout: Resumed`;
      }
    },
    [unitPreference, getDistanceInDisplayPreferenceUnits]
  );
  return { getTriggerSummaryDisplayString };
};

export default useAlertTriggers;
