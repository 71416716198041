import { useCallback, useEffect, useState } from "react";
import { useWsmPlugin } from "./useWsmPlugin";
import WSMDevice from "@WahooFitness/wsm-native/dist/esm/types/device";

const useBCAutoPair = () => {
  const { sensorManagerPlugin } = useWsmPlugin();
  const [pairing, setPairing] = useState(false);
  const [pairingAttempts, setPairingAttempts] = useState({ count: 0 });
  const maxPairingAttempts = 12;
  const [pairingFailed, setPairingFailed] = useState(false);
  const [resultCallback, setResultCallback] = useState<((device: WSMDevice) => void) | undefined>(
    undefined
  );
  const [appToken, setAppToken] = useState<string | undefined>(undefined);

  const startPairing = useCallback(
    (appToken: string, resultCallback: (WSMDevice: any) => void) => {
      setPairingFailed(false);
      setPairing(true);
      setPairingAttempts({ count: maxPairingAttempts });
      setResultCallback(resultCallback);
      setAppToken(appToken);
      sensorManagerPlugin?.startDeviceDiscovery();
    },
    [sensorManagerPlugin]
  );

  const stopDiscovery = useCallback(() => {
    setPairing(false);
    setPairingAttempts({ count: 0 });
    setResultCallback(undefined);
    setAppToken(undefined);
    sensorManagerPlugin?.stopDeviceDiscovery();
  }, [sensorManagerPlugin]);

  useEffect(() => {
    if (!pairing || pairingAttempts.count <= 0) {
      return () => {};
    }
    const interval = setInterval(async () => {
      const discoveredDevices = (await sensorManagerPlugin?.getDiscoveredDevices())?.devices;
      const discoveredDevice = discoveredDevices?.find((device) => device.appToken === appToken);
      if (discoveredDevice) {
        sensorManagerPlugin?.pairToDevice({ sensorId: discoveredDevice.sensorId });
        resultCallback?.(discoveredDevice);
        stopDiscovery();
      }
      pairingAttempts.count--;
      if (pairingAttempts.count <= 0) {
        stopDiscovery();
        setPairingFailed(true);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [appToken, pairing, pairingAttempts, resultCallback, sensorManagerPlugin, stopDiscovery]);

  return { startPairing, pairingFailed, pairing };
};

export default useBCAutoPair;
