import { useBCFirstRunState } from "@/hooks/useBCFirstRunState";
import { postRoute } from "@WahooFitness/wahoo-offline-mfe";
import { WSMBoltAppFirstRunState } from "@WahooFitness/wsm-native/dist/esm/types/bolt_cfg";
import { useEffect } from "react";
import Bugsnag from "@bugsnag/js";

export const useCheckForOnboardingEffect = (appToken: string) => {
  const { firstRunState } = useBCFirstRunState(appToken);
  useEffect(() => {
    // Ignore a "not done" state, as this is the default state
    if (
      firstRunState?.state !== undefined &&
      ![WSMBoltAppFirstRunState.Complete, WSMBoltAppFirstRunState.NotDone].includes(
        firstRunState.state
      )
    ) {
      Bugsnag.notify(
        new Error(
          `Loaded ELEMNT settings with onboarding set to ${WSMBoltAppFirstRunState[firstRunState.state]}`
        ),
        function (event) {
          event.severity = "warning";
        }
      );
      postRoute("home");
    }
  }, [firstRunState]);
};
