import { useSnackbarContext, useConfigContext } from "@WahooFitness/wahoo-offline-mfe";
import { Alert, AlertColor, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from "@WahooFitness/redesignr";
import { useMemo } from "react";

const GlobalSnackbar: React.FC = () => {
  const {
    snackbar: { open, message = "", severity = "success", enableClickAway = false },
    handleClose,
  } = useSnackbarContext();

  const iconMap: Record<AlertColor, any> = {
    success: <SuccessIcon />,
    info: <InfoIcon />,
    warning: <WarningIcon />,
    error: <ErrorIcon />,
  };

  const hideDuration = useMemo(() => Math.min(4000 + message.length * 50, 10000), [message.length]);

  const { globalBottomPadding } = useConfigContext();

  return (
    <Snackbar
      open={open}
      autoHideDuration={hideDuration}
      onClose={handleClose}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      ClickAwayListenerProps={{
        mouseEvent: enableClickAway ? undefined : false,
        touchEvent: enableClickAway ? undefined : false,
      }}
      sx={{ bottom: globalBottomPadding !== "0" ? globalBottomPadding : undefined }}
    >
      <Alert
        variant="filled"
        sx={{ width: ["100%", "unset"] }}
        icon={iconMap[severity]}
        severity={severity}
        onClose={handleClose}
      >
        <Typography variant="prose-base">{message}</Typography>
      </Alert>
    </Snackbar>
  );
};

export default GlobalSnackbar;
