import PlannedWorkoutSettings from "@/components/WorkoutProfiles/PlannedWorkout/PlannedWorkoutSettings";
import { useParams } from "react-router";

const PlannedWorkoutSettingsPage = () => {
  const params = useParams();

  return <PlannedWorkoutSettings workoutProfileId={+(params.profileId || 0)} />;
};

export default PlannedWorkoutSettingsPage;
