import { Wifi } from "@carbon/icons-react";
import { useTheme } from "@mui/material";
import {
  WifiSignalStrength1Icon,
  WifiSignalStrength2Icon,
  WifiSignalStrength3Icon,
} from "@WahooFitness/redesignr";

// These boundaries are arbitrary and copied from BACA. Feel free
// to update with something more appropriate.
const WifiIcon = ({ signalLevel }: { signalLevel: number }) => {
  const { palette } = useTheme();
  if (signalLevel <= 20) {
    return <Wifi size={24} color={palette.success.main} opacity={0.2} />;
  }
  if (signalLevel <= 50) {
    return <WifiSignalStrength1Icon sx={{ color: palette.success.main }} />;
  }
  if (signalLevel <= 100) {
    return <WifiSignalStrength2Icon sx={{ color: palette.success.main }} />;
  }
  if (signalLevel <= 175) {
    return <WifiSignalStrength3Icon sx={{ color: palette.success.main }} />;
  }
  return <Wifi size={24} color={palette.success.main} />;
};

export { WifiIcon };
